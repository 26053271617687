import goodsOwnStore from './own'
import goodsJdStore from './jd'
import goodsResourceStore from './resource'
import goodsDetailStore from "./detail"
import goodsModifyStore from "./modify"

export default {
  goodsOwnStore,
  goodsJdStore,
  goodsResourceStore,
  goodsDetailStore,
  goodsModifyStore
}
