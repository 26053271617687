import React, { useState, useEffect } from 'react';
import { Input, Row, Col, Cascader, Space, Button, Radio, Checkbox, message } from "antd";
import lodash from "lodash";
import { withRouter } from 'react-router-dom'
import { Modals } from "components"
import { getQuery, showMoney } from "utils/tools";
import { judgeNum } from "utils/validate";
import stores from 'stores'
import styles from './index.module.scss'

const { commonStore, storeLocationStore, storeFreightStore } = stores


const { StoreChooseAreaModal, StoreChooseAddressModal } = Modals
const CheckboxGroup = Checkbox.Group;

const DELIVER_MAP = [{
  label: "快递",
  value: 1
}, {
  label: "物流",
  value: 2
}, {
  label: "运费可议",
  value: 3
},]

function Add(props) {

  const [pageType, setPageType] = useState("add");//add | edit
  const [name, setName] = useState("");//名称
  const [from, setFrom] = useState([]);//发货地
  const [locationList, setLocationList] = useState([]);//三级地址数据
  const [retLocation, setRetLocation] = useState({});//退货地址
  const [showChooseAddress, setShowChooseAddress] = useState(false)
  const [deliverType, setDeliverType] = useState([]);//运送方式
  const [freeShip, setFreeShip] = useState(0);//包邮
  const [chargeType, setChargeType] = useState(1);//计费方式
  const [showFreightAreaModal, setShowFreightAreaModal] = useState(false);//选择区域弹窗
  const [changeAreaIndex, setChangeAreaIndex] = useState(0);//选择区域弹窗
  const [freightAreaType, setFreightAreaType] = useState("")
  const [areaModalType, setAreaModalType] = useState("able")

  const [freightInput, setFreightInput] = useState([{
    firstNum: '',
    firstMoney: '',
    continueNum: '',
    continueMoney: ''
  },]);//运费规则

  const [refresh, setRefresh] = useState(false)
  useEffect(() => {
    localStorage.removeItem("freightArea")
    let query = getQuery();
    if (query.id) {
      setPageType("edit")
      _initEditInfo(query.id)
    } else {
      setPageType("add")
      _initLocation();
      _initAddress();
    }
  }, [])
  useEffect(() => {
    refresh && setTimeout(() => setRefresh(false));
  }, [refresh])

  const _initEditInfo = async (id) => {
    const freightInfo = await storeFreightStore.getFreightDetail({ templateId: id })
    // 名称
    setName(freightInfo.templateName)
    let locationArr = [freightInfo.provinceId, freightInfo.cityId, freightInfo.districtId]
    // 发货地
    setFrom(locationArr)
    _initLocation(locationArr.join("_"));
    // 退货地址
    setRetLocation(freightInfo.returnAddress)
    // 运送方式
    setDeliverType(freightInfo.deliveryType.split(",").map(item => +item))
    // 包邮
    setFreeShip(+freightInfo.isFreeShipping)
    if (!freightInfo.isFreeShipping) {
      // 计费方式
      setChargeType(freightInfo.tradeType)
      // 计费规则
      setFreightInput(freightInfo.rules && freightInfo.rules.length ? freightInfo.rules.map(item => ({
        firstNum: freightInfo.tradeType == 1 ? item.firstNumber : item.firstNumber / 1e6,
        firstMoney: showMoney(item.firstPrice),
        continueNum: freightInfo.tradeType == 1 ? item.nextNumber : item.nextNumber / 1e6,
        continueMoney: showMoney(item.nextPrice)
      })) : [{
        firstNum: '',
        firstMoney: '',
        continueNum: '',
        continueMoney: ''
      },])
      //获取区域数据
      await storeFreightStore.getFreightArea({ dictType: "freightArea" })
      // 取出所有已选的省
      let area = freightInfo.rules && freightInfo.rules.length ? lodash.flatten(freightInfo.rules.map((item, index) => item.province.split(",").map(itemIn => ({
        index,
        id: itemIn
      })))) : [];
      let disabledArea = freightInfo.noDeliveryRegion ? freightInfo.noDeliveryRegion.split(",").map(i => +i) : [];
      let areaArr = JSON.parse(localStorage.getItem("freightArea"))
      areaArr = areaArr && areaArr.length ? areaArr.map(item => {
        item.children = item.children.map(itemIn => {
          let obj = area.find(area => area.id == itemIn.dictKey);
          let disObj=disabledArea.find(id => id == itemIn.dictKey)
          if (obj) {
            itemIn.freightIndex = obj.index
          }
          if(disObj){
            itemIn.freightIndex = -1
          }
          return itemIn;
        })
        let freightIndex = item.children[0].freightIndex;
        // 判断父节点是否需要设置freightIndex
        if (freightIndex) {
          let flag = item.children.every(itemIn => itemIn.freightIndex === freightIndex);
          if (flag) {
            item.freightIndex = freightIndex
          }
        }
        return item
      }) : [];
      localStorage.setItem("freightArea", JSON.stringify(areaArr));
      setRefresh(true)
    }
  }


  const _initLocation = async areaCodeStr => {
    let arr = await commonStore.getLocationLevel({
      parentCode: 0
    });
    let list = []
    if (areaCodeStr) {
      let areaCode = areaCodeStr.split("_")
      let arr1 = await commonStore.getLocationLevel({
        parentCode: areaCode[0]
      });
      let arr2 = await commonStore.getLocationLevel({
        parentCode: areaCode[1]
      });
      list = arr.map(item =>
        +item.id === +areaCode[0] ?
          ({
            label: item.name,
            value: item.id,
            isLeaf: false,
            children: arr1.map(itemIn => +itemIn.id === +areaCode[1] ?
              ({
                label: itemIn.name,
                value: itemIn.id,
                isLeaf: false,
                children: arr2.map(itemInIn => ({
                  label: itemInIn.name,
                  value: itemInIn.id,
                  isLeaf: true
                }))
              }) : ({
                label: itemIn.name,
                value: itemIn.id,
                isLeaf: false
              }))
          }) : ({
            label: item.name,
            value: item.id,
            isLeaf: false
          }))
    } else {
      list = arr.map(item => ({
        label: item.name,
        value: item.id,
        isLeaf: false
      }))
    }
    setLocationList(list)
  }
  const _initAddress = async () => {
    const res = await storeLocationStore.getDefaultLocation({ type: 2 })
    setRetLocation(res)
  }
  const getLocationData = async selectedOptions => {
    console.log(selectedOptions)
    const targetOption = selectedOptions[selectedOptions.length - 1];
    targetOption.loading = true;
    let arr = await commonStore.getLocationLevel({
      parentCode: targetOption.value
    });
    targetOption.children = arr.map(item => ({
      label: item.name,
      value: item.id,
      isLeaf: +item.level === 3
    }))
    targetOption.loading = false;
    setLocationList(locationList)
    setRefresh(true)
  }


  const getAreaNames = (index) => {
    return (localStorage.getItem("freightArea") ?
      lodash.flatten(JSON.parse(localStorage.getItem("freightArea")).map(item => item.children)) :
      [])
      .filter(itemIn => itemIn.freightIndex === index)
      .map(itemIn => itemIn.dictValue).join('，')
  }

  const changeInput = (e, index, type) => {
    let val = e.target.value;
    if (["firstNum", "continueNum"].includes(type)) {
      val = chargeType === 1 ? judgeNum(val, 'num') : judgeNum(val, "weight")
    }
    if (["firstMoney", "continueMoney"].includes(type)) {
      val = judgeNum(val, 'money')
    }
    freightInput[index][type] = val;
    setFreightInput(freightInput)
    setRefresh(true)
  }

  const addAreaFreight = (type) => {
    if (type === "disabled") {

      setChangeAreaIndex(-1)
      setShowFreightAreaModal(true)
      setFreightAreaType(getAreaNames(-1) ? "edit" : "add")
      setAreaModalType("disabled")
    } else {
      freightInput.push({
        firstNum: '',
        firstMoney: '',
        continueNum: '',
        continueMoney: ''
      })

      setFreightInput(freightInput)
      setChangeAreaIndex(freightInput.length - 1)
      setShowFreightAreaModal(true)
      setFreightAreaType("add")
    }

  }

  const editAreaFreight = (index) => {
    setChangeAreaIndex(index)
    setShowFreightAreaModal(true)
    setFreightAreaType("edit")
  }

  const deleteAreaFreight = (index) => {
    let area = JSON.parse(localStorage.getItem("freightArea"))
    area = area.map(item => {
      if (item.freightIndex === index) {
        item.freightIndex = 0
      } else if (item.freightIndex > index) {
        item.freightIndex -= 1
      }
      item.children = item.children.map(itemIn => {
        if (itemIn.freightIndex === index) {
          itemIn.freightIndex = 0
        } else if (itemIn.freightIndex > index) {
          itemIn.freightIndex -= 1
        }
        return itemIn
      })
      return item
    })
    localStorage.setItem("freightArea", JSON.stringify(area))
    freightInput.splice(index, 1)
    setFreightInput(freightInput)
    setRefresh(true)
  }

  const saveFreightTemp = async () => {
    if (!name) {
      message.error("请输入模板名称")
      return;
    }
    if (from.length < 3) {
      message.error("请选择发货地")
      return;
    }
    if (!deliverType.length) {
      message.error("请选择运送方式")
      return;
    }
    if (!retLocation) {
      message.error("请选择退货地址")
      return
    }
    let params = {
      templateName: name,
      provinceId: from[0],
      cityId: from[1],
      districtId: from[2],
      returnAddressId: retLocation.id,//退货地址id
      deliveryType: deliverType.join(","),//运送方式
      freeShipping: !!freeShip,
    }
    if (!freeShip) {
      let boolArr = freightInput.map(item => item.firstNum && item.firstMoney && item.continueNum && item.continueMoney);
      let area = JSON.parse(localStorage.getItem("freightArea"));
      let areaArr = area ? lodash.flatten(area.map(item => item.children)) : [];
      if (!boolArr.every(item => item)) {
        message.error('请输入区域计费方式');
        return;
      }
      if (boolArr.length > 1) {
        let numArr = Array.from(new Array(boolArr.length).keys()).slice(1);
        let flag = numArr.every(item => areaArr.findIndex(itemIn => itemIn.freightIndex === item) > -1)
        if (!flag) {
          message.error('请选择计费方式的区域');
          return;
        }
      }
      let rules = freightInput.map((item, index) => {
        let province = '';
        if (index > 0) {
          province = areaArr.filter(area => area.freightIndex === index).map(area => area.dictKey).join(',')
        }
        return {
          firstNumber: chargeType === 1 ? item.firstNum : item.firstNum * 1e6,
          firstPrice: item.firstMoney * 1e2,
          nextNumber: chargeType === 1 ? item.continueNum : item.continueNum * 1e6,
          nextPrice: item.continueMoney * 1e2,
          isDefault: +!(index ^ 0),
          province
        }
      });
      let noDeliveryRegion = areaArr.filter(area => area.freightIndex === -1).map(area => area.dictKey).join(',')
      params = Object.assign(params, {
        tradeType: chargeType,
        noDeliveryRegion:noDeliveryRegion||undefined,
        rules
      })
    }

    // 请求接口
    let res;
    if (pageType === "edit") {
      let query = getQuery()
      params = Object.assign(params, {
        freightTemplateId: query.id
      })
      res = await storeFreightStore.updateFreight(params)
      if (res.success) {
        message.success("修改成功")
        setTimeout(() => {
          props.history.push({
            pathname: "/setting/freight"
          })
        }, 1000)
      }
    } else {
      res = await storeFreightStore.addFreight(params)
      if (res.success) {
        message.success("添加成功")
        setTimeout(() => {
          props.history.push({
            pathname: "/setting/freight"
          })
        }, 1000)
      }
    }
  }
  return (
    <div className={styles.freight_edit_container}>
      <Row>
        <Col span={3} style={{ display: "flex", justifyContent: "flex-end", paddingRight: 20 }}>
          <span className={styles.line_title}>模板名称:</span>
        </Col>
        <Col span={21}>
          <div className={styles.line_content}>
            <Input style={{ width: 250 }}
              allowClear={true}
              placeholder="请输入模板名称"
              onChange={e => setName(e.target.value.trim())}
              value={name} />
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={3} style={{ display: "flex", justifyContent: "flex-end", paddingRight: 20 }}>
          <span className={styles.line_title}>发货地:</span>
        </Col>
        <Col span={21}>
          <div className={styles.line_content}>
            <Cascader value={from}
              onChange={val => {
                setFrom(val)
              }}
              allowClear
              changeOnSelect
              options={locationList}
              loadData={getLocationData}
              style={{ width: 250 }}
              placeholder="请选择发货地" />
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={3} style={{ display: "flex", justifyContent: "flex-end", paddingRight: 20 }}>
          <span className={styles.line_title}>退货地址:</span>
        </Col>
        <Col span={21}>
          <div className={styles.line_content}>
            <Space>
              <span>{retLocation ? `${retLocation.fullAddress} ${retLocation.consignee}${retLocation.mobileNumber ? "，" + retLocation.mobileNumber : ""}${retLocation.landlineNumber ? "，" + retLocation.landlineNumber : ""} ` : "您没有添加收货地址或收货地址已删除"}</span>
              <Button type="primary" onClick={() => setShowChooseAddress(true)}>{"更换退货地址"}</Button></Space>
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={3} style={{ display: "flex", justifyContent: "flex-end", paddingRight: 20 }}>
          <span className={styles.line_title}>运送方式:</span>
        </Col>
        <Col span={21}>
          <div className={styles.line_content}>
            <CheckboxGroup
              options={DELIVER_MAP}
              value={deliverType}
              onChange={list => setDeliverType(list)}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={3} style={{ display: "flex", justifyContent: "flex-end", paddingRight: 20 }}>
          <span className={styles.line_title}>是否包邮:</span>
        </Col>
        <Col span={21}>
          <div className={styles.line_content}>
            <Radio.Group style={{ lineHeight: "60px" }} onChange={e => setFreeShip(e.target.value)} value={freeShip}>
              <Radio value={0} key={0}>自定义运费</Radio>
              <Radio value={1} key={1}>卖家承担运费</Radio>
            </Radio.Group>
          </div>
        </Col>
      </Row>
      {!+freeShip &&
        <>
          <Row>
            <Col span={3} style={{ display: "flex", justifyContent: "flex-end", paddingRight: 20 }}>
              <span className={styles.line_title}>计费方式:</span>
            </Col>
            <Col span={21}>
              <div className={styles.line_content}>
                <Radio.Group style={{ lineHeight: "60px" }} onChange={e => setChargeType(e.target.value)}
                  value={chargeType}>
                  <Radio value={1} key={1}>按件数</Radio>
                  <Radio value={2} key={2}>按重量</Radio>
                </Radio.Group>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={21} offset={3}>
              <div className={styles.freight_input_container}>
                <div className={styles.global_freight_container}>
                  {chargeType === 1 ? <p className={styles.global_freight}>默认运费
                    <Input className={styles.global_input} onChange={(e) => changeInput(e, 0, 'firstNum')}
                      value={freightInput[0].firstNum} placeholder="首件" /> 件内,
                    <Input className={styles.global_input} onChange={(e) => changeInput(e, 0, 'firstMoney')}
                      value={freightInput[0].firstMoney} placeholder="首费" /> 元, 每增加
                    <Input className={styles.global_input} onChange={(e) => changeInput(e, 0, 'continueNum')}
                      value={freightInput[0].continueNum} placeholder="续件" /> 件,增加运费
                    <Input className={styles.global_input} onChange={(e) => changeInput(e, 0, 'continueMoney')}
                      value={freightInput[0].continueMoney} placeholder="续费" /> 元</p> :
                    <p className={styles.global_freight}>默认运费
                    <Input className={styles.global_input} onChange={(e) => changeInput(e, 0, 'firstNum')}
                        value={freightInput[0].firstNum} placeholder="首重" /> 公斤内,
                    <Input className={styles.global_input} onChange={(e) => changeInput(e, 0, 'firstMoney')}
                        value={freightInput[0].firstMoney} placeholder="首费" /> 元, 每增加
                    <Input className={styles.global_input} onChange={(e) => changeInput(e, 0, 'continueNum')}
                        value={freightInput[0].continueNum} placeholder="续重" /> 公斤,增加运费
                    <Input className={styles.global_input} onChange={(e) => changeInput(e, 0, 'continueMoney')}
                        value={freightInput[0].continueMoney} placeholder="续费" /> 元</p>
                  }
                  <p className={styles.global_tip}>* 除指定地区外，其余地区的运费采用“默认运费”</p>
                </div>

                {freightInput.length > 1 ?
                  <div className={styles.part_freight_container}>
                    <div className={styles.part_freight_title}>
                      {chargeType === 1 ?
                        <Row style={{ backgroundColor: "#f0f0f0" }}>
                          <Col span={6} className={styles.part_freight_title_text}>运送到</Col>
                          <Col span={4} className={styles.part_freight_title_text}>首件(件)</Col>
                          <Col span={4} className={styles.part_freight_title_text}>首费(元)</Col>
                          <Col span={4} className={styles.part_freight_title_text}>续件(件)</Col>
                          <Col span={4} className={styles.part_freight_title_text}>续费(元)</Col>
                          <Col span={2} className={styles.part_freight_title_text}>操作</Col>
                        </Row> :
                        <Row style={{ backgroundColor: "#f0f0f0" }}>
                          <Col span={6} className={styles.part_freight_title_text}>运送到</Col>
                          <Col span={4} className={styles.part_freight_title_text}>首重(公斤)</Col>
                          <Col span={4} className={styles.part_freight_title_text}>首费(元)</Col>
                          <Col span={4} className={styles.part_freight_title_text}>续重(公斤)</Col>
                          <Col span={4} className={styles.part_freight_title_text}>续费(元)</Col>
                          <Col span={2} className={styles.part_freight_title_text}>操作</Col>
                        </Row>}
                    </div>
                    <div className={styles.part_freight_content}>
                      {freightInput.map((item, index) => {
                        if (index > 0) {
                          return (
                            <Row className={styles.content_row} key={index}>
                              <Col span={6} className={styles.content_cell}>
                                <div className={styles.area_container}>
                                  <p
                                    className={styles.area_names}>{getAreaNames(index)
                                    }</p>
                                  <span onClick={() => editAreaFreight(index)}
                                    className={styles.area_edit_btn}>编辑</span>
                                </div>
                              </Col>
                              <Col span={4} className={styles.content_cell}>
                                <Input className={styles.cell_input} placeholder={chargeType === 1 ? "件数" : "重量"}
                                  onChange={(e) => changeInput(e, index, 'firstNum')} value={item.firstNum} />
                              </Col>
                              <Col span={4} className={styles.content_cell}>
                                <Input className={styles.cell_input} placeholder="金额"
                                  onChange={(e) => changeInput(e, index, 'firstMoney')}
                                  value={item.firstMoney} />
                              </Col>
                              <Col span={4} className={styles.content_cell}>
                                <Input className={styles.cell_input} placeholder={chargeType === 1 ? "件数" : "重量"}
                                  onChange={(e) => changeInput(e, index, 'continueNum')}
                                  value={item.continueNum} />
                              </Col>
                              <Col span={4} className={styles.content_cell}>
                                <Input className={styles.cell_input} placeholder="金额"
                                  onChange={(e) => changeInput(e, index, 'continueMoney')}
                                  value={item.continueMoney} />
                              </Col>
                              <Col span={2} className={styles.content_cell}>
                                <span className={styles.table_action_btn}
                                  onClick={() => deleteAreaFreight(index)}>删除</span>
                              </Col>
                            </Row>
                          )
                        } else {
                          return ''
                        }
                      })}
                    </div>
                  </div> : ''}
                <Button type="link"
                  style={{ lineHeight: '40px', width: 200 }} onClick={addAreaFreight}>+ 为指定地区设置运费</Button>


                <div className={styles.global_freight_container} style={{
                  lineHeight: "40px",
                  marginTop: 8
                }}>
                  <Button type="link" onClick={() => addAreaFreight("disabled")}>+ 设置不配送地区</Button>
                  <p className={styles.global_tip}>* 设置不配送地区，用户下单时，收货地址选择该区域时，无法提交订单</p>
                </div>
                <div className={styles.disabled_area_container}>{getAreaNames(-1)}</div>
              </div>
            </Col>
          </Row>
        </>}

      <Row>
        <Col span={21} offset={3}>
          <Button type="primary"
            style={{ width: 100, marginTop: 20 }}
            onClick={saveFreightTemp}>保 存</Button>
        </Col>
      </Row>

      {showChooseAddress &&
        <StoreChooseAddressModal visible={showChooseAddress}
          onCancel={() => setShowChooseAddress(false)}
          onOk={(item) => {
            setRetLocation(item)
          }} value={retLocation} />}
      {showFreightAreaModal &&
        <StoreChooseAreaModal visible={showFreightAreaModal}
          modalType={areaModalType}
          type={freightAreaType}
          freightIndex={changeAreaIndex}
          onOk={() => {
          }}
          onCancel={() => {
            setShowFreightAreaModal(false)
          }} />}
    </div>
  );
}

export default withRouter(Add);
