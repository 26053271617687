import { observable, action, runInAction, toJS } from "mobx";
import httpClient from "utils/httpClient";
import api from "config/api"
import { addKey } from "utils/tools";

const { goods } = api;

class ModifyStore {

  @observable modifyList = { list: [], total: 0 }

  /**
   * 申请变更
   * @param {*} params 
   */
  @action
  requestModify = async params => {
    const res = await httpClient.post(goods.POST_REQUEST_MODIFY, params);
    return res
  }

  /**
  * 获取变更列表
  * @param {*} params 
  */
  @action
  getModifyList = async params => {
    const res = await httpClient.post(goods.POST_GET_MODIFY_LIST, params);
    if (res.success) {
      this.modifyList = addKey(res.data);
    }
    return this.modifyList;
  }

  /**
  * 获取变更详情
  * @param {*} params 
  */
  @action
  getModifyDetail = async params => {
    const res = await httpClient.post(goods.POST_GET_MODIFY_DETAIL, params);
    if (res.success) {
      return res.data
    }
  }

  /**
  * 校验是否参加活动
  * @param {*} params 
  */
  @action
  checkIsInActivity = async params => {
    const res = await httpClient.post(goods.POST_CHECK_IS_IN_ACTIVITY, params);
    return res
  }


}

export default new ModifyStore();