import React, { useEffect, useState } from 'react';
import { Modal, Input, Button, Form, Cascader, message, Checkbox } from 'antd';
import PropTypes from 'prop-types';
import stores from 'stores';
import styles from './index.module.scss';

const { Item } = Form;
const { commonStore, storeLocationStore } = stores;

function AddAddress(props) {
  const { visible, onOk, onCancel, type, item } = props;
  const [locationList, setLocationList] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    console.log('============', typeof commonStore.getLocationList);
    _initLocation(type);
    if (type === 'edit') {
      let areaCodeArr = item.areaCode
        .split('_')
        .map((item) => +item)
        .filter((item, index) => index < 3);
      form.setFieldsValue({
        name: item.consignee,
        location: areaCodeArr,
        address: item.address,
        phone: item.mobileNumber,
        tag: item.tag ? [1] : [],
        tel: item.landlineNumber,
      });
    }
  }, []);
  useEffect(() => {
    refresh && setTimeout(() => setRefresh(false));
  }, [refresh]);

  const _initLocation = async (type) => {
    console.log('type======', item);
    let arr = await commonStore.getLocationLevel({
      parentCode: 0,
    });
    let list = [];
    if (type === 'edit') {
      let areaCode = item.areaCode.split('_');
      let arr1 = await commonStore.getLocationLevel({
        parentCode: areaCode[0],
      });
      let arr2 = await commonStore.getLocationLevel({
        parentCode: areaCode[1],
      });
      list = arr.map((item) =>
        +item.id === +areaCode[0]
          ? {
              label: item.name,
              value: item.id,
              isLeaf: false,
              children: arr1.map((itemIn) =>
                +itemIn.id === +areaCode[1]
                  ? {
                      label: itemIn.name,
                      value: itemIn.id,
                      isLeaf: false,
                      children: arr2.map((itemInIn) => ({
                        label: itemInIn.name,
                        value: itemInIn.id,
                        isLeaf: true,
                      })),
                    }
                  : {
                      label: itemIn.name,
                      value: itemIn.id,
                      isLeaf: false,
                    }
              ),
            }
          : {
              label: item.name,
              value: item.id,
              isLeaf: false,
            }
      );
      console.log('list=======', list);
    } else {
      list = arr.map((item) => ({
        label: item.name,
        value: item.id,
        isLeaf: false,
      }));
    }
    setLocationList(list);
  };

  const getLocationData = async (selectedOptions) => {
    console.log(selectedOptions);
    const targetOption = selectedOptions[selectedOptions.length - 1];
    targetOption.loading = true;
    let arr = await commonStore.getLocationLevel({
      parentCode: targetOption.value,
    });
    targetOption.children = arr.map((item) => ({
      label: item.name,
      value: item.id,
      isLeaf: +item.level === 3,
    }));
    targetOption.loading = false;
    setLocationList(locationList);
    setRefresh(true);
  };

  const confirm = () => {
    form
      .validateFields()
      .then(async (values) => {
        console.log('values==========', values);
        let provinceObj = locationList.find(
          (item) => item.value === values.location[0]
        );
        let cityObj = provinceObj.children.find(
          (item) => item.value === values.location[1]
        );
        let districtObj = cityObj.children.find(
          (item) => item.value === values.location[2]
        );

        let params = {
          consignee: values.name.trim(),
          province: provinceObj.label,
          city: cityObj.label,
          district: districtObj.label,
          areaCode: values.location.join('_') + '_0',
          address: values.address.trim(),
          mobileNumber: values.phone,
          landlineNumber: values.tel,
          tag: values.tag ? values.tag.length : 0,
          type: 2,
        };
        console.log(params);
        let res =
          type === 'edit'
            ? await storeLocationStore.updateLocation(
                Object.assign(params, { id: item.id })
              )
            : await storeLocationStore.addLocation(params);
        if (res.success) {
          message.success(type === 'edit' ? '修改成功！' : '增加成功！');
          onCancel(true);
        }
      })
      .catch((errors) => {
        console.log(errors);
      });
  };
  return (
    <Modal
      visible={visible}
      centered
      footer={
        <div className={styles.modal_footer}>
          <Button
            type="primary"
            style={{ width: 100 }}
            onClick={() => confirm()}
          >
            保存
          </Button>
        </div>
      }
      title={type === 'add' ? '新增地址' : '编辑地址'}
      onCancel={onCancel}
    >
      <Form labelCol={{ span: 6 }} wrapperCol={{ span: 16 }} form={form}>
        <Item
          label="联系人"
          name="name"
          rules={[
            { required: true, message: '请输入联系人', whitespace: true },
            {
              type: 'string',
              min: 2,
              max: 12,
              message: '联系人限制2-12字符',
              whitespace: true,
            },
          ]}
        >
          <Input maxLength={12} />
        </Item>
        <Item
          label="所在地区"
          name="location"
          rules={[{ required: true, type: 'array', message: '请选择地区' }]}
        >
          <Cascader
            changeOnSelect
            options={locationList}
            loadData={getLocationData}
            placeholder="请选择地址"
          />
        </Item>
        <Item
          label="详细地址"
          name="address"
          rules={[
            { required: true, message: '请输入详细地址', whitespace: true },
            {
              type: 'string',
              min: 4,
              max: 80,
              message: '详细地址限制4-80字符',
              whitespace: true,
            },
          ]}
        >
          <Input.TextArea maxLength={80} />
        </Item>
        <Item
          label="手机号"
          dependencies={['tel']}
          name="phone"
          rules={[
            { pattern: /^1[0-9]{10}$/, message: '请输入正确的手机号' },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (value || getFieldValue('tel')) {
                  return Promise.resolve();
                }
                return Promise.reject('请输入手机号或者电话号码');
              },
            }),
          ]}
        >
          <Input maxLength={11} />
        </Item>
        <Item
          label="电话号码"
          dependencies={['phone']}
          name="tel"
          rules={[
            { whitespace: true },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (value || getFieldValue('phone')) {
                  return Promise.resolve();
                }
                return Promise.reject('请输入手机号或者电话号码');
              },
            }),
          ]}
        >
          <Input />
        </Item>
        <Item name="tag" wrapperCol={{ span: 16, offset: 6 }}>
          <Checkbox.Group
            options={[{ label: '设为默认地址', value: 1 }]}
          ></Checkbox.Group>
        </Item>
      </Form>
    </Modal>
  );
}

AddAddress.propTypes = {
  visible: PropTypes.bool.isRequired,
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['add', 'edit']),
  item: PropTypes.object,
};

export default AddAddress;
