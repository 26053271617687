import React, { useState } from 'react'
import styles from './index.module.scss'
import { Form, Input, Button, Space, Modal, Radio,Table } from 'antd'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { showMoney } from 'utils/tools'
import ActNumComp from '../actNumComp/index'
const { TextArea } = Input

function RebateApply() {
  const [form2] = Form.useForm()
  const [form2Data, setForm2Data] = useState([])
  const [selVal, setSelVal] = useState(1)
  const [actNumVis,setActNumVis] = useState(false)
  const handleSubmit = async () => {
    try {
      const data = await form2.getFieldsError()
      console.log(data)
    } catch (e) {
      console.log(e)
    }
    // const data = await form2.getFieldValue()
    // console.log(data, '===')
  }
  const handleCheck = (rule, value, callback, type, index) => {
    const _data = [...form2.getFieldValue().names]
    const currentData = _data[index]
    //判断是否为数字
    if (isNaN(value)) {
      if (type == 1) {
        currentData['money'] = ''
      } else {
        currentData['back'] = ''
      }
      form2.setFieldsValue({
        names: _data,
      })
      return Promise.reject('必须为数字')
    }

    //判断当前输入是否大于前一档输入金额和返点,小于后一档输入金额和返点
    if (_data[index - 1] !== undefined) {
      if (type == 1) {
        if (Number(_data[index - 1].money) > Number(_data[index].money)) {
          return Promise.reject('不能小于前一档,不能高于后一档')
        }
      } else if (type == 2) {
        if (Number(_data[index - 1].back) > Number(_data[index].back)) {
          return Promise.reject('不能小于前一档,不能高于后一档')
        }
      }
    }
    if (_data[index + 1] != undefined) {
      if (type == 1) {
        if (Number(_data[index].money) > Number(_data[index + 1].money)) {
          return Promise.reject('不能小于前一档,不能高于后一档')
        }
      } else if (type == 2) {
        if (Number(_data[index].back) > Number(_data[index + 1].back)) {
          return Promise.reject('不能小于前一档,不能高于后一档')
        }
      }
    }
    
    console.log(type,currentData,"===")
    //判断是否返点50%，50%弹窗提示
    if (type == 2) {
      if (Number(currentData['back']) >= 50) {
        Modal.confirm({
          title: '温馨提示',
          content: (
            <div style={{ textAlign: 'center', width: '100%' }}>
              <div style={{ fontWeight: 'bold' }}>确认返点 50% ？</div>
              <p style={{ margin: '14px 0 5px 0' }}>
                同一个客户在活动时间内订购指定商品订单
              </p>
              <p style={{ margin: '0 0 5px 0' }}>
                满 <em>80000</em>元以上，返现<em>50%</em>，即返现<em>4万</em>
                元以上，
              </p>
              <p style={{ margin: '0' }}>
                活动生效后不可修改，请确认能否承受返点比例！
              </p>
            </div>
          ),
        })
        return Promise.resolve()
      }
    }

    // if(value > 2.5) {
    //   return Promise.reject('不得超过2.5')
    // }
    setForm2Data(_data)
    return Promise.resolve()
  }

  //最高返现校验
  const topBackCheck = (rule, value, callback) => {
    const lastData = form2Data[form2Data.length - 1]
    if (isNaN(value)) {
      return Promise.reject('必须为数字!!')
    }
    if (!value) {
      return Promise.reject('最高返现金额不能为空!!')
    }
    if (lastData != undefined) {
      const topBackMoney = Number((lastData.money * lastData.back) / 100)
      if (value < topBackMoney) {
        return Promise.reject('最高返现金额不得低于最高档的返现金额!!')
      }
    } else {
      return Promise.reject('活动力度未填写!!')
    }
    return Promise.resolve()
  }
  const selChange = (e) => {
    setSelVal(e.target.value)
  }

  const inputBlur = (e, record, type) => {
    // const val = e.target.value
    // const _data = [...data]
    // const index = _data.findIndex((item) => item.id === record.id)
    // const target = _data[index]
    // //是否数字 大于0
    // const isLimit = isNaN(val) || val < 0
    // if (type == 1) {
    //   _data.splice(index, 1, { ...target, num: isLimit ? '' : val })
    // } else {
    //   _data.splice(index, 1, { ...target, price: isLimit ? '' : val })
    // }
    // setData(_data)
  }

  const handleOk = () => {

  }
  const handleCancel = () => {

  }

  const inputClick = () => {
    return false
  }

  const columns = [
    {
      title: '商品名称',
      dataIndex: 'name',
      render: () => (
        <div>1</div>
        // <div className = 'fx-flexbox'>
        //   <img src = {record.mainImage} style = {{width:'100px',height:'73px'}} />
        //   <div style={{marginLeft:'15px'}}>
        //     <p className='fx-ellipsis-row' style={{width:'230px',color:'#1890ff'}}>{record.name}</p>
        //     <p>{record.categoryTreeName}</p>
        //   </div>
        // </div>
      ),
    },
    {
      title: '市场价',
      dataIndex: 'market',
      render: (info, record) => {
        return (
        <div>{ showMoney(info) }</div>
        )
      },
    },
    {
      title: '销售价',
      dataIndex: 'sales',
      render: (info, record) => {
        return (
        <div>{ showMoney(info) }</div>
        )
      },
    },
    {
      title: '享金',
      dataIndex: 'enjoy',
      render: (info, record) => {
        return (
        <div>{ showMoney(info) }</div>
        )
      },
    },
    {
      title: '活动数量',
      dataIndex: 'price',
      // key: 'price',
      render: (info, record) => {
        return (
          <Input
            key={info}
            style={{ width: '120px' }}
            onClick={ inputClick }
            // onBlur={(e) => {
            //   inputBlur(e, record, 2)
            // }}
            defaultValue={info}
          />
        )
      },
    },
    {
      title: '操作',
      key: 'action',
      render: () => <div style={{ color: 'blue' }}>移除</div>,
    },
  ]

  const testData = [
    {
      name:'11',
      market:'22',
      sales:'33',
      enjoy:'44',
      price:'55'
    }
  ]

  const modalData = [
    {type:'移动4g',color:'红色',momory:'64g',nowMomory:2,num:19},
    {type:'移动4g',color:'黄色',momory:'64g',nowMomory:80,num:39}
  ]


  return (
    <div className={styles.rebateApplyContent}>
      <div className={styles.desc}>
        <div className={styles.title}>活动介绍</div>
        <div className={styles.info}>
          <div>活动名称： 返点会场-高佣金</div>
          <div>预热时间： 2020-04-15 19:00:00～2020-04-18 18:59:59</div>
          <div>活动时间： 2020-04-15 19:00:00～2020-04-18 18:59:59</div>
          <div
            style={{ display: 'flex', alignItems: 'flex-start', color: 'red' }}
          >
            <span>报名说明： </span>
            <span
              style={{
                display: 'inline-block',
                width: '200px',
                wordWrap: 'break-word',
              }}
            >
              xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
            </span>
          </div>
        </div>
      </div>

      <div className={styles.actInfo}>
        <div className={styles.title} style={{ marginBottom: '30px' }}>
          填写活动资料
        </div>
        <Form style={{ marginLeft: '50px' }} form={form2}>
          <Form.Item label="会场名称" name="name" rules={[{ required: true, message: '请填写会场名称!' }]}>
            <Input style={{ width: '300px' }} />
          </Form.Item>
          <Form.Item label="会场卖点" required>
            <div style={{ display: 'flex' }}>
              <Form.Item name="test1" rules={[{ required: true, message: '请填写会场卖点说明!' }]}>
                <Input
                  style={{ width: '300px' }}
                  maxLength={6}
                  placeholder="最多6个字符"
                />
              </Form.Item>
              <Form.Item name="test2" style={{ marginLeft: '10px' }} rules={[{ required: true, message: '请填写会场卖点说明!' }]}>
                <Input
                  style={{ width: '300px' }}
                  maxLength={6}
                  placeholder="最多6个字符"
                />
              </Form.Item>
            </div>
          </Form.Item>
          <Form.Item label="会场说明" name='desc' rules={[{ required: true, message: '请填写会场卖点说明!' }]}>
            <TextArea
              rows={4}
              style={{ width: '700px' }}
              maxLength={200}
              placeholder="最多可输入200个字"
            />
          </Form.Item>

          <Form.Item label="发货说明">
            <TextArea
              rows={3}
              style={{ width: '700px' }}
              maxLength={100}
              placeholder="最多可输入100个字"
            />
          </Form.Item>

          <Form.Item label="服务说明">
            <TextArea
              rows={3}
              style={{ width: '700px' }}
              maxLength={100}
              placeholder="最多可输入100个字"
            />
          </Form.Item>

          <Form.Item label="会场背景图"></Form.Item>
        </Form>
        <div className={styles.actDiscounts}>
          <div className={styles.title} style={{ marginBottom: '20px' }}>
            活动力度
          </div>
          <div style={{ marginLeft: '50px' }}>
            <Form
              name="dynamic_form_item"
              form={form2}
              initialValues={{
                names: [
                  {
                    money: '',
                    back: '',
                    // name:0,
                    // fieldKey:0,
                    // isListField: true,
                    // key:0,
                    // name:0,
                  },
                ],
              }}
            >
              <Form.List name="names">
                {(fields, { add, remove }) => {
                  return (
                    <div>
                      {fields.map((field, index) => (
                        <Space
                          key={field.key}
                          style={{ display: 'flex', marginBottom: 8 }}
                          align="start"
                        >
                          <Form.Item>满</Form.Item>

                          <Form.Item
                            {...field}
                            name={[field.name, 'money']}
                            fieldKey={[field.fieldKey, 'money']}
                            rules={[
                              { required: true, message: 'Missing first name' },
                              {
                                validator: (rule, value, callback) =>
                                  handleCheck(rule, value, callback, 1, index),
                              },
                            ]}
                          >
                            <Input placeholder="money" />
                          </Form.Item>
                          <Form.Item>返</Form.Item>

                          <Form.Item
                            {...field}
                            name={[field.name, 'back']}
                            fieldKey={[field.fieldKey, 'back']}
                            rules={[
                              { required: true, message: 'Missing last name' },
                              {
                                validator: (rule, value, callback) =>
                                  handleCheck(rule, value, callback, 2, index),
                              },
                            ]}
                          >
                            <Input placeholder="back" />
                          </Form.Item>

                          <MinusCircleOutlined
                            onClick={() => {
                              remove(field.name)
                            }}
                          />

                          <Form.Item style={{ marginLeft: '20px' }}>
                            {!!(form2Data.length && form2Data[index]) && (
                              <span>
                                满<em>{form2Data[index].money}元</em>返现
                                <em>{form2Data[index].back}%</em>
                              </span>
                            )}
                          </Form.Item>
                        </Space>
                      ))}
                      <div style={{ display: 'flex' }}>
                        <Radio.Group onChange={selChange} value={selVal}>
                          <Radio value={1}>上不封顶</Radio>
                          <Radio value={2}>最高返现金额</Radio>
                        </Radio.Group>

                        {selVal == 2 && (
                          <div
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            <Form.Item
                              name="topBackMoney"
                              rules={[
                                {
                                  validator: (rule, value, callback) =>
                                    topBackCheck(rule, value, callback),
                                },
                              ]}
                            >
                              <Input suffix="元" />
                            </Form.Item>

                            <span
                              style={{
                                position: 'relative',
                                top: '-12px',
                                left: '30px',
                              }}
                            >
                              满
                            </span>
                          </div>
                        )}
                      </div>
                      {fields.length < 3 && (
                        <Form.Item style={{ marginTop: '20px' }}>
                          <Button
                            type="primary"
                            onClick={() => {
                              add()
                            }}
                          >
                            新增一档
                          </Button>
                        </Form.Item>
                      )}
                    </div>
                  )
                }}
              </Form.List>
            </Form>
          </div>
        </div>
        
      </div>
    
      <div className={ styles.goodsBox }>
        <div className={styles.title} onClick={ handleSubmit }>提报商品</div>
        <div style={{ marginLeft:'50px',marginTop:'20px' }}>
          <div>选择已上架的商品参与此活动，至少添加 4款商品</div>
          <div style={{ marginTop:'20px',marginBottom:'20px' }}><Button type='primary'>选择商品</Button></div>
          <Table dataSource={testData} columns={columns} rowKey={'id'} />
        </div>
      </div>
      <Modal visible={ actNumVis } title="活动数量" width={800} onOk={ handleOk } onCancel={ handleCancel }>
          <ActNumComp  modalData = { modalData }/>
      </Modal>
    </div>
  )
}
export default RebateApply
