import React, {useState, useEffect} from 'react';
import {Space, Button} from "antd";
import PropTypes from 'prop-types';

const ButtonGroupRadio = props => {
  const {list, value, onChange, style, gap, size} = props
  const changeSelected = val => {
    onChange && onChange(val);
  }
  return (
    <Space style={style} size={gap || 8}>
      {(list || []).map((item, index) =>
        <Button key={item.key || item.value || index}
                size={size || "default"}
                type={item.value === value ? "primary" : "default"}
                onClick={() => changeSelected(item.value)}>{item.label || item.name}</Button>)}
    </Space>
  );
};

ButtonGroupRadio.defaultProps = {
  list: [],
  value: undefined,
}

ButtonGroupRadio.propTypes = {
  list: PropTypes.array,  // 数据列表
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),  // 选中的值（包括默认值）
  onChange: PropTypes.func,  // 每次改变选中值时候调用的方法，会带出来新的选中值
  style: PropTypes.object || false,
  gap: PropTypes.number || false,
  size: PropTypes.oneOf(["small", "default", "large"]) || false,
};

export default ButtonGroupRadio;
