import React, { useState, useEffect, useRef } from 'react';
import {
  Button,
  Descriptions,
  Divider,
  Input,
  Table,
  Radio,
  Modal,
  message,
} from 'antd';
import PickGoodsModal from 'components/Modals/PickGoods/index';
import factionStore from 'stores/actManagement';
import { formatTime, getQuery } from 'utils/tools';
import styles from './index.module.scss';
import { judgeNum } from 'utils/validate';

const { confirm } = Modal;

function FactionPresent(props) {
  const [refresh, setRefresh] = useState(false);
  const [activityId, setActivityId] = useState('');
  const [pageInfo, setPageInfo] = useState({});
  const [dataList, setDataList] = useState([]);
  const [showPickGoods, setShowPickGoods] = useState(false);
  const [chooseSpecShow, setChooseSpecShow] = useState(false);
  const [actionGoodsIndex, setActionGoodsIndex] = useState(-1);
  const [radioOptions, setRadioOptions] = useState([]);
  const [radioVal, setRadioVal] = useState(0);

  useEffect(() => {
    refresh && setTimeout(() => setRefresh(false));
  }, [refresh]);

  useEffect(() => {
    // console.log(radioVal, radioRef)
    // if(radioRef.current)
    //   radioRef.current.props.value = radioVal
    setRadioVal(radioVal);
  }, [radioVal]);

  const radioRef = useRef();

  useEffect(() => {
    // 获取Id
    const id = getQuery().id;
    setActivityId(id);

    // 获取页面数据
    getActivityInfo(id);
  }, []);

  const getActivityInfo = async (id) => {
    const info = await factionStore.getActivityInfo({ activityId: id });
    setPageInfo(info);
  };

  const removeItem = (index) => {
    dataList.splice(index, 1);
    setDataList([...dataList]);
    setRefresh(true);
  };

  const changeGoodsNum = (e, index) => {
    let val = judgeNum(e.target.value.trim(), 'num');
    dataList[index].num = val;
    setDataList(dataList);
    setRefresh(true);
  };
  const changeGoodsPrice = (e, index) => {
    let val = judgeNum(e.target.value.trim(), 'money');
    dataList[index].price = val;
    setDataList(dataList);
    setRefresh(true);
  };

  const chooseSpec = (i) => {
    const radioStyle = {
      display: 'block',
      height: '30px',
      lineHeight: '30px',
    };
    let arr = dataList[i].skuList.map((item, index) => ({
      value: index,
      label: Object.values(item.specName).join(' '),
    }));
    setRadioOptions(arr);
    setActionGoodsIndex(i);
    setChooseSpecShow(true);
  };

  // 表格columns
  const columns = [
    {
      title: '商品名称',
      align: 'center',
      dataIndex: 'name',
      render: (text, record) => (
        <div className={styles.goods_item_container}>
          <div className={styles.left}>
            <img
              src={record.mainImage}
              style={{ width: 80 }}
              className={styles.item_img}
              alt=""
            />
          </div>
          <div className={styles.right}>
            <p className={styles.item_name}>{text}</p>
            <p className={styles.item_cate}>{record.categoryTreeName}</p>
          </div>
        </div>
      ),
    },
    {
      title: '选择规格',
      align: 'center',
      render: (text, record, index) => {
        if (+record.isExistSpec === 1) {
          if (record.selectedSpec) {
            return (
              <Button type="link" onClick={() => chooseSpec(index)}>
                已选择：{Object.values(record.selectedSpec.specName).join(' ')}
              </Button>
            );
          } else {
            return (
              <Button type="link" onClick={() => chooseSpec(index)}>
                {record.skuList.length}种规格可选
              </Button>
            );
          }
        }
        return `无规格`;
      },
    },
    {
      title: '样品数量',
      align: 'center',
      render: (text, record, index) => (
        <Input
          value={record.num}
          onChange={(e) => changeGoodsNum(e, index)}
          style={{ width: 100 }}
          placeholder="不可为空"
          allowClear
        />
      ),
    },
    {
      title: '样品价格',
      align: 'center',
      render: (text, record, index) => (
        <Input
          value={record.price}
          onChange={(e) => changeGoodsPrice(e, index)}
          style={{ width: 100 }}
          placeholder="不可为空"
          allowClear
        />
      ),
    },
    {
      title: '操作',
      align: 'center',
      render: (text, record, index) => (
        <Button type="link" onClick={() => removeItem(index)}>
          移除
        </Button>
      ),
    },
  ];

  // 提报
  const toPresent = () => {
    if (!dataList.length) {
      message.error('请选择商品');
      return;
    }
    let goods = dataList.find(
      (item) => item.isExistSpec === 1 && !item.selectedSpec
    );
    if (goods) {
      message.error('请选择商品规格');
      return;
    }
    goods = dataList.find((item) => item.num !== 0 && !item.num);
    if (goods) {
      message.error('请输入商品数量');
      return;
    }
    goods = dataList.find((item) => item.num === 0);
    if (goods) {
      message.error('商品数量不能为0');
      return;
    }
    goods = dataList.find((item) => +item.num > +pageInfo.winThreshold);
    if (goods) {
      message.error('商品数量不能大于开奖人数！');
      return;
    }
    goods = dataList.find((item) => !item.price && +item.price !== 0);
    if (goods) {
      message.error('请输入样品价格');
      return;
    }
    confirm({
      title: `确认提报${dataList.length}款商品？`,
      content: '将提交给平台审核审核通过后，不可变更。',
      okText: '确认',
      cancelText: '取消',
      onOk: confirmPresent,
    });
  };

  // 确认提报
  const confirmPresent = async () => {
    let params = {
      activityId,
      activityGoodsParamList: dataList.map((item) => ({
        activityInventory: item.num,
        activityPrice: item.price * 1e2,
        spuId: item.spuId,
        goodsId: item.goodsId,
        skuId: item.isExistSpec === 1 ? item.selectedSpec.skuId : item.skuId,
      })),
    };
    let res = await factionStore.postPresentActivity(params);
    if (res.success) {
      message.success('提报成功！');
      setTimeout(() => window.open('/activity/faction', '_self'), 2000);
    }
  };

  return (
    <div>
      <Descriptions column={3} title="活动介绍">
        <Descriptions.Item label="活动名称">
          {pageInfo.activityName}
        </Descriptions.Item>
        <Descriptions.Item label="活动开始时间">
          {formatTime(pageInfo.startTime)}
        </Descriptions.Item>
        <Descriptions.Item label="单品开奖人数要求">
          {pageInfo.winThreshold}人
        </Descriptions.Item>
        <Descriptions.Item label="活动规则" span={3}>
          {pageInfo.activityRule}
        </Descriptions.Item>
        <Descriptions.Item label="报名说明" span={3}>
          {pageInfo.registrationInstruction}
        </Descriptions.Item>
      </Descriptions>
      <Divider />
      <Descriptions column={1} title="提报商品">
        <Descriptions.Item>
          选择已上架至飞象商城的商品参与此活动，最多添加10款商品
        </Descriptions.Item>
        <Descriptions.Item>
          <Button style={{ width: 120 }} onClick={() => setShowPickGoods(true)}>
            选择商品
          </Button>
        </Descriptions.Item>
        {!!dataList.length && (
          <Descriptions.Item>
            <Table
              columns={columns}
              dataSource={dataList}
              pagination={false}
              rowKey={(record) => record.key}
            />
          </Descriptions.Item>
        )}

        <Descriptions.Item>
          <Button type="primary" style={{ width: 120 }} onClick={toPresent}>
            确认提报
          </Button>
        </Descriptions.Item>
      </Descriptions>

      {showPickGoods && (
        <PickGoodsModal
          visible={showPickGoods}
          value={dataList.map((item) => item.goodsId)}
          activityId={activityId}
          onCancel={() => setShowPickGoods(false)}
          onChange={(rows) =>
            setDataList(
              dataList
                .concat(rows.map((item) => Object.assign(item, { num: '' })))
                .slice(0, 10)
                .map((item, index) => Object.assign(item, { key: index }))
            )
          }
        />
      )}
      <Modal
        title="选择规格"
        visible={chooseSpecShow}
        okText="确认"
        bodyStyle={{
          overflow: 'auto',
          // width: 500,
          maxHeight: 450,
        }}
        onOk={() => {
          dataList[actionGoodsIndex].selectedSpec =
            dataList[actionGoodsIndex].skuList[radioVal];
          setDataList(dataList);
          setRefresh(true);
          setChooseSpecShow(false);
        }}
        onCancel={() => setChooseSpecShow(false)}
        cancelText="取消"
        width={500}
        closable={false}
      >
        <Radio.Group
          ref={radioRef}
          value={radioVal}
          onChange={(e) => {
            setRadioVal(e.target.value);
            // setRefresh(true)
          }}
          options={radioOptions}
        />
      </Modal>
    </div>
  );
}

export default FactionPresent;
