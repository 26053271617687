import React, {Component, useEffect, useState} from 'react';
import {Upload} from 'antd';
import {PlusOutlined} from '@ant-design/icons';
import {ReactSortable} from 'react-sortablejs';
import PropTypes from 'prop-types';
import {mainHost} from 'config';
import commonStore from 'stores/common';

class SortableUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [{id: 0}, {id: 1}, {id: 2}, {id: 3}, {id: 4}],
      canUp: true,
    };
  }

  componentDidMount() {
    this.setState({
      list: this.props.fileList,
    });
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState({
      list: nextProps.fileList,
    });
  }

  checkPic = (file, fileList, index) => {
    const {canUp, list} = this.state;
    const {onChangePic} = this.props;
    console.log('change======');
    if (canUp) {
      // const {postUploadFile} = this.props.commonStore;
      const {postUploadFile} = commonStore;
      let params = new FormData();
      params.append('file', file);
      return new Promise((res, rej) => {
        res(postUploadFile(params));
      }).then((res) => {
        if (res.success) {
          let obj = {
            lastModified: file.lastModified,
            originFileObj: file,
            uid: file.uid,
            name: file.name,
            type: file.type,
            size: file.size,
            response: res,
            url: res.data,
          };
          list[index] = Object.assign(list[index], obj);
          this.setState(
            {
              list: this.state.list,
            }, () => onChangePic(list));
        }
      });
    } else {
      this.setState({
        canUp: true,
      });
    }
  };

  removePic = (file, index) => {
    const {canUp, list} = this.state;
    const {onChangePic} = this.props;
    console.log('del=====');
    this.setState({
      canUp: false,
    });
    let obj = {id: file.id};
    list[index] = obj;
    console.log(list, '=====list=====');
    this.setState(
      {
        list: this.state.list,
      }, () => {
        onChangePic(list)
        setTimeout(() => {
          this.setState({
            canUp: true,
          });
        }, 500)
      }
    );
  };

  render() {
    const {onChangeSort, onPreview, fileList} = this.props;
    const {list} = this.state;
    return (
      <div>
        <ReactSortable
          list={fileList}
          setList={(newList) => onChangeSort(newList)}
          style={{display: 'flex'}}
        >
          {list.map((item, index) => {
            // console.log(item, (!item || !item.url))
            return (
              <div style={{width: 112, display: 'inline-block'}} key={index}>
                <Upload
                  // action={`${mainHost}/basic/file/upload`}
                  // headers={{authority_token: localStorage.getItem("token")}}
                  listType="picture-card"
                  fileList={item && item.url ? [item] : []}
                  onPreview={(file) => {
                    onPreview(file);
                  }}
                  beforeUpload={() => false}
                  onChange={({file, fileList, event}) =>
                    this.checkPic(file, fileList, index)
                  }
                  onRemove={(file) => {
                    this.removePic(file, index);
                  }}
                >
                  {(!item || !item.url) && (
                    <div>
                      <PlusOutlined/>
                      <div className="ant-upload-text">点击上传</div>
                    </div>
                  )}
                </Upload>
              </div>
            );
          })}
        </ReactSortable>
      </div>
    );
  }
}

SortableUpload.propTypes = {
  fileList: PropTypes.array,
  onChangePic: PropTypes.func,
  onPreview: PropTypes.func,
  onChangeSort: PropTypes.func,
};

export default SortableUpload;
