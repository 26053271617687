import React from "react";
import zhCN from "antd/lib/locale-provider/zh_CN";
import {ConfigProvider} from "antd";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import Loadable from "react-loadable";
import {Provider} from "mobx-react";

import "./App.scss";
// stores
import stores from "stores"

// components
import {Loading} from "components";
// views
import Login from "views/Login";
import Main from "./layout/Main"

import Test from "views/test"

// console.log(stores, "==")

function App() {
  // let str = Object.keys(stores).join("_")
  // alert(str)
  return (
    <Provider {...stores}>
      <ConfigProvider locale={zhCN}>
        <Router>
          <Switch>
            <Route path="/test" exact component={Test}/>
            <Route path="/login" exact component={Login}/>
            <Route
              path="/"
              component={LoadMain}
            />
          </Switch>
        </Router>
      </ConfigProvider>
    </Provider>
  );
}


const LoadMain = Loadable({
  loader: () => import("./layout/Main"),
  loading: Loading
})


export default App;
