import React, {Component} from 'react'
import styles from './index.module.scss'


/**
 * @props title:String
 * @props right:Object|String
 */
class ModuleTitle extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  render() {
    const {title, right} = this.props;
    return (
      <div className={styles.module_title_container}>
        <span className={styles.title}>{title}</span>
        {right}
      </div>
    );
  }
}

export default ModuleTitle;
