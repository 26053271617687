import React, { useState } from 'react'
import { Table,Modal,Input,Form,Select, Button } from 'antd'
import { QuestionOutlined } from '@ant-design/icons'
import moment from 'moment'
import { showMoney } from 'utils/tools'
import usePage from 'hooks/page'
const { TextArea } = Input
const { Option } = Select;
function ApplyRecord () {
  const getData = () => {
    
  }
  const [form] = Form.useForm();
  // const { pagination,pageParams } = usePage(getData)
  const dataSource = Array.from({ length:100 },(val,index) => { return{a:`test-${index}`} })
  const columns = [
    {
      title: '商品id',
      dataIndex: 'goodsId',
      align:'center',
    },
    {
      title: '商品名称',
      dataIndex: 'goodsName',
      align:'center',
      render:(info,record) => (
        
        <div className = 'fx-flexbox'>
          <img src = {record.goodsImageUrl} style = {{width:'100px',height:'73px'}} />
          <div style={{marginLeft:'15px'}}>
            <p className='fx-ellipsis-row' style={{width:'230px',color:'#1890ff'}}>{record.goodsName}</p>
            <p>{record.categoryTreeName}</p>
          </div>
        </div>
      )
    },
    {
      title: '活动名称',
      dataIndex: 'activityName',
      align:'center',
      render:(info,record) => (
        <div>
          <p>活动id:{record.activityId}</p>
          <p>活动名称:{record.activityName}</p>
        </div>
      )
    },
    {
      title:'活动时间',
      dataIndex: 'startTime',
      width:'200px',
      align:'center',
      render:(info,record) => (
        <div>
          <p>{moment(info).format('YYYY-MM-DD HH:mm:ss')}</p>
          ~
          <p>{moment(record.endTime).format('YYYY-MM-DD HH:mm:ss')}</p>
        </div>
      )
    },
    {
      title:'市场价',
      align:'center',
      dataIndex: 'minMarketPrice',
      sortDirections:[ 'descend', 'ascend' ],
      render:(_,record) => (
        <div>
          {`¥${showMoney(record.minMarketPrice)} ~ ¥${showMoney(record.maxMarketPrice)}`}
        </div>
      )
    },
    {
      title:'销售价',
      dataIndex: 'a',
      align:'center',
      sortDirections:[ 'descend', 'ascend' ],
      render:() => (
        <div>-</div>
      )
    },
    {
      title:'活动价格',
      dataIndex: 'a',
      align:'center',
      sortDirections:[ 'descend', 'ascend' ],
      render:() => (
        <div>-</div>
      )
    },
    {
      title:'活动享金',
      dataIndex: 'a',
      align:'center',
      sortDirections:[ 'descend', 'ascend' ],
      render:() => (
        <div>-</div>
      )
    },
    {
      title:'最高返点',
      dataIndex: 'maxRebateAmount',
      align:'center',
      sortDirections:[ 'descend', 'ascend' ],
      render:(info) => (
        <div>
          {info == -1 ? '上不封顶' : `最高返现${info}`}
        </div>
      )
    },
    {
      title:'活动数量',
      dataIndex: 'a',
      align:'center',
      sortDirections:[ 'descend', 'ascend' ],
      key: 'a',
    },
    {
      title:'活动状态',
      dataIndex: 'a',
      align:'center',
      key: 'a',
    },
    {
      title:'审核时间',
      dataIndex: 'a',
      align:'center',
      key: 'a',
    },
    {
      title:'报名状态',
      dataIndex: 'a',
      align:'center',
      key: 'a',
      render:(info,record) => {
        return(
          <div style={{ position:'relative' }}>
            { info }
            <QuestionOutlined style={{ position:'absolute',right:'20px',top:'-5px',color:'red', fontSize:'16px' }} />
          </div>
        )
      }
    },
    {
      title:'操作',
      dataIndex: 'a',
      align:'center',
      key: 'a',
      fixed:'right',
      render:() => (
      <div style={{ color:'blue' }}>查看详情</div>
      )
    }
  ];
  const actType = [
    {name:'活动类型',value:''},
    {name:'一件拿样',value:'1'},
    {name:'每日必推',value:'2'},
    {name:'返点广场',value:'3'},
    {name:'专题活动',value:'4'}
  ]
  const actStatus = [
    {name:'报名状态',value:''},
    {name:'已通过',value:'1'},
    {name:'审核中',value:'2'},
    {name:'已驳回',value:'3'}
  ]

  //排序
  const tableChange = (pagination, filters, sorter,) => {
    if(sorter.columnKey){
      let sort = ''
      let asc = sorter.order == 'ascend' ? true : false
      sorter.field == 'downloadTotal' ? sort = 'download_total' : sorter.field == 'browseTotal' ?
      sort = 'browse_total' : sort = ''
    }
  }

  //搜索
  const handleSearch = () => {
    const data =  form.getFieldsValue()
    console.log(data,"===")
  }
  //重置搜索
  const resetSearch = () => {
    form.setFieldsValue({ actType:'',actStatus:'',actName:'',goodsName:'' })
  }
  
  
  return(
    <div>
      <div style={{ display:'flex',justifyContent:'flex-end',marginBottom:'20px' }}>
        <Form form={form} layout='inline'>
          <Form.Item name='actType'>
            <Select  style={{ width: 180 }} placeholder="搜索活动类型">
              {
                actType.map((item,index) => <Option key={ index } value={ item.value }>{ item.name }</Option>)
              }
            </Select>
          </Form.Item>
          <Form.Item name="actStatus">
            <Select  style={{ width: 180 }} placeholder="搜索报名状态">
              {
                actStatus.map((item,index) => <Option key={ index } value={ item.value }>{ item.name }</Option>)
              }
            </Select>
          </Form.Item>

          <Form.Item name="actName">
            <Input placeholder="请输入活动id/活动名称" />
          </Form.Item>

          <Form.Item name="goodsName">
            <Input placeholder="请输入商品id/商品名称" />
          </Form.Item>

          <Form.Item>
            <Button type='primary' onClick={ handleSearch }>查询</Button>
            <Button style={{ marginLeft:'10px' }} onClick={ resetSearch }>重置搜索</Button>
          </Form.Item>
        </Form>
      </div>
      <Table dataSource={dataSource} columns={columns} rowKey={'a'} scroll={{ x: 1500 }} sticky onChange={tableChange}    />
    </div>
  )
}
export default ApplyRecord