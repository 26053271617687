import theme from "./theme"

const ENV = process.env.REACT_APP_API_ENV;
const apiHost = {
  dev: "http://dev-gateway.fxqifu.net/pangu",
  test:"https://qa-gateway.fxqifu.net/pangu",
  // test: "https://qa-gateway.peliten.net/pangu",
  prod: "https://gateway.fxqifu.net/pangu"
};


const userIdListENV = {//[飞象自营,京东]
  dev: [],
  test: ["202009251537400003756213", "202009251537380003756207"],
  prod: ["202010132318330004953476", "202010132318320004953470"]
}

export const userIdList = userIdListENV[ENV]

export const mainHost = apiHost[ENV];
export const DATA_CODE = {
  SUCCESS: 2000,
  QUERY_PARAMS_ERROR: 4000,
  LOGIN_ERROR: "100003",
  OTHER_ERROR: 5000,
  GOODS_CHANGE_PERMIT_BY_ACTIVITY: '50000407'
};

// 税率列表
export const taxRateOption = [
  {
    label: "6%",
    value: 6,
    disabled: false
  }, {
    label: "9%",
    value: 9,
    disabled: false
  }, {
    label: "10%",
    value: 10,
    disabled: false
  }, {
    label: "11%",
    value: 11,
    disabled: false
  }, {
    label: "13%",
    value: 13,
    disabled: false
  },
]

export default {
  mainHost,
  userIdList,
  theme,
  DATA_CODE,
  taxRateOption
};
