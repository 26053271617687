import React from 'react';
import PropTypes from 'prop-types';
import {Modal, Form, Input} from "antd";

const {Item} = Form

const ChangePwd = props => {
  const {visible, onCancel} = props
  const [formPwd] = Form.useForm()
  const confirm = () => {
    formPwd.validateFields().then(values => {
      // TODO:请求修改密码接口
    })
  }
  return (
    <Modal title="修改密码" centered width={400} visible={visible} onCancel={onCancel} onOk={confirm}>
      <Form form={formPwd} style={{paddingTop: 32}}>
        <Item name="password"
              rules={[{required: true, message: "请输入8-20位字母、数字、字符其中至少两种"},
                // {min: 8, message: "长度只能在8-20个字符之间"},
                {
                  pattern: /^((?=.*[a-zA-Z])(?=.*\d))|((?=.*[a-zA-Z])(?=.*[$@$!%*?&.]))|((?=.*\d)(?=.*[$@$!%*?&.]))[A-Za-z\d$@$!%*?&.]{8,16}$/,
                  message: "请输入8-20位字母、数字、字符其中至少两种"
                }]}>
          <Input allowClear placeholder="输入新的密码" style={{width: 350}}/>
        </Item>
      </Form>
    </Modal>
  );
};

ChangePwd.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func
};

export default ChangePwd;
