import React, { useState, useEffect } from 'react';
import classnames from 'classnames';

import { Icon } from 'antd';

import './index.scss';

/**
 * 目前只考虑10张图
 */
function ImgScroll(props) {
  const { items = [] } = props;

  const [mainImg, setMainImg] = useState('');
  const [left, setLeft] = useState(0);

  useEffect(() => {
    setMainImg(items[0] || "");
  }, [items.length])

  const handlePrev = () => {
    setLeft(0);                                                       
  }

  const hanldeNext = () => {
    const len = items.length;
    if (len < 6) return;

    let currLeft = 0;

    if (!left) {
      currLeft = len < 11 ? (len - 5) * 76 : 5 * 76;
      setLeft(-currLeft);
    }
  }

  return (
    <div className="img-box">
      <div className="main-img" style={{ backgroundImage: `url(${mainImg})` }} />

      <div className="img-list-wrap">
        <Icon className="icon-arrow prev" type="left" onClick={() => { !!left && handlePrev() }} />
        <Icon className="icon-arrow next" type="right" onClick={() => hanldeNext()} />
        <div className="img-list-container">
          {!!items.length && (
            <ul className="img-list" style={{ width: items.length * 76 - 10, left }}>
              {items.map((url, ind) => (
                <li
                  key={`item_list_${ind}`}
                  className={classnames('img-list-item', { 'active': mainImg === url })}
                  style={{ backgroundImage: `url(${url})` }}
                  onClick={() => setMainImg(url)}
                />
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  )
}

export default ImgScroll;
