import React, {useState, useEffect} from 'react';
import styles from './index.module.scss'

function Goods(props) {
  return (
    <div>
      Goods
    </div>
  );
}
export default Goods;
