import React, { useState, useEffect } from 'react'

 function usePage (initParams = {},getData=()=>{}) {

  
  const [paramsData,setParams] = useState({
      pageSize:20,
      pageNo:1,
      total:0,
      ...initParams
  })

  const changePage = (page,pageSize) => {
    setParams(prev => {
      return { ...prev,pageNo:page,pageSize }
    })
  }
  const [pagination,setPagination] = useState({
    total: 0,
    pageSize:20,
    current: 1,
    showSizeChanger: true,
    showQuickJumper: true,
    pageSizeOptions: ["10", "20", "30"],
    onChange: changePage,
    showTotal: total => `共 ${total} 条数据`
  })


  const getInfo = async(paramsData,otherParams={}) => {
    const  data  = await getData({...paramsData,...otherParams})
    const { total,pageSize,page } = data
    setPagination({...pagination,total,pageSize,current:page})
  }
  useEffect(() => {
    getInfo(paramsData)
  },[paramsData])
  return {
    pagination,paramsData,setParams,getInfo
  }
 }

 export default usePage