import {observable, action} from "mobx";
import httpClient from "utils/httpClient";
import api from "config/api"
import {formatArea} from "utils/tools";

const {store} = api;

class StoreFreightStore {
  @observable freightList = []
  @observable freightSimpleList = []
  @observable freightDetail = {}

  /**
   * 运费模板列表
   * @param params
   * @returns {Promise<*>}
   */
  @action
  getFreightList = async params => {
    const res = await httpClient.post(store.POST_FREIGHT_LIST, params);
    if (res.success) {
      let list = res.data.map((item, index) => Object.assign(item, {key: index}))
      this.freightList = list;
      return list
    }
    return []
  }

  /**
   * 运费模板简单列表
   * @param params
   * @returns {Promise<*>}
   */
  @action
  getFreightSimpleList = async params => {
    const res = await httpClient.get(store.GET_FREIGHT_SIMPLE_LIST, {params});
    if (res.success) {
      let list = res.data.map((item, index) => Object.assign(item, {key: index}))
      this.freightSimpleList = list;
      return list
    }
    return []
  }

  /**
   * 获取运费模板详情
   * @param params
   * @returns {Promise<*>}
   */
  @action
  getFreightDetail = async params => {
    const res = await httpClient.post(store.POST_FREIGHT_DETAIL, params);
    if (res.success) {
      this.freightDetail = res.data;
      return res.data
    }
  }

  /**
   * 增加运费模板
   * @param params
   * @returns {Promise<*>}
   */
  @action
  addFreight = async params => {
    return await httpClient.post(store.POST_ADD_FREIGHT, params);
  }

  /**
   * 修改运费模板
   * @param params
   * @returns {Promise<*>}
   */
  @action
  updateFreight = async params => {
    return await httpClient.post(store.POST_UPDATE_FREIGHT, params);

  }

  /**
   * 删除运费模板
   * @param params
   * @returns {Promise<*>}
   */
  @action
  deleteFreight = async params => {
    return await httpClient.post(store.POST_DELETE_FREIGHT, params);
  }

  /**
   * 获取运费模板区域列表
   * @param params
   * @returns {Promise<void>}
   */
  @action
  getFreightArea = async (params) => {
    const data = await httpClient.get(store.GET_FREIGHT_AREA, {params})
    if (data.success) {
      localStorage.setItem("freightArea", JSON.stringify(formatArea(data.data)))
    }
  }

}

export default new StoreFreightStore();
