import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Space } from 'antd';
import { withRouter } from 'react-router-dom';
import stores from 'stores';
import styles from './index.module.scss';

const { storeLocationStore } = stores;

function ChooseAddress(props) {
  const { visible, onOk, onCancel, value } = props;

  const [addressList, setAddressList] = useState([]);
  const [selectedItem, setSelectedItem] = useState(undefined);

  useEffect(() => {
    setSelectedItem(value);
    _getData();
  }, []);

  const _getData = async () => {
    const res = await storeLocationStore.getLocationList({ type: 2 });
    setAddressList(res);
  };

  const confirm = () => {
    onOk(selectedItem);
    onCancel();
  };

  return (
    <Modal
      title={
        <Space>
          <span>选择地址</span>
          <Button
            type="primary"
            onClick={() => {
              props.history.push({
                pathname: '/setting/location',
              });
            }}
          >
            添加新地址
          </Button>
        </Space>
      }
      visible={visible}
      centered
      width={700}
      okText="确定保存"
      onOk={confirm}
      onCancel={onCancel}
    >
      {addressList.map((item) => (
        <div key={item.key} className={styles.address_item}>
          <span className={styles.address_content}>{`${item.fullAddress} ${
            item.consignee
          }${item.mobileNumber ? '，' + item.mobileNumber : ''}${
            item.landlineNumber ? '，' + item.landlineNumber : ''
          }`}</span>
          {selectedItem && item.id === selectedItem.id ? (
            <Button disabled className={styles.address_btn}>
              已选择
            </Button>
          ) : (
            <Button
              type="primary"
              className={styles.address_btn}
              onClick={() => setSelectedItem(item)}
            >
              选择
            </Button>
          )}
        </div>
      ))}
    </Modal>
  );
}

ChooseAddress.propTypes = {
  visible: PropTypes.bool.isRequired,
  onOk: PropTypes.func,
  onCancel: PropTypes.func,
  value: PropTypes.object,
};

export default withRouter(ChooseAddress);
