import React, { useEffect, useState } from 'react';
import {
  Tabs,
  Table,
  Input,
  Button,
  Select,
  Badge,
  Popover,
  Cascader,
  message,
  Col,
  Modal,
} from 'antd';

import RangeInput from 'components/RangeInput';
import factionStore from 'stores/actManagement';
import commonStore from 'stores/common';

import styles from './index.module.scss';
import { formatTime, getQuery, showMoney, downloadFile } from 'utils/tools';

const { TabPane } = Tabs;

function FactionList(props) {
  const [tab, setTab] = useState('1');
  useEffect(() => {
    let query = getQuery();
    if (query.tab) {
      setTab(query.tab);
    }
  }, []);
  return (
    <div>
      <Tabs
        activeKey={tab}
        onChange={(val) => {
          setTab(val);
          props.history.push(`/activity/faction?tab=${val}`);
          // window.location.href = `/activity/faction?tab=${val}`
        }}
      >
        <TabPane tab="报名中" key="1">
          {+tab === 1 && <SignUpTable />}
        </TabPane>
        <TabPane tab="报名记录" key="2">
          {+tab === 2 && <SignUpRecordTable />}
        </TabPane>
        <TabPane tab="用户资料" key="3">
          {+tab === 3 && <UserInfoTable />}
        </TabPane>
      </Tabs>
    </div>
  );
}

// 报名中
function SignUpTable(props) {
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [dataList, setDataList] = useState({ list: [], total: 0 });

  useEffect(() => {
    _getData();
  }, [pageNo, pageSize]);

  /**
   * 页码改变
   * @param page
   * @param pageSize
   * @returns {Promise<void>}
   */
  const changePage = (page, pageSize) => {
    setPageNo(page);
  };

  /**
   * pageSize改变
   * @param current
   * @param size
   * @returns {Promise<void>}
   */
  const changePageSize = (current, size) => {
    setPageNo(1);
    setPageSize(size);
  };

  /**
   * 分页参数
   * @type {{total: number, current: number, pageSizeOptions: string[], defaultPageSize: number, onChange: changePage, showTotal: (function(*): string), onShowSizeChange: changePageSize, showQuickJumper: boolean, showSizeChanger: boolean}}
   */
  const pagination = {
    total: dataList.total,
    current: pageNo,
    showSizeChanger: true,
    showQuickJumper: true,
    pageSizeOptions: ['20', '50', '100'],
    defaultPageSize: 20,
    onChange: changePage,
    onShowSizeChange: changePageSize,
    showTotal: (total) => `共 ${total} 条数据`,
  };

  const columns = [
    {
      title: '活动id',
      align: 'center',
      dataIndex: 'id',
    },
    {
      title: '活动名称',
      align: 'center',
      dataIndex: 'activityName',
    },
    {
      title: '活动开始时间',
      align: 'center',
      dataIndex: 'startTime',
      render: (text) => formatTime(text),
    },
    {
      title: '单品开奖人数要求',
      align: 'center',
      dataIndex: 'winThreshold',
    },
    {
      title: '报名说明',
      align: 'center',
      dataIndex: 'registrationInstruction',
      width: 400,
      render: (text) => (
        <Popover content={text}>
          <p className={styles.item_rule}>{text}</p>
        </Popover>
      ),
    },
    {
      title: '操作',
      align: 'center',
      render: (text, record) => (
        <Button
          type="link"
          onClick={() =>
            window.open(`/activity/faction/present?id=${record.id}`, '_self')
          }
        >
          提报商品
        </Button>
      ),
    },
  ];

  /**
   * 获取数据
   * @private
   */
  const _getData = async () => {
    let params = { pageNo, pageSize };
    console.log(params);
    let data = await factionStore.getSignUpList(params);
    setDataList(data);
  };

  return (
    <Table
      dataSource={dataList.list}
      pagination={pagination}
      columns={columns}
      rowKey={(record) => record.key}
    />
  );
}

// 报名记录
function SignUpRecordTable(props) {
  const statusMap = [
    { value: 2, name: '已通过' },
    { value: 1, name: '审核中' },
    { value: 3, name: '已驳回' },
  ];

  const [status, setStatus] = useState(undefined);
  const [range, setRange] = useState(['', '']);
  const [rangeSucc, setRangeSucc] = useState(true);
  const [name, setName] = useState('');
  const [sortInfo, setSortInfo] = useState({});
  const [sortField, setSortField] = useState('');
  const [asc, setAsc] = useState(undefined);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [dataList, setDataList] = useState({ list: [], total: 0 });
  const [refreshData, setRefreshData] = useState(true);
  const [exitItem, setExitItem] = useState({});
  const [showExitModal, setShowExitModal] = useState(false);
  const [exitReason, setExitReason] = useState('');

  useEffect(() => {
    refreshData && _getData();
    refreshData && setTimeout(() => setRefreshData(false));
  }, [refreshData]);

  /**
   * 页码改变
   * @param page
   * @param pageSize
   * @returns {Promise<void>}
   */
  const changePage = (page, pageSize) => {
    setPageNo(page);
    setRefreshData(true);
  };

  /**
   * pageSize改变
   * @param current
   * @param size
   * @returns {Promise<void>}
   */
  const changePageSize = (current, size) => {
    setPageNo(1);
    setPageSize(size);
    setRefreshData(true);
  };

  const changeTable = (p, f, s) => {
    const columnMap = {
      activityInventory: 'activity_inventory',
      supplyPriceList: 'min_sale_price',
    };
    console.log(p, f, s);
    setSortInfo(s);
    if (s.field) {
      setSortField(columnMap[s.field]);
      if (s.column) {
        setAsc(s.order === 'ascend');
      } else {
        setSortField(undefined);
        setAsc(undefined);
      }
      setPageNo(1);
      setRefreshData(true);
    }
  };

  /**
   * 分页参数
   * @type {{total: number, current: number, pageSizeOptions: string[], defaultPageSize: number, onChange: changePage, showTotal: (function(*): string), onShowSizeChange: changePageSize, showQuickJumper: boolean, showSizeChanger: boolean}}
   */
  const pagination = {
    total: dataList.total,
    current: pageNo,
    showSizeChanger: true,
    showQuickJumper: true,
    pageSizeOptions: ['20', '50', '100'],
    defaultPageSize: 20,
    onChange: changePage,
    onShowSizeChange: changePageSize,
    showTotal: (total) => `共 ${total} 条数据`,
  };
  const approvalStatusMap = ['', '审核中', '已通过', '已驳回'];
  const exitStatusMap = ['待审核', '', '已退出', '已驳回'];
  const columns = [
    {
      title: '商品id',
      align: 'center',
      dataIndex: 'id',
    },
    {
      title: '商品名称',
      align: 'center',
      dataIndex: 'goodsName',
      render: (text, record) => (
        <div className={styles.goods_item_container}>
          <div className={styles.left}>
            <img
              src={record.goodsImageUrl}
              className={styles.item_img}
              alt=""
            />
          </div>
          <div className={styles.right}>
            <p className={styles.item_name}>{text}</p>
            <p className={styles.item_spec}>
              {record.goodsAttribute
                ? Object.values(JSON.parse(record.goodsAttribute)).join(' ')
                : ' '}
            </p>
            <p className={styles.item_cate}>{record.categoryTreeName}</p>
          </div>
        </div>
      ),
    },
    {
      title: '报名活动',
      align: 'center',
      dataIndex: 'activityName',
    },
    {
      title: '样品数量',
      align: 'center',
      dataIndex: 'activityInventory',
      sorter: true,
      sortOrder:
        (sortInfo.field === 'activityInventory' && sortInfo.order) || false,
    },
    {
      title: '销售价',
      align: 'center',
      dataIndex: 'supplyPriceList',
      sorter: true,
      sortOrder:
        (sortInfo.field === 'supplyPriceList' && sortInfo.order) || false,
      render: (text, record) => {
        if (record.saleType === 2) {
          return showMoney(record.salePriceList[0].price);
        } else {
          let arr = record.salePriceList.map((item, index, arr) => {
            if (!item.price || !item.min) {
              return '';
            }
            if (arr.length - 1 === index) {
              return `¥${showMoney(item.price)}(≥${item.min}${
                record.saleUnit || '个'
              })`;
            } else if (item.min === item.max) {
              return `¥${showMoney(item.price)}(${item.min}${
                record.saleUnit || '个'
              })`;
            } else {
              return `¥${showMoney(item.price)}(${item.min}-${item.max}${
                record.saleUnit || '个'
              })`;
            }
          });
          return <p dangerouslySetInnerHTML={{ __html: arr.join('<br/>') }} />;
        }
      },
    },
    {
      title: '样品价格',
      align: 'center',
      dataIndex: 'activityPrice',
      render: (text) => `¥${showMoney(text)}`,
    },
    {
      title: '提报时间',
      align: 'center',
      dataIndex: 'createTime',
      render: (text) => formatTime(text),
    },
    {
      title: '审核时间',
      align: 'center',
      dataIndex: 'approvalTime',
      render: (text) => (text ? formatTime(text) : '--'),
    },
    {
      title: '报名状态',
      align: 'center',
      dataIndex: 'approvalStatus',
      render: (text, record) => {
        if (+text === 1 || +text === 2) {
          return approvalStatusMap[text];
        } else {
          return (
            <Popover content={record.rejectReason}>
              <Badge count="?">{approvalStatusMap[text]}</Badge>
            </Popover>
          );
        }
      },
    },
    {
      title: '退出状态',
      align: 'center',
      dataIndex: 'exitActivityType',
      render: (text, record) => {
        if (+text === 0 || +text === 1 || +text === 2) {
          return exitStatusMap[text];
        } else {
          return (
            <Popover content={record.exitActivityRejectReason}>
              <Badge count="?">{exitStatusMap[text]}</Badge>
            </Popover>
          );
        }
      },
    },
    {
      title: '操作',
      align: 'center',
      render: (text, record) =>
        !(
          +record.approvalStatus === 3 ||
          +record.exitActivityType === 0 ||
          +record.exitActivityType === 2
        ) && (
          <Button
            type="link"
            onClick={(e) => {
              setExitItem(record);
              setShowExitModal(true);
            }}
          >
            退出活动
          </Button>
        ),
    },
  ];

  /**
   * 获取数据
   * @private
   */
  const _getData = async () => {
    let params = {
      approvalStatus: status || undefined,
      goodsName: name || undefined,
      minSalePrice: range[0] ? range[0] * 1e2 : undefined,
      maxSalePrice: range[1] ? range[1] * 1e2 : undefined,
      sortField: sortField || undefined,
      asc: asc,
      pageNo,
      pageSize,
    };

    console.log(params);
    let data = await factionStore.getSignUpRecordList(params);
    setDataList(data);
  };

  const search = () => {
    if (!rangeSucc) {
      message.error('请输入正确的筛选条件！');
      return;
    }
    setPageNo(1);
    setRefreshData(true);
  };
  const reset = () => {
    setStatus(undefined);
    setName('');
    setRange(['', '']);
    setRangeSucc(true);
    setSortField('');
    setSortInfo({});
    setAsc(undefined);
    setPageNo(1);
    setRefreshData(true);
  };

  const exitActivity = async () => {
    console.log(exitItem);
    if (!exitReason) {
      message.error('请填写退出原因');
      return;
    }
    console.log(exitItem);
    const { exitActivity } = factionStore;
    let params = {
      id: exitItem.id,
      exitActivityApplyReason: exitReason,
    };
    let res = await exitActivity(params);
    if (res.success) {
      message.success('申请退出成功');
      _getData();
    }
    setShowExitModal(false);
  };

  return (
    <div>
      <div className={styles.screen_container}>
        <div className={styles.left}></div>
        <div className={styles.right}>
          <Select
            placeholder="状态"
            style={{ minWidth: 100, marginRight: 20 }}
            value={status}
            onChange={(value) => setStatus(value)}
          >
            {statusMap.map((item, index) => (
              <Select.Option value={item.value} key={index}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
          <RangeInput
            name="销售价"
            addonAfter="¥"
            divider="至"
            value={range}
            onChange={(vals, isOk) => {
              setRange(vals);
              setRangeSucc(isOk);
            }}
            style={{ width: 130 }}
          />
          <Input
            placeholder="请输入商品名称"
            value={name}
            onChange={(e) => setName(e.target.value.trim())}
            style={{ marginRight: 20, marginLeft: 20 }}
          />
          <Button type="primary" style={{ marginRight: 16 }} onClick={search}>
            查询
          </Button>
          <Button onClick={reset}>重置搜索</Button>
        </div>
      </div>
      <Table
        dataSource={dataList.list}
        pagination={pagination}
        columns={columns}
        onChange={changeTable}
        rowKey={(record) => record.key}
      />
      {showExitModal && (
        <Modal
          title="填写退出原因"
          visible={showExitModal}
          okText="确认退出"
          onCancel={() => {
            setShowExitModal(false);
            setExitReason('');
          }}
          onOk={exitActivity}
          centered
        >
          <Input.TextArea
            maxLength={120}
            placeholder="120字以内"
            value={exitReason}
            onChange={(e) => {
              setExitReason(e.target.value.trim());
            }}
          ></Input.TextArea>
        </Modal>
      )}
    </div>
  );
}

// 用户资料
function UserInfoTable(props) {
  const awardMap = [
      { value: 1, name: '是' },
      { value: 2, name: '否' },
    ],
    keywordTypeMap = [
      { value: 1, name: '搜索活动名称' },
      { value: 2, name: '搜索联系人' },
      { value: 3, name: '搜索联系方式' },
      { value: 4, name: '搜索地址' },
    ];
  const [isAward, setIsAward] = useState(undefined);
  const [keywordType, setKeywordType] = useState(1);
  const [keyword, setKeyword] = useState('');
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [location, setLocation] = useState([]);
  const [locationItem, setLocationItem] = useState([]);
  const [locationData, setLocationData] = useState([]);
  const [dataList, setDataList] = useState({ list: [], total: 0 });
  const [refreshData, setRefreshData] = useState(true);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    refresh && setTimeout(() => setRefresh(false));
  }, [refresh]);

  useEffect(() => {
    _initLocation();
  }, []);
  const _initLocation = async () => {
    console.log(commonStore, '===');
    let locationList = await commonStore.postLocationByParent({
      parentCode: 0,
    });
    let arr = locationList.map((item) => ({
      label: item.name,
      value: item.id,
      isLeaf: false,
    }));
    setLocationData(arr);
  };

  /**
   * 级联地址改变
   * @param value
   * @param selectedOptions
   */
  const changeLocation = (value, selectedOptions) => {
    setLocation(value);
    setLocationItem(selectedOptions);
  };

  /**
   * 获取级联地址
   * @param selectedOptions
   */
  const getLocationData = async (selectedOptions) => {
    console.log(selectedOptions);
    const targetOption = selectedOptions[selectedOptions.length - 1];
    targetOption.loading = true;
    let arr = await commonStore.postLocationByParent({
      parentCode: targetOption.value,
    });
    targetOption.children = arr.map((item) => ({
      label: item.name,
      value: item.id,
      isLeaf: +item.level === 4,
    }));
    targetOption.loading = false;
    setLocationData(locationData);
    setRefresh(true);
  };

  useEffect(() => {
    refreshData && _getData();
    refreshData && setTimeout(() => setRefreshData(false));
  }, [refreshData]);

  /**
   * 页码改变
   * @param page
   * @param pageSize
   * @returns {Promise<void>}
   */
  const changePage = (page, pageSize) => {
    setPageNo(page);
    setRefreshData(true);
  };

  /**
   * pageSize改变
   * @param current
   * @param size
   * @returns {Promise<void>}
   */
  const changePageSize = (current, size) => {
    setPageNo(1);
    setPageSize(size);
    setRefreshData(true);
  };

  /**
   * 分页参数
   * @type {{total: number, current: number, pageSizeOptions: string[], defaultPageSize: number, onChange: changePage, showTotal: (function(*): string), onShowSizeChange: changePageSize, showQuickJumper: boolean, showSizeChanger: boolean}}
   */
  const pagination = {
    total: dataList.total,
    pageSize,
    current: pageNo,
    showSizeChanger: true,
    showQuickJumper: true,
    pageSizeOptions: ['20', '50', '100'],
    defaultPageSize: 20,
    onChange: changePage,
    onShowSizeChange: changePageSize,
    showTotal: (total) => `共 ${total} 条数据`,
  };

  const awardStatusMap = ['否', '是', '否'];
  const columns = [
    {
      title: '活动名称',
      align: 'center',
      dataIndex: 'activityName',
    },
    {
      title: '是否中奖',
      align: 'center',
      dataIndex: 'winType',
      render: (text) => awardStatusMap[text],
    },
    {
      title: '公司名称',
      align: 'center',
      dataIndex: 'companyName',
    },
    {
      title: '联系人',
      align: 'center',
      dataIndex: 'contactName',
    },
    {
      title: '联系方式',
      align: 'center',
      dataIndex: 'contactNumber',
    },
    {
      title: '职位',
      align: 'center',
      dataIndex: 'position',
    },
    {
      title: '名片',
      align: 'center',
      dataIndex: 'businessCardImageUrl',
      render: (text) => <img src={text} alt="" style={{ width: 80 }} />,
    },
    {
      title: '地址',
      align: 'center',
      width: 200,
      dataIndex: 'address',
      render: (text) => (
        <Popover content={text}>
          <p className={styles.table_one_line}>{text}</p>
        </Popover>
      ),
    },
  ];

  /**
   * 获取数据
   * @private
   */
  const _getData = async () => {
    let params = {
      pageNo,
      pageSize,
      winType: isAward,
      activityName: keywordType === 1 ? keyword || undefined : undefined,
      contactName: keywordType === 2 ? keyword || undefined : undefined,
      contactNumber: keywordType === 3 ? keyword || undefined : undefined,
      province:
        keywordType === 4
          ? locationItem[0]
            ? locationItem[0].label
            : undefined
          : undefined,
      city:
        keywordType === 4
          ? locationItem[1]
            ? locationItem[1].label
            : undefined
          : undefined,
      district:
        keywordType === 4
          ? locationItem[2]
            ? locationItem[2].label
            : undefined
          : undefined,
      town:
        keywordType === 4
          ? locationItem[3]
            ? locationItem[3].label
            : undefined
          : undefined,
      address: keywordType === 4 ? keyword || undefined : undefined,
    };

    console.log(params);
    let data = await factionStore.getUserInfoList(params);
    setDataList(data);
  };

  const search = () => {
    setPageNo(1);
    setRefreshData(true);
  };
  const reset = () => {
    setPageNo(1);
    setIsAward(undefined);
    setKeywordType(1);
    setLocation([]);
    setLocationItem([]);
    setKeyword('');
    setRefreshData(true);
  };
  const downloadRes = async () => {
    let params = {
      pageNo,
      pageSize,
      winType: isAward,
      activityName: keywordType === 1 ? keyword || undefined : undefined,
      contactName: keywordType === 2 ? keyword || undefined : undefined,
      contactNumber: keywordType === 3 ? keyword || undefined : undefined,
      province:
        keywordType === 4
          ? locationItem[0]
            ? locationItem[0].label
            : undefined
          : undefined,
      city:
        keywordType === 4
          ? locationItem[1]
            ? locationItem[1].label
            : undefined
          : undefined,
      district:
        keywordType === 4
          ? locationItem[2]
            ? locationItem[2].label
            : undefined
          : undefined,
      town:
        keywordType === 4
          ? locationItem[3]
            ? locationItem[3].label
            : undefined
          : undefined,
      address: keywordType === 4 ? keyword || undefined : undefined,
    };
    const { data, headers } = await factionStore.downloadUserInfo(params);
    let fileName = '';
    try {
      fileName = window.decodeURI(headers['content-disposition'].split('=')[1]);
      if (fileName.startsWith('utf-8')) {
        fileName = fileName.replace("utf-8''", '');
      }
    } catch (error) {}
    downloadFile(data, 'vnd.ms-excel', fileName);
  };
  return (
    <div>
      <div className={styles.screen_container}>
        <div className={styles.left}></div>
        <div className={styles.right}>
          <Select
            placeholder="是否中奖"
            style={{ minWidth: 120, marginRight: 20 }}
            value={isAward}
            onChange={(value) => setIsAward(value)}
          >
            {awardMap.map((item, index) => (
              <Select.Option value={item.value} key={index}>
                {item.name}
              </Select.Option>
            ))}
          </Select>

          <Select
            placeholder="搜索类型"
            style={{ minWidth: 150 }}
            value={keywordType}
            onChange={(value) => {
              setKeywordType(value);
              setKeyword('');
            }}
          >
            {keywordTypeMap.map((item, index) => (
              <Select.Option value={item.value} key={index}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
          {keywordType === 4 && (
            <Cascader
              changeOnSelect
              onChange={changeLocation}
              options={locationData}
              loadData={getLocationData}
              value={location}
              placeholder="请选择地址"
              style={{ width: '100%' }}
            />
          )}

          <Input
            placeholder={keywordType === 4 ? '请输入详细地址' : '请输入关键字'}
            value={keyword}
            onChange={(e) => setKeyword(e.target.value.trim())}
            style={{ marginRight: 20 }}
          />
          <Button type="primary" style={{ marginRight: 16 }} onClick={search}>
            查询
          </Button>
          <Button style={{ marginRight: 16 }} onClick={reset}>
            重置搜索
          </Button>
          <Button onClick={downloadRes}>导出结果</Button>
        </div>
      </div>
      <Table
        dataSource={dataList.list}
        pagination={pagination}
        columns={columns}
        rowKey={(record) => record.key}
      />
    </div>
  );
}

export default FactionList;
