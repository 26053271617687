import React, {useState, useEffect} from 'react';
import {Route, Switch} from "react-router-dom";

import Freight from "./Freight";
import FreightAdd from "./Freight/Add"
import FreightGoods from "./Freight/Goods"
import Location from "./Location";
const SettingModule = () => {
  return (
    <Switch>
      <Route path="/setting/freight" exact component={Freight}/>
      <Route path="/setting/freight/add" exact component={FreightAdd}/>
      <Route path="/setting/freight/goods" exact component={FreightGoods}/>
      <Route path="/setting/location" exact component={Location}/>
    </Switch>
  );
};

export default SettingModule;
