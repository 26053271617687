import { observable, action, runInAction, toJS } from "mobx";
import httpClient from "utils/httpClient";
import api from "config/api"
import { addKey } from "utils/tools";
import { formatStoreCateTree } from "utils/common"

const { goods } = api;

class GoodsJdStore {
  // 京东商品列表
  @observable productsListJD = {
    list: [],
    pages: {
      total: 0,
      pageSize: 20,
      currentPage: 1
    }
  }


  // 京东改价规则回显
  @observable priceRuleInfo = {
    apiToShopParamInfo: {
      stairParamList: []
    }
  }
  // 自营改价规则回显
  @observable selfPriceRuleInfo = {}

  @observable cateList = {
    list: [],
    pages: {
      total: 0,
      pageSize: 20,
      currentPage: 1
    }
  }
  @observable cateTree = []


  // 获取京东商品库，列表
  @action
  getJDStoreList = async params => {
    // 1审核商品商品库， 2已挑选商品库
    let res
    switch (params.platform) {
      case 1:
        res = await httpClient.post(goods.GET_JD_STORE_LIST, { ...params });
        break;
      case 2:
        res = await httpClient.post(goods.GET_ELESTORE_JD_LIST, { ...params });
        break;
    }

    let data = addKey(res.data)
    this.productsListJD = {
      list: data.list,
      pages: {
        total: data.total,
        pageSize: params.pageSize,
        currentPage: data.page
      }
    }

    return this.productsListJD
  }

  // 匹配商品库存
  @action
  getStocks = async params => {
    const localList = [...params.list]

    for (const row of localList) {
      for (const { thirdPartySkuId, stockNum, isExistStock } of params.data.values()) {
        if (thirdPartySkuId === row.jdSkuId) {
          row['stock'] = stockNum > 0 ? stockNum : isExistStock === 1 ? '有货' : '无货'
        }
      }
    }
    return localList
  }

  // 获取京东商品库存
  @action
  getGoodsStock = async params => {
    const { data } = await httpClient.post(goods.GET_JD_STOCKS, { ...params })
    const { list, pages } = this.productsListJD
    const newList = await this.getStocks({ data, list, pages })
    runInAction(() => {
      this.productsListJD.list = newList
    })
  }


  // 更新市场价-多个商品
  @action
  updateMulMarketPrice = async (params) => {
    return await httpClient.post(goods.GET_NEW_MARKET_PRICE_MUL, { ...params })
  }

  // 获取地址库-供应链
  @action
  getAddress = async (params) => {
    const res = await httpClient.post(goods.GET_ADDRESS, { ...params })
    if (res.success) {
      return res.data
    }
  }

  // 获取弹窗调价规则回显信息
  @action
  getPriceRule = async (params) => {
    if (params.supplierPlatform === 'jd') {
      const { data } = await httpClient.post(goods.GET_JD_PRICE_RULE, { ...params })
      this.priceRuleInfo = {
        ...data,
        apiToShopParamInfo: data.apiToShopParamList[0] ? toJS(data.apiToShopParamList[0]) : { stairParamList: [] }
      }
    } else if (params.supplierPlatform === 'self') {
      const { data } = await httpClient.post(goods.GET_SELF_PRICE_RULE, { ...params })
      this.selfPriceRuleInfo = {
        stairParamList: [],
        ...data,
      }
    }
  }


  // 商城-挑选商品-提交改价
  @action
  sendPriceRule = async (params) => {
    return params.supplierPlatform === 'jd'
      ? await httpClient.post(goods.SEND_JD_PRICE_RULE, { ...params })
      : await httpClient.post(goods.SEND_SELF_PRICE_RULE, { ...params })
  }


  // 采购商城-对选择的商品批量操作 （1上架 2下架 3删除 4驳回）
  @action sendPickedGoods(params) {
    switch (params.modalType) {
      case -10:
        return httpClient.post(goods.SET_PRODUCT_ONSALE, { ...params });
        break
      case 10:
        return httpClient.post(goods.SET_PRODUCT_OFFSALE, { ...params });
        break;
      case 30:
        return httpClient.post(goods.DEL_PRODUCT, { ...params });
        break;
      case 4:
        return httpClient.post(goods.REJECT_PRODUCT, { ...params });
        break;
    }
  }

  // 重置调价-全部
  @action resetPurchasePriceRuleInfo(params) {
    this.priceRuleInfo = {
      apiToShopParamInfo: {
        stairParamList: []
      }
    }
    this.selfPriceRuleInfo = {
      // stairParamList: []
    }
  }

  // 京东商品改价-点击手动爬虫获取当前市场价-单个商铺
  @action
  async getNewMarketPrice(params) {
    const { data } = await httpClient.get(goods.GET_NEW_MARKET_PRICE, { params })
    this.priceRuleInfo.marketPrice = data
    // this.priceRuleInfo.marketPrice = 20000
  }

  // 获取全部分类树
  @action
  async getCateTree(params) {
    const { data } = await httpClient.post(goods.GET_CATE_TREE, { page: 1, pageSize: 100, parentId: 0 })
    let newData = JSON.parse(JSON.stringify(data).replace(/childNodes/g, "children"));
    this.cateTree = formatStoreCateTree(newData)
  }

}


export default new GoodsJdStore();
