// dashboard
// finance
// order
import OrderChangeMoneyModal from './order/ChangeMoney'
import OrderGoodsDeliverModal from './order/GoodsDeliver'
import OrderExpressInfoModal from './order/ExpressInfo'

import OrderServiceDealModal from './order/DealService'
import OrderServiceRefundModal from './order/RefundService'
import OrderServiceDealDeliverModal from './order/DealDeliver'

// activity
// goods
import GoodsChooseCategoryModal from "./goods/ChooseCategory"
import GoodsModifyModal from './goods/Modify'

// store
import StoreAddAddressModal from "./store/AddAddress"
import StoreChooseAreaModal from "./store/ChooseArea"
import StoreChooseAddressModal from "./store/ChooseAddress"

// supply
import SupplyChangePwdModal from './supply/ChangePwd'
import SupplyChangePhoneModal from './supply/ChangePhone'

export default {
  // dashboard
  // finance
  // order
  OrderChangeMoneyModal,
  OrderGoodsDeliverModal,
  OrderExpressInfoModal,

  OrderServiceDealModal,
  OrderServiceRefundModal,
  OrderServiceDealDeliverModal,
  // activity
  // goods
  GoodsChooseCategoryModal,
  GoodsModifyModal,
  // store
  StoreAddAddressModal,
  StoreChooseAreaModal,
  StoreChooseAddressModal,

  // supply
  SupplyChangePwdModal,
  SupplyChangePhoneModal,
}
