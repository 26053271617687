import React, { useState, useEffect } from 'react';
import styles from './index.module.scss'
import { Modal, Divider, Table, Button, Space, Input } from "antd";
import stores from "stores"


const { goodsOwnStore,commonStore } = stores
// 100  商铺
// 110  卡券

const storesMap = [
  {
    id: 1,
    type: "purchase",
    name: <span>发布集采批发商品<br />（公开商品）</span>,
    disabled: false
  }, {
    id: 2,
    type: "once",
    name: <span>发布一件代发商品<br />（公开商品）</span>,
    disabled: false
  }, {
    id: 3,
    type: "self",
    name: <span>发布限定渠道商品<br />（非公开商品）</span>,
    disabled: false
  }
]
const conditionMap = [
  { id: 1, name: "快速发布商品" },
  { id: 2, name: "发布全新商品" },
]

function Before(props) {
  const [storeList, setStoreList] = useState(storesMap)
  const [selectedStore, setSelectedStore] = useState(storesMap[0])
  const [conditionBtn, setConditionBtn] = useState(1)
  const [goodsList, setGoodsList] = useState(undefined)
  const [selectedGoods, setSelectedGoods] = useState(undefined)
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    refresh && setTimeout(() => setRefresh(false));
  }, [refresh]);
  useEffect(() => {
    setRights()
  }, [])

  const setRights = async () => {
    await commonStore.getAccountRights();
    let accountRightsStr = localStorage.getItem("accountRights");
    let accountRights = accountRightsStr ? JSON.parse(accountRightsStr) : null;
    if (!accountRights.shopOpend) {
      storesMap[0].disabled = true;
      setSelectedStore(storesMap[1])
    }
    // if(!accountRights.haveManagePower){
    //   storesMap[2].disabled = true;
    // }

    if (+accountRights.supplyMode === 1) {
      storesMap[2].disabled = true;
    }
    if (+accountRights.supplyMode === 2) {
      storesMap[1].disabled = true;
      if (!accountRights.shopOpend) {
        setSelectedStore(storesMap[2])
      }
    }
    setRefresh(true)
  }

  let timeout = null;
  const search = e => {
    let val = e.target.value.trim();
    console.log(val)
    let params = {
      goodsStatusList: [10, -10],
      keyword: val,
      pageNo: 1,
      pageSize: 10,
    }
    if (val) {
      clearTimeout(timeout)
      timeout = setTimeout(async () => {
        // 节流请求
        let res = await goodsOwnStore.getGoodsList(params)
        console.log(res.list)
        setGoodsList(res.list)
      }, 500)
    } else {
      clearTimeout(timeout)
    }
  }

  const columns = [{
    dataIndex: "goodsId"
  }, {
    dataIndex: "mainImage",
    render: text => <img src={text} alt="" className={styles.table_item_pic} />
  }, {
    dataIndex: "name",
    render: text => <p className={styles.table_item_name}>{text}</p>
  },]

  const rowSelection = {
    type: "radio",
    onChange: (keys, rows) => {
      console.log(keys, rows)
      setSelectedGoods(keys[0])
    }
  }

  const confirm = () => {
    if (+conditionBtn === 2) {
      window.open(`/goods/edit?type=add&storeType=${selectedStore.type}`, "_self")
    } else {
      window.open(`/goods/edit?type=add&storeType=${selectedStore.type}&id=${selectedGoods}`, "_self")
    }
  }
  return (
    <div className={styles.page_container}>
      <div className={styles.page_title}>
        <span>发布商品</span>
      </div>
      <Divider />
      <div className={styles.page_content}>
        <div className={styles.store_container}>
          {storeList.map(item =>
            <div
              className={`${styles.store_item} ${selectedStore.id === item.id ? styles.store_active : ""} ${item.disabled ? styles.store_disabled : ""}`}
              onClick={() => !item.disabled && setSelectedStore(item)}
              key={item.id}>
              {item.name}
            </div>)}
        </div>
        <div className={styles.condition_btn_container}>
          <Space size={16}>
            {conditionMap.map(item =>
              <Button type={conditionBtn === item.id ? "primary" : "default"}
                size="large"
                onClick={() => setConditionBtn(item.id)}>{item.name}</Button>)}
          </Space>
          {+conditionBtn === 1 ? <>
            <p className={styles.condition_btn_tip}>您可以通过已经发布在您店内的商品，快速复制一个新商品。</p>
            <div className={styles.search_container}>
              <Space className={styles.search_line} size={32}>
                <div className={styles.search_label}>
                  <span className={styles.label_line1}>查找已发布商品:</span>
                  <span className={styles.label_line2}>（包含已上架和已下架商品）</span>
                </div>
                <Input size="large" placeholder="输入商品ID/商品名称" style={{ width: 400 }} allowClear onChange={search} />
              </Space>
            </div>
            {!!goodsList &&
              <div className={styles.table_container}>
                <p className={styles.table_tip}>为您找到以下商品，选择商品，将复制该商品的所有详情信息：</p>
                <div className={styles.table_wrapper}>
                  <Table columns={columns}
                    rowSelection={rowSelection}
                    dataSource={goodsList}
                    rowKey={record => record.goodsId}
                    pagination={false}
                    showHeader={false} />
                </div>
              </div>}
          </> : <div style={{ height: 117 }} />}
        </div>
        <div className={styles.confirm_container}>
          <Button type="primary" size="large" style={{ width: 336 }}
            disabled={+conditionBtn === 1 && !selectedGoods}
            onClick={() => confirm()}>{+conditionBtn === 1 ? "下一步，完善商品信息" : "下一步，填写商品信息"}</Button>
        </div>
      </div>
    </div>
  );
}

export default Before;
