import React, { useState, useEffect, useContext } from 'react'
import styles from './index.module.scss'
import { Tabs,Input, Button,Pagination, Table } from 'antd'
import { FieldTimeOutlined } from '@ant-design/icons'
import activityStore from 'stores/actManagement'
import moment from 'moment'
import { observer } from 'mobx-react'
import usePage from 'hooks/page'
const { TabPane } = Tabs

function ActSquare(props) {
  const { ActSquareList,tabKey } = activityStore
  const initParams = {
    activityIdOrName:'',//活动名称id
    activityType:tabKey,//活动类型 1-派样，2-每日必选活动，3-返点活动，4-营销活动
    orderField:'', //排序字段, 最新发布时间:create_time,报名开始时间:apply_start_time,活动开始时间:start_time,默认是create_time
  }

  const getData = async(params) => {
    return activityStore.getActSquareList(params)
  }

  const { pagination,setParams,getInfo,paramsData } = usePage(initParams,getData)
  
  const tabList = [
    { name: '所有活动', key: '' },
    { name: '派样中心', key: '1' },
    { name: '每日必推', key: '2' },
    { name: '返点广场', key: '3' },
    { name: '专题活动', key: '4' },
  ]
  
  const [actOption, setOption] = useState(0)
  const [keyWord,setKeyWord] = useState('')
  const callback = (val) => {
    setParams({...initParams,activityType:val,pageSize:20,pageNo:1})
    activityStore.setActTabKey(val)
  }
  const chooseOption = (index) => {
    let field = index == 0 ? 'create_time' : index== 1 ? 'apply_start_time' : index== 2 ? 'start_time' : ''
    setParams({...paramsData,pageNo:1,orderField:field})
    setOption(index)
  }
  const inputChange = (e) => {
    setKeyWord(e.target.value)
  }
  const handleSearch = () => {
    setParams({...paramsData,activityIdOrName:keyWord,pageNo:1})
  }

  const countRemain = (item) => {
    const now = moment(moment(new Date()).format('YYYY-MM-DD HH:mm:ss'))
    const itemTime = moment(moment(item.endTime).format('YYYY-MM-DD HH:mm:ss'))
    if(itemTime < now) {
      return `报名已结束`
    }else {
      //相差毫秒数
      const diff = itemTime.diff(now)
      const dayDiff = Math.floor(diff / (24 * 3600 * 1000));//计算出相差天数
      const leave1 = diff % (24 * 3600 * 1000)    //计算天数后剩余的毫秒数
      const hours = Math.floor(leave1 / (3600 * 1000))//计算出小时数
      return `${dayDiff}天${hours}小时`
    }
  }
  


  return (
    <div className={styles.actSquare_container}>
      <Tabs onChange={callback} activeKey={tabKey}>
        {tabList.map((item, index) => (
          <TabPane key={item.key} tab={item.name}>
            <div className={styles.options}>
              {Array.from({ length: 3 }).map((item, index) => {
                return (
                  <span
                    key={index}
                    className={actOption === index ? styles.active : ''}
                    onClick={() => chooseOption(index)}
                  >
                    {index === 0
                      ? '最新发布时间'
                      : index === 1
                      ? '报名开始时间'
                      : '活动开始时间'}
                  </span>
                )
              })}
              <div style={{ display:'flex',flexGrow:'1',justifyContent:'flex-end' }}>
                <Input value={keyWord} onChange={inputChange} placeholder='请输入活动ID/活动名称' style={{ width:'200px'}} />
                <div onClick={handleSearch} style={{ width:'100px',background:'#0085ff',color:'#fff',textAlign:'center',lineHeight:'32px',marginLeft:'20px'}}>搜索</div>
              </div>
              
            </div>
            <div className={styles.actList}>
              {ActSquareList.map((item, index) => {
                return (
                  <div className={styles.actItem} key={item.id}>
                    <div className={styles.actItem_name}>
                      <p>{ item.activityType == 1 ? '派样活动' : item.activityType == 2 ? '每日必推' : item.activityType == 3 ? '返点活动' : '营销活动'}</p>
                      <div className={styles.actItem_time}>
                        <FieldTimeOutlined />
                <span>报名剩余：{ countRemain(item) }</span>
                      </div>
                    </div>
                    <div className={styles.act_desc}>
                      <div className={styles.act_desc_title}>
                        { item.activityName }
                      </div>
                      <div style={{ marginTop: '11px' }}>
                        { item.registrationInstruction }
                      </div>
                      <div style={{ marginTop: '21px' }}>
                        报名时间：{`${moment(item.applyStartTime).format('YYYY-MM-DD HH:mm:ss')}~${moment(item.applyEndTime).format('YYYY-MM-DD HH:mm:ss')}`}
                      </div>
                      <div style={{ marginTop: '11px' }}>
                        活动时间：{`${moment(item.startTime).format('YYYY-MM-DD HH:mm:ss')}~${moment(item.endTime).format('YYYY-MM-DD HH:mm:ss')}`}
                      </div>
                      <div style={{ marginTop: '11px' }}>
                        已报名： <span>{item.totalSignUpNumber}</span>
                      </div>
                    </div>
                    <div className={styles.act_btn}>立即报名</div>
                  </div>
                )
              })}
            </div>
            <div style={{ display:'flex',justifyContent:'flex-end',marginTop:'20px'}}>

            <Pagination total={ pagination.total }  pageSize={ pagination.pageSize } current={ pagination.current }
              showSizeChanger={pagination.showSizeChanger} showQuickJumper={pagination.showQuickJumper}
              pageSizeOptions={pagination.pageSizeOptions} 
              onChange={pagination.onChange} onShowSizeChange={ pagination.onShowSizeChange }
              showTotal={pagination.showTotal}
            />
            </div>
            
          </TabPane>
        ))}
      </Tabs>
    </div>
  )
}

export default observer(ActSquare)
