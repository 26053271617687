const api = {
  // 通用接口
  common: {
    POST_GET_SMS_CODE: '/elebuys/basic/sms/sms/sms-code', //获取短信验证码
    POST_LOGIN_BY_PWD: '/merchant/right/login/login/telephoneAuth/step1', //密码登录
    POST_LOGIN_BY_PWD_2: '/merchant/right/login/login/telephoneAuth/step2', //密码登录
    POST_LOGIN_BY_SMS: '/merchant/right/login/login/telephone/step1', //验证码登录
    POST_LOGIN_BY_SMS_2: '/merchant/right/login/login/telephone/step2', //验证码登录
    POST_LOGOUT: '/merchant/right/login/logout', //退出
    GET_ACCOUNT_RIGHTS: '/merchant/shpplier-web/check-power', //查询账户是否开通商铺，是否有供应商管理权限
    GET_SUPPLIER_SIMPLE_LIST:
      '/merchant/shpplier-web/all-short-names/by-master', //查询供应商列表（下拉）

    GET_LEVEL_PROVINCE_CITY_DISTRICT: '/basic/area/areas', // 获取三级地址
    POST_GET_LEVEL_CATEGORY: '/merchant/product/category/getList', //获取两级分类
    POST_GET_SEARCH_CATEGORY:'/merchant/product/category/searchByName',//获取关键字搜索分类
    POST_UPLOAD_FILE_RETURN_ID: '/basic/file/uploadNew', // 上传文件返回id
    POST_BATCH_UPLOAD_FILE_RETURN_IDS: '/basic/file/batchUploadNew', // 批量上传文件返回
    POST_UPLOAD_FILE: '/basic/file/upload', //上传单个文件
    POST_UPLOAD_FILES: '/basic/file/batchUpload', //上传多个文件
    GET_EXPRESS_LIST: '/basic/express/list', //物流公司列表
    GET_EXPRESS_INFO: '/basic/express/queryLogisticByExpressNo', //查询物流信息
  },
  // 商品模块
  goods: {
    POST_GET_BRAND_LIST: '/merchant/product/brand/getListPage', //获取品牌列表
    POST_GET_BRAND_INFO_BY_ID: '/merchant/product/brand/getInfo', //通过id获取品牌信息
    POST_ADD_GOODS: '/merchant/product/goods/add', //商品录入
    POST_UPDATE_GOODS: '/merchant/product/goods/edit', //商品编辑
    POST_GET_EDIT_INFO: '/merchant/product/goods/getEditInfo', //获取商品编辑信息
    GET_GOODS_LIST: '/merchant/product/goods/search/getSupplyList', //获取商品列表
    POST_DOWN_GOODS: '/merchant/product/goods/offShelf', //下架商品
    POST_UP_GOODS: '/merchant/product/goods/onlineShelf', //上架商品
    POST_DEL_GOODS: '/merchant/product/goods/remove', //删除商品
    POST_MODIFY_APPLY_LIST: '/merchant/product/goods/change/getList', //商品变更列表
    POST_MODIFY_APPLY_DETAIL: '/merchant/product/goods/change/getInfo', //商品变更详情
    POST_MODIFY_APPLY: '/merchant/product/goods/change/apply', //商品变更申请
    POST_CHECK_GOODS_IN_ACTIVITY: '/supply/product/goods/isJoinActivity', //判断商品是否参加活动

    // JD
    GET_JD_STORE_LIST: '/merchant/product/goods/search/getJdListChoose',
    GET_ELESTORE_JD_LIST: '/merchant/product/goods/search/getJdListShop',
    GET_JD_STOCKS: '/merchant/product/stock/getStockJdBatch',
    GET_NEW_MARKET_PRICE: '/merchant/jd/getMarketPrice',
    GET_NEW_MARKET_PRICE_MUL: '/merchant/jd/syncMarketPrice',
    GET_ADDRESS: '/basic/area/areas',
    GET_JD_PRICE_RULE: '/merchant/product/goods/pick/getApiToShopEcho',
    GET_SELF_PRICE_RULE: '/merchant/product/goods/pick/getSelfToShopEcho',
    SEND_JD_PRICE_RULE: '/merchant/product/goods/pick/apiToShop',
    SEND_SELF_PRICE_RULE: '/merchant/product/goods/pick/selfToShopBatch',
    GET_CATE_TREE: '/merchant/product/category/getTreeList',
    SET_PRODUCT_ONSALE: '/merchant/product/pool/goods/onlineShelfBatch',
    SET_PRODUCT_OFFSALE: '/merchant/product/pool/goods/offShelfBatch',
    DEL_PRODUCT: '/merchant/product/pool/goods/removeBatch',
    REJECT_PRODUCT: '/merchant/product/pool/rejectBatch',

    //3.0资源库
    POST_RESOURCE_LIST: '/merchant/product/spu/getListByPage', //3.0资源库列表
    POST_RESOURCE_INFO: '/merchant/product/spu/getInfo', //3.0资源库详情
    POST_UP_RESOURCE: '/merchant/product/spu/shelves', //3.0资源库上架

    //供应商商品
    POST_ADD_SUPPLIER_GOODS: '/supply/product/goods/add', //新增供应商商品
    POST_EDIT_SUPPLIER_GOODS: '/supply/product/goods/edit', //编辑供应商商品
    POST_GET_SUPPLIER_GOODS_INFO: '/supply/product/goods/getGoodsInfo', //获取供应商商品信息
    POST_SUPPLIER_GOODS_LIST: '/supply/product/search/goodsSearch', //获取供应商商品列表
    POST_SUPPLIER_GOODS_OFFSHELF: '/supply/product/goods/offShelf', //单商品下架
    POST_SUPPLIER_GOODS_ONLINESHELF: '/supply/product/goods/onlineShelf', //单商品上架
    POST_SUPPLIER_GOODS_DETAIL: '/supply/product/goods/preview', //商品详情
    POST_GOODS_BRAND_LIST: '/supply/product/brand/list', //获取商品品牌列表
    POST_GOODS_BRAND: '/supply/product/brand/get', //通过brandId获取品牌
    POST_GOODS_CATEGORY_SEARCH: '/supply/product/category/search', //商品分类搜索
    POST_GOODS_CATEGORY: '/supply/product/category/get', //通过id查找category对象
    POST_GOODS_CATEGORY_LIST: '/supply/product/category/getList', //通过parentId查询商品分类

    POST_GET_PICK_GOODS_LIST:
      '/merchant/activity/sample/goods/searchPurchaseGoods', //获取派样挑选商品列表

    //京东api
    GET_JD_CACHE_STOCK_AREA: '/supply/jd/listStockArea', //缓存库查询城市
    GET_JD_PRODUCT_LIST: '/supply/jd/listSelectProduct', //查询京东待挑选商品信息
    GET_JD_CATEGORY_LIST: '/supply/jd/listCategory', //京东分类查询
    GET_JD_AREA_LIST: '/supply/jd/listArea', //京东地区查询
    GET_JD_PRODUCT_DETAIL: '/supply/jd/getProductDetails', //京东商品详情查询
    GET_JD_MARKET_PRICE: '/supply/jd/getMarketPrice', //实时爬取京东商品市场价
    GET_JD_PRODUCT_INFO: '/supply/jd/getJdProduct', //获取京东商品信息
    POST_JD_PICK_GOODS: '/supply/product/standard/jd', //挑选京东商品

    // 资源库
    POST_GET_RESOURCE_LIST: '/supply/product/spu/getListByPage', //获取资源库列表
    POST_GET_RESOURCE_PRODUCT_INFO: '/supply/product/spu/getInfo', //获取资源库产品详情
    POST_GET_RESOURCE_PRODUCT_BASIC_INFO: '/supply/product/spu/getEasyInfo', //获取资源库产品基本详情
    POST_EDIT_RESOURCE_PRODUCT_BASIC_INFO: '/supply/product/spu/edit', //编辑资源库产品基本详情
    POST_UP_SHELF_RESOURCE_PRODUCT: '/supply/product/spu/shelves', //上架资源库商品

    // 详情
    POST_GET_GOODS_DETAIL: '/merchant/product/goods/preview', //获取商品详情
    POST_GET_FREIGHT_DETAIL: '/merchant/basic/freightTemplate/get', //获取运费模板详情
    POST_GET_FREIGHT: '/basic/freight-template/calculate', //获取区域运费

    // 变更
    POST_REQUEST_MODIFY: '/merchant/product/goods/change/apply', //申请商品变更
    POST_GET_MODIFY_LIST: '/merchant/product/goods/change/getList', //获取变更列表
    POST_GET_MODIFY_DETAIL: '/merchant/product/goods/change/info', //获取变更详情
    POST_CHECK_IS_IN_ACTIVITY: '/merchant/product/activity/isJoinActivity', //校验是否参加活动
  },

  //活动模块
  activity: {
    ACT_SQUARE_LIST: '/merchant/marketing/activity/rebate/getActivityList',
    // 派样活动
    POST_GET_SIGN_UP_LIST: '/merchant/marketing/activity/sample/applying-list', //获取报名中列表
    GET_SIGN_UP_RECORD_LIST: '/merchant/activity/sample/goods/listByPage', //获取报名记录列表
    POST_GET_USER_INFO_LIST: '/merchant/marketing/activity/sample/listByPage', //用户资料列表
    GET_ACTIVITY_INFO: '/merchant/marketing/activity/sample/getDetail', //获取派样活动详情
    POST_PRESENT_ACTIVITY: '/merchant/activity/sample/goods/report', //提报活动
    GET_DOWNLOAD_USER_INFO: '/merchant/marketing/activity/sample/export', //导出用户资料
    POST_EXIT_ACTIVITY: '/merchant/activity/sample/goods/applyExitActivity', //退出活动
  },

  //商铺模块
  store: {
    // 商铺信息
    GET_STORE_INFO: '/merchant/shop-manage/detail/by-company-id', //获取商铺信息
    GET_STORE_SETTING_INFO: '/merchant/shop-manage/setup', //获取要编辑的商铺信息
    POST_UPDATE_STORE_INFO: '/merchant/shop-manage/setup/modify', //修改商铺信息

    // 运费模板部分
    POST_FREIGHT_LIST: '/merchant/basic/freightTemplate/templates', //运费模板列表
    GET_FREIGHT_SIMPLE_LIST: '/merchant/basic/freightTemplate/templates-simple', //运费模板简单列表
    POST_FREIGHT_DETAIL: '/merchant/basic/freightTemplate/get', //获取运费模板详情
    POST_ADD_FREIGHT: '/merchant/basic/freightTemplate/save', //增加运费模板
    POST_UPDATE_FREIGHT: '/merchant/basic/freightTemplate/update', //修改运费模板
    POST_DELETE_FREIGHT: '/merchant/basic/freightTemplate/delete', //删除运费模板
    GET_FREIGHT_AREA: '/basic/dict/dictionaries', //获取运费模板区域

    //地址管理部分
    GET_lOCATION_LIST: '/elebuys/basic/delivery-address/listDeliveryAddress', //获取地址列表
    POST_GET_DEFAULT_LOCATION:
      '/elebuys/basic/delivery-address/getDefaultDeliveryAddress', //获取默认地址
    POST_ADD_lOCATION: '/elebuys/basic/delivery-address/save', //新增地址
    POST_UPDATE_lOCATION: '/elebuys/basic/delivery-address/update', //修改地址
    POST_DELETE_lOCATION: '/elebuys/basic/delivery-address/delete', //删除地址
    POST_SET_DEFAULT_LOCATION: '/elebuys/basic/delivery-address/choose', //设置默认地址
  },
  //订单
  order: {
    POST_GET_ORDER_LIST: '/merchant/supplier/order/listByPage', //获取订单列表
    // GET_ORDER_DETAIL: '/merchant/shop/order/getShopOrderDetails', //获取订单详情
    GET_ORDER_DETAIL: '/merchant/shop/order/getShopOrderDetailsByParentOrderNo', //获取订单详情
    GET_ORDER_DETAIL_SASS:
      '/merchant/supplier/order/getSupplierOrderDetailsByParentOrderNo', // 获取sass订单详情
    GET_MODIFY_PRICE_INFO: '/merchant/shop/order/listChangePrice', //获取改价商品列表
    POST_MODIFY_PRICE: '/merchant/shop/order/changePrice', //改价
    POST_DELIVER_GOODS: '/merchant/supplier/order/deliverGoods', //发货
    GET_DELIVER_INFO: '/merchant/shop/order/listShopDeliveryInfo', //查询发货信息

    GET_ONCE_DELIVER_INFO: '/merchant/supplier/order/listDeliveryInfo', //查询一件代发发货信息
    GET_ONCE_ORDER_DETAIL:
      '/merchant/supplier/order/getSupplierOrderDetailsByParentOrderNo', //获取一件代发订单详情（子订单号查询）
    POST_DELIVER_ONCE_GOODS: '/merchant/supplier/order/deliverGoods', //一件代发发货

    // 售后
    POST_GET_SERVICE_LIST: '/merchant/order/refund/listByPage', //退款列表
    GET_SERVICE_INFO: '/merchant/order/refund/detail', //退款详情
    POST_REFUND_VERIFY: '/merchant/order/refund/audit', //退款审核
    POST_REFUND_APPLY: '/merchant/order/refund/refund', //退款
    POST_CHANGE_GOODS: '/merchant/order/refund/saveExchangeExpressInfo', //商家换货
  },
  finance: {
    GET_OVERVIEW_DATA: '/balance/supply/getBalanceCenterAmount', //获取概览数据
    GET_MANAGE_BASE_DATA: '/balance/supply/queryAmountBeforeApply', //结算管理头部信息
    GET_SETTLEMENT_RECORD_LIST: '/balance/supply/billPage', //分页结算记录
    POST_DEAL_DETAIL_DATA: '/balance/supply/trade/amount', //交易明细统计数据
    POST_DEAL_DETAIL_LIST: '/balance/supply/trade/serial', //交易明细列表

    GET_SETTLEMENT_ACCOUNT_LIST: '/balance/account/getAll', //结算账户列表
    GET_COMPANY_INFO: '/balance/account/getCompanyName', //企业信息
    POST_ADD_SETTLEMENT_ACCOUNT: '/balance/account/create', //新建结算账户
    POST_DEL_SETTLEMENT_ACCOUNT: '/balance/account/delete', //删除结算账户
    POST_UPDATE_SETTLEMENT_ACCOUNT: '/balance/account/update', //更新结算账户

    GET_SETTLEMENT_BASIC_INFO: '/balance/supply/queryAmountBeforeApply', //结算发起获取结算基础信息
    GET_RECORD_DETAIL_LIST: '/balance/supply/queryPageInvoiceGoodsBeforeApply', //查询可以结算开票明细(合并过的商品详情)
    GET_ORDER_DETAIL_LIST: '/balance/supply/queryPageOrderGoodsBeforeApply', //查询可以结算原始订单明细(未合并的原始商品详情)
    GET_EXPORT_DETAIL: '/balance/supply/exportInvoiceGoodsBeforeApply', //导出明细
    GET_EXPORT_ORDER: '/balance/supply/exportOrderGoodsBeforeApply', //导出订单
    GET_DETAIL_RECORD_DETAIL_LIST:
      '/balance/supply/queryPageInvoiceGoodsAfterApply', //结算发起开票明细(合并过的商品详情)
    GET_DETAIL_ORDER_DETAIL_LIST:
      '/balance/supply/queryPageOrderGoodsAfterApply', //结算发起订单明细(未合并的原始商品详情)
    GET_DETAIL_EXPORT_DETAIL: '/balance/supply/exportInvoiceGoodsAfterApply', //导出明细(详情)
    GET_DETAIL_EXPORT_ORDER: '/balance/supply/exportOrderGoodsAfterApply', //导出订单(详情)
    POST_APPLY_SETTLEMENT: '/balance/supply/applyBill', //申请结算
    GET_INVOICE_INFO: '/balance/invoice/getFxInvoiceFullInfo', //查询飞象开票信息和收件人信息Invoice(这个接口运营端和供应商共用)
    POST_CANCEL_SETTLEMENT: '/balance/supply/cancelBill', //取消结算
    POST_SUBMIT_SETTLEMENT: '/balance/supply/updateBill', //提交结算
    GET_ONE_SETTLEMENT_INFO: '/balance/supply/get', //获取单个结算单信息
    GET_PARENT_ORDER_NO:
      '/merchant/shop/order/getShopOrderDetailsByParentOrderNo', //获取父订单号
  },
};

export default api;
