import { observable, action } from 'mobx';
import httpClient from 'utils/httpClient';
import api from 'config/api';
import { message } from 'antd';
import { addKey } from 'utils/tools';

const { goods } = api;

class SupplierStore {
  @observable supplierGoodsInfo = {};
  @observable supplierGoodsList = {
    list: [],
    page: 1,
    pageSize: 20,
    total: 0,
  };

  @observable brandGoodsList = [];
  @observable goodsCategorySearch = [];
  @observable categoryList = [];
  @observable modifyApplyList = {
    list: [],
    page: 1,
    pageSize: 20,
    total: 0,
  };
  @observable modifyApplyDetail = {};

  @observable pickGoodsList = {
    list: [],
    page: 1,
    pageSize: 20,
    total: 0,
  };

  /**
   * 新增供应商商品
   * @param params
   * @returns {Promise<void>}
   */
  @action
  postAddSupplierGoods = async (params) => {
    const data = await httpClient.post(goods.POST_ADD_SUPPLIER_GOODS, {
      ...params,
    });
    if (data.success) {
      message.success('添加成功');
    }
    return data;
  };

  /**
   * 编辑供应商商品
   * @param params
   * @returns {Promise<void>}
   */
  @action
  postEditSupplierGoods = async (params) => {
    const data = await httpClient.post(goods.POST_EDIT_SUPPLIER_GOODS, {
      ...params,
    });
    if (data.success) {
      message.success('编辑成功');
      window.history.go(-1);
    }
    return data;
  };

  /**
   * 获取供应商商品信息
   * @param params
   * @returns {Promise<void>}
   */
  @action
  postGetSupplierGoodsInfo = async (params) => {
    const data = await httpClient.post(goods.POST_GET_SUPPLIER_GOODS_INFO, {
      ...params,
    });
    if (data.success) {
      this.supplierGoodsInfo = data.data;
    }
  };

  /**
   * 获取供应商商品列表
   * @param params
   * @returns {Promise<void>}
   */
  @action
  postSupplierGoodsList = async (params) => {
    const { data } = await httpClient.post(goods.POST_SUPPLIER_GOODS_LIST, {
      ...params,
    });
    if (data) {
      this.supplierGoodsList = addKey(data);
    }
  };

  /**
   * 单商品下架
   * @param params
   * @returns {Promise<void>}
   */
  @action
  postSupplierGoodsOffShelf = async (params) => {
    const data = await httpClient.post(goods.POST_SUPPLIER_GOODS_OFFSHELF, {
      ...params,
    });
    if (data.success) {
      message.success('下架成功');
    }
  };

  /**
   * 单商品上架
   * @param params
   * @returns {Promise<void>}
   */
  @action
  postSupplierGoodsOnlineShelf = async (params) => {
    const data = await httpClient.post(goods.POST_SUPPLIER_GOODS_ONLINESHELF, {
      ...params,
    });
    if (data.success) {
      message.success('上架成功');
    }
  };

  /**
   * 商品详情
   * @param params
   * @returns {Promise<void>}
   */
  @action
  postSupplierGoodsDetail = async (params) => {
    const { data } = await httpClient.post(goods.POST_SUPPLIER_GOODS_DETAIL, {
      ...params,
    });
  };

  /**
   * 获取商品品牌列表
   * @param params
   * @returns {Promise<void>}
   */
  @action
  postGoodsBrandList = async (params) => {
    const { data } = await httpClient.post(goods.POST_GOODS_BRAND_LIST, {
      ...params,
    });
    if (data) this.brandGoodsList = data.list;
  };

  /**
   * 根据brandId获取品牌
   * @param params
   * @returns {Promise<void>}
   */
  @action
  postGoodsBrand = async (params) => {
    const data = await httpClient.post(goods.POST_GOODS_BRAND, { ...params });
    if (data) {
      this.brandGoodsList = [data.data];
    }
  };

  /**
   * 商品分类搜索
   * @param params
   * @returns {Promise<void>}
   */
  @action
  postGoodsCategorySearch = async (params) => {
    let { data } = await httpClient.post(goods.POST_GOODS_CATEGORY_SEARCH, {
      ...params,
    });
    if (data) {
      let reg = new RegExp(params.keyword, 'g');
      this.goodsCategorySearch = data.map((item) => {
        let str = item.categoryTreeName;
        item.renderName = str.replace(
          reg,
          `<span style="color:#f00">${params.keyword}</span>`
        );
        return item;
      });
    }
  };

  /**
   * 通过id获取category对象
   * @param params
   * @returns {Promise<{}|*>}
   */
  @action
  postGoodsCategory = async (params) => {
    const data = await httpClient.post(goods.POST_GOODS_CATEGORY, {
      ...params,
    });
    if (data.success) {
      return data.data;
    }
    return {};
  };

  /**
   * 通过parentId查询商品分类
   * @param params
   * @returns {Promise<void>}
   */
  @action
  postGoodsCategoryList = async (params) => {
    const { data } = await httpClient.post(goods.POST_GOODS_CATEGORY_LIST, {
      ...params,
    });
    if (data) {
      let arr = data.map((item) => ({
        parentId: item.parentId,
        id: item.categoryId,
        name: item.categoryName,
        level: item.categoryLevel,
      }));
      this.categoryList = arr;
      return arr;
    }
  };

  /**
   * 商品变更列表
   * @param params
   * @returns {Promise<void>}
   */
  @action
  postModifyApplyList = async (params) => {
    const data = await httpClient.post(goods.POST_MODIFY_APPLY_LIST, {
      ...params,
    });
    if (data.success) {
      this.modifyApplyList = addKey(data.data);
    }
  };

  /**
   * 商品变更详情
   * @param params
   * @returns {Promise<void>}
   */
  @action
  postModifyApplyDetail = async (params) => {
    const data = await httpClient.post(goods.POST_MODIFY_APPLY_DETAIL, {
      ...params,
    });
    if (data.success) {
      this.modifyApplyDetail = data.data;
    }
  };

  /**
   * 商品变更申请
   * @param params
   * @returns {Promise<void>}
   */
  @action
  postModifyApply = async (params) => {
    const data = await httpClient.post(goods.POST_MODIFY_APPLY, { ...params });
    return data;
  };

  /**
   * 判断商品是否参加活动
   * @param params
   * @returns {Promise<AxiosResponse<any>>}
   */
  @action
  checkGoodsInActivity = async (params) => {
    const res = await httpClient.post(
      goods.POST_CHECK_GOODS_IN_ACTIVITY,
      params
    );
    return res;
  };

  /**
   * 获取派样挑选商品列表
   * @param params
   * @returns {Promise<void>}
   */
  @action
  getPickGoodsList = async (params) => {
    const res = await httpClient.post(goods.POST_GET_PICK_GOODS_LIST, params);
    if (res.success) {
      this.pickGoodsList = res.data;
    }
  };
}

export default new SupplierStore();
