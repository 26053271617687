import React, {useState, useEffect} from 'react';
import {Button, Form, Input, Select, Space, Table, DatePicker} from 'antd'
import stores from 'stores'
import styles from './index.module.scss'
import {formatTime, showMoney} from "utils/tools";

const {Item} = Form
const {Option} = Select
const {RangePicker} = DatePicker

const {orderServiceStore} = stores

const auditStatusMap = new Map([
  [1, "待审核"],
  [2, "已通过"],
  [3, "已驳回"],
])

const statusMap = new Map([
  [2, '待退款'],
  [3, '退款中'],
  [4, '退款失败'],
  [5, '待买家退货'],
  [6, '待换货'],
  [7, '已完成'],
  [9, '已撤回'],
])

const typeMap = new Map([
  [0, "我要退款"],
  [1, "我要退货退款"],
  [2, "仅退款（不退货）"],
  [3, "我要换货"],
])

const verifyStatusMap = new Map([
  [1, "待处理"],
  [2, "已处理"],
  [3, "已驳回"],
])

function Service(props) {
  const [form] = Form.useForm()
  const [pageNo, setPageNo] = useState(1)
  const [pageSize, setPageSize] = useState(20)
  const [searchParams, setSearchParams] = useState({category: []})
  const [dataList, setDataList] = useState({list: [], total: 0});
  const [refreshData, setRefreshData] = useState(false);
  useEffect(() => {
    refreshData && _getData();
    refreshData && setTimeout(() => setRefreshData(false));
  }, [refreshData]);

  useEffect(() => {
    setRefreshData(true)
  }, [])

  const columns = [
    {title: "售后工单", align: "center", dataIndex: "id"},
    {title: "订单编号", align: "center", dataIndex: "orderId"},
    {
      title: "售后商品", align: "center",
      render: (text, record) => {
        let {goodsAttribute} = record.refundOrderGoodsBo[0];
        let str = '';
        if (goodsAttribute) {
          goodsAttribute = JSON.parse(goodsAttribute);
          str = `规格：${Object.values(goodsAttribute).join(' ')}`;
        }
        return (
          <div className={styles.item_container}>
            <div className={styles.item_img_container}>
              <img
                src={record.refundOrderGoodsBo[0].goodsImageUrl}
                className={styles.item_img}
                alt=""
              />
            </div>
            <div className={styles.item_info_container}>
              <p className={styles.item_name}>{record.refundOrderGoodsBo[0].goodsName}</p>
              <p className={styles.item_attr}>{str}</p>
            </div>
          </div>
        );
      }
    },
    {
      title: "销售价", align: "center",
      render: (text, record) => `¥${showMoney(record.refundOrderGoodsBo[0].orderPrice)}`
    },
    {
      title: "数量", align: "center",
      render: (text, record) => record.refundOrderGoodsBo[0].orderQuantity
    },
    {
      title: "售后类型", align: "center", dataIndex: "type",
      render: text => typeMap.get(text)
    },
    {
      title: "售后产品件数", align: "center",
      render: (text, record) => record.refundOrderGoodsBo[0].applyReturnQuantity
    },
    {
      title: "申请时间", align: "center", dataIndex: "createTime",
      render: text => formatTime(text)
    },
    {
      title: "审批状态", align: "center", dataIndex: "auditStatus",
      render: text => auditStatusMap.get(text)
    },
    {
      title: "售后状态", align: "center", dataIndex: "status",
      render: text => statusMap.get(text) || "--"
    },
    {
      title: "操作", align: "center", fixed: "right",
      render: (text, record) => <>
        <Button onClick={() => jump(record)}>查看详情</Button>
      </>
    },
  ]
  /**
   * 页码改变
   * @param page
   * @param pageSize
   * @returns {Promise<void>}
   */
  const changePage = (page, pageSize) => {
    setPageNo(page);
    setRefreshData(true)
  }

  /**
   * pageSize改变
   * @param current
   * @param size
   * @returns {Promise<void>}
   */
  const changePageSize = (current, size) => {
    setPageNo(1);
    setPageSize(size);
    setRefreshData(true)
  }
  const pagination = {
    total: dataList.total,
    pageSize,
    current: pageNo,
    showSizeChanger: true,
    showQuickJumper: true,
    pageSizeOptions: ["20", "50", "100"],
    defaultPageSize: 20,
    onChange: changePage,
    onShowSizeChange: changePageSize,
    showTotal: total => `共 ${total} 条数据`,
  }
  const _getData = async () => {
    let params = {
      pageNo,
      pageSize,
      createTimeStart: searchParams.timeRange && searchParams.timeRange[0] ? searchParams.timeRange[0].format("YYYY-MM-DD") + " 00:00:00" : undefined,
      createTimeEnd: searchParams.timeRange && searchParams.timeRange[1] ? searchParams.timeRange[1].format("YYYY-MM-DD") + " 23:59:59" : undefined,
      auditStatus: searchParams.auditStatus,
      status: searchParams.status,
      id: searchParams.workId,
      orderId: searchParams.orderId
    }
    let res = await orderServiceStore.getServiceList(params)
    setDataList(res)
  }

  const search = () => {
    form.validateFields().then(value => {
      console.log(value)
      setSearchParams(value)
      setPageNo(1)
      setRefreshData(true)
    })
  }

  const reset = () => {
    form.setFieldsValue({
      timeRange: undefined,
      auditStatus: undefined,
      status: undefined,
      workId: undefined,
      orderId: undefined
    })
    setSearchParams({})
    setPageNo(1)
    setRefreshData(true)
  }

  const jump = (item) => {
    window.open(`/order/service/detail?id=${item.id}`, "_self")
  }

  return (
    <>
      <div className={styles.search_line}>
        <Form layout="inline" form={form}>
          <Space>
            <Item name="timeRange" label="申请时间">
              <RangePicker placeholder={["申请时间开始", "申请时间结束"]}/>
            </Item>
            <Item name="auditStatus">
              <Select placeholder="审批状态">
                {Array.from(auditStatusMap).map(item =>
                  <Option value={item[0]} key={item[0]}>{item[1]}</Option>)}
              </Select>
            </Item>
            <Item name="status">
              <Select placeholder="售后状态">
                {Array.from(statusMap).map(item =>
                  <Option value={item[0]} key={item[0]}>{item[1]}</Option>)}
              </Select>
            </Item>
            <Item name="workId">
              <Input placeholder="服务工单查询"/>
            </Item>
            <Item name="orderId">
              <Input placeholder="订单编号查询"/>
            </Item>
            <Button type="primary" onClick={search}>筛选</Button>
            <Button type="default" onClick={reset}>重置</Button>
          </Space>
        </Form>

      </div>
      <Table columns={columns} dataSource={dataList.list}
             pagination={pagination} rowKey={record => record.key}/>
    </>
  );
}

export default Service;
