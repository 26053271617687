// 卡券查找
// 绑定类型
const bindTypeEunm = new Map([
  [1, '卡密'],
  [2, '手机号'],
  [3, '工号'],
  [4, '邮箱'],
  [5, '身份证'],
])

const REG_MAP = {
  img: /(\.bmp)|(\.gif)|(\.jpe?g)|(\.png)$/,
  ppt: /(\.pptx?)$/,
  pdf: /(\.pdf)$/,
  xls: /(\.xlsx?)$/,
  file: /(\.pptx?)|(\.pdf)|(\.xlsx?)$/,
}

// 商城
const stockOptionEunm = new Map([
  [1, '缓存库存查询'],
  [2, '精准库存查询（单页）']
])
const onSaleAppEnum = new Map([
  [10, '采购商城'],
  [11, '卡券应用'],
  [12, '福利应用'],
  [13, '营销应用'],
])

const changePriceBasicEnum = new Map([
  [1, '按供应价上调'],
  [2, '按市场价下调'],
  [3, '按供应价上调利润率的'],
])

const isAutoChangePriceEnum = new Map([
  [2, '智能定价'],
  [1, '手动定价'],
])

const isOnSaleStateEnum = new Map([
  [10, '上架'],
  [-10, '下架'],
])

export {
  bindTypeEunm,
  REG_MAP,

  stockOptionEunm,
  onSaleAppEnum,
  changePriceBasicEnum,
  isAutoChangePriceEnum,
  isOnSaleStateEnum
}
