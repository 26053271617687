import React, {useState} from "react";
import {Button, Form, Input, message, Select, Tabs} from "antd";
import MD5 from 'md5';
import styles from "./index.module.scss";
import {getQuery} from "utils/tools";
import stores from 'stores'

const {TabPane} = Tabs
const {useForm,} = Form
const {commonStore} = stores


function Login(props) {
  const [form1] = useForm()
  const [form2] = useForm()
  const [form3] = useForm()

  const [step, setStep] = useState(1)
  const [tab, setTab] = useState("1")
  const [canGetSms, setCanGetSms] = useState(true)
  const [getSmsBtn, setGetSmsBtn] = useState("获取验证码")
  const [step1Res, setStep1Res] = useState({})
  const [step1Params, setStep1Params] = useState({})
  const submitLogin = async () => {
    let params = {
      customerType: 5,
      siteType: 1,
    }
    if (tab === "1") {
      // 密码登录
      form1.validateFields().then(async values => {
        params.authType = 8
        params.smsCode = MD5(values.password)
        params.telephone = values.account
        setStep1Params(params)
        const res = await commonStore.loginByPwd(params)
        // console.log("step1==============", res)
        // alert(JSON.stringify(res))
        if (res.success) {
          setStep1Res(res.data)
          if (res.data.companyList.length > 1) {
            setStep(2)
          } else {
            await submitLoginStep2(params, res.data)
          }
        }
      }).catch(err => {
        console.log(err)
      })
    } else if (tab === "2") {
      // 验证码登陆
      form2.validateFields().then(async values => {
        params.authType = 2
        params.smsCode = values.smsCode
        params.telephone = values.account
        setStep1Params(params)
        const res = await commonStore.loginBySms(params)
        // console.log("step1==============", res)
        if (res.success) {
          setStep1Res(res.data)
          if (res.data.companyList.length > 1) {
            setStep(2)
          } else {
            submitLoginStep2(params, res.data)
          }
        }

      }).catch(err => {
        console.log(err)
      })
    }
  }
  const submitLoginStep2 = async (data, res) => {
    let params = {}
    if (data && res) {
      params = {
        authType: data.authType,
        customerType: data.customerType,
        siteType: data.siteType,
        userId: res.companyList[0].userList[0].userId
      }
    } else {
      let values = await form3.validateFields()
      params = {
        authType: step1Params.authType,
        customerType: step1Params.customerType,
        siteType: step1Params.siteType,
        userId: values.company
      }
    }
    let result = null;
    result = params.authType === 8 ?
      await commonStore.loginByPwd2(params) :
      await commonStore.loginBySms2(params)

    // alert(JSON.stringify(result))
    // console.log("step2==============", result)
    if (result.success) {
      message.success("登录成功！")
      loginCallback()
    }
  }
  let smsCodeInterval = null;
  const getSmsCode = () => {
    form2.setFieldsValue({smsCode: ""})
    form2.validateFields().catch(async error => {
      console.log(error)
      if (!error.errorFields.find(item => item.name[0] === "account")) {
        if (canGetSms) {
          let count = 60;
          setCanGetSms(false)
          setGetSmsBtn(`${count}后重新获取`)
          smsCodeInterval = setInterval(() => {
            if (count > 0) {
              count--;
              setGetSmsBtn(`${count}后重新获取`)
            } else {
              setGetSmsBtn("获取验证码")
              clearInterval(smsCodeInterval)
              setCanGetSms(true)
            }
          }, 1000)

          // 请求验证码接口
          let params = {
            mobile: error.values.account
          }
          let res = await commonStore.getSmsCode(params)
          if (res.success) {
            message.success("验证码发送成功！")
          } else {
            setGetSmsBtn("获取验证码")
            clearInterval(smsCodeInterval)
            setCanGetSms(true)
          }
        }
      }
    })

  }
  const loginCallback = async () => {
    await commonStore.getAccountRights();
    let query = getQuery();
    let returnUrl = query.returnUrl && query.returnUrl.indexOf("login") === -1 ? decodeURIComponent(query.returnUrl) : '/'
    setTimeout(() => window.open(returnUrl, "_self"), 1000)
  }
  return (<div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.left}>
          <img src="login_welcome.png" className={styles.welcome_pic} alt=""/>
        </div>
        {step === 1 &&
        <div className={styles.right}>
          <Tabs style={{width: "100%"}}
                size="large"
                activeKey={tab}
                onChange={val => setTab(val)}>
            <TabPane tab="密码登录" key="1">
              <Form form={form1}
                    style={{width: "100%", paddingTop: 50, paddingBottom: 24,}}
                    size="large">
                <Form.Item name="account"
                           rules={[{required: true, message: '请输入手机号！'},
                             {pattern: /^1[0-9]{10}$/, message: "请输入正确的手机号"}]}>
                  <Input placeholder="请输入手机号" allowClear maxLength={11}/>
                </Form.Item>
                <Form.Item name="password"
                           rules={[{required: true, message: '请输入登录密码！'}]}>
                  <Input.Password placeholder="请输入登录密码" allowClear visibilityToggle={false}/>
                </Form.Item>
              </Form>
            </TabPane>
            <TabPane tab="验证码登录" key="2">
              <Form form={form2}
                    style={{width: "100%", paddingTop: 50, paddingBottom: 24, position: 'relative'}}
                    size="large">
                <Form.Item name="account"
                           rules={[{required: true, message: '请输入手机号！'},
                             {pattern: /^1[0-9]{10}$/, message: "请输入正确的手机号"}]}>
                  <Input placeholder="请输入手机号" allowClear maxLength={11}/>
                </Form.Item>
                <Form.Item name="smsCode" style={{width: "60%"}}
                           rules={[{required: true, message: '请输入验证码！'}]}>
                  <Input placeholder="动态验证码" maxLength={6}
                         allowClear/>
                </Form.Item>
                <Button type="primary" style={{width: "40%", position: "absolute", right: 0, bottom: 48}}
                        onClick={getSmsCode}
                        disabled={!canGetSms}>{getSmsBtn}</Button>
              </Form>
            </TabPane>
          </Tabs>

          <Button type="primary" style={{width: "100%", marginTop: 30}} size="large" onClick={submitLogin}>登录</Button>
        </div>}
        {step === 2 &&
        <div className={styles.right}>
          <div style={{width: "100%"}}>
            <p className={styles.login_title}>企业选择</p>
            <p className={styles.title_tip}>账号属于多个企业/个体，请选择一个企业登陆</p>
            <Form form={form3}
                  style={{width: "100%", paddingTop: 50, paddingBottom: 24,}}
                  size="large">
              <Form.Item name="company"
                         rules={[{required: true, message: '请选择企业！'}]}>
                <Select placeholder="请选择企业" allowClear>
                  {step1Res.companyList.map(item => <Select.Option
                    value={item.userList[0].userId}>{item.companyName}</Select.Option>)}
                  {/*<Select.Option value={123}>123445</Select.Option>*/}
                </Select>
              </Form.Item>
            </Form>
          </div>
          <Button type="primary" style={{width: "100%", marginTop: 30}} size="large"
                  onClick={() => submitLoginStep2()}>登录</Button>
        </div>}
      </div>

    </div>
  );
}

export default Login;
