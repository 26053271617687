import React, {useState, useEffect} from "react";
import {Modal} from "antd";
import PropTypes from 'prop-types'


function PreviewPic(props) {
  const {pic, visible,onClose} = props;
  return   <Modal visible={visible} footer={null} onCancel={onClose}>
    <img alt="example" style={{width: '100%'}} src={pic}/>
  </Modal>
}

PreviewPic.propTypes = {
  pic: PropTypes.string,
  visible: PropTypes.bool,
  onClose: PropTypes.func
}

export default PreviewPic
