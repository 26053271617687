import {observable, action} from "mobx";
import httpClient from "utils/httpClient";
import api from "config/api"
import {addKey} from "utils/tools";

const {goods} = api;

class GoodsResourceStore {
  @observable resourceList = {list: [], total: 0}
  @observable resourceInfo = {}

  /**
   * 获取资源库列表
   * @param params
   * @returns {Promise<{total: number, list: []}>}
   */
  @action
  getResourceList = async params => {
    const res = await httpClient.post(goods.POST_RESOURCE_LIST, params)
    if (res.success) {
      this.resourceList = res.data
    }
    return this.resourceList
  }

  /**
   * 获取资源库详情
   * @param params
   * @returns {Promise<{}>}
   */
  @action
  getResourceInfo = async params => {
    const res = await httpClient.post(goods.POST_RESOURCE_INFO, params)
    if (res.success) {
      this.resourceInfo = res.data
    }
    return this.resourceInfo
  }

  /**
   * 上架资源库
   * @param params
   * @returns {Promise<AxiosResponse<any>>}
   */
  @action
  upResource = async params => {
    const res = await httpClient.post(goods.POST_UP_RESOURCE, params)
    return res
  }
}

export default new GoodsResourceStore();
