import {observable, action} from "mobx";
import httpClient from "utils/httpClient";
import api from "config/api"

const {order} = api;

class OrderServiceStore {
  @observable serviceList = {list: [], total: 0}
  @observable serviceInfo = {}

  /**
   * 退款列表
   * @param params
   * @returns {Promise<{total: number, list: []}>}
   */
  @action
  getServiceList = async params => {
    const res = await httpClient.post(order.POST_GET_SERVICE_LIST, params)
    if (res.success) {
      this.serviceList = res.data
    }
    return this.serviceList
  }

  /**
   * 退款详情
   * @param params
   * @returns {Promise<any>}
   */
  @action
  getServiceInfo = async params => {
    const res = await httpClient.get(order.GET_SERVICE_INFO, {params})
    if (res.success) {
      this.serviceInfo = res.data
    }
    return this.serviceInfo
  }

  /**
   * 退款审核
   * @param params
   * @returns {Promise<AxiosResponse<any>>}
   */
  @action
  refundVerify = async params => {
    const res = await httpClient.post(order.POST_REFUND_VERIFY, params)
    return res
  }

  /**
   * 退款
   * @param params
   * @returns {Promise<AxiosResponse<any>>}
   */
  @action
  refundApply = async params => {
    const res = await httpClient.post(order.POST_REFUND_APPLY, params)
    return res
  }

  /**
   * 商家换货
   * @param params
   * @returns {Promise<AxiosResponse<any>>}
   */
  @action
  changeGoods = async params => {
    const res = await httpClient.post(order.POST_CHANGE_GOODS, params)
    return res
  }


}

export default new OrderServiceStore()
