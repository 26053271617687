import { message } from 'antd'

export function judgeNum(num, type, lowerLimit = 0, upperLimit, reg, precision) {
  if (type === 'express') {
    reg = /^[a-zA-Z0-9]$/
    return num.split("").map(item => reg.test(item) ? item : '').join('').slice(0, 20)
  } else if (type === 'num') {
    if (num === '') {
      return ''
    } else if (isNaN(num) || +num < 1) {
      return 1
    } else if (+num > 999999999) {
      return 999999999
    } else {
      return parseInt(num)
    }
  } else if (type === 'num_0') {
    if (num === '') {
      return ''
    } else if (isNaN(num) || +num < 1) {
      return 0
    } else if (+num > 999999999) {
      return 999999999
    } else {
      return parseInt(num)
    }
  } else if (type === 'money') {
    upperLimit = '999999999.99';
    reg = /^(0|([1-9]\d{0,8}))(\.\d{0,2})?$/;
    precision = 2;
  } else if (type === 'weight') {
    lowerLimit = "0.0001"
    upperLimit = '99999.9999'
    reg = /^(0|([1-9]\d{0,4}))(\.\d{0,4})?$/;
    precision = 4;
  } else if (type === 'percent') {
    upperLimit = '999.99';
    reg = /^(0|([1-9]\d{0,2}))(\.\d{0,2})?$/;
    precision = 2;
  }
  if (num === '') {
    return ''
  } else if (isNaN(num)) {
    return '0'
  } else {

    if (reg.test(num)) {
      if (+num < +lowerLimit) {
        num = lowerLimit
      }
      if (+num > +upperLimit) {
        num = upperLimit
      }
      return num
    } else {
      if (+num < +lowerLimit) {
        return lowerLimit
      }
      if (+num > +upperLimit) {
        return upperLimit
      }
      return (+num).toFixed(precision)
    }

  }
}



