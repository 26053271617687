import {observable, action} from "mobx";
import httpClient from "utils/httpClient";
import api from "config/api"

const {store} = api;

class StoreInfoStore {

  /**
   * 获取商铺信息
   * @param params
   * @returns {Promise<{}|T>}
   */
  @action
  getStoreInfo = async params => {
    const res = await httpClient.get(store.GET_STORE_INFO, {params})
    if (res.success) {
      return res.data
    }
    return {}
  }

  /**
   * 获取要编辑的商铺信息
   * @param params
   * @returns {Promise<{}|T>}
   */
  @action
  getStoreSettingInfo = async params => {
    const res = await httpClient.get(store.GET_STORE_SETTING_INFO, {params})
    if (res.success) {
      return res.data
    }
    return {}
  }

  /**
   * 修改商铺信息
   * @param params
   * @returns {Promise<AxiosResponse<any>>}
   */
  @action
  updateStoreInfo = async params => {
    const res = await httpClient.post(store.POST_UPDATE_STORE_INFO, params)
    return res
  }


}

export default new StoreInfoStore()
