import React from 'react'
import { Table,Modal,Input,Form,Select, Button } from 'antd'
const { Option } = Select;


function SendSampleUserInfo () {
  const [form] = Form.useForm();
  const winOptions = [{name:'是否中奖',value:''},{name:'是',value:'1'},{name:'否',value:'2'}]
  const searchOptions = [{name:'全部',value:''},{name:'搜索活动名称',value:'1'},{name:'搜索联系人',value:'2'},
  {name:'搜索联系方式',value:'3'},{name:'搜索地址',value:'4'}]
  const handleSearch = () => {
    const data =  form.getFieldsValue()
    console.log(data)
  }
  const exportData = () => {
    // httpClient.get(operate.ACT_DATA_EXPORT,{ params, responseType: 'blob' } )
    // const data = await actDataStore.actListExport(_payload)
    // window.open(window.URL.createObjectURL(data))
    // axios if (res.config.url === '/admin/marketing/activity/sample/participant/export') {
    //   resolve(data);
    //   return
    // }
  }
  const columns = [
    {
      title: '活动名称',
      dataIndex: 'a',
      key: 'a',
    },
    {
      title: '是否中奖',
      dataIndex: 'a',
      key: 'a',
    },
    {
      title: '公司名称',
      dataIndex: 'a',
      key: 'a',
    },
    {
      title:'联系人',
      dataIndex: 'a',
      key: 'a',
    },
    {
      title:'联系方式',
      dataIndex: 'a',
      sortDirections:[ 'descend', 'ascend' ],
      key: 'a',
    },
    {
      title:'职位',
      dataIndex: 'a',
      sortDirections:[ 'descend', 'ascend' ],
      key: 'a',
    },
    {
      title:'名片',
      dataIndex: 'a',
      sortDirections:[ 'descend', 'ascend' ],
      key: 'a',
    },
    {
      title:'地址',
      dataIndex: 'a',
      key: 'a',
    },
  ];
  return(
    <div>
      <div style={{ display:'flex',justifyContent:'flex-end',marginBottom:'20px' }}>
        <Form form={form} layout='inline'>
          <Form.Item name='isWin'>
            <Select  style={{ width: 180 }} placeholder="搜索活动类型">
              {
                winOptions.map((item,index) => <Option key={ index } value={ item.value }>{ item.name }</Option>)
              }
            </Select>
          </Form.Item>
          <Form.Item name="actName">
            <Select  style={{ width: 180 }} placeholder="搜索活动名称">
              {
                searchOptions.map((item,index) => <Option key={ index } value={ item.value }>{ item.name }</Option>)
              }
            </Select>
          </Form.Item>

          <Form.Item name="goodsName">
            <Input placeholder="请输入关键字" />
          </Form.Item>

          <Form.Item>
            <Button type='primary' onClick={ handleSearch }>查询</Button>
            <Button style={{ marginLeft:'10px' }} onClick={ exportData }>导出</Button>
          </Form.Item>
        </Form>
      </div>
      <Table dataSource={[]} columns={columns}  />
    </div>
  )
}
export default SendSampleUserInfo