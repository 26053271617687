import React, { Component } from 'react';
import { Badge, Button, Card, Cascader, Checkbox, Col, Divider, Input, message, Modal, Popover, Radio, Row, Select, Space, Table, Tooltip, Upload, } from 'antd';
import { CloseCircleFilled, MinusOutlined, PlusOutlined, QuestionCircleOutlined, } from '@ant-design/icons';
import { inject, observer } from 'mobx-react';
import lodash from "lodash"
import { v4 } from 'uuid'

import { cartesianProduct, getBase64, getQuery, objectEquals, showMoney, } from 'utils/tools';
import Editor from 'components/Editor';
import { GoodsAddCard, PreviewPic } from 'components';
import { DATA_CODE, mainHost, taxRateOption } from 'config';
import { judgeNum } from 'utils/validate';
import styles from './index.module.scss';
import SortableUpload from 'components/SortableUpload';
import { Modals } from 'components'
import storeFreightStore from 'stores/store/freight';

const SelectOption = Select.Option;
const RadioGroup = Radio.Group;
const { GoodsChooseCategoryModal } = Modals

/**
 * 希望这是最后一次改商品了，淦！
 * 2020-11-28
 * 产品的蛇皮需求真垃圾，淦！
 *  
 */

/**
 *  disabled={this.modifyBasic()}
 * 
 * (!this.modifyBasic()) &&
 */

@inject(...['commonStore', 'localStore', 'goodsOwnStore', 'goodsResourceStore', 'storeFreightStore', "goodsModifyStore"])
@observer
class GoodsPublish extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageType: 'add',//页面类型 add ,edit ,modify
      poolType: "",//商品池类型 purchase | once | self
      modifyType: "",//basic | deal
      goodsId: '',// 商品id
      goodsState: '',// 商品状态 10 -20 -10
      spuId: '',
      poolId: "",
      supplierSimpleList: [],
      goodsSupplier: undefined,
      goodsType: 1, //商品类型
      goodsName: '', //商品名称
      goodsDesc: '', //商品描述
      goodsCategory: [], //商品类目
      goodsCategoryItem: [],//商品类目item
      showCategoryModal: false,
      categoryData: [],
      goodsProperty: [
        {
          key: 'brand',
          name: '商品品牌',
          value: undefined,
          canDel: false,
          isRequired: true,
        },
        {
          key: 'invoice',
          name: '发票类型',
          value: 13,
          canDel: false,
          isRequired: true,
        },
        {
          key: 'unit',
          name: '商品单位',
          value: '',
          canDel: false,
          isRequired: true,
        },
        // {
        //   key: 'location',
        //   name: '原产地',
        //   value: [],
        //   canDel: false,
        //   isRequired: false,
        // },
        {
          key: 'location',
          name: '原产地',
          value: '',
          canDel: false,
          isRequired: false,
        },
        {
          key: 'box',
          name: '箱规',
          value: '',
          canDel: false,
          isRequired: false,
        },
        {
          key: 'model',
          name: '商品货号',
          value: '',
          canDel: false,
        },
        {
          key: 'code',
          name: '商品条码',
          value: '',
          canDel: false,
        },
        // , {
        //   key: 4,
        //   name: "",
        //   value: "",
        //   canDel: true
        // }
      ], //商品属性
      locationData: [], //地址数据集
      goodsStandard: [
        // {
        //   standardName: "abc",
        //   hasPic: true,
        //   standardValues: [
        //     {
        //       value: "白色",
        //       pic: [{
        //         uid: '-1',
        //         name: 'image.png',
        //         status: 'done',
        //         url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
        //         response: {data: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png'}
        //       }]
        //     }, {
        //       value: "123",
        //       pic: []
        //     }
        //   ]
        // },
      ],
      goodsSkuList: [],
      goodsStairList: [
        {
          maxNum: '',
          minNum: '',
          price: '',
        },
      ],
      stairCheck: {
        suc: true,
        msg: ""
      },
      skuCheck: {
        suc: true,
        msg: ""
      },
      onceCheck: {
        suc: true,
        msg: ""
      },
      selfCheck: {
        suc: true,
        msg: ""
      },
      goodsMarketPrice: '', //市场价
      goodsCostPrice: '', //成本价
      goodsSupplyPrice: "",//供应价
      goodsSalePrice: "",//集采|销售|代发价
      goodsQuoteType: 1, //报价方式
      goodsQuoteTypeBatchVisible: [
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
      ], //报价方式批量设置tooltip显示
      goodsQuoteTypeBatchValue: ['', '', '', '', '', '', '', ''],
      //0供应价 supplyPrice ； 1成本价 costPrice ； 2市场价 marketPrice ； 3集采|销售|代发价 salePrice ； 4商品货号 goodsNo ； 5库存数（个） stock ； 6商品条码 upc
      goodsStartBuyNum: 1, //起批量
      goodsStock: '', // 现货库存
      freightTempList: [],
      goodsFreightTemp: undefined, //运费模板id
      goodsWeight: '', //商品重量
      goodsService: 1, //售后服务
      goodsFileList: [
        // {
        //   id: 0,
        //   uid: 0,
        //   url: "https://oss.elebuys.com/tmpdir/202009251706590006530257.png",
        //   type: "image/png",
        //   response: {
        //     success: true,
        //     data: "https://oss.elebuys.com/tmpdir/202009251706590006530257.png"
        //   }
        // },
        { id: 0 },
        { id: 1 },
        { id: 2 },
        { id: 3 },
        { id: 4 },
      ], //商品图
      goodsPicPreview: '', //商品图预览
      goodsPicPreviewModal: false, //商品图预览弹窗
      editor: '', //富文本编辑器
      goodsStoreType: 10,
      submitLoading: false,
      draftLoading: false,
    };
  }

  componentDidMount() {
    let query = getQuery();
    this.setState(
      {
        pageType: query.type || 'add',
        poolType: query.storeType || '',
        goodsId: query.id || '',
        goodsState: query.state || '',
        modifyType: query.modifyType || '',
      },
      () => {
        if (query.type === "add" && query.storeType === "self") {
          this._initSupplierSimpleList()
        }
        if (query.id) {
          // 编辑 | 变更
          this._initInfo();
        } else {
          // 新增
          // 分类初始化
          this._initCategoryTree();
          // 发货地初始化
          this._initLocation();
        }
        // 运费模板初始化
        this._initFreightTempList();
      }
    );
  }

  _initSupplierSimpleList = async (id) => {
    const { getSupplierSimpleList } = this.props.commonStore;
    let list = await getSupplierSimpleList();
    this.setState({
      supplierSimpleList: list,
      goodsSupplier: id || list[0].supplierId
    })
  }

  _initInfo = async () => {
    const { pageType, goodsId, poolType } = this.state
    const { goodsOwnStore, goodsResourceStore } = this.props;
    let res = pageType === "resource" ?
      await goodsResourceStore.getResourceInfo({ spuId: goodsId }) :
      await goodsOwnStore.getEditInfo({ goodsId });
    // 分类初始化
    this._initCategoryTree(res.categoryTree);
    // 品牌初始化
    if (res.brandId) this._initBrand(res.brandId);
    // 原产地初始化
    this._initLocation(res.areaCodeTree);

    // 商品属性
    let goodsProperty = [
      {
        key: 'brand',
        name: '商品品牌',
        value: res.brandId || undefined,
        canDel: false,
        isRequired: true,
      },
      {
        key: 'invoice',
        name: '发票类型',
        value: res.taxRate || 13,
        canDel: false,
        isRequired: true,
      },
      {
        key: 'unit',
        name: '商品单位',
        value: res.unit || undefined,
        canDel: false,
        isRequired: true,
      },
      {
        key: 'location',
        name: '原产地',
        value: res.originPlace,
        canDel: false,
        isRequired: false,
      },
      {
        key: 'box',
        name: '箱规',
        value: res.packUnit || undefined,
        canDel: false,
        isRequired: false,
      },
      {
        key: 'model',
        name: '商品货号',
        value: res.goodsNo || undefined,
        canDel: false,
      },
      {
        key: 'code',
        name: '商品条码',
        value: res.upc || undefined,
        canDel: false,
      },
      ...(res.property
        ? res.property.map((item, index) => ({
          key: index,
          name: item.propertyName,
          value: item.propertyValue,
          canDel: true,
        }))
        : []),
    ];

    // 商品规格
    let goodsStandard = res.attributeList
      ? res.attributeList.map((item, index) => ({
        standardName: item.name,
        hasPic:
          index === 0 &&
          item.valueList &&
          item.valueList.length > 0 &&
          item.valueList.some((value) => value.image),
        standardValues:
          item.valueList && item.valueList.length > 0
            ? item.valueList.map((value) => ({
              value: value.value,
              pic: (index === 0 && value.image)
                ? [{
                  uid: '-1',
                  name: 'image.png',
                  status: 'done',
                  url: value.image,
                  response: {
                    data: value.image,
                  },
                },
                ] : [],
            })) : [],
      })) : [];

    // 阶梯价
    let goodsStairList =
      res.stairList && res.stairList.length ?
        res.stairList.map((item) => ({
          minNum: item.minNum,
          maxNum: item.maxNum,
          salePrice: item.salePrice ? showMoney(item.salePrice) : undefined,
          supplyPrice: item.supplyPrice ? showMoney(item.supplyPrice) : undefined,
        })) :
        [{
          minNum: '',
          maxNum: '',
          salePrice: '',
          supplyPrice: ''
        },];
    // 商品图片
    let goodsFileList = [{ id: 0 }, { id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }];
    (res.imageList || []).forEach((item, index) => {
      goodsFileList[index] = Object.assign(goodsFileList[index], {
        uid: index,
        name: 'image.png',
        status: 'done',
        url: item,
        response: {
          data: item,
        },
      });
    });
    let cateIds = res.categoryTree
      ? res.categoryTree.split('_').map((item) => +item)
      : []
    this.setState(
      {
        spuId: res.spuId,
        poolType: pageType === "add" ?
          poolType :
          !!+res.isSupportPurchase ? "purchase" :
            ((!!+res.isSupportOnce) && (!+res.isSelf)) ? "once" :
              ((!!+res.isSupportOnce) && (!!+res.isSelf)) ? "self" : undefined,
        goodsName: res.name,
        goodsDesc: res.description || undefined,
        goodsCategory: cateIds,
        goodsCategoryItem: res.categoryNameList.map((i, index) => ({
          label: i,
          value: cateIds[index]
        })),
        goodsProperty,
        goodsStandard,
        goodsStairList,
        goodsMarketPrice: isNaN(res.marketPrice) ? undefined :
          showMoney(res.marketPrice),
        goodsCostPrice: isNaN(res.costPrice) ? undefined :
          showMoney(res.costPrice),
        goodsSupplyPrice: isNaN(res.supplyPrice) ? undefined :
          showMoney(res.supplyPrice),
        goodsSalePrice: isNaN(res.salePrice) ? undefined :
          showMoney(res.salePrice),
        goodsQuoteType: res.salesType || 1,
        goodsStartBuyNum: res.minBuyNum || undefined,
        goodsStock: res.stock,
        goodsFreightTemp: res.freightTemplateId,
        goodsWeight: res.weight ? res.weight / 1e6 : undefined,
        goodsService: res.afterSalesNoReason,
        goodsFileList,
        editor: res.detail,
      },
      () => {
        let goodsSkuList = this.transferSkuList();
        if (this.state.poolType === "self") {
          this._initSupplierSimpleList(res.supplierId)
        }
        if (goodsSkuList.length) {
          goodsSkuList = goodsSkuList.map((item) => {
            let obj = res.skuList.find((itemIn) => {
              let skuObjAttribute = JSON.parse(itemIn.attributeSpec);
              return objectEquals(item.attribute, skuObjAttribute);
            });
            item.costPrice = isNaN(obj.costPrice) ? undefined : showMoney(obj.costPrice);
            item.marketPrice = isNaN(obj.marketPrice) ? undefined : showMoney(obj.marketPrice);
            item.salePrice = isNaN(obj.salePrice) ? undefined : showMoney(obj.salePrice);
            item.supplyPrice = isNaN(obj.supplyPrice) ? undefined : showMoney(obj.supplyPrice);
            item.stock = isNaN(obj.stock) ? undefined : obj.stock;
            item.goodsNo = obj.goodsNo || undefined;
            item.upc = obj.upc || undefined;
            return item;
          });
        }
        this.setState({
          goodsSkuList,
        });
      }
    );
  };

  _initBrand = async (id) => {
    const { goodsOwnStore } = this.props;
    await goodsOwnStore.getBrandInfoById({ brandId: id });
  };

  _initFreightTempList = async () => {
    const { storeFreightStore } = this.props;
    //获取用户运费模板列表
    const list = await storeFreightStore.getFreightSimpleList({});
    this.setState({ freightTempList: list }, () =>
      console.log(this.state.freightTempList)
    );
  };

  refreshTemplate = async () => {
    await this.setState({
      refreshTemplate: true
    })
    await this._initFreightTempList();
    this.setState({
      refreshTemplate: false
    })
  }

  /**
   * 获取商品详情数据回显
   * @returns {Promise<void>}
   * @private
   */
  _initLocation = async (tree) => {
    const { commonStore } = this.props;
    let locationData = [];
    let loc1 = await commonStore.getLocationLevel({ parentCode: 0 });
    locationData = loc1.map((item) => ({
      label: item.name,
      value: item.id,
      isLeaf: +item.level > 1,
    }));
    if (tree) {
      let treeMap = tree.split('_').map((item) => +item);
      let loc2 = await commonStore.getLocationLevel({ parentCode: treeMap[0] });
      let index = locationData.findIndex((item) => +item.value === treeMap[0]);

      console.log(locationData, index, loc2);
      locationData[index].children = loc2.map((item) => ({
        label: item.name,
        value: item.id,
        isLeaf: +item.level > 1,
      }));
    }
    this.setState({
      locationData,
    });
  };

  _initCategoryTree = async (tree) => {
    const { commonStore } = this.props;
    let categoryData = [];
    let cate1 = await commonStore.getCategoryLevel({ parentId: 0 });
    categoryData = cate1.map((item) => ({
      label: item.name,
      value: item.id,
      isLeaf: +item.level > 1,
    }));
    if (tree) {
      let treeMap = tree.split('_').map((item) => +item);
      let cate2 = await commonStore.getCategoryLevel({ parentId: treeMap[0] });
      let index = categoryData.findIndex((item) => +item.value === treeMap[0]);
      categoryData[index].children = cate2.map((item) => ({
        label: item.name,
        value: item.id,
        isLeaf: +item.level > 1,
      }));
    }
    this.setState({
      categoryData,
    });
  };

  /**
   * 获取级联分类
   * @param selectedOptions
   */
  getMoreCate = async (selectedOptions) => {
    const { commonStore } = this.props;
    const targetOption = selectedOptions[selectedOptions.length - 1];
    targetOption.loading = true;

    let arr = await commonStore.getCategoryLevel({
      parentId: targetOption.value,
    });
    targetOption.children = arr.map((item) => ({
      label: item.name,
      value: item.id,
      isLeaf: +item.level > 1,
    }));
    targetOption.loading = false;
    this.setState({
      categoryData: [...this.state.categoryData],
    });
  };
  /**
   * 商品类型选择
   * @param e
   */
  changeGoodsType = (e, disable) => {
    if (!disable) {
      this.setState({
        goodsType: e,
      });
    }
  };

  /**
   * 添加商品属性
   */
  addGoodsProperty = () => {
    let arr = this.state.goodsProperty;
    arr.push({
      key: new Date().getTime(),
      name: '',
      value: '',
      canDel: true,
    });
    this.setState({
      goodsProperty: arr,
    });
  };

  /**
   * 删除商品属性
   * @param index
   */
  delGoodsProperty = (index) => {
    let arr = this.state.goodsProperty;
    arr.splice(index, 1);
    this.setState({
      goodsProperty: arr,
    });
  };

  /**
   * 输入商品属性
   * @param e
   * @param index
   * @param type
   */
  changeGoodsProperty = (e, index, type) => {
    let arr = this.state.goodsProperty;
    let val = e.target.value.trim();
    if (type === 'name') {
      val = val.slice(0, 10);
    } else if (type === 'value') {
      val = arr[index]['judgeType']
        ? judgeNum(val, arr[index]['judgeType'])
        : val.slice(0, 20);
    }
    arr[index][type] = val;
    this.setState({
      goodsProperty: arr,
    });
  };

  /**
   * 查找商品品牌
   * @param value
   */
  searchBrand = (value) => {
    if (value) {
      if (this.throttleTimeout) {
        clearTimeout(this.throttleTimeout);
      }
      this.throttleTimeout = setTimeout(async () => {
        await this.props.goodsOwnStore.getBrandList({
          keyword: value,
          pageNo: 1,
          pageSize: 100,
        });
        this.forceUpdate();
      }, 500);
    }
  };

  /**
   * 商品品牌选择
   * @param value
   */
  changeGoodsBrand = (value) => {
    let arr = this.state.goodsProperty;
    arr[0].value = value;
    this.setState({
      goodsProperty: arr,
    });
  };

  /**
   * 商品发票税率选择
   * @param val
   */
  changeGoodsTaxRate = (val) => {
    let arr = this.state.goodsProperty;
    arr[1].value = val;
    this.setState({
      goodsProperty: arr,
    });
  };

  /**
   * 级联地址改变
   * @param value
   * @param selectedOptions
   */
  changeLocation = (value, selectedOptions) => {
    console.log(value, selectedOptions);
    let arr = this.state.goodsProperty;
    if (value.length === 2) {
      arr[3].value = value;
    }
    this.setState({
      goodsProperty: arr,
    });
  };

  getUnit = () => this.state.goodsProperty[2].value || '个';
  /**
   * 获取级联地址
   * @param selectedOptions
   */
  getLocationData = async (selectedOptions) => {
    const { commonStore } = this.props;
    console.log(selectedOptions);
    const targetOption = selectedOptions[selectedOptions.length - 1];
    targetOption.loading = true;
    let arr = await commonStore.getLocationLevel({
      parentCode: targetOption.value,
    });
    targetOption.children = arr.map((item) => ({
      label: item.name,
      value: item.id,
      isLeaf: true,
    }));
    targetOption.loading = false;
    this.setState({
      locationData: [...this.state.locationData],
    });
  };

  /**
   * 预览商品图片
   * @param file
   * @returns {Promise<void>}
   */
  previewGoodsPic = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    this.setState({
      goodsPicPreview: file.url || file.preview,
      goodsPicPreviewModal: true,
    });
  };

  /**
   * 上传商品图片
   * @param fileList
   */
  changeGoodsPic = (fileList) => {
    this.setState({
      goodsFileList: JSON.parse(JSON.stringify(fileList)),
    });
  };

  /**
   * 添加商品规格
   */
  addGoodsModel = () => {
    let arr = this.state.goodsStandard;
    arr.push({
      standardName: '',
      hasPic: false,
      standardValues: [],
    });
    this.setState(
      {
        goodsStandard: arr,
      },
      () => {
        console.log(this.state.goodsStandard);
      }
    );
  };

  /**
   * 删除商品规格
   * @param index
   */
  delGoodsModel = (index) => {
    let arr = this.state.goodsStandard;
    arr.splice(index, 1);
    this.setState(
      { goodsStandard: arr, },
      () => {
        this.throttleTransferSkuList(() => { console.log("standard======", this.state.goodsStandard, this.state.goodsSkuList) });
      }
    );
  };

  /**
   * 商品规格名称
   * @param e
   * @param index
   */
  changeGoodsModelName = (e, index) => {
    let arr = this.state.goodsStandard;
    arr[index].standardName = e.target.value.trim();
    this.setState(
      { goodsStandard: arr, },
      () => {
        console.log(this.state.goodsStandard);
        this.throttleTransferSkuList();
      }
    );
  };


  blurGoodsModelName = (e, index) => {
    let arr = this.state.goodsStandard;
    if (arr[0] && arr[1] && arr[1].standardName === arr[0].standardName) {
      message.error("规格名不能重复！")
      arr[index].standardName = "";
      this.setState(
        { goodsStandard: arr, },
        () => {
          console.log(this.state.goodsStandard);
          this.throttleTransferSkuList();
        }
      );
    }
  };

  changeGoodsModelPicCheck = (e) => {
    let arr = this.state.goodsStandard;
    arr[0].hasPic = e.target.checked;
    if (!e.target.checked) {
      arr[0].standardValues = arr[0].standardValues.map((item) => {
        item.pic = [];
        return item;
      });
    }
    this.setState(
      { goodsStandard: arr, },
      () => {
        console.log(this.state.goodsStandard);
        // 格式化skuList
        // this.throttleTransferSkuList();
      }
    );
  };

  /**
   * 添加规格值
   * @param index
   */
  addGoodsModelValue = (index) => {
    let arr = this.state.goodsStandard;
    let cacheArr = lodash.cloneDeep(this.state.goodsSkuList)
    arr[index].standardValues.push({
      value: '',
      pic: [],
    });
    this.setState(
      { goodsStandard: arr, },
      () => {
        console.log(this.state.goodsStandard);
        this.throttleTransferSkuList(() => {
          let arrIn = this.state.goodsSkuList;
          console.log(arrIn, cacheArr)
          arrIn = arrIn.map(itemIn => {
            let obj = cacheArr.find(cacheItem => objectEquals(itemIn.attribute, cacheItem.attribute))
            return obj || itemIn
          })

          this.setState({
            goodsSkuList: arrIn
          })
        });
      }
    );
  };

  /**
   * 删除规格值
   * @param index
   * @param indexIn
   */
  delGoodsModelValue = (index, indexIn) => {
    let arr = this.state.goodsStandard;
    let cacheArr = lodash.cloneDeep(this.state.goodsSkuList)
    console.log(cacheArr)
    arr[index].standardValues.splice(indexIn, 1);
    this.setState(
      { goodsStandard: arr, },
      () => {
        this.throttleTransferSkuList(() => {
          let arrIn = this.state.goodsSkuList;
          console.log(arrIn, cacheArr)
          arrIn = arrIn.map(itemIn => {
            let obj = cacheArr.find(cacheItem => objectEquals(itemIn.attribute, cacheItem.attribute))
            return obj || itemIn
          })
          this.setState({
            goodsSkuList: arrIn
          })
        });
      }
    );
  };

  /**
   * 规格值变化
   * @param e
   * @param index
   * @param indexIn
   */
  changeGoodsModelValue = (e, index, indexIn) => {
    let arr = this.state.goodsStandard;
    let cacheArr = lodash.cloneDeep(this.state.goodsSkuList)
    console.log(arr);
    let val = e.target.value.trim()
    arr[index].standardValues[indexIn].value = val;
    console.log("filter===", arr[index].standardValues.filter(item => item.value === val))
    if (arr[index].standardValues.filter(item => item.value === val).length <= 1) {
      this.setState(
        { goodsStandard: arr, },
        () => {
          this.throttleTransferSkuList(() => {
            let arrIn = this.state.goodsSkuList;
            console.log("arrIn, cacheArr=======", arrIn, cacheArr)
            arrIn = arrIn.map(itemIn => {
              let obj = cacheArr.find(cacheItem => objectEquals(itemIn.attribute, cacheItem.attribute))
              let objIndex = cacheArr.findIndex(cacheItem => objectEquals(itemIn.attribute, cacheItem.attribute))
              if (objIndex > -1) {
                cacheArr.splice(objIndex, 1)
              }
              return obj || itemIn
            })
            console.log(arrIn)
            this.setState({
              goodsSkuList: arrIn
            })
          });
        }
      );
    } else {
      message.error("规格值不能相同！")
      this.setState({
        goodsStandard: arr,
      })
    }

  };

  // 规格值失焦校验是否有重复的规格值
  blurGoodsModelValue = (e, index, indexIn) => {
    let arr = this.state.goodsStandard;
    let cacheArr = lodash.cloneDeep(this.state.goodsSkuList)
    let val = e.target.value.trim();
    // console.log("val,arr======", val, arr)

    if (arr[index].standardValues.filter(item => item.value === val).length > 1) {
      // message.error("规格值不能相同！")
      arr[index].standardValues[indexIn].value = "";
      this.setState(
        { goodsStandard: arr, },
        () => {
          this.throttleTransferSkuList(() => {
            let arrIn = this.state.goodsSkuList;
            arrIn = arrIn.map(itemIn => {
              let obj = cacheArr.find(cacheItem => objectEquals(itemIn.attribute, cacheItem.attribute))
              let objIndex = cacheArr.findIndex(cacheItem => objectEquals(itemIn.attribute, cacheItem.attribute))
              if (objIndex > -1) {
                cacheArr.splice(objIndex, 1)
              }
              return obj || itemIn
            })
            console.log(arrIn)
            this.setState({
              goodsSkuList: arrIn
            })
          });
        }
      );
    }
  }

  /**
   * 规格值添加图片
   * @param fileList
   * @param index
   */
  changeGoodsModelPic = (file, fileList, index) => {
    const { postUploadFile } = this.props.commonStore;
    let arr = this.state.goodsStandard;
    let params = new FormData();
    params.append('file', file);
    return new Promise((res, rej) => {
      res(postUploadFile(params));
    }).then((res) => {
      if (res.success) {
        console.log('re=====', res);
        let obj = {
          lastModified: file.lastModified,
          originFileObj: file,
          uid: file.uid,
          name: file.name,
          type: file.type,
          size: file.size,
          response: res,
        };
        // let obj=JSON.parse(JSON.stringify(file))
        fileList[0].response = res;
        arr[0].standardValues[index].pic = [obj];
        this.setState(
          {
            goodsStandard: arr,
          },
          () => {
            console.log(this.state.goodsStandard);
            // 格式化skulist
            // this.throttleTransferSkuList();
          }
        );
      }
    });
  };

  removeGoodsModelPic = (index) => {
    let arr = this.state.goodsStandard;
    arr[0].standardValues[index].pic = [];
    this.setState(
      {
        goodsStandard: arr,
      },
      () => {
        console.log(this.state.goodsStandard);
        // 格式化skulist
        // this.throttleTransferSkuList();
      }
    );
  };

  /**
   * 阶梯价新增或删除
   * @param index
   * @param type
   */
  addOrDelStair = (index, type, canDo) => {
    let arr = this.state.goodsStairList;
    if (canDo) {
      switch (type) {
        case 'add':
          arr.splice(index + 1, 0, {
            maxNum: '',
            minNum: '',
            price: '',
          });
          break;
        case 'del':
          arr.splice(index, 1);
          break;
      }
      this.setState(
        {
          goodsStairList: arr,
        },
        () => {
          this.stairValuesRender();
        }
      );
    }
  };

  /**
   * 阶梯价输入
   * @param e
   * @param index
   * @param type
   */
  changeStairValue = (e, index, type) => {
    let arr = this.state.goodsStairList;
    let val = e.target.value;
    switch (type) {
      case 'num':
        val = judgeNum(val, 'num');
        arr[index].minNum = val;
        if (index > 0) {
          arr[index - 1].maxNum = val - 1;
        }
        break;
      case 'supplyPrice':
        arr[index].supplyPrice = judgeNum(val, 'money');
        break;
      case 'salePrice':
        arr[index].salePrice = judgeNum(val, 'money');
        break;
    }
    this.setState({
      goodsStairList: arr,
    });
  };

  /**
   * 阶梯价数据整合
   */
  stairValuesRender = () => {
    let arr = this.state.goodsStairList;
    if (arr.length > 1)
      arr.forEach((item, index, arr) => {
        if (index < arr.length - 1 && arr[index + 1].minNum) {
          arr[index].maxNum = arr[index + 1].minNum - 1;
        }
      });
    this.setState({
      goodsStairList: arr,
    }, () => {
      let res = this.validateStairList()
      this.setState({
        stairCheck: res
      })
    });
  };

  /**
   * 批量报价input变化
   * @param e
   * @param index
   */
  changeGoodsQuoteTypeBatchValue = (e, index) => {
    let arr = this.state.goodsQuoteTypeBatchValue,
      val = e.target.value;
    if ([0, 1, 2, 3].includes(index)) {
      val = judgeNum(val, 'money');
    } else if ([5].includes(index)) {
      val = judgeNum(val, 'num');
    }
    arr[index] = val;
    this.setState({
      goodsQuoteTypeBatchValue: arr,
    });
  };

  /**
   * tooltip打开
   * @param index
   */
  openGoodsQuoteTypeBatch = (index) => {
    let arr = this.state.goodsQuoteTypeBatchVisible;
    arr[index] = true;
    this.setState({
      goodsQuoteTypeBatchVisible: arr,
    });
  };

  /**
   * 批量tooltip关闭
   * @param index
   */
  cancelGoodsQuoteTypeBatch = (index) => {
    let arr = this.state.goodsQuoteTypeBatchVisible,
      values = this.state.goodsQuoteTypeBatchValue;
    arr[index] = false;
    values = values.map((item) => '');
    this.setState({
      goodsQuoteTypeBatchVisible: arr,
      goodsQuoteTypeBatchValue: values,
    });
  };

  /**
   * 批量toolTip确定
   * @param index
   * @param type
   */
  confirmGoodsQuoteTypeBatch = (index, type) => {
    const { poolType } = this.state
    let values = this.state.goodsQuoteTypeBatchValue,
      arr = this.state.goodsSkuList;
    let value = values[index];
    arr.map((item) => {
      item[type] = value;
      return item;
    });
    console.log(arr);
    this.setState(
      {
        goodsSkuList: arr,
      }, () => {
        this.cancelGoodsQuoteTypeBatch(index);
        if (poolType === "purchase" && ["salePrice", "marketPrice", "supplyPrice"].includes(type)) {
          let res = this.validateSkuList();
          console.log("res====", res)
          this.setState({
            skuCheck: res
          })
        } else if (poolType === "once" && ["salePrice", "marketPrice", "supplyPrice"].includes(type)) {
          let res = this.validateOncePrice();
          console.log("res====", res)
          this.setState({
            onceCheck: res
          })
        } else if (poolType === "self" && ["salePrice", "marketPrice"].includes(type)) {
          let res = this.validateSelfPrice();
          console.log("res====", res)
          this.setState({
            selfCheck: res
          })
        }
      }
    );
  };

  /**
   * 批量报价单个输入框变化
   * @param e
   * @param index
   * @param type
   */
  changeGoodsQuoteTypeValue = (e, index, type, saleType) => {
    let arr = this.state.goodsSkuList;
    let val = e.target.value;
    if (['price', 'costPrice', 'marketPrice', 'salePrice', 'oncePrice'].includes(type)) {
      val = judgeNum(val, 'money');
    } else if (['stock'].includes(type)) {
      val = judgeNum(val, 'num_0');
    }
    // console.log(typeof val);
    arr[index][type] = val.toString();
    if (saleType) {
      arr.forEach((item) => {
        item[type] = val.toString();
      });
    }
    this.setState({
      goodsSkuList: arr,
    });
  };

  /**
   * 转化skuList
   * @returns {Array}
   */
  transferSkuList = () => {
    const goodsStandard = [...this.state.goodsStandard];
    let arr = [];
    if (goodsStandard.length > 0) {
      let goodsStandard_f = cartesianProduct(
        goodsStandard.map((item) =>
          item.standardValues.map((itemIn) => ({
            ...itemIn,
            standardName: item.standardName,
            image: itemIn.pic[0] ? itemIn.pic[0].response.data : '',
          }))
        )
      );
      // console.log(goodsStandard_f)
      arr = goodsStandard_f.map((item, index) => {
        if (!Array.isArray(item)) {
          item = [item];
        }
        let attribute = {},
          image = '',
          propertyNames = [];
        item.forEach((itemIn) => {
          // console.log('itemIn', itemIn)
          propertyNames.push(itemIn.standardName);
          attribute[itemIn.standardName] = itemIn.value;
          if (itemIn.image) image = itemIn.image;
        });
        return {
          attribute,
          image,
          propertyNames,
          marketPrice: '',
          costPrice: '',
          supplyPrice: "",
          salePrice: "",
          goodsNo: '',
          upc: '',
          stock: '',
          key: v4() + Math.random(),
        };
      });
    }
    console.log("arr======", arr)
    return arr;
  };

  /**
   * 节流转化skuList
   * @type {null}
   */
  throttleTimeout = null;
  throttleTransferSkuList = (callback) => {
    if (this.throttleTimeout) clearTimeout(this.throttleTimeout);
    this.throttleTimeout = setTimeout(() => {
      let list = this.transferSkuList();
      console.log("list=======", list)
      this.setState({
        goodsSkuList: list,
      }, callback);
    }, 500);
  };

  /**
   * 渲染阶梯价DOM
   * @returns {JSX.Element}
   */
  renderStairPrice = () => {
    const { goodsStairList, stairCheck } = this.state
    return (
      <div className={styles.goods_tiered_price_container}>
        <div
          className={styles.table_line}
          style={{ backgroundColor: '#f0f0f0' }}
        >
          <div className={styles.table_cell} style={{ flex: 1 }}>
            <span>操作</span>
          </div>
          <div className={styles.table_cell} style={{ flex: 1 }}>
            <span className={styles.table_title_required}>购买数量</span>
          </div>
          <div className={styles.table_cell} style={{ flex: 1 }}>
            <span className={styles.table_title_required}>
              供应价(元/{this.getUnit()})
            </span>
          </div>
          <div className={styles.table_cell} style={{ flex: 1 }}>
            <span className={styles.table_title_required}>
              集采价(元/{this.getUnit()})
            </span>
          </div>
          <div className={styles.table_cell} style={{ flex: 3 }}>
            <span>预览(可根据买家采购不同数量设置不同价格)</span>
          </div>
        </div>

        {goodsStairList.map((item, index, arr) => {
          return (
            <div className={styles.table_line} key={index}>
              {/*操作*/}
              <div className={styles.table_cell} style={{ flex: 1 }}>
                <PlusOutlined
                  style={{ marginRight: 20 }}
                  className={`${styles.table_cell_action} ${arr.length >= 3 ? styles.table_cell_action_disabled : ''
                    }`}
                  onClick={() => {
                    if (!this.modifyBasic())
                      this.addOrDelStair(index, 'add', arr.length < 3)
                  }
                  }
                />
                <MinusOutlined
                  className={`${styles.table_cell_action} ${arr.length === 1 ? styles.table_cell_action_disabled : ''
                    }`}
                  onClick={() => {
                    if (!this.modifyBasic())
                      this.addOrDelStair(index, 'del', arr.length > 1)
                  }
                  }
                />
              </div>
              {/*数量*/}
              <div className={styles.table_cell} style={{ flex: 1 }}>
                <Input
                  disabled={this.modifyBasic()}
                  placeholder="请输入"
                  allowClear={true}
                  style={{ width: 100 }}
                  value={item.minNum}
                  onChange={(e) => this.changeStairValue(e, index, 'num')}
                  onBlur={() => {
                    let res = this.validateStairList();
                    this.setState({
                      stairCheck: res
                    })
                  }}
                />
              </div>
              {/*供应价*/}
              <div className={styles.table_cell} style={{ flex: 1 }}>
                <Input
                  disabled={this.modifyBasic()}
                  placeholder="请输入"
                  allowClear={true}
                  style={{ width: 100 }}
                  value={item.supplyPrice}
                  onChange={(e) => this.changeStairValue(e, index, 'supplyPrice')}
                  onBlur={() => {
                    let res = this.validateStairList();
                    this.setState({
                      stairCheck: res
                    })
                  }}
                />
              </div>
              {/*集采价*/}
              <div className={styles.table_cell} style={{ flex: 1 }}>
                <Input
                  disabled={this.modifyBasic()}
                  placeholder="请输入"
                  allowClear={true}
                  style={{ width: 100 }}
                  value={item.salePrice}
                  onChange={(e) => this.changeStairValue(e, index, 'salePrice')}
                  onBlur={() => {
                    let res = this.validateStairList();
                    this.setState({
                      stairCheck: res
                    })
                  }}
                />
              </div>
              {/*预览*/}
              <div className={styles.table_cell} style={{ flex: 3 }}>
                {item.minNum && item.salePrice
                  ? index === arr.length - 1
                    ? `≥${item.minNum}${this.getUnit()} : ${item.salePrice
                    }元/${this.getUnit()}`
                    : item.minNum === item.maxNum
                      ? `${item.minNum}${this.getUnit()} : ${item.salePrice
                      }元/${this.getUnit()}`
                      : `${item.minNum}-${item.maxNum}${this.getUnit()} : ${item.salePrice
                      }元/${this.getUnit()}`
                  : ''}
              </div>
            </div>
          );
        })}
        {!stairCheck.suc && <span className={styles.stair_tip}>{stairCheck.msg}</span>}
      </div>
    );
  };

  /**
   * 渲染按规格报价表格，不同规格不同价格
   * @returns {JSX.Element}
   */
  renderQuoteType = () => {
    const {
      poolType,
      goodsQuoteTypeBatchVisible,
      goodsQuoteTypeBatchValue,
      goodsSkuList,
      skuCheck, onceCheck, selfCheck
    } = this.state;

    const labelArr = [
      {
        key: 1,
        label: '成本价',
        name: 'costPrice',
      }, {
        key: 2,
        label: '市场价',
        name: 'marketPrice',
      },]
    if (poolType !== "self") {
      labelArr.push({
        key: 0,
        label: '供应价',
        name: 'supplyPrice',
      })
    }
    labelArr.push({
      key: 3,
      label: poolType === "purchase" ? '集采价' : "代发价",
      name: 'salePrice',
    }, {
      key: 5,
      label: '库存数',
      name: 'stock',
    }, {
      key: 4,
      label: '商品货号',
      name: 'goodsNo',
    }, {
      key: 6,
      label: '商品条码',
      name: 'upc',
    }
    );

    let commonColumns = labelArr.map((item) => ({
      title: (
        <div>
          <span
            className={
              [0, 2, 3, 5].includes(item.key) ? styles.table_title_required : ''
            }
          >
            {item.label}
          </span>
          {[0, 1, 2, 3, 5].includes(item.key) &&
            <Tooltip
              placement="bottom"
              visible={goodsQuoteTypeBatchVisible[item.key]}
              trigger="click"
              title={
                <div>
                  <Input
                    size="small"
                    style={{ width: 100, marginRight: 10 }}
                    value={goodsQuoteTypeBatchValue[item.key]}
                    onChange={(e) => {
                      console.log(e);
                      this.changeGoodsQuoteTypeBatchValue(e, item.key);
                    }}
                  />
                  <Button
                    type="primary"
                    style={{ marginRight: 10 }}
                    size="small"
                    onClick={() =>
                      this.confirmGoodsQuoteTypeBatch(item.key, item.name)
                    }
                  >
                    确定
                </Button>
                  <Button
                    type="default"
                    style={{ marginRight: 10 }}
                    size="small"
                    onClick={() => this.cancelGoodsQuoteTypeBatch(item.key)}
                  >
                    取消
                </Button>
                </div>
              }
            >
              <Button
                disabled={this.modifyBasic()}
                type="link"
                onClick={() => {
                  this.openGoodsQuoteTypeBatch(item.key);
                }}
              >
                批量
            </Button>
            </Tooltip>}
        </div>
      ),
      align: 'center',
      width: 110,
      render: (text, record, index) => (
        <Input
          disabled={this.modifyBasic()}
          value={record[item.name]}
          placeholder="请输入"
          allowClear={true}
          onChange={(e) => {
            this.changeGoodsQuoteTypeValue(e, index, item.name);
          }}
          onBlur={() => {
            if (poolType === "purchase" &&
              ["salePrice", "marketPrice", "supplyPrice"].includes(item.name)) {
              let res = this.validateSkuList();
              this.setState({
                skuCheck: res
              })
            } else if (poolType === "once" &&
              ["salePrice", "marketPrice", "supplyPrice"].includes(item.name)) {
              let res = this.validateOncePrice();
              this.setState({
                onceCheck: res
              })
            } else if (poolType === "self" &&
              ["salePrice", "marketPrice"].includes(item.name)) {
              let res = this.validateSelfPrice();
              this.setState({
                selfCheck: res
              })
            }
          }}
          style={{ width: 100 }}
        />
      ),
      // width: 200,
    }));

    let columns = (goodsSkuList[0]
      ? goodsSkuList[0].propertyNames.map((item) => ({
        title: item,
        align: 'center',
        render: (text, record, index) => record.attribute[item],
      }))
      : []
    ).concat(commonColumns);
    console.log("table====", goodsSkuList)
    return (<div className={styles.price_by_standard_table}>
      <div className={styles.table_container}>
        <Table
          bordered
          columns={columns}
          dataSource={goodsSkuList}
          rowKey={(record) => record.key}
          // rowKey={(record) => JSON.stringify(record.attribute)}
          pagination={false}
        />
      </div>

      {poolType === "purchase" && !skuCheck.suc &&
        <span className={styles.stair_tip}>{skuCheck.msg}</span>}
      {poolType === "once" && !onceCheck.suc &&
        <span className={styles.stair_tip}>{onceCheck.msg}</span>}
      {poolType === "self" && !selfCheck.suc &&
        <span className={styles.stair_tip}>{selfCheck.msg}</span>}
    </div>
    );
  };

  /**
   * 渲染按规格填写库存，不同规格相同价格
   * @returns {JSX.Element}
   */
  renderStockContent = () => {
    const {
      poolType,
      goodsSkuList,
      goodsQuoteTypeBatchVisible,
      goodsQuoteTypeBatchValue,
    } = this.state;
    const labelArr = [
      {
        key: 1,
        label: '成本价',
        width: 110,
        name: 'costPrice',
      }, {
        key: 2,
        label: '市场价',
        width: 110,
        name: 'marketPrice',
      }, {
        key: 5,
        label: '库存数',
        width: 110,
        name: 'stock',
      }, {
        key: 4,
        label: '商品货号',
        width: 110,
        name: 'goodsNo',
      }, {
        key: 6,
        label: '商品条码',
        width: 110,
        name: 'upc',
      },
    ];
    let commonColumns = labelArr.map((item) => ({
      title: (
        <div>
          <span
            className={
              [2, 5].includes(item.key) ? styles.table_title_required : ''
            }
          >
            {item.label}
          </span>
          {[1, 5].includes(item.key) && (
            <Tooltip
              placement="bottom"
              visible={goodsQuoteTypeBatchVisible[item.key]}
              trigger="click"
              title={
                <div>
                  <Input
                    size="small"
                    style={{ width: 100, marginRight: 10 }}
                    value={goodsQuoteTypeBatchValue[item.key]}
                    onChange={(e) =>
                      this.changeGoodsQuoteTypeBatchValue(e, item.key)
                    } />
                  <Button
                    type="primary"
                    style={{ marginRight: 10 }}
                    size="small"
                    onClick={() =>
                      this.confirmGoodsQuoteTypeBatch(item.key, item.name)
                    }>确定</Button>
                  <Button
                    type="default"
                    style={{ marginRight: 10 }}
                    size="small"
                    onClick={() => this.cancelGoodsQuoteTypeBatch(item.key)}
                  >取消</Button>
                </div>
              }
            >
              <Button
                disabled={this.modifyBasic()}
                type="link"
                onClick={() => {
                  this.openGoodsQuoteTypeBatch(item.key);
                }}>
                批量
              </Button>
            </Tooltip>
          )}
        </div>
      ),
      align: 'center',
      width: 110,
      render: (text, record, index) =>
        item.key === 2 ? (
          <Input
            disabled={this.modifyBasic()}
            value={record[item.name]}
            placeholder="请输入"
            allowClear={true}
            onChange={(e) => {
              this.changeGoodsQuoteTypeValue(e, index, item.name, 1);
            }}
            onBlur={() => {
              let res = this.validateStairList();
              this.setState({
                stairCheck: res
              })
            }}
            style={{ width: 100 }}
          />
        ) : (
            <Input
              disabled={this.modifyBasic()}
              value={record[item.name]}
              placeholder="请输入"
              allowClear={true}
              onChange={(e) => {
                this.changeGoodsQuoteTypeValue(e, index, item.name);
              }}
              style={{ width: 100 }}
            />
          ),
      // width: 200,
    }));
    let columns = (goodsSkuList[0]
      ? goodsSkuList[0].propertyNames.map((item) => ({
        title: item,
        align: 'center',
        render: (text, record, index) => record.attribute[item],
      }))
      : []
    ).concat(commonColumns);
    return (
      <>
        <div className={styles.table_container}>
          <Table
            bordered
            columns={columns}
            dataSource={goodsSkuList}
            rowKey={(record) => record.key}
            // rowKey={(record) => JSON.stringify(record.attribute)}
            pagination={false}
          />
        </div>

        <Row style={{ paddingTop: 16 }}>
          <Col span={3} style={{ color: "#999" }}>注：</Col>
          {poolType === "self" ?
            <Col span={21} style={{ color: "#999" }}>
              市场价为客户参考的零售价，在平台作为划线价使用，对订单不影响。<br />
              成本价填写仅自己可见， 用于内部管理使用，不对外展示。<br />
              代发价为平台售价，请慎重填写！
            </Col> :
            poolType === "purchase" ?
              <Col span={21} style={{ color: "#999" }}>
                市场价为客户参考的零售价，在平台作为划线价使用，对订单不影响。<br />
                成本价填写仅自己可见， 用于内部管理使用，不对外展示。<br />
                集采价为市场报价，所有普通会员可见，在此价基础上根据不同会员等级飞象会推送不同会员优惠价格。<br />
                供应价为与飞象的预结算价格，具体结算价以正式签订购货合同为准。<br />
                集采价和供应价越低被推荐销售的资源越多， 请慎重填写价格！
              </Col> :
              poolType === "once" ?
                <Col span={21} style={{ color: "#999" }}>
                  市场价为客户参考的零售价，在平台作为划线价使用，对订单不影响。<br />
                  成本价填写仅自己可见， 用于内部管理使用，不对外展示。<br />
                  代发价为市场报价，所有免费会员可见，在此价基础上根据不同付费会员等级飞象会推送不同优惠价格。<br />
                  供应价为与飞象的预结算价格，具体结算价以正式签订购货合同为准。<br />
                  代发价和供应价越低被推荐销售的资源越多， 请慎重填写价格！
                </Col> : ""}
        </Row>
      </>
    );
  };

  validateSelfPrice = () => {
    const { goodsSkuList, goodsMarketPrice, goodsSalePrice } = this.state
    let suc = true, msg = "";
    if (goodsSkuList.length) {
      let skuItem = goodsSkuList.find(item => +item.marketPrice < +item.salePrice)
      if (skuItem) {
        suc = false
        msg = "市场价≥代发价"
      }
    } else {
      if (+goodsMarketPrice < +goodsSalePrice) {
        suc = false
        msg = "市场价≥代发价"
      }
    }
    console.log("validateSelfPrice", { suc, msg })
    return { suc, msg }
  }


  /**
   * 校验一件代发价
   * @returns {{suc: boolean, msg: string}}
   */
  validateOncePrice = () => {
    const { goodsSkuList, goodsMarketPrice, goodsSupplyPrice, goodsSalePrice } = this.state
    let suc = true, msg = "";
    if (goodsSkuList.length) {
      let skuItem = goodsSkuList.find(item => +item.marketPrice < +item.salePrice || +item.salePrice <= +item.supplyPrice)
      if (skuItem) {
        suc = false
        msg = "市场价≥代发价>供应价"
      }
    } else {
      if (+goodsMarketPrice < +goodsSalePrice || +goodsSalePrice <= +goodsSupplyPrice) {
        suc = false
        msg = "市场价≥代发价>供应价"
      }
    }
    console.log("validateOncePrice", { suc, msg })
    return { suc, msg }
  }


  /**
   * 校验skuList
   * @returns {{suc: boolean, msg: string}}
   */
  validateSkuList = () => {
    const { goodsQuoteType, goodsSkuList, goodsStandard } = this.state
    if (goodsStandard.length && goodsQuoteType === 2) {
      let suc = true, msg = "";
      let skuItem = goodsSkuList.find(item => +item.marketPrice < +item.salePrice || +item.salePrice <= +item.supplyPrice)
      if (skuItem) {
        return {
          suc: false,
          msg: "市场价≥代发价>供应价"
        }
      }
      console.log("validateSkuList", { suc, msg })
      return { suc, msg }
    }
  }

  /**
   * 校验阶梯价
   * @returns {{suc: boolean, msg: string}}
   */
  validateStairList = () => {
    let { goodsStairList, goodsMarketPrice, goodsQuoteType, goodsSkuList, goodsStandard, } = this.state;
    goodsMarketPrice = goodsQuoteType === 1 && !!goodsStandard.length ? goodsSkuList[0].marketPrice : goodsMarketPrice
    let msg = '';
    let stair = goodsStairList.find((item) => {
      if (!item.minNum) {
        msg = '请输入阶梯价商品数量';
        return true;
      }
      if (!item.supplyPrice) {
        msg = '请输入阶梯价供应价';
        return true;
      }
      if (!item.salePrice) {
        msg = '请输入阶梯价集采价';
        return true;
      }
    });
    if (stair) {
      return {
        suc: false,
        msg,
      };
    }
    let checkStair = true;
    for (let i = 0; i < goodsStairList.length; i++) {
      console.log(+goodsStairList[i].salePrice, (+goodsMarketPrice || 0), +goodsStairList[i].salePrice < (+goodsMarketPrice || 0))
      if (goodsStairList[i + 1]) {
        if (+goodsStairList[i].minNum >= +goodsStairList[i + 1].minNum) {
          msg = '阶梯价数量下者必须大于上者';
          checkStair = false;
          break
        }
        if (+goodsStairList[i].supplyPrice <= +goodsStairList[i + 1].supplyPrice) {
          msg = '阶梯价供应价下者必须小于上者';
          checkStair = false;
          break;
        }
        if (+goodsStairList[i].salePrice <= +goodsStairList[i + 1].salePrice) {
          msg = '阶梯价集采价下者必须小于上者';
          checkStair = false;
          break;
        }
      }
      if (+goodsStairList[i].salePrice > (+goodsMarketPrice || 0) || +goodsStairList[i].supplyPrice >= +goodsStairList[i].salePrice) {
        msg = "市场价≥集采价>供应价"
        checkStair = false;
        break;
      }
    }
    console.log({
      suc: checkStair,
      msg,
    })
    return {
      suc: checkStair,
      msg,
    };
  };

  /**
   * 校验信息
   * @param type
   * @returns {boolean}
   */
  validate = (type) => {
    let {
      poolType,
      goodsState,
      goodsName,
      goodsCategory,
      goodsProperty,
      goodsFileList,
      goodsStandard,
      goodsQuoteType,
      goodsMarketPrice,
      goodsSupplyPrice,
      goodsSalePrice,
      goodsStartBuyNum,
      goodsStock,
      goodsFreightTemp,
      freightTempList,
      goodsWeight,
      goodsSkuList,
    } = this.state;

    // 商品名称，所有情况下必填
    if (!goodsName) {
      message.error('请输入商品名称！');
      return false;
    }
    if (goodsCategory.length === 1) {
      message.error('请选择正确的商品类目！');
      return false;
    }
    // 提交前判断
    if (
      type !== 'draft' ||
      (type === 'draft' && goodsState && +goodsState !== -20)
    ) {
      // 商品类目
      if (!goodsCategory.length) {
        message.error('请选择商品类目！');
        return false;
      }
      // 商品属性
      if (!goodsProperty[0].value) {
        message.error(`请选择商品品牌`);
        return false;
      }
      // 商品属性
      if (!goodsProperty[1].value) {
        message.error(`请选择发票类型`);
        return false;
      }
      // 商品单位
      if (!goodsProperty[2].value) {
        message.error(`请输入商品单位`);
        return false;
      }
      let property = goodsProperty.find(
        (item, index) => index > 6 && (!item.name || !item.value)
      );
      if (property) {
        message.error(`请填写完整商品属性`);
        return false;
      }

      //无规格校验市场价和库存
      if (!goodsStandard.length) {
        if (!goodsMarketPrice) {
          message.error('请输入市场价！');
          return false;
        }
        if (poolType === "once") {
          if (!goodsSupplyPrice) {
            message.error('请输入供应价！');
            return false;
          }
          if (!goodsSalePrice) {
            message.error('请输入代发价！');
            return false;
          }
          let validateOnceRes = this.validateOncePrice();
          if (!validateOnceRes.suc) {
            message.error(validateOnceRes.msg);
            return false;
          }
        }
        if (poolType === "self") {
          if (!goodsSalePrice) {
            message.error('请输入代发价！');
            return false;
          }
          let validateSelfRes = this.validateSelfPrice();
          if (!validateSelfRes.suc) {
            message.error(validateSelfRes.msg);
            return false;
          }
        }
        // 库存
        if (goodsStock === '') {
          message.error('请输入现货库存！');
          return false;
        }
      }
      //无规格或者有规格并且不同规格相同价格存在阶梯价，校验阶梯价
      if (poolType === "purchase") {
        if (
          !goodsStandard.length ||
          (goodsStandard.length && goodsQuoteType === 1)
        ) {
          let validateStairRes = this.validateStairList();
          if (!validateStairRes.suc) {
            message.error(validateStairRes.msg);
            return false;
          }
        } else {
          // 不存在阶梯价，校验起批量
          if (!goodsStartBuyNum && +goodsStartBuyNum !== 0) {
            message.error('请输入起批量');
            return false;
          }
          if (+goodsStartBuyNum < 1) {
            message.error('起批量必须大于1');
            return false;
          }
        }
      }
      //规格存在
      if (goodsStandard.length) {
        //校验规格输入的合法性
        let standard = goodsStandard.find((item) => {
          if (!item.standardName) {
            message.error('请输入规格名称！');
            return true;
          }
          if (!item.standardValues.length) {
            message.error('请添加规格值！');
            return true;
          }
          if (item.standardValues.find((itemIn) => !itemIn.value)) {
            message.error('请输入规格值！');
            return true;
          }
          if (
            item.hasPic &&
            item.standardValues.find((itemIn) => !itemIn.pic.length)
          ) {
            message.error('请上传规格值对应的图片！');
            return true;
          }
        });
        if (standard) {
          return false;
        }
        let goodsSku
        if (poolType === "purchase") {
          //  不同规格相同价格 校验skuList
          if (goodsQuoteType === 1) {
            //商品数量报价，阶梯价
            let sku = goodsSkuList.find((item) => {
              if (!item.marketPrice) {
                message.error('请输入商品市场价');
                return true;
              }
              if (item.stock === '') {
                message.error('请输入商品库存');
                return true;
              }
            });
            if (sku) {
              return false;
            }
            let totalStock = goodsSkuList.reduce((sum, item) => isNaN(item.stock) ? sum : sum += item.stock, 0)
            if (totalStock <= 0) {
              message.error('请输入商品库存');
              return false;
            }

          }
          //  不同规格不同价格 校验skuList
          if (goodsQuoteType === 2) {
            // 规格报价
            goodsSku = goodsSkuList.find((item) => {
              if (!item.marketPrice) {
                message.error('请输入市场价');
                return true;
              }
              if (!item.salePrice) {
                message.error('请输入集采价');
                return true;
              }
              if (!item.supplyPrice) {
                message.error('请输入供应价');
                return true;
              }
              if (item.stock === '') {
                message.error('请输入库存数');
                return true;
              }
            });
            if (goodsSku) {
              return false;
            }
            let totalStock = goodsSkuList.reduce((sum, item) => isNaN(item.stock) ? sum : sum += item.stock, 0)
            if (totalStock <= 0) {
              message.error('请输入商品库存');
              return false;
            }
            let validateSkuRes = this.validateSkuList();
            if (!validateSkuRes.suc) {
              message.error(validateSkuRes.msg);
              return false;
            }
          }
        }
        if (poolType === "once") {
          goodsSku = goodsSkuList.find((item) => {
            if (!item.marketPrice) {
              message.error('请输入市场价');
              return true;
            }
            if (!item.supplyPrice) {
              message.error('请输入供应价');
              return true;
            }
            if (!item.salePrice) {
              message.error('请输入代发价');
              return true;
            }
            if (item.stock === '') {
              message.error('请输入库存数');
              return true;
            }
          });
          if (goodsSku) {
            return false;
          }
          let totalStock = goodsSkuList.reduce((sum, item) => isNaN(item.stock) ? sum : sum += item.stock, 0)
          if (totalStock <= 0) {
            message.error('请输入商品库存');
            return false;
          }
          let validateOnceRes = this.validateOncePrice();
          if (!validateOnceRes.suc) {
            message.error(validateOnceRes.msg);
            return false;
          }
        }
        if (poolType === "self") {
          goodsSku = goodsSkuList.find((item) => {
            if (!item.marketPrice) {
              message.error('请输入市场价');
              return true;
            }
            if (!item.salePrice) {
              message.error('请输入代发价');
              return true;
            }
            // if (item.stock === '') {
            //   message.error('请输入库存数');
            //   return true;
            // }
          });
          if (goodsSku) {
            return false;
          }
          let totalStock = goodsSkuList.reduce((sum, item) => isNaN(item.stock) ? sum : sum += item.stock, 0)
          if (totalStock <= 0) {
            message.error('请输入商品库存');
            return false;
          }
          let validateSelfRes = this.validateSelfPrice();
          if (!validateSelfRes.suc) {
            message.error(validateSelfRes.msg);
            return false;
          }
        }

      }
      // 运费
      if (!goodsFreightTemp) {
        message.error('请选择运费模板！');
        return false;
      }
      let freightItem = freightTempList.find(
        (item) => +item.freightTemplateId == +goodsFreightTemp
      );
      let freightTempType = freightItem ? freightItem.tradeType : undefined;
      if (freightTempType == 2 && !+goodsWeight) {
        message.error('请输入商品重量！');
        return false;
      }
      // 图片
      if (goodsFileList.every((item) => !item.response)) {
        message.error('请上传商品图！');
        return false;
      }
      let file = goodsFileList.find(
        (item) => item.uid && !item.response.data
      );
      if (file) {
        message.error('请确保商品图上传成功！');
        return false;
      }
      // 详情
      console.log("editor==============", this.state.editor)
      if (!this.state.editor || this.state.editor === "<p></p>") {
        message.error('请填写商品详情！');
        return false;
      }

    }
    return true;
  };

  //获取提交参数
  getSubmitParams = () => {
    let { poolType, goodsType, goodsName, goodsDesc, goodsCategory, goodsProperty, goodsStandard, goodsMarketPrice, goodsCostPrice, goodsSupplyPrice, goodsSalePrice, goodsQuoteType, goodsStartBuyNum, goodsStock, goodsFreightTemp, goodsWeight, goodsService, goodsSkuList, goodsStairList, goodsFileList, editor } = this.state;

    // 自定义属性
    let propertyList = [];
    let goodsProperty_self = goodsProperty.filter((item, index) => index > 6);
    if (goodsProperty_self.length) {
      propertyList = goodsProperty_self.map((item) => ({
        propertyName: item.name,
        propertyValue: item.value,
      }));
    }
    // 阶梯价
    let stairList = JSON.parse(JSON.stringify(goodsStairList)).map(
      (item, index, arr) => {
        if (index === arr.length - 1) item.maxNum = '999999999';
        return {
          maxNum: item.maxNum,
          minNum: item.minNum,
          salePrice: item.salePrice * 1e2,
          supplyPrice: item.supplyPrice * 1e2,
        };
      }
    );

    console.log("goodsSkuList=====", goodsSkuList)
    //规格报价处理
    let goodsSkuListSave = JSON.parse(JSON.stringify(goodsSkuList)).map(
      (item) => {
        item.image = goodsStandard[0].hasPic ? goodsStandard[0].standardValues.find(itemIn => itemIn.value === item.attribute[goodsStandard[0].standardName]).pic[0].response.data : undefined;
        item.costPrice *= 1e2;
        item.marketPrice *= 1e2;
        item.supplyPrice *= 1e2;
        item.salePrice *= 1e2;
        return item;
      }
    );

    let params = {
      isEntity: goodsType,
      isSupportPurchase: +(poolType === 'purchase'),
      isSupportOnce: +(poolType === 'once' || poolType === 'self'),
      isSelf: +(poolType === 'self'),
      name: goodsName.trim(),
      desc: goodsDesc ? goodsDesc.trim() : undefined,
      categoryId: goodsCategory[1] || undefined,
      brandId: goodsProperty[0].value,
      taxRate: goodsProperty[1].value,
      unit: this.getUnit(),
      originPlace: goodsProperty[3].value,
      packUnit: goodsProperty[4].value,
      goodsNo: goodsProperty[5].value,
      upc: goodsProperty[6].value,
      propertyList,
      marketPrice: goodsMarketPrice * 1e2,
      costPrice: goodsCostPrice * 1e2,
      salePrice: goodsSalePrice * 1e2,
      supplyPrice: goodsSupplyPrice * 1e2,
      salesType: poolType === "purchase" ? (goodsStandard.length ? goodsQuoteType : 1) : (goodsStandard.length ? 2 : 1),
      stock: goodsStock,
      stairList,
      skuList: goodsSkuListSave,
      minBuyNum: goodsStartBuyNum,
      freightTemplateId: goodsFreightTemp,
      weight: goodsWeight === '' ? null : goodsWeight * 1e6,
      afterSalesNoReason: goodsService,
      imageList: goodsFileList
        .filter((item) => item.response)
        .map((item) => item.response.data)
        .filter((item) => item),
      detail: editor,
      applicationId: 1,
      spuStatus: 10,
    };
    return params;
  };

  /**
   * 提交
   * @param type
   * @returns {Promise<void>}
   */
  saveGoods = async (type) => {
    const { goodsOwnStore, goodsResourceStore, goodsModifyStore } = this.props;
    let { pageType, modifyType, poolType, goodsId, goodsState, spuId, goodsStoreType } = this.state;

    if (!this.state.submitLoading && !this.state.draftLoading) {
      if (!this.validate(type)) return;
      if (type === 'save') {
        this.setState({
          submitLoading: true,
        });
      } else {
        this.setState({
          draftLoading: true,
        });
      }
      let params = this.getSubmitParams();
      console.log(params)
      // return;
      let res;
      if (pageType === 'add') {
        // 添加
        if (type === 'save') {
          //录入商品 add
          params.status = goodsStoreType;
          res = await goodsOwnStore.postAddGoods(params);
        } else {
          //草稿箱
          params.status = -20;
          res = await goodsOwnStore.postAddGoods(params);
        }
        this.setState({
          submitLoading: false,
          draftLoading: false,
        });
        if (res.success) {
          message.success('添加成功');
          setTimeout(() => {
            if (goodsId) {
              window.open("/goods/add", "_self")
            } else {
              window.location.reload();
            }
          }, 2000);
        }
      }
      else if (pageType === 'edit') {
        // 编辑
        if (type === 'save') {
          params.status = goodsStoreType;
        } else {
          params.status = goodsState;
        }
        let editAddParams = {
          goodsId,
          spuId,
        };
        res = await goodsOwnStore.postUpdateGoods(
          Object.assign(params, editAddParams)
        );
        if (res.success) {
          console.log(`/goods/${poolType}` + (poolType === "self" ? "" : "/list") + `?tab=-20`)
          message.success(type === 'save' ? '上架成功！' : '保存成功');
          setTimeout(() => {
            if (type === "draft") {
              window.open(`/goods/${poolType}` + (poolType === "self" ? "" : "/list") + `?tab=${goodsState}`, '_self');
            }
            if (type === 'save') {
              window.open(`/goods/${poolType}` + (poolType === "self" ? "" : "/list") + `?type=${goodsStoreType}`, '_self');
            }
          }, 2000)
        }
        this.setState({
          submitLoading: false,
          draftLoading: false,
        });
      }
      else if (pageType === 'resource') {
        if (type === 'save') {
          params.status = 10;
          params.spuStatus = 10;
          params.spuId = goodsId
          res = await goodsResourceStore.upResource(params)
          if (res.success) {
            message.success("上架成功")
            setTimeout(() => {
              window.open("/goods/resource", "_self")
            }, 2000)
          }
        }
        this.setState({
          submitLoading: false,
          draftLoading: false,
        });
      }
      else if (pageType === 'modify') {
        //变更
        if (type === 'save') {
          params.status = 10;
        }
        let finalParams = {
          applyReason: this.props.localStore.getGoodsModifyReason(),
          changeType: modifyType === "basic" ? 2 : 1,
          goodsId,
          goodsParam: {
            ...params,
            spuId,
            goodsId,
          },
        };

        res = await goodsModifyStore.requestModify(finalParams);
        if (res.success) {
          message.success(modifyType === "basic" ? "变更基础信息成功" : '提交成功，等待审核');
          setTimeout(() => window.open(`/goods/${poolType}` + (poolType === "self" ? "" : "/list") + `?tab=10`, '_self'), 2000)
          this.setState({
            submitLoading: false,
            draftLoading: false,
          });
        } else if (res.code === DATA_CODE.GOODS_CHANGE_PERMIT_BY_ACTIVITY) {
          const textStyle = {
            textAlign: 'center',
            fontSize: '16px',
            lineHeight: '32px',
          };
          const _this = this;
          Modal.info({
            icon: false,
            centered: true,
            zIndex: 10001,
            content: (
              <>
                <div style={textStyle}>该商品正在参加活动：</div>
                <div style={{ textAlign: 'center' }}>{res.message}</div>
                <div style={textStyle}>请先退出活动 再修改！</div>
              </>
            ),
            okText: '确定',
            onOk() {
              _this.setState({
                submitLoading: false,
                draftLoading: false,
              });
            },
          });
        }
      }
    }
  };

  modifyBasic = () => {
    return this.state.pageType === "modify" && this.state.modifyType === "basic";
  }

  render = () => {
    const {
      pageType, goodsType, supplierSimpleList, goodsSupplier, goodsName, goodsStoreType, goodsState, poolType, goodsCategory, goodsCategoryItem, showCategoryModal,
      goodsMarketPrice, goodsCostPrice, goodsSupplyPrice, goodsSalePrice, skuCheck, onceCheck, selfCheck, stairCheck, refreshTemplate
    } = this.state;
    let unit = '';
    return (
      <div className={styles.publish_container}>
        {/*供应商信息*/}
        {poolType === "self" &&
          <GoodsAddCard size="small" title="供应商信息" className={styles.page_card}>
            <div className={styles.basic_info_container}>
              <Row>
                <Col span={3}>
                  <div className={styles.card_line_title_container}>
                    <span className={styles.card_line_title}>供应商简称：</span>
                  </div>
                </Col>
                <Col span={21}>
                  <div className={styles.card_line_content_container_flex}>
                    <Select
                      style={{ width: 500 }}
                      value={goodsSupplier}
                      onChange={val => this.setState({ goodsSupplier: val })}>
                      {supplierSimpleList.map((item) => <SelectOption value={item.supplierId}
                        key={item.supplierId}>{item.supplierShortName}</SelectOption>)}
                    </Select>
                  </div>
                </Col>
              </Row>
            </div>
          </GoodsAddCard>}
        {/*基本信息*/}
        <GoodsAddCard size="small" title="基本信息" className={styles.page_card}>
          <div className={styles.basic_info_container}>
            {/*商品名称*/}
            <Row>
              <Col span={3}>
                <div className={styles.card_line_title_container}>
                  <span className={styles.card_line_title}>商品名称：</span>
                </div>
              </Col>
              <Col span={21}>
                <div className={styles.card_line_content_container_flex}>
                  <Input
                    placeholder="请输入商品名称"
                    maxLength={60}
                    onChange={(e) =>
                      this.setState({ goodsName: e.target.value })
                    }
                    value={goodsName}
                    suffix={`${goodsName.length}/60`}
                    allowClear
                    style={{ width: 500 }}
                  />
                </div>
              </Col>
            </Row>
            {/*商品描述*/}
            <Row>
              <Col span={3}>
                <div className={styles.card_line_title_container}>
                  <span className={styles.card_line_title_unrequired}>
                    商品描述：
                  </span>
                </div>
              </Col>
              <Col span={21}>
                <div className={styles.card_line_content_container_flex}>
                  <Input.TextArea
                    placeholder="建议使用通俗的产品名称，突出商品特性、卖点。"
                    maxLength={60}
                    onChange={(e) =>
                      this.setState({ goodsDesc: e.target.value })
                    }
                    value={this.state.goodsDesc}
                    allowClear
                    style={{ width: 500 }}
                  />
                </div>
              </Col>
            </Row>
            {/*商品类目*/}
            <Row>
              <Col span={3}>
                <div className={styles.card_line_title_container}>
                  <span className={styles.card_line_title}>商品类目：</span>
                </div>
              </Col>
              <Col span={21}>
                <div className={styles.card_line_content_container_flex}>
                  {/* <Cascader
                    disabled={this.modifyBasic()}
                    options={this.state.categoryData}
                    loadData={this.getMoreCate}
                    onChange={(value, selectedOptions) =>
                      this.setState({
                        goodsCategory: value,
                      })
                    }
                    value={this.state.goodsCategory}
                    changeOnSelect
                    style={{ width: 500 }}
                  /> */}
                  {goodsCategoryItem.length ?
                    <>
                      <span>{goodsCategoryItem.map(item => item.label).join(" > ")}</span>
                      <Button type="link" disabled={this.modifyBasic()} onClick={() => {
                        this.setState({
                          showCategoryModal: true
                        })
                      }}>修改类目</Button>
                    </> :
                    <Button
                      disabled={this.modifyBasic()}
                      className={styles.button_line}
                      style={{ width: 200, marginTop: 14, color: this.modifyBasic() ? "#ccc" : '#40a9ff' }}
                      onClick={() => {
                        this.setState({
                          showCategoryModal: true
                        })
                      }}
                    >
                      <PlusOutlined /> 选择商品分类
                    </Button>}

                </div>
              </Col>
            </Row>
            {/*商品属性title*/}
            <Row>
              <Col span={3}>
                <div className={styles.card_line_title_container}>
                  <span className={styles.card_line_title}>商品属性：</span>
                </div>
              </Col>
              <Col span={21}>
                <div className={styles.card_line_content_container_flex}>
                  <p>完善产品的参数，让用户更了解您的产品。</p>
                </div>
              </Col>
            </Row>
            {/*商品属性content*/}
            <Row>
              <Col span={21} offset={3}>
                <div className={styles.card_line_content_container}>
                  <div className={styles.goods_property_container}>
                    <Row className={styles.property_line}>
                      {this.state.goodsProperty.map((item, index) => (
                        <Col
                          span={8}
                          className={styles.property_cell}
                          key={item.key}
                        >
                          <Row>
                            {index >= 7 ? (
                              <Col span={10} className={styles.property_title}>
                                <Input
                                  placeholder="属性名"
                                  onChange={(e) =>
                                    this.changeGoodsProperty(e, index, 'name')
                                  }
                                  value={item.name}
                                  allowClear={true}
                                />
                              </Col>
                            ) : (
                                <Col
                                  span={10}
                                  className={
                                    item.isRequired
                                      ? styles.property_title_required
                                      : styles.property_title
                                  }
                                >
                                  <p>{item.name}:</p>
                                </Col>
                              )}
                            {index === 0 ? ( //品牌搜索下拉
                              <Col
                                span={14}
                                className={styles.property_content}
                              >
                                <Select
                                  disabled={this.modifyBasic()}
                                  allowClear={true}
                                  showSearch
                                  value={item.value}
                                  placeholder="请选择品牌"
                                  style={{ width: '100%' }}
                                  defaultActiveFirstOption={false}
                                  showArrow={true}
                                  filterOption={false}
                                  onSearch={this.searchBrand}
                                  onChange={this.changeGoodsBrand}
                                  notFoundContent="没有该品牌"
                                >
                                  {this.props.goodsOwnStore.brandList.map(
                                    (option) => (
                                      <SelectOption
                                        key={option.brandId}
                                        value={option.brandId}
                                      >
                                        {option.brandName}
                                      </SelectOption>
                                    )
                                  )}
                                </Select>
                              </Col>
                            ) : index === 1 ? ( //发票类型下拉
                              <Col
                                span={14}
                                className={styles.property_content}
                              >
                                <Select
                                  disabled={this.modifyBasic()}
                                  allowClear={true}
                                  value={item.value}
                                  placeholder="请选择发票类型"
                                  style={{ width: '100%' }}
                                  onChange={this.changeGoodsTaxRate}
                                >
                                  {taxRateOption.map((item, index) => (
                                    <SelectOption
                                      value={item.value}
                                      key={index}
                                    >
                                      {item.label} 增值税专用发票
                                    </SelectOption>
                                  ))}
                                </Select>
                              </Col>
                            ) : (
                                  //   : index === 3 ? ( //原产地
                                  //   <Col
                                  //     span={14}
                                  //     className={styles.property_content}
                                  //   >
                                  //     <Cascader
                                  //       changeOnSelect
                                  //       allowClear
                                  //       placeholder="请选择原产地"
                                  //       onChange={this.changeLocation}
                                  //       options={this.state.locationData}
                                  //       loadData={this.getLocationData}
                                  //       value={item.value}
                                  //       style={{ width: '100%' }}
                                  //     />
                                  //   </Col>
                                  // )
                                  <Col
                                    span={14}
                                    className={styles.property_content}
                                  >
                                    <Input
                                      disabled={index === 2 && this.modifyBasic()}
                                      placeholder={index >= 7 ? '属性值' : '请输入'}
                                      value={item.value}
                                      onChange={(e) =>
                                        this.changeGoodsProperty(e, index, 'value')
                                      }
                                      allowClear={true}
                                    />
                                    {item.canDel && (
                                      <Button
                                        type="link"
                                        size="small"
                                        onClick={() => {
                                          this.delGoodsProperty(index);
                                        }}
                                      >
                                        删除
                                      </Button>
                                    )}
                                  </Col>
                                )}
                          </Row>
                        </Col>
                      ))}
                    </Row>
                  </div>
                  <Button
                    className={styles.button_line}
                    style={{ width: 200, marginTop: 10, color: '#40a9ff' }}
                    onClick={this.addGoodsProperty}
                  >
                    <PlusOutlined /> 添加自定义产品属性
                  </Button>
                </div>
              </Col>
            </Row>
            {/*商品规格*/}
            <Row>
              <Col span={3}>
                <div className={styles.card_line_title_container}>
                  <span className={styles.card_line_title_unrequired}>
                    商品规格：
                  </span>
                </div>
              </Col>
              <Col span={21}>
                <div className={styles.card_line_content_container}>
                  {this.state.goodsStandard.map((item, index, arr) => (
                    <div
                      className={styles.goods_standard_container}
                      key={index}
                    >
                      <Row className={styles.goods_standard_line}>
                        <Col span={8}>
                          <Row>
                            <Col
                              span={10}
                              className={styles.goods_standard_line_title}
                            >
                              规格名称:
                            </Col>
                            <Col
                              span={14}
                              className={
                                styles.goods_standard_line_content_name_line
                              }
                              style={{ paddingRight: 0 }}
                            >
                              <Input
                                disabled={this.modifyBasic()}
                                placeholder="请输入规格名称"
                                allowClear={true}
                                value={item.standardName}
                                onChange={(e) =>
                                  this.changeGoodsModelName(e, index)
                                }
                                onBlur={(e) =>
                                  this.blurGoodsModelName(e, index)
                                }
                              // style={{width: 300, marginRight: 20}}
                              />
                            </Col>
                          </Row>
                        </Col>
                        <Col span={6} className={styles.goods_standard_line_content_name_line}
                          style={{ paddingLeft: 20 }}>
                          {index === 0 &&
                            <Checkbox
                              disabled={this.modifyBasic()}
                              checked={item.hasPic}
                              onChange={this.changeGoodsModelPicCheck}
                            >添加规格图片</Checkbox>}
                        </Col>
                        <Col
                          span={10}
                          className={
                            styles.goods_standard_line_content_close_icon
                          }
                        >
                          <CloseCircleFilled
                            style={{
                              color: this.modifyBasic() ? "#ccc" : 'rgba(0,0,0,.7)',
                              fontSize: '26px',
                              cursor: this.modifyBasic() ? "not-allowed" : 'pointer',
                            }}
                            onClick={() => { if (!this.modifyBasic()) this.delGoodsModel(index) }}
                          />
                        </Col>

                        <Col
                          span={3}
                          className={styles.goods_standard_line_title}
                          style={{ position: "relative" }}
                        >
                          <span style={{
                            position: "absolute",
                            right: 6,
                            top: '50%',
                            transform: "translateY(-50%)"
                          }}>规格值:</span>
                        </Col>
                        <Col
                          span={21}
                          className={
                            styles.goods_standard_line_content_standard_value}
                        >

                          {item.standardValues.map(
                            (itemIn, indexIn, arrIn) => (

                              <div key={indexIn}
                                className={styles.standard_value_container}
                              >
                                <Input
                                  disabled={this.modifyBasic()}
                                  placeholder="请输入"
                                  allowClear={true}
                                  value={itemIn.value}
                                  onChange={(e) =>
                                    this.changeGoodsModelValue(e, index, indexIn)
                                  }
                                  onBlur={e =>
                                    this.blurGoodsModelValue(e, index, indexIn)}
                                  style={{ width: 104, marginRight: 10 }}
                                />
                                {item.hasPic &&
                                  <div
                                    className={styles.standard_value_upload}
                                  >
                                    <Upload
                                      disabled={this.modifyBasic()}
                                      listType="picture-card"
                                      fileList={itemIn.pic}
                                      onPreview={this.previewGoodsPic}
                                      beforeUpload={(e1, e2) => {
                                        if (!this.modifyBasic())
                                          this.changeGoodsModelPic(e1, e2, indexIn);
                                      }}
                                      onChange={() => {
                                        console.log('onChange====');
                                      }}
                                      onRemove={() => {
                                        if (!this.modifyBasic())
                                          this.removeGoodsModelPic(indexIn);
                                      }}
                                    >
                                      {itemIn.pic.length >= 1 ? null : (
                                        <div>
                                          <PlusOutlined />
                                          <div className="ant-upload-text">
                                            点击上传
                                        </div>
                                        </div>
                                      )}
                                    </Upload>
                                  </div>}
                                <CloseCircleFilled
                                  style={{
                                    color: this.modifyBasic() ? "#ccc" : '#1890ff',
                                    fontSize: '25px',
                                    position: 'absolute',
                                    top: 0,
                                    left: 105,
                                    zIndex: 2,
                                    cursor: this.modifyBasic() ? "not-allowed" : 'pointer',
                                  }}
                                  onClick={() => {
                                    if (!this.modifyBasic())
                                      this.delGoodsModelValue(index, indexIn)
                                  }}
                                />
                              </div>
                            )
                          )}
                          {item.standardValues.length < 20 &&
                            <Button
                              disabled={this.modifyBasic()}
                              type="link"
                              onClick={() =>
                                this.addGoodsModelValue(index)}
                            >添加规格值</Button>
                          }
                        </Col>
                      </Row>
                    </div>
                  ))}
                  {this.state.goodsStandard.length < 2 && (
                    <Button
                      disabled={this.modifyBasic()}
                      className={styles.button_line}
                      style={{ width: 200, marginTop: 14, color: this.modifyBasic() ? "#ccc" : '#40a9ff' }}
                      onClick={this.addGoodsModel}
                    >
                      <PlusOutlined /> 添加规格
                    </Button>
                  )}
                </div>
              </Col>
            </Row>
          </div>
        </GoodsAddCard>
        <GoodsAddCard
          size="small"
          title="交易信息（现货）"
          className={styles.page_card}
        >
          <div className={styles.sale_info_container}>
            {!!this.state.goodsStandard.length && (
              <p className={styles.module_title}>定价方式</p>
            )}
            {this.state.goodsStandard.length ? poolType === "purchase" ? (
              // 报价方式title
              <Row>
                <Col span={3}>
                  <div className={styles.card_line_title_container}>
                    <span className={styles.card_line_title}>报价方式：</span>
                  </div>
                </Col>
                <Col span={21}>
                  <div className={styles.card_line_content_container_flex}>
                    <RadioGroup
                      disabled={this.modifyBasic()}
                      onChange={(e) =>
                        this.setState({
                          goodsQuoteType: e.target.value,
                        })
                      }
                      value={this.state.goodsQuoteType}
                    >
                      <Radio value={1} key={1}>
                        不同规格相同价格
                      </Radio>
                      <Radio value={2} key={2}>
                        不同规格不同价格
                      </Radio>
                    </RadioGroup>
                  </div>
                </Col>
              </Row>
            ) : <></> : (
                // 商品价格
                <>
                  <Row>
                    <Col span={3}>
                      <div className={styles.card_line_title_container}>
                        <span className={styles.card_line_title}>商品价格：</span>
                      </div>
                    </Col>
                    <Col span={21}>
                      <div className={styles.card_line_content_container}>
                        <div className={styles.goods_property_container}>
                          <Row className={styles.property_line}>
                            <Col span={8} className={styles.property_cell}>
                              <Row>
                                <Col
                                  span={10}
                                  className={styles.property_title_required}
                                >
                                  <p>市场价(元):</p>
                                </Col>
                                <Col
                                  span={14}
                                  className={styles.property_content}
                                >
                                  <Input
                                    disabled={this.modifyBasic()}
                                    placeholder="请输入"
                                    allowClear
                                    value={goodsMarketPrice}
                                    onChange={(e) => {
                                      this.setState({
                                        goodsMarketPrice: judgeNum(
                                          e.target.value.trim(),
                                          'money'
                                        ),
                                      })
                                    }
                                    }
                                    onBlur={() => {
                                      let res
                                      if (poolType === "purchase") {
                                        res = this.validateStairList();
                                        this.setState({
                                          stairCheck: res
                                        })
                                      } else if (poolType === "once") {
                                        res = this.validateOncePrice();
                                        this.setState({
                                          onceCheck: res
                                        })
                                      } else if (poolType === "self") {
                                        res = this.validateSelfPrice();
                                        this.setState({
                                          selfCheck: res
                                        })
                                      }

                                    }}
                                  />
                                </Col>
                              </Row>
                            </Col>
                            <Col span={8} className={styles.property_cell}>
                              <Row>
                                <Col span={10} className={styles.property_title}>
                                  <p>成本价(元):</p>
                                </Col>
                                <Col
                                  span={14}
                                  className={styles.property_content}
                                >
                                  <Input
                                    disabled={this.modifyBasic()}
                                    placeholder="请输入"
                                    allowClear
                                    value={goodsCostPrice}
                                    onChange={(e) =>
                                      this.setState({
                                        goodsCostPrice: judgeNum(
                                          e.target.value.trim(),
                                          'money'
                                        ),
                                      })
                                    }
                                  />
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                          {poolType === "once" &&
                            <Row className={styles.property_line} style={{ position: "relative" }}>
                              <Col span={8} className={styles.property_cell}>
                                <Row>
                                  <Col
                                    span={10}
                                    className={styles.property_title_required}
                                  >
                                    <p>供应价(元):</p>
                                  </Col>
                                  <Col
                                    span={14}
                                    className={styles.property_content}
                                  >
                                    <Input
                                      disabled={this.modifyBasic()}
                                      placeholder="请输入"
                                      allowClear
                                      value={goodsSupplyPrice}
                                      onChange={(e) => {
                                        this.setState({
                                          goodsSupplyPrice: judgeNum(
                                            e.target.value.trim(),
                                            'money'
                                          ),
                                        })
                                      }}
                                      onBlur={() => {
                                        let res = this.validateOncePrice();
                                        this.setState({
                                          onceCheck: res
                                        })
                                      }}
                                    />
                                  </Col>
                                </Row>
                              </Col>
                              <Col span={8} className={styles.property_cell}>
                                <Row>
                                  <Col span={10} className={styles.property_title_required}>
                                    <p>代发价(元):</p>
                                  </Col>
                                  <Col
                                    span={14}
                                    className={styles.property_content}>
                                    <Input
                                      disabled={this.modifyBasic()}
                                      placeholder="请输入"
                                      allowClear
                                      value={this.state.goodsSalePrice}
                                      onChange={(e) =>
                                        this.setState({
                                          goodsSalePrice: judgeNum(
                                            e.target.value.trim(),
                                            'money'),
                                        })}
                                      onBlur={() => {
                                        let res = this.validateOncePrice();
                                        this.setState({
                                          onceCheck: res
                                        })
                                      }} />
                                  </Col>
                                </Row>
                              </Col>
                              {!onceCheck.suc &&
                                <span className={styles.stair_tip}>{onceCheck.msg}</span>}
                            </Row>}
                          {poolType === "self" &&
                            <Row className={styles.property_line} style={{ position: "relative" }}>
                              <Col span={8} className={styles.property_cell}>
                                <Row>
                                  <Col span={10} className={styles.property_title_required}>
                                    <p>代发价(元):</p>
                                  </Col>
                                  <Col
                                    span={14}
                                    className={styles.property_content}>
                                    <Input
                                      disabled={this.modifyBasic()}
                                      placeholder="请输入"
                                      allowClear
                                      value={goodsSalePrice}
                                      onChange={(e) =>
                                        this.setState({
                                          goodsSalePrice: judgeNum(
                                            e.target.value.trim(),
                                            'money'),
                                        })
                                      }
                                      onBlur={() => {
                                        let res = this.validateSelfPrice();
                                        this.setState({
                                          selfCheck: res
                                        })
                                      }} />
                                  </Col>
                                </Row>
                              </Col>
                              {!selfCheck.suc &&
                                <span className={styles.stair_tip}>{selfCheck.msg}</span>}
                            </Row>}
                        </div>
                      </div>
                    </Col>
                    <Col span={21} offset={3}>
                      <Row style={{ paddingTop: 32 }}>
                        <Col span={3} className={styles.property_title}>
                          <p
                            style={{
                              paddingRight: 20,
                              textAlign: 'right',
                              color: "#999"
                            }}
                          >
                            注:
                        </p>
                        </Col>

                        {poolType === "self" ?
                          <Col span={21} style={{ color: "#999" }}>
                            市场价为客户参考的零售价，在平台作为划线价使用，对订单不影响。<br />
                          成本价填写仅自己可见， 用于内部管理使用，不对外展示。<br />
                          代发价为平台售价，请慎重填写！
                        </Col> :
                          poolType === "purchase" ?
                            <Col span={21} style={{ color: "#999" }}>
                              市场价为客户参考的零售价，在平台作为划线价使用，对订单不影响。<br />
                            成本价填写仅自己可见， 用于内部管理使用，不对外展示。<br />
                            集采价为市场报价，所有普通会员可见，在此价基础上根据不同会员等级飞象会推送不同会员优惠价格。<br />
                            供应价为与飞象的预结算价格，具体结算价以正式签订购货合同为准。<br />
                            集采价和供应价越低被推荐销售的资源越多， 请慎重填写价格！
                          </Col> :
                            poolType === "once" ?
                              <Col span={21} style={{ color: "#999" }}>
                                市场价为客户参考的零售价，在平台作为划线价使用，对订单不影响。<br />
                              成本价填写仅自己可见， 用于内部管理使用，不对外展示。<br />
                              代发价为市场报价，所有免费会员可见，在此价基础上根据不同付费会员等级飞象会推送不同优惠价格。<br />
                              供应价为与飞象的预结算价格，具体结算价以正式签订购货合同为准。<br />
                              代发价和供应价越低被推荐销售的资源越多， 请慎重填写价格！
                            </Col> : ""}
                      </Row>
                    </Col>
                  </Row>
                  <Divider />
                </>
              )}
            {/*现货库存 title*/}
            <Row>
              <Col span={3}>
                <div className={styles.card_line_title_container}>
                  <span className={styles.card_line_title}>现货库存：</span>
                </div>
              </Col>

              {this.state.goodsStandard.length ? (
                <Col span={21}>
                  <div className={styles.card_line_content_container_flex}>
                    <p> 总库存
                      {` ${this.state.goodsSkuList.reduce(
                      (sum, item) => isNaN(item.stock) ? sum : (sum += +item.stock),
                      0)} ${this.getUnit()}`}</p>

                  </div>
                </Col>) : (
                  <Col span={21}>
                    <div className={styles.card_line_content_container_flex}>
                      <Input
                        disabled={this.modifyBasic()}
                        placeholder="请填写商品总库存数"
                        onChange={(e) =>
                          this.setState({
                            goodsStock: judgeNum(e.target.value, 'num'),
                          })}
                        value={this.state.goodsStock}
                        style={{ width: 400 }}
                        addonBefore={this.getUnit()}
                        allowClear
                      />
                    </div>
                  </Col>
                )}
              {poolType === 'once' &&
                <Col offset={3}>
                  <p>
                    注：平台要求一件代发商品务必保证库存稳定， 请确保现货库存是真实库存！违反规则，将受到平台惩罚，详见<Button type="link" style={{
                      paddingLeft: 0,
                      paddingRight: 0
                    }}>《飞象供应商平台规则》</Button>
                  </p>
                </Col>}
            </Row>
            {/*现货库存content*/}
            {!!this.state.goodsStandard.length && (
              <Row>
                <Col span={21} offset={3}>
                  <div className={styles.card_line_content_container}>
                    {poolType === "purchase" ? this.state.goodsQuoteType === 1 ?
                      // 不同规格相同价格
                      this.renderStockContent() :
                      // 不同规格不同价格
                      this.renderQuoteType() :
                      // 一件代发和自有商品多规格
                      this.renderQuoteType()}
                  </div>
                </Col>
              </Row>
            )}
            {poolType === "purchase" && <Divider />}
            {!!this.state.goodsStandard.length &&
              poolType === "purchase" &&
              this.state.goodsQuoteType === 2 && (
                <Row>
                  <Col span={3}>
                    <div className={styles.card_line_title_container}>
                      <span className={styles.card_line_title}>起批量：</span>
                    </div>
                  </Col>
                  <Col span={21}>
                    <div className={styles.card_line_content_container_flex}>
                      <Input
                        disabled={this.modifyBasic()}
                        placeholder="请输入起批量"
                        onChange={(e) =>
                          this.setState({
                            goodsStartBuyNum: judgeNum(
                              e.target.value.trim(),
                              'num'
                            ),
                          })
                        }
                        value={this.state.goodsStartBuyNum}
                        style={{ width: 400 }}
                        allowClear
                      />
                    </div>
                  </Col>
                </Row>
              )}
            {/*阶梯价*/}
            {!(this.state.goodsStandard.length &&
              this.state.goodsQuoteType === 2) &&
              poolType === "purchase" && (
                <Row>
                  <Col span={3}>
                    <div className={styles.card_line_title_container}>
                      <span className={styles.card_line_title}>阶梯价：</span>
                    </div>
                  </Col>
                  <Col span={21}>
                    <div className={styles.card_line_content_container}>
                      {this.renderStairPrice()}
                    </div>
                  </Col>
                </Row>
              )}
            <Divider />
            {/*运费设置*/}
            <Row>
              <Col span={3}>
                <div className={styles.card_line_title_container}>
                  <span className={styles.card_line_title}>运费设置：</span>
                </div>
              </Col>
              <Col span={21}>
                <div className={styles.card_line_content_container_flex}>
                  <Space>
                    <Select
                      disabled={this.modifyBasic()}
                      style={{ width: 400, marginRight: 20 }}
                      placeholder="请选择运费模板"
                      value={this.state.goodsFreightTemp}
                      onChange={(val) =>
                        this.setState({
                          goodsFreightTemp: val,
                        })
                      }
                    >
                      {this.state.freightTempList.map((item) => (
                        <SelectOption
                          key={item.freightTemplateId}
                          value={item.freightTemplateId}
                        >
                          {item.templateName}
                        </SelectOption>
                      ))}
                    </Select>
                    <Button
                      type="primary"
                      onClick={() => {
                        window.open('/setting/freight', '_blank');
                      }}
                    >管理运费模板</Button>
                    <Button type="link"
                      loading={refreshTemplate}
                      disabled={refreshTemplate}
                      onClick={this.refreshTemplate}>刷新</Button>
                  </Space>
                </div>
              </Col>
            </Row>
            {/*单位重量*/}
            <Row>
              <Col span={3}>
                <div className={styles.card_line_title_container}>
                  <span
                    className={
                      this.state.freightTempList.find(item => +item.id === +this.state.goodsFreightTemp) && this.state.freightTempList.find(item => +item.id === +this.state.goodsFreightTemp).tradeType === 2 ? styles.card_line_title
                        : styles.card_line_title_unrequired
                    }>
                    单位重量：
                  </span>
                </div>
              </Col>
              <Col span={21}>
                <div className={styles.card_line_content_container_flex}>
                  <Input
                    disabled={this.modifyBasic()}
                    placeholder="请输入"
                    style={{ width: 400 }}
                    onChange={(e) =>
                      this.setState({
                        goodsWeight: judgeNum(e.target.value.trim(), 'weight'),
                      })
                    }
                    value={this.state.goodsWeight}
                    suffix={`千克/${unit || '个'}`}
                    allowClear={true}
                  />
                </div>
              </Col>
            </Row>
            <Divider />
            {/*售后服务*/}
            <Row>
              <Col span={3}>
                <div className={styles.card_line_title_container}>
                  <span className={styles.card_line_title}>售后服务：</span>
                </div>
              </Col>
              <Col span={21}>
                <div className={styles.card_line_content_container_flex}>
                  <span style={{ paddingRight: 20 }}>7天无理由退换货</span>
                  <RadioGroup
                    disabled={this.modifyBasic()}
                    value={this.state.goodsService}
                    onChange={(e) =>
                      this.setState({
                        goodsService: e.target.value,
                      })}
                  >
                    <Radio value={1} key={1}>
                      支持
                    </Radio>
                    <Radio value={-1} key={-1}>
                      不支持
                    </Radio>
                  </RadioGroup>
                </div>
              </Col>
            </Row>
          </div>
        </GoodsAddCard>
        <GoodsAddCard size="small" title="商品素材" className={styles.page_card}>
          <div className={styles.goods_detail_container}>
            {/*商品图title*/}
            <Row>
              <Col span={3}>
                <div className={styles.card_line_title_container}>
                  <span className={styles.card_line_title}>商品图：</span>
                </div>
              </Col>
              <Col span={21}>
                <div className={styles.card_line_content_container_flex}>
                  <p style={{ color: "#666" }}>
                    建议您上传 800*800
                    像素以上的实拍大图，包含商品整体款式和商品细节。
                  </p>
                </div>
              </Col>
            </Row>
            {/*商品图content*/}
            <Row>
              <Col span={21} offset={3}>
                <div className={styles.card_line_content_container}>
                  <div className={styles.goods_picture_container}>
                    <SortableUpload
                      fileList={this.state.goodsFileList}
                      onChangePic={this.changeGoodsPic}
                      onPreview={this.previewGoodsPic}
                      onChangeSort={(newList) =>
                        this.setState({
                          goodsFileList: newList,
                        })}
                    />
                  </div>
                </div>
              </Col>
            </Row>
            {/*商品详情*/}
            <Row>
              <Col span={3}>
                <div className={styles.card_line_title_container}>
                  <span className={styles.card_line_title}>商品详情：</span>
                </div>
              </Col>
              <Col span={21}>
                <div className={`${styles.card_line_content_container} ${styles.editor_container}`}>
                  <Editor
                    url={`${mainHost}/basic/file/batchUpload`}
                    headers={{ authority_token: localStorage.getItem('token') }}
                    onChange={(val) => this.setState({ editor: val })}
                    value={this.state.editor}
                  />
                </div>
              </Col>
            </Row>
          </div>
        </GoodsAddCard>
        <GoodsAddCard size="small" title="发布时间" className={styles.page_card}>
          <div className={styles.goods_detail_container}>
            <Row>
              <Col span={3}>
                <div className={styles.card_line_title_container}>
                  <span className={styles.card_line_title}>上架时间：</span>
                </div>
              </Col>
              <Col span={21}>
                <div className={styles.card_line_content_container_flex}>
                  <Radio.Group value={goodsStoreType}
                    onChange={e =>
                      this.setState({
                        goodsStoreType: e.target.value
                      })}>
                    <Radio value={-10}>
                      <Badge count={
                        <Popover placement="top"
                          content={"发布后暂不提交平台审核，您可以在仓库中再次发起提交审核。"}
                          trigger="hover">
                          <QuestionCircleOutlined style={{ color: "#f5222d" }} />
                        </Popover>}>
                        放入仓库
                      </Badge>
                    </Radio>
                    <Radio value={10}>
                      <Badge count={
                        <Popover placement="top"
                          content={"发布后自动上架到平台。"}
                          trigger="hover">
                          <QuestionCircleOutlined style={{ color: "#f5222d" }} />
                        </Popover>}>
                        立即上架
                      </Badge>
                    </Radio>
                  </Radio.Group>
                </div>
              </Col>
            </Row>
          </div>
        </GoodsAddCard>
        {pageType === 'modify' ? //变更
          (<Card className={`${styles.page_card} ${styles.confirm_card}`}>
            <Button
              size="large"
              type="primary"
              style={{ width: 150 }}
              onClick={() => {
                this.saveGoods('save');
              }}
              loading={this.state.submitLoading}
              disabled={this.state.submitLoading}
            >
              确认变更
            </Button>
          </Card>) :
          pageType === "resource" ?
            (<Card className={`${styles.page_card} ${styles.confirm_card}`}>
              <Space>
                <Button
                  size="large"
                  type="primary"
                  style={{ width: 150, marginRight: 40 }}
                  onClick={() => {
                    this.saveGoods('save');
                  }}
                  loading={this.state.submitLoading}
                  disabled={this.state.submitLoading}
                >
                  发布上架
                </Button>
                <span style={{ color: "#999" }}>选择发布上架，自动提交平台审核，<br />待平台审核通过后，才会上架成功哦</span>
              </Space>
            </Card>) :
            (<Card className={`${styles.page_card} ${styles.confirm_card}`}>
              <Space>
                <Button
                  size="large"
                  type="default"
                  style={{ width: 150, marginRight: 80 }}
                  onClick={() => {
                    this.saveGoods('draft');
                  }}
                  loading={this.state.draftLoading}
                  disabled={this.state.draftLoading}
                >
                  {pageType === 'add' ?
                    '存入草稿' :
                    +goodsState === -20 ?
                      '保存草稿' : '保存'}
                </Button>
                <Button
                  size="large"
                  type="primary"
                  style={{ width: 150, marginRight: 40 }}
                  onClick={() => {
                    this.saveGoods('save');
                  }}
                  loading={this.state.submitLoading}
                  disabled={this.state.submitLoading}
                >
                  立即发布
                  </Button>
                <span style={{ color: "#999" }}>选择立即发布，自动提交平台审核，<br />待平台审核通过后，才会上架成功哦</span>
              </Space>
            </Card>
            )}
        <PreviewPic
          visible={this.state.goodsPicPreviewModal}
          pic={this.state.goodsPicPreview}
          onClose={() => {
            this.setState({ goodsPicPreviewModal: false });
          }} />
        {showCategoryModal &&
          <GoodsChooseCategoryModal
            visible={showCategoryModal}
            value={goodsCategory}
            onOk={(keys, items) => {
              this.setState({
                goodsCategory: keys,
                goodsCategoryItem: items
              })
            }}
            onCancel={() => {
              this.setState({
                showCategoryModal: false
              })
            }} />}
      </div>
    );
  };
}

export default GoodsPublish;
