import React, { useState,useEffect } from 'react'
import {Modal, Form, Row, Col, Input,message} from 'antd'
import {observer} from 'mobx-react'
import styles from './index.module.scss'
import financeStore from 'stores/finance'


function SettlementAccountActionModal (props) {
  const [form] = Form.useForm()
  const [title,setTitle] = useState('')
  const [name,setName] = useState('')
  const [id,setId] = useState('')

  const _init = async() => {
    const {type,account} = props;
    
    let title = ''
    switch (type) {
      case 'add':
        title = "添加提现账户"
        await financeStore.getCompanyInfo({})
        setName(financeStore.companyInfo)
        break;
      case 'edit':
        title = "编辑提现账户"
        form.setFieldsValue({
          num:account.balanceAccountId,
          bank:account.balanceBankName
        })
        setName(account.balanceAccountName)
        setId(account.id)
        break
    }
    setTitle(title)
  }

  const confirm =  async(e) => {
    e.preventDefault();
    const {type} = props;
    try{
      const values = await form.validateFields()
      let params = {},result={success:true}
        if (type === 'add') {
          params = {
            balanceAccountName: name,
            balanceAccountId: values.num,
            balanceBankName: values.bank
          }
         result= await financeStore.postAddSettlementAccount(params)
          if(result.success){
            message.success("创建成功")
            close(true)
          }
        } else if (type === 'edit') {
          params = {
            balanceAccountName: name,
            balanceAccountId: values.num.trim(),
            balanceBankName: values.bank.trim(),
            id: id
          }
          result= await financeStore.postUpdateSettlementAccount(params)
          if(result.success){
            message.success("修改成功");
            close(true)
          }
        }
    }catch(e) {
      
    }
    

  }

  const close = e => {
    console.log(e)
    props.close(e ? 'ok' : 'cancel')
  }

  useEffect(() => {
    _init()
  },[])
  return(
    <Modal visible={props.visible} title={title} onOk={confirm}
             onCancel={() => close(false)}>
        <Form form={form}>
          <Row>
            <Col span={6} className={styles.col_title}>
              <span className={styles.line_title}>开户名：</span>
            </Col>
            <Col span={18} className={styles.col_content}>
              <p className={styles.content_name}>
                {name}
              </p>
              <p className={styles.content_tip}>
                开户名为您认证的主体名称，若需更改主体名称，请重新认证。
              </p>
            </Col>
            <Col span={6} className={styles.col_title}>
              <span className={styles.line_title}>银行卡号：</span>
            </Col>
            <Col span={18} className={styles.col_content}>
              <Form.Item name='num' rules={[
                    {
                      required: true,
                      message: '请输入银行卡号！',
                    }, {
                      pattern: /^\s{0,}\d{8,30}\s{0,}$/,
                      message: "请输入正确的银行卡号"
                    }
                  ]}>
                <Input />
              </Form.Item>
            </Col>
            <Col span={6} className={styles.col_title}>
              <span className={styles.line_title}>开户行：</span>
            </Col>
            <Col span={18} className={styles.col_content}>
              <Form.Item name='bank' rules={[
                    {
                      required: true,
                      message: '请输入开户行！',
                    }, {
                      pattern: /^\s{0,}[\u4eo0-\u9fa5a-zA-Z_0-9]{2,50}\s{0,}$/,
                      message: "请输入正确的开户行!"
                    }
                  ]}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
  )
}

export default observer(SettlementAccountActionModal)