import React, {Component} from 'react'
import {Button, Row, Col, Card, Statistic, Badge, Popover, Table} from "antd";
import {inject, observer} from "mobx-react";
import {toJS} from 'mobx'
import {formatTime, showMoney} from "utils/tools";

import styles from './index.module.scss'

@inject(...["financeStore"])
@observer
class SettlementManage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      pageNo: 1,
      pageSize: 20
    }
  }


  componentDidMount(){
    this.props.financeStore.getManageBaseData({})
    this.getData()
  }


  getData = () => {
    let params = {
      pageNo: this.state.pageNo,
      pageSize: this.state.pageSize
    }
    this.props.financeStore.getSettlementRecordList(params)
  }


  renderPopper = (text, record, index) =>
    <Popover content={<p>{text}</p>}>
      <p className={styles.table_dot_text}>{text}</p>
    </Popover>


  renderTable = () => {
    const {settlementRecordList} = this.props.financeStore;
    const settlementStatus = ['', "结算申请中", "平台审核中", "结算已驳回", "结算已到账", "结算已取消"]

    const columns = [
      {
        title: "结算日期起始",
        align: "center",
        dataIndex: "balanceStartTime",
        render: (text, record, index) => formatTime(text)
      }, {
        title: "结算日期结束",
        align: "center",
        dataIndex: "balanceEndTime",
        render: (text, record, index) => formatTime(text)
      }, {
        title: "结算金额",
        align: "center",
        dataIndex: "balanceAmount",
        render: (text, record, index) => showMoney(text)
      }, {
        title: "手续费",
        align: "center",
        dataIndex: "balanceFee",
        render: (text, record, index) => showMoney(text)
      }, {
        title: "到账金额",
        align: "center",
        dataIndex: "balanceRealAmount",
        render: (text, record, index) => showMoney(text)
      }, {
        title: "开户名",
        align: "center",
        dataIndex: "balanceAccountName",
        render: this.renderPopper
      }, {
        title: "开户行",
        align: "center",
        dataIndex: "balanceBankName",
        render: this.renderPopper
      }, {
        title: "结算账户",
        align: "center",
        dataIndex: "balanceAccountId",
      }, {
        title: "申请时间",
        align: "center",
        dataIndex: "applyTime",
        render: (text, record, index) => formatTime(text)
      }, {
        title: "处理时间",
        align: "center",
        dataIndex: "auditTime",
        render: (text, record, index) => formatTime(text)
      }, {
        title: "状态",
        align: "center",
        dataIndex: "balanceStatus",
        render: (text, record, index) => settlementStatus[text]
      }, {
        title: "操作",
        align: "center",
        render: (text, record, index) => record.balanceStatus==5?"":<span className={styles.table_action_btn} onClick={()=>this.goToApplySettlement("detail",record)}>详情</span>
      },
    ]

    const pagination = {
      current: this.state.pageNo,
      pageSize: this.state.pageSize,
      total: settlementRecordList.total,
      showTotal: (total) => `共有${total}条纪录  `,
      showSizeChanger: true,
      showQuickJumper: true,
      defaultPageSize: 20,
      pageSizeOptions: ['20', '50', '100'],
      onChange: this.paginationChange,
      onShowSizeChange: this.paginationPageSizeChange
    }
    return <Table columns={columns}
                  pagination={pagination}
                  rowKey={record => record.balanceId}
                  dataSource={settlementRecordList.list}/>
  }
  /**
   * 分页组件页码变动回调
   * @param page
   * @param pageSize
   */
  paginationChange = (page, pageSize) => {
    this.setState({
      pageNo: page
    }, () => {
      this.getData()
    })
  }

  /**
   * 分页组件pageSize变化回调
   * @param current
   * @param size
   */
  paginationPageSizeChange = (current, size) => {
    this.setState({
      pageNo: 1,
      pageSize: size
    }, () => {
      this.getData()
    })
  }

  goToApplySettlement = (type, item) => {
    switch (type) {
      case "new":
        window.open('/finance/center/settlement-progress',"_self")
        break;
      case 'detail':
        window.open(`/finance/center/settlement-progress-detail?id=${item.balanceId}`,"_self")
        break;
    }
  }

  render() {
    const {manageBaseData} = this.props.financeStore
    const cardStyle = {
      height: 150,
      display: "flex",
      alignItems: "center"
    };
    const badgeStyle = {
      backgroundColor: '#faad14',
      color: '#fff',
    }
    return (
      <div className={styles.page_container}>
        <Row>
          <Col span={12}>
            <Card bodyStyle={cardStyle}>
              <p>可结算金额：</p>
              <Statistic value={manageBaseData.totalAmount / 100} precision={2} style={{marginRight: 10}} prefix="¥"/>
              <Popover content={
                <p>可以申请结算的总额</p>
              }>
                <Badge
                  count={"?"}
                  style={badgeStyle}
                />
              </Popover>
              <Button style={{width: 100, margin: "0 10px 0 20px"}}
                      disabled={manageBaseData.lastBillInProgress || +manageBaseData.balanceThreshold > +manageBaseData.totalAmount}
                      onClick={() => this.goToApplySettlement("new")}>申请结算</Button>
              {manageBaseData.lastBillInProgress ? <span
                className={styles.tip_grey}>已有进行中的结算申请</span> : +manageBaseData.balanceThreshold > +manageBaseData.totalAmount ?
                <span className={styles.tip_black}>大于¥{showMoney(manageBaseData.balanceThreshold)}可结算</span> : ''}

            </Card>
          </Col>
          <Col span={12}>
            <Card bodyStyle={cardStyle}>
              <p>结算锁定金额： </p>
              <Statistic value={manageBaseData.freezeAmount / 100} precision={2} style={{marginRight: 10}} prefix="¥"/>
              <Popover content={
                <p>结算申请提交后，会对本次结算金额进行锁定，审核后将会归零</p>
              }>
                <Badge
                  count={"?"}
                  style={badgeStyle}
                />
              </Popover>
            </Card>
          </Col>
        </Row>
        <p className={styles.table_title}>结算记录</p>
        <div className={styles.table_container}>
          {this.renderTable()}
        </div>

      </div>
    )
  }
}

export default SettlementManage;
