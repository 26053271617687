import React, {Component} from 'react'
import {Icon} from "antd";
import PropTypes, {arrayOf} from 'prop-types'

/**
 * @props data:Array[{
 *    pic,link
 * }]
 * @props imgSize:Array[x,y,marginRight]
 * @props style:Object{ 外部容器样式
 *   width:required
 *   height:required
 * }
 *
 * @props styleInner:Object 内部容器样式
 * @props styleImg:Object 图片样式
 * @props arrow:Array[left,right] 箭头
 * @props styleArrow:Object 箭头样式
 * @props arrowHover:boolean 箭头显示
 *
 * @props minNum:number
 */
class ScrollImgs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false
    }
  }

  componentDidMount() {
    const {arrowHover}=this.props;
    this.setState({
      hover:!arrowHover
    })
  }

  topicGo = type => {
    const {data, minNum, imgSize,styleInner} = this.props;
    let min=minNum||(styleInner.width+imgSize[2])/(imgSize[0]+imgSize[2]);
    console.log("min=====",styleInner.width,min)
    const actionFlag = data.length >= min,
      itemWidth = imgSize[0] + imgSize[2],
      imgContainerWidth = (imgSize[0] + imgSize[2]) * data.length;
    if (actionFlag) {
      let imgAll = this.refs.imgAll
      let left = +imgAll.style.left.slice(0, -2);
      console.log(left)
      switch (type) {
        case "l":
          if (left <= -imgContainerWidth) {
            imgAll.style.transition = "";
            imgAll.style.left = "0px";
            setTimeout(() => {
              imgAll.style.transition = "left 0.5s ease";
              left = -itemWidth;
              imgAll.style.left = `${left}px`;
            })
          } else {
            left -= itemWidth;
            imgAll.style.left = `${left}px`;
          }

          break;
        case "r":
          if (left >= 0) {
            imgAll.style.transition = "";
            imgAll.style.left = `${-imgAll.clientWidth / 2}px`;
            setTimeout(() => {
              imgAll.style.transition = "left 0.5s ease";
              left = -imgContainerWidth + itemWidth;
              imgAll.style.left = `${left}px`;
            })
          } else {
            left += itemWidth;
            imgAll.style.left = `${left}px`;
          }
          break;
      }
    }


  }

  render() {
    const {data, minNum, imgSize, style, styleInner, styleImg, arrow, styleArrow, arrowHover} = this.props;
       let min=minNum||(styleInner.width+imgSize[2])/(imgSize[0]+imgSize[2]);
    const {hover} = this.state;
    const imgContainerWidth = (imgSize[0] + imgSize[2]) * data.length,//图片整个容器占的宽度
      actionFlag = data.length >= min,//是否图片数量足够（能否左右滚动）
      btnStyle = {
        height: '100%',
        width: 40,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "20px",
        color: "#fff",
        backgroundColor: "rgba(0, 0, 0, .3)",
        position: "absolute",
        top: 0,
        cursor: actionFlag?"pointer":"not-allowed",
        opacity: +hover,
        transition: "all .5s ease"
      }
    return (
      // 最外层容器
      <div style={Object.assign({
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative"
      }, style || {})}
           onMouseEnter={() => arrowHover && this.setState({hover: true})}
           onMouseLeave={() => arrowHover && this.setState({hover: false})}>
        {/*内容外部固定宽高的容器*/}
        <div style={Object.assign({
          width: "100%",
          overflow: "hidden",
          height: imgSize[1],
          position: "relative"
        }, styleInner || {})}>
          {/*总容器*/}
          <div style={{
            width: actionFlag ? imgContainerWidth * 2 : imgContainerWidth,
            position:"relative",
            left:0,
            transition: "left 0.5s ease",
          }} ref="imgAll">
            {/*分容器*/}
            <div style={{
              width: imgContainerWidth,
              display: "inline-block"
            }}>
              {/*内容*/}
              {data.map((item, index) =>
                <div key={index} style={Object.assign({
                  width: imgSize[0],
                  height: imgSize[1],
                  marginRight: `${imgSize[2]}px`,
                  display: "inline-block",
                  overflow: "hidden",
                  cursor: item.link ? "pointer" : "default",
                }, styleImg || {})} onClick={() => {
                  item.link && window.open(item.link, "_self")
                }}>
                  <img src={item.pic} style={{width: "100%", height: "100%"}} alt=""/>
                </div>
              )}
            </div>
            {/*分容器*/}
            {actionFlag && <div style={{
              width: imgContainerWidth,
              display: "inline-block"
            }}>
              {/*内容*/}
              {data.map((item, index) =>
                <div key={index} style={Object.assign({
                  width: imgSize[0],
                  height: imgSize[1],
                  marginRight: `${imgSize[2]}px`,
                  display: "inline-block",
                  overflow: "hidden",
                  cursor: item.link ? "pointer" : "default",
                }, styleImg || {})} onClick={() => {
                  item.link && window.open(item.link, "_self")
                }}>
                  <img src={item.pic} style={{width: "100%", height: "100%"}} alt=""/>
                </div>
              )}
            </div>}

          </div>
        </div>

        {/*左按钮*/}
        <span style={Object.assign({...btnStyle, left: 0}, styleArrow)}
              onClick={() => this.topicGo("l")}>
                {arrow ? arrow[0] : <Icon type="left"/>}
              </span>
        {/*右按钮*/}
        <span style={Object.assign({...btnStyle, right: 0}, styleArrow)}
              onClick={() => this.topicGo("r")}>
               {arrow ? arrow[1] : <Icon type="right"/>}
              </span>
      </div>
    );
  }
}

/**
 * @props data:Array[{
 *    pic,link
 * }]
 * @props imgSize:Array[x,y,marginRight]
 * @props style:Object{ 外部容器样式
 *   width:required
 *   height:required
 * }
 *
 * @props styleInner:Object 内部容器样式
 * @props styleImg:Object 图片样式
 * @props arrow:Array[left,right] 箭头
 * @props styleArrow:Object 箭头样式
 * @props arrowHover:boolean 箭头显示
 *
 * @props minNum:number
 */

ScrollImgs.propTypes={
  data:PropTypes.arrayOf(PropTypes.object),
  imgSize:PropTypes.arrayOf(PropTypes.number),
  style:PropTypes.object,
  styleInner:PropTypes.object,
  styleImg:PropTypes.bool||PropTypes.object,
  arrow:PropTypes.bool||arrayOf(PropTypes.oneOfType([PropTypes.string,PropTypes.element])),
  styleArrow:PropTypes.oneOfType([PropTypes.bool,PropTypes.object]),
  arrowHover:PropTypes.bool,
  minNum:PropTypes.number
}

export default ScrollImgs;
