import React from 'react';
import {Route, Switch} from "react-router-dom";

import Signup from "./Signup";
import Tools from "./Tools";

function ActivityModule(props) {

  return (
    <Switch>
      <Route path="/activity/signup" exact component={Signup}/>
      <Route path="/activity/tools" exact component={Tools}/>
    </Switch>
  )
}

export default ActivityModule;
