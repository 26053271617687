import React, { useState, useEffect } from 'react';
import styles from './index.module.scss'
import { Button, Table } from "antd";

import stores from 'stores'
import goods from 'stores/goods';
import { formatTime } from 'utils/tools';
const { goodsModifyStore } = stores

function Change(props) {

  const [pageNo, setPageNo] = useState(1)
  const [pageSize, setPageSize] = useState(20)
  const [dataList, setDataList] = useState({ list: [], total: 0 });
  const [refreshData, setRefreshData] = useState(true)

  useEffect(() => {
    refreshData && _getData()
    refreshData && setTimeout(() => setRefreshData(false));
  }, [refreshData])


  const statusMap = new Map([
    [1, "已通过"],
    [2, "待审核"],
    [3, "已驳回"],
  ])

  const modifyTypeMap = new Map([
    [1, "交易信息变更"],
    [2, "基础信息变更"],
    [3, "商品下架"]
  ])

  const columns = [{
    title: "申请时间",
    align: "center",
    dataIndex: 'createTime',
    render: text => formatTime(text)
  }, {
    title: "处理时间",
    align: "center",
    dataIndex: "approveTime",
    render: text => formatTime(text)
  }, {
    title: "商品名称",
    align: "center",
    dataIndex: "goodsName",
    render: text => <p className={styles.list_name}>{text}</p>
  }, {
    title: "变更类型",
    align: "center",
    dataIndex: "changeType",
    render: text => modifyTypeMap.get(text)
  }, {
    title: "状态",
    align: "center",
    dataIndex: "status",
    render: text => statusMap.get(text)
  }, {
    title: "操作",
    align: "center",
    fixed: "right",
    render: (text, record) =>
     <Button type="link" onClick={()=>window.open(`/goods/modify/detail?id=${record.id}`,"_self")}>详情</Button>
  }]
  /**
   * 页码改变
   * @param page
   * @param pageSize
   * @returns {Promise<void>}
   */
  const changePage = (page, pageSize) => {
    setPageNo(page);
    setRefreshData(true)
  }

  /**
   * pageSize改变
   * @param current
   * @param size
   * @returns {Promise<void>}
   */
  const changePageSize = (current, size) => {
    setPageNo(1);
    setPageSize(size);
    setRefreshData(true)
  }
  const pagination = {
    total: dataList.total,
    pageSize,
    current: pageNo,
    showSizeChanger: true,
    showQuickJumper: true,
    pageSizeOptions: ["20", "50", "100"],
    defaultPageSize: 20,
    onChange: changePage,
    onShowSizeChange: changePageSize,
    showTotal: total => `共 ${total} 条数据`,
  }
  const _getData = async () => {
    let params = {
      pageNo, pageSize,
      isSupportPurchase: 1,
      isSelf: 0
    }
    console.log(params)

    let data = await goodsModifyStore.getModifyList(params)
    setDataList(data)
  }
  return (
    <div>
      <Table
        columns={columns}
        dataSource={dataList.list}
        pagination={pagination}
        scroll={{ x: true }}
        rowKey={record => record.key} />
    </div>
  );
}

export default Change;
