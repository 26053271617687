import React, {useState, useEffect} from 'react';
import {Tabs, Table, Cascader, Input, Button, Space, Form, Popover, Badge, Select, Modal, message} from 'antd'
import {formatTime, getQuery, showMoney} from 'utils/tools'
import {withRouter} from 'react-router-dom'
import stores from 'stores'
import {Modals} from 'components'
import styles from './index.module.scss'


const {TabPane} = Tabs
const {confirm} = Modal
const {commonStore, goodsOwnStore} = stores
const {GoodsModifyModal} = Modals

const goodsCheckStatusMap = [
  {name: "审核中", value: -10},
  {name: "已驳回", value: -20},
  {name: "已通过", value: 10},
]
const goodsSelectStatusMap = [
  {name: "已挑选", value: -10},
  {name: "未挑选", value: -20},
]

function List(props) {
  const [form] = Form.useForm();
  const [tab, setTab] = useState("10"); // 10 | -10 | -20
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [categoryData, setCategoryData] = useState([]);
  const [searchParams, setSearchParams] = useState({category: []});
  const [orderParams, setOrderParams] = useState({});
  const [dataList, setDataList] = useState({list: [], total: 0});
  const [showModifyModal, setShowModifyModal] = useState(false);
  const [actionItem, setActionItem] = useState({});
  const [refreshData, setRefreshData] = useState(false);
  const [refresh, setRefresh] = useState(false);
  useEffect(() => {
    // 获取链接的tab值 10 -10 -20
    let query = getQuery()
    console.log(props.location.search)
    if (query.tab) {
      setTab(query.tab)
      setRefreshData(true)
    }else{
      setTab("10")
      setRefreshData(true)
    }
  }, [props.location.search]);

  useEffect(() => {
    _initCategory();
  }, []);
  // 请求数据
  useEffect(() => {
    refreshData && _getData()
    refreshData && setTimeout(() => setRefreshData(false));
  }, [refreshData]);
  useEffect(() => {
    refresh && setTimeout(() => setRefresh(false));
  }, [refresh]);

  const _initCategory = async () => {
    let cate1 = await commonStore.getCategoryLevel({parentId: 0})
    setCategoryData(cate1.map((item) => ({
      label: item.name,
      value: item.id,
      isLeaf: +item.level > 1
    })))
    setRefresh(true)
  };

  /**
   * 获取级联分类
   * @param selectedOptions
   */
  const getMoreCate = async selectedOptions => {
    const targetOption = selectedOptions[selectedOptions.length - 1];
    targetOption.loading = true;

    let arr = await commonStore.getCategoryLevel({parentId: targetOption.value})
    targetOption.children = arr.map((item) => ({
      label: item.name,
      value: item.id,
      isLeaf: +item.level > 1
    }))
    targetOption.loading = false;
    setCategoryData([...categoryData])
  };
  const getColumns = () => {
    const entityMap = ["虚拟商品", "实物商品"]
    const statusMap = {"-10": "审核中", "10": "已通过", "-20": "已驳回"}
    const columns = [
      {
        title: "商品名称",
        align: "center",
        width: 300,
        dataIndex: "",
        render: (text, record, index) =>
          <div className={styles.item_container}>
            <div className={styles.item_img_container}>
              <img src={record.mainImage} className={styles.item_img} alt=""/>
            </div>
            <div className={styles.item_info_container}>
              <p className={styles.item_name} 
              onClick={()=>{
                if(+tab!==-20){
                   window.open(`/goods/detail?id=${record.goodsId}`,"_blank")
                }
              }
             }>{record.name}</p>
              <p className={styles.item_id}>商品id:{record.goodsId}</p>
            </div>
          </div>,
      }, {
        title: "商品分类",
        align: "center",
        dataIndex: "categoryTreeName",
        render: (text, record, index) => <>
          <p>{entityMap[record.isEntity] || "--"}</p>
          {text || "--"}
        </>,
      },
    ]
    if (+tab !== -20) {
      columns.push({
        title: "市场价(元)",//草稿箱没有
        align: "center",
        dataIndex: "",
        render: (text, record, index) => {
          switch (+record.salesType) {
            case 1:
              return `¥${showMoney(record.marketPriceMin)}`
            case 2:
              return record.marketPriceMin === record.marketPriceMax ?
                `¥${showMoney(record.marketPriceMin)}` :
                `¥${showMoney(record.marketPriceMin)} ~ ¥${showMoney(record.marketPriceMax)}`
          }
        },
      }, {
        title: "供应价(元)",//草稿箱没有
        align: "center",
        dataIndex: "",
        render: (text, record, index) => {
          switch (+record.salesType) {
            case 1:
              return <div dangerouslySetInnerHTML={{
                __html: record.stairList.map((item, index, arr) => {
                  return index === arr.length - 1 ?
                    `¥${showMoney(item.supplyPrice)} (≥${item.minNum}个)` :
                    `¥${showMoney(item.supplyPrice)} (${item.minNum}-${item.maxNum}个)`
                }).join("<br/>")
              }}/>
            case 2:
              return record.supplyPriceMin === record.supplyPriceMax ?
                `¥${showMoney(record.supplyPriceMin)}` :
                `¥${showMoney(record.supplyPriceMin)} ~ ¥${showMoney(record.supplyPriceMax)}`
          }
        },
      }, {
        title: "集采价(元)",//草稿箱没有
        align: "center",
        dataIndex: "",
        render: (text, record, index) => {
          switch (+record.salesType) {
            case 1:
              return <div dangerouslySetInnerHTML={{
                __html: record.stairList.map((item, index, arr) => {
                  return index === arr.length - 1 ?
                    `¥${showMoney(item.salePrice)} (≥${item.minNum}个)` :
                    `¥${showMoney(item.salePrice)} (${item.minNum}-${item.maxNum}个)`
                }).join("<br/>")
              }}/>
            case 2:
              return record.salePriceMin === record.salePriceMax ?
                `¥${showMoney(record.salePriceMin)}` :
                `¥${showMoney(record.salePriceMin)} ~ ¥${showMoney(record.salePriceMax)}`
          }
        },
      }, {
        title: "累计销量",
        align: "center",
        dataIndex: "salesNum",
        sorter: true,
        render: (text, record, index) => text
      }, {
        title: "库存",//草稿箱没有
        align: "center",
        dataIndex: "stock",
        sorter: true,
        render: (text, record, index) => text,
      })
    }
    if (+tab === -20) {
      columns.push({
        title: "创建时间",//草稿箱是创建时间
        align: "center",
        dataIndex: "createTime",
        sorter: true,
        render: (text, record, index) => formatTime(text),
      })
    } else {
      columns.push({
        title: "发布时间",//草稿箱是创建时间
        align: "center",
        dataIndex: "shelvesTime",
        sorter: true,
        render: (text, record, index) => formatTime(text),
      })
      if (+tab === 10) {
        columns.push({
          title: "平台销售状态",//未上架，草稿箱没有
          align: "center",
          dataIndex: "platformShelvesStatus",
          render: (text, record, index) => +text === -10 ?
            "未显示":"销售中",
        }, {
          title: "审核状态",//未上架，草稿箱没有
          align: "center",
          fixed: "right",
          dataIndex: "platformStatus",
          render: (text, record, index) => +text === -20 ?
            <div>
              <Popover content={<p style={{maxLength:"300px"}}>{record.reason}</p>}>
                <Badge count="?">
                  <span>{statusMap[text]}</span>
                </Badge>
              </Popover>
            </div> : statusMap[text],
        })
      }
    }
    columns.push({
      title: "操作",
      align: "center",
      fixed: "right",
      render: (text, record) => {
        switch (+tab) {
          case 10://已上架
            switch (+record.platformStatus) {
              case 10:
                return +record.changeStatus === 2 ?
                  <Button type="link" disabled>变更审核中</Button> :
                  <Button type="link"
                          onClick={() => {
                            setActionItem(record)
                            setShowModifyModal(true)
                          }}>申请变更</Button>
              case -10://审核中
              case -20://已驳回
                return <>
                  <Button type="link"
                          onClick={() => goodsAction(record, "edit")}>编辑</Button>

                  <Button type="link" onClick={() => goodsAction(record, "down")}>下架</Button>
                </>
            }
          case -10://已下架
            return <>
              <Button type="link" onClick={() => goodsAction(record, "edit")}>编辑</Button>
              <Button type="link" onClick={() => goodsAction(record, "up")}>上架</Button>
              <Button type="link" onClick={() => goodsAction(record, "delete")}>删除</Button>

            </>
          case -20://草稿箱
            return <>
              <Button type="link" onClick={() => goodsAction(record, "edit")}>编辑</Button>
              <Button type="link" onClick={() => goodsAction(record, "delete")}>删除</Button>
            </>
        }
      }
    })
    return columns
  }
  /**
   * 页码改变
   * @param page
   * @param pageSize
   * @returns {Promise<void>}
   */
  const changePage = (page, pageSize) => {
    setPageNo(page);
    setRefreshData(true)
  }

  /**
   * pageSize改变
   * @param current
   * @param size
   * @returns {Promise<void>}
   */
  const changePageSize = (current, size) => {
    setPageNo(1);
    setPageSize(size);
    setRefreshData(true)
  }
  const pagination = {
    total: dataList.total,
    pageSize,
    current: pageNo,
    showSizeChanger: true,
    showQuickJumper: true,
    pageSizeOptions: ["20", "50", "100"],
    defaultPageSize: 20,
    onChange: changePage,
    onShowSizeChange: changePageSize,
    showTotal: total => `共 ${total} 条数据`,
  }

  const changeSorter = (page, filter, sorter) => {
    console.log(page, filter, sorter)
    if (sorter.field !== orderParams.field || sorter.order !== orderParams.order) {
      setOrderParams(sorter)
      setPageNo(1)
      setRefreshData(true)
    }
  };
  const _getData = async () => {
    const orderNameMap = new Map([
      ["salesNum", "goods_sales_num"],
      ["stock", "goods_stock"],
      ["shelvesTime", "goods_shelves_time"],
      ["createTime", "create_time"],
    ]);
    let params = {
      supplierType:2,
      isSupportPurchase: 1,
      isSelf:0,
      pageNo,
      pageSize,
      goodsStatus: tab,
      categoryTree: searchParams.category ? searchParams.category.join("_") : undefined,
      goodsPlatformStatus: searchParams.goodsPlatformStatus || undefined,
      keyword: searchParams.name || undefined,
      orderName: orderParams.order ? orderNameMap.get(orderParams.field) : undefined,
      orderWay: orderParams.order ? orderParams.order.slice(0, -3) : undefined,
    }
    let data = await goodsOwnStore.getGoodsList(params)
    // setDataList(goodsOwnStore.goodsList)
    setDataList(data)
  }

  const goodsAction = async (item, type) => {
    console.log(item, type)
    let params = {},
      res = null
    switch (type) {
      case "edit":
        // props.history.push({pathname: `/goods/add?id=${item.goodsId}&type=edit&state=${tab}`});
        window.open(`/goods/edit?id=${item.goodsId}&type=edit&state=${tab}`, "_self")
        break;
      case "down":
        params.goodsId = item.goodsId
        res = await goodsOwnStore.downGoods(params)
        if (res.success) {
          message.success("下架成功！")
          setTimeout(() => {
            setRefreshData(true)
          }, 2000)
        }
        break;
      case "up":
        params.goodsId = item.goodsId
        res = await goodsOwnStore.upGoods(params)
        if (res.success) {
          message.success("已提交平台审核，1～3个工作日审核完成");
          setTimeout(() => {
            setRefreshData(true)
          }, 2000)
        }
        break;
      case "delete":
        confirm({
          title: '温馨提示 确认删除?',
          content: '删除后您将无法看到这个商品',
          okText: "确认删除",
          cancelText: "我再想想",
          centered: true,
          async onOk() {
            params = {
              goodsId: item.goodsId
            }
            res = await goodsOwnStore.deleteGoods(params)
            if (res.success) {
              message.success("删除成功！");
              setTimeout(() => {
                setRefreshData(true)
              }, 2000)
            }
          },
          onCancel() {
            console.log('Cancel');
          },
        });
        break;
    }
  }

  const search = () => {
    let params = form.getFieldsValue()
    setSearchParams(params)
    setPageNo(1)
    setRefreshData(true)
  }

  const reset = () => {
    let params = {
      category: undefined,
      goodsPlatformStatus: undefined,
      name: undefined
    }
    form.setFieldsValue(params)
    setSearchParams(params)
    setPageNo(1)
    setRefreshData(true)

  }
  return (
    <div>
      <Tabs activeKey={tab}
            onChange={val => {
              setTab(val)
              let params = {
                category: undefined,
                goodsPlatformStatus: undefined,
                name: undefined
              }
              form.setFieldsValue(params)
              setSearchParams(params)
              setPageNo(1)
              setRefreshData(true)
              props.history.push(`/goods/purchase/list?tab=${val}`)
            }}
            style={{paddingLeft: "24px"}}>
        <TabPane tab="已上架" key="10"/>
        <TabPane tab="仓库中" key="-10"/>
        <TabPane tab="草稿箱" key="-20"/>
      </Tabs>
      <>
        <Space className={styles.search_line}>
          <Form layout="inline" form={form}>
            {+tab !== -20 &&
            <Form.Item name="category">
              <Cascader placeholder="分类" allowClear
                        options={categoryData}
                        loadData={getMoreCate}
                        changeOnSelect/>
            </Form.Item>}
            {+tab == 10 &&
            <Form.Item name="goodsPlatformStatus">
              <Select placeholder="审核状态" allowClear style={{minWidth: "100px"}}>
                {goodsCheckStatusMap.map(item =>
                  <Select.Option value={item.value}
                                 key={item.value}>{item.name}</Select.Option>)}
              </Select>
            </Form.Item>}
            <Form.Item name="name">
              <Input placeholder="请输入商品名称/id" allowClear/>
            </Form.Item>

          </Form>
          <Button type="primary" onClick={search}>查询</Button>
          <Button type="default" onClick={reset}>重置搜索</Button></Space>
        <div className={styles.table_container}>
          <Table columns={getColumns()}
                 dataSource={dataList.list}
                 pagination={pagination}
                 scroll={{x: 1220}}
                 onChange={changeSorter}
                 rowKey={record => record.key}/>
        </div>

      </>

      {showModifyModal &&
      <GoodsModifyModal visible={showModifyModal}
                        item={actionItem}
                        onCancel={flag => {
                          setShowModifyModal(false)
                          if (flag) {
                            setTimeout(() => {
                              setRefreshData(true)
                            }, 2000)
                          }
                        }}/>}
    </div>
  );
}


export default withRouter(List);
