import React, { useState, useEffect } from 'react';
import { Modal, Select, message } from 'antd';
import PropTypes from 'prop-types';
import stores from 'stores'
import styles from './index.module.scss';
import { set } from 'mobx';

const { Option } = Select;
const { commonStore } = stores

function ChooseCategoryModal(props) {
  const { visible, onCancel, onOk, value } = props;
  const [innerVal, setInnerVal] = useState([]);
  const [cateList, setCateList] = useState([]);
  const [searchInputVal, setSearchInputVal] = useState(undefined);
  const [searchVal, setSearchVal] = useState(undefined);
  const [searchList, setSearchList] = useState([])
  let [selectVal, setSelectVal] = useState([])
  let [selectItem, setSelectItem] = useState([])
  const [refresh, setRefresh] = useState(true)
  useEffect(() => {
    refresh && setTimeout(() => setRefresh(false));
  }, [refresh])
  useEffect(() => {
    setInnerVal(value)
    _initCategory(value)
  }, [value])

  // 初始化分类数据
  const _initCategory = async (val) => {
    let cateList = [];
    let cate1 = await commonStore.getCategoryLevel({ parentId: 0 });
    cateList = cate1.map((item) => ({
      label: item.name,
      value: item.id,
      isLeaf: +item.level > 1,
    }));
    let cate2 = await commonStore.getCategoryLevel({ parentId: val[1] ? val[0] : cateList[0].value });
    let index = val[1] ? cateList.findIndex((item) => +item.value === val[0]) : 0;
    cateList[index].children = cate2.map((item) => ({
      label: item.name,
      value: item.id,
      isLeaf: +item.level > 1,
    }));

    if (val[0]) {
      let item0 = cateList.find(item => item.value === val[0]);
      let index0 = cateList.findIndex(item => item.value === val[0]);
      await itemClick(item0, index0, 0);
      if (val[1]) {
        let item1 = item0.children.find(item => item.value === val[1]);
        let index1 = item0.children.findIndex(item => item.value === val[1]);
        await itemClick(item1, index1, 1);
      }
    }

    setCateList([...cateList]);

  }

  // 获取第二等级分类
  const getCategoryLevel = async (item, index) => {
    let cate = await commonStore.getCategoryLevel({ parentId: item.value });
    cateList[index].children = cate.map((item) => ({
      label: item.name,
      value: item.id,
      isLeaf: +item.level > 1,
    }));
    setCateList([...cateList]);
  }

  // 关键字搜索分类
  const searchCategory = async (val) => {
    let value = val && val.trim()
    setSearchInputVal(value)
    if (value) {
      let params = {
        keyword: value,
      }
      let res = await commonStore.getCategorySearch(params);
      let list = res.map(item => ({
        name: item.categoryTreeName.replace(new RegExp(val, 'g'), `<span style="color:red">${val}</span>`),
        value: item.categoryTree
      }))
      setSearchList(list)
      setRefresh(true)
    }

  }

  // 选中搜索的分类
  const changeCategory = async (val) => {
    setSearchVal(val)
    if (val) {
      let valArr = val.split("_").map(item => +item)
      let item0 = cateList.find(item => item.value === valArr[0]);
      let index0 = cateList.findIndex(item => item.value === valArr[0]);
      await itemClick(item0, index0, 0)
      if (valArr[1]) {
        let item1 = item0.children.find(item => item.value === valArr[1]);
        let index1 = item0.children.findIndex(item => item.value === valArr[1]);
        await itemClick(item1, index1, 1)
      }
    }

  }

  // 级联分类点击
  const itemClick = async (item, index, level) => {
    if (level === 0 && (!item.children || !item.children.length)) {
      await getCategoryLevel(item, index)
    }
    selectVal[level] = item.value
    selectItem[level] = item
    // if (level === 0) {
    //   delete selectItem[1];
    //   delete selectVal[1];
    // }

    selectItem = selectItem.slice(0, level + 1)
    selectVal = selectVal.slice(0, level + 1)
    setSelectVal([...selectVal])
    setSelectItem([...selectItem])
  }

  // 确认
  const confirm = () => {
    if (selectVal.length !== 2 || selectItem.length !== 2) {
      message.error("请选择分类！")
      return
    }
    onCancel();
    onOk(selectVal, selectItem)
  }

  const getEmptyTip = () =>
    searchInputVal ? `很抱歉，没有搜到您要的 “${searchInputVal}”，请重新输入关键词 或 手动选择类目` :
      "请输入关键词"

  return <Modal visible={visible} title="选择商品类目" onOk={confirm} onCancel={onCancel}>

    <div className={styles.select_container}>
      <Select
        allowClear
        showSearch
        searchValue={searchInputVal}
        value={searchVal}
        placeholder="请输入类目关键词"
        style={{ width: '100%' }}
        defaultActiveFirstOption={false}
        showArrow={true}
        filterOption={false}
        onSearch={searchCategory}
        onChange={changeCategory}
        notFoundContent={getEmptyTip()}
      >
        {searchList.map(
          (item) => (
            <Option
              key={item.value}
              value={item.value}
            >
              <span dangerouslySetInnerHTML={{ __html: item.name }} />
            </Option>
          )
        )}
      </Select>
    </div>

    <div className={styles.level_container}>
      <div className={styles.level_items_container}>
        {cateList.map((item, index) =>
          <div className={`${styles.cate_item} ${item.value === selectVal[0] ? styles.active : ""}`}
            key={item.value} onClick={() => itemClick(item, index, 0)}>{item.label}</div>)}
      </div>
      <div className={styles.level_items_container}>
        {!!selectItem[0] && !!selectItem[0].children && !!selectItem[0].children.length && selectItem[0].children.map((item, index) =>
          <div className={`${styles.cate_item} ${item.value === selectVal[1] ? styles.active : ""}`}
            key={item.value} onClick={() => itemClick(item, index, 1)}>{item.label}</div>)}
      </div>
    </div>
    {!!selectItem.length && <p className={styles.show_result_txt}>已选择类目：{selectItem.filter(item => item).map(item => item.label).join(" >> ")}</p>}
  </Modal>
}

ChooseCategoryModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onOk: PropTypes.func,
  onCancel: PropTypes.func,
  value: PropTypes.array,
}

export default ChooseCategoryModal;

