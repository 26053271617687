import React from 'react';
import { Route, Switch } from "react-router-dom";

import AddBefore from "./Add/Before"
import Add from "./Add";
// import List from "./List";
import PurchaseList from "./Purchase/List"
import PurchaseModify from "./Purchase/Modify"
import OnceList from './Once/List'
import OnceModify from './Once/Modify'
import SelfList from './Self/List'
import ModifyDetail from './Modify/Detail'
import Resource from './Resource'
import Detail from "./Detail"

import { userIdList } from 'config'

function GoodsModule(props) {
  // const userIdMap = new Map([[userIdList[0], EleList], [userIdList[1], JdList]])
  // let userId = ""
  // const userInfoStr = localStorage.getItem("userInfo")
  // if (userInfoStr) {
  //   userId = JSON.parse(userInfoStr).outUserId
  // }
  return (
    <Switch>
      {/*<Route path="/goods/list" exact component={userIdList.includes(userId) ? userIdMap.get(userId) : List}/>*/}
      <Route path="/goods/add" exact component={AddBefore} />
      <Route path="/goods/edit" exact component={Add} />
      <Route path="/goods/purchase/list" exact component={PurchaseList} />
      <Route path="/goods/purchase/modify" exact component={PurchaseModify} />
      <Route path="/goods/once/list" exact component={OnceList} />
      <Route path="/goods/once/modify" exact component={OnceModify} />
      <Route path="/goods/self" exact component={SelfList} />
      <Route path="/goods/modify/detail" exact component={ModifyDetail} />
      {/*<Route path="/goods/change" exact component={Change}/>*/}
      <Route path="/goods/resource" exact component={Resource} />
      <Route path="/goods/detail" exact component={Detail} />

    </Switch>
  )
}

export default GoodsModule;
