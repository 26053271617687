import React, {useState, useEffect} from 'react';
import {Table, Descriptions, Divider} from 'antd'
import {Modals} from "components"
import styles from './index.module.scss'

const {OrderChangeMoneyModal, OrderGoodsDeliverModal, OrderExpressInfoModal} = Modals
const {Item} = Descriptions

const getDesLabel = (str) => <div className={styles.des_label}>{str}</div>
const getDesContent = (str) => <p className={styles.des_content}>{str}</p>


function Detail(props) {

  return (
    <>
      <div className={styles.order_detail_container}>
        <div className={styles.order_info_container}>
          <OrderInfoModule title="订单详情——改价前"/>
        </div>
        <div className={styles.order_status_container}>
          <OrderInfoModule title="订单详情——改价后"/>
        </div>
      </div>
      <div className={styles.goods_table_container}>
        <GoodsTable/>
      </div>

      {
        <OrderChangeMoneyModal visible={false}
                               onCancel={() => {
                               }}
                               onOk={() => {
                               }}/>}

      {
        <OrderGoodsDeliverModal visible={false}
                                onCancel={() => {
                                }}
                                onOk={() => {
                                }}/>}
      {
        <OrderExpressInfoModal visible={false}
                               onOk={() => {
                               }}
                               onCancel={() => {
                               }}/>}
    </>
  );

}


function OrderInfoModule(props) {
  const {title} = props;
  return <>
    <Descriptions title={title || "订单详情"} column={1}>
      <Item label={getDesLabel("订单编号")} span={1}>{getDesContent("")}</Item>
      <Item label={getDesLabel("订单金额")} span={1}>{getDesContent("")}</Item>
    </Descriptions>
    <Divider dashed/>
    <Descriptions column={1}>
      <Item label={getDesLabel("收货人姓名")} span={1}>{getDesContent("")}</Item>
      <Item label={getDesLabel("收货人手机号")} span={1}>{getDesContent("")}</Item>
      <Item label={getDesLabel("收货人地址")}
            span={1}>{getDesContent("jsvlmvl;ksdmflkmsdklfmklsmkldmklsmdlkmsalkfmksdmkfmkdsmfkmsdklmfksdmkl")}</Item>
      <Item label={getDesLabel("买家留言")} span={1}>{getDesContent("")}</Item>
    </Descriptions>
  </>
}

function GoodsTable(props) {
  const {list} = props;
  const columns = [{
    title: "商品",
    align: "center"
  }, {
    title: "数量",
    align: "center"
  }, {
    title: "销售价(改价前)",
    align: "center"
  }, {
    title: "小计(改价前)",
    align: "center"
  }, {
    title: "销售价(改价后)",
    align: "center"
  }, {
    title: "小计(改价后)",
    align: "center"
  },]

  return <Table dataSource={list} columns={columns} pagination={false} bordered/>
}

export default Detail;
