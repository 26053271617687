import React from 'react'
import { Table,Typography,Input } from 'antd'
const { Text } = Typography;

function ActNumComp ({ modalData = [] }) {
  const modalColums = [
    {
      title: '网络类型',
      dataIndex: 'type',
      render: (value, row, index) => {
        const obj = {
          children: value,
          props: {},
        };
        if (index === 0) {
          obj.props.rowSpan = 2;
        }
        // These two are merged into above cell
        if (index === 1) {
          obj.props.rowSpan = 0;
        }
        // if (index === 4) {
        //   obj.props.colSpan = 0;
        // }
        return obj;
      },
    },
    {
      title: '颜色',
      dataIndex: 'color',
    },
    {
      title: '机身内存',
      dataIndex: 'momory',
    },
    {
      title: '现有库存',
      dataIndex: 'nowMomory',
    },
    {
      title: '活动数量',
      dataIndex: 'num',
      render: (info, record) => {
        return (
          <Input
            key={info}
            style={{ width: '120px' }}
            onBlur={(e) => {
              inputBlur(e, record, 1)
            }}
            defaultValue={info}
          />
        )
      },
    },
  ]

  const inputBlur = (e, record, type) => {
    // const val = e.target.value
    // const _data = [...data]
    // const index = _data.findIndex((item) => item.id === record.id)
    // const target = _data[index]
    // //是否数字 大于0
    // const isLimit = isNaN(val) || val < 0
    // if (type == 1) {
    //   _data.splice(index, 1, { ...target, num: isLimit ? '' : val })
    // } else {
    //   _data.splice(index, 1, { ...target, price: isLimit ? '' : val })
    // }
    // setData(_data)
  }
  const summary = () => {
    return(
      <Table.Summary.Row>
      <Table.Summary.Cell colSpan={3}>总计</Table.Summary.Cell>
      <Table.Summary.Cell colSpan={1}>
        <Text type="danger">{200}</Text>
      </Table.Summary.Cell>
      <Table.Summary.Cell colSpan={1}>
        <Text type="danger">{100}</Text>
      </Table.Summary.Cell>
    </Table.Summary.Row>
    )
    
  }
  return(
    <div>
      <Table columns = { modalColums } dataSource = { modalData } rowKey={'num'} summary={ summary } bordered  />
    </div>
  )
}

export default ActNumComp