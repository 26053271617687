import React,{useContext} from 'react'
import { Tabs } from 'antd'
import styles from './index.module.scss'
import ApplyRecord from './applyRecord'
import ActSquare from './actSquare'
import activityStore from 'stores/actManagement'
import { observer } from 'mobx-react'


const { TabPane } = Tabs

function Tab() {
  const { ActManagementKey } = activityStore
  const tabList = [
    { name: '活动广场', key: '1' },
    { name: '我的报名记录', key: '2' },
  ]
  const callback = (val) => {
    activityStore.setActManagementKey(val)
  }

  return (
    <div className={styles.act_container}>
      <Tabs onChange={callback} type="card" defaultActiveKey={ ActManagementKey }>
        <TabPane key='1' tab='活动广场'>
          <ActSquare test={1} />
        </TabPane>
        <TabPane key='2' tab='我的报名记录'>
          <ApplyRecord />
        </TabPane>
      </Tabs>
    </div>
  )
}
export default observer(Tab)
