import React from 'react';
import {Route, Switch} from "react-router-dom";

import Open from './Open'
import List from './List'
import Edit from './Edit'

function SupplyModule(props) {

  return (
    <Switch>
      <Route path="/supply/open" exact component={Open}/>
      <Route path="/supply/manage" exact component={List}/>
      <Route path="/supply/manage/edit" exact component={Edit}/>
    </Switch>
  )
}

export default SupplyModule;
