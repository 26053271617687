import React, { Component } from 'react';
import { Spin } from 'antd';
import styles from './index.module.scss'

function Loading(props) {
  return (
    <div style={{ height: '100vh', width: '100%', display: 'flex',alignItems: 'center', justifyContent: 'center' }} >
      <Spin size="large" />
    </div>
  );
}

export default Loading;
