import { observable, action, toJS } from 'mobx';
import httpClient from 'utils/httpClient';
import api from 'config/api';
import { addKey } from 'utils/tools';

const { finance } = api;

class FinanceStore {
  @observable overviewData = {
    restAmount: 0,
    availableAmount: 0,
    amount: 0,
  };
  @observable manageBaseData = {
    totalAmount: 0,
    freezeAmount: 0,
  };
  @observable settlementRecordList = {
    list: [],
    page: 1,
    pageSize: 20,
    total: 0,
  };

  @observable dealDetailData = {};
  @observable dealDetailList = {
    list: [],
    page: 1,
    pageSize: 20,
    total: 0,
  };

  @observable settlementAccountList = [];
  @observable companyInfo = {};

  @observable settlementBasicInfo = {};
  @observable recordDetailList = {
    list: [],
    page: 1,
    pageSize: 20,
    total: 0,
  };
  @observable orderDetailList = {
    list: [],
    page: 1,
    pageSize: 20,
    total: 0,
  };
  @observable invoiceInfo = {};
  @observable settlementId = null;
  @observable oneSettlementInfo = {};

  /**
   * overview页面数据
   * @param params
   * @returns {Promise<void>}
   */
  @action
  async getOverviewData(params) {
    const data = await httpClient.get(finance.GET_OVERVIEW_DATA, { params });
    if (data.success) {
      this.overviewData = data.data;
    }
  }

  /**
   * manage头部数据
   * @param params
   * @returns {Promise<void>}
   */
  @action
  async getManageBaseData(params) {
    const data = await httpClient.post(finance.GET_MANAGE_BASE_DATA, {
      ...params,
    });
    if (data.success) {
      this.manageBaseData = data.data;
    }
  }

  /**
   * 结算记录列表
   * @param params
   * @returns {Promise<void>}
   */
  @action
  async getSettlementRecordList(params) {
    const data = await httpClient.post(finance.GET_SETTLEMENT_RECORD_LIST, {
      ...params,
    });
    if (data.success) {
      this.settlementRecordList = data.data;
    }
  }

  /**
   * 交易明细统计数据
   * @param params
   * @returns {Promise<void>}
   */
  @action
  async postDealDetailData(params) {
    const data = await httpClient.post(finance.POST_DEAL_DETAIL_DATA, {
      ...params,
    });
    if (data.success) {
      this.dealDetailData = data.data;
    }
  }

  /**
   * 交易明细列表
   * @param params
   * @returns {Promise<void>}
   */
  @action
  async postDealDetailsList(params) {
    const data = await httpClient.post(finance.POST_DEAL_DETAIL_LIST, {
      ...params,
    });
    if (data.success) {
      this.dealDetailList = data.data;
    }
  }

  /**
   * 结算账户列表
   * @param params
   * @returns {Promise<void>}
   */
  @action
  getSettlementAccountList = async (params) => {
    const data = await httpClient.get(finance.GET_SETTLEMENT_ACCOUNT_LIST, {
      params,
    });
    if (data.success) {
      this.settlementAccountList = data.data;
    }
  };

  /**
   * 企业信息
   * @param params
   * @returns {Promise<void>}
   */
  @action
  getCompanyInfo = async (params) => {
    const data = await httpClient.get(finance.GET_COMPANY_INFO, { params });
    if (data.success) {
      this.companyInfo = data.data;
    }
  };

  /**
   * 新增结算账户
   * @param params
   * @returns {Promise<void>}
   */
  @action
  postAddSettlementAccount = async (params) => {
    const data = await httpClient.post(finance.POST_ADD_SETTLEMENT_ACCOUNT, {
      ...params,
    });
    return data;
  };

  /**
   * 删除结算账户
   * @param params
   * @returns {Promise<void>}
   */
  @action
  async postDelSettlementAccount(params) {
    const data = await httpClient.post(finance.POST_DEL_SETTLEMENT_ACCOUNT, {
      ...params,
    });
    return data;
  }

  /**
   * 更新结算账户
   * @param params
   * @returns {Promise<void>}
   */
  @action
  async postUpdateSettlementAccount(params) {
    const data = await httpClient.post(finance.POST_UPDATE_SETTLEMENT_ACCOUNT, {
      ...params,
    });
    return data;
  }

  /**
   * 获取发起结算基础信息
   * @param params
   * @returns {Promise<void>}
   */
  @action
  getSettlementBasicInfo = async (params) => {
    const data = await httpClient.post(finance.GET_SETTLEMENT_BASIC_INFO, {
      ...params,
    });
    if (data.success) {
      this.settlementBasicInfo = data.data;
    }
  };

  /**
   * 结算发起报表透视
   * @param params
   * @returns {Promise<void>}
   */
  @action
  getRecordDetailList = async (params) => {
    const data = await httpClient.post(finance.GET_RECORD_DETAIL_LIST, {
      ...params,
    });
    if (data.success) {
      let res = addKey(data.data);
      this.recordDetailList = res;
    }
  };

  /**
   * 结算发起订单明细
   * @param params
   * @returns {Promise<void>}
   */
  @action
  getOrderDetailList = async (params) => {
    const data = await httpClient.post(finance.GET_ORDER_DETAIL_LIST, {
      ...params,
    });
    if (data.success) {
      let res = addKey(data.data);
      this.orderDetailList = res;
    }
  };

  /**
   * 导出明细
   * @param params
   * @returns {Promise<void>}
   */
  @action
  getExportDetail = async (params) => {
    const data = await httpClient.get(finance.GET_EXPORT_DETAIL, {
      params,
      responseType: 'blob',
    });
    return data;
    // if(data.success){
    //   return data.data
    // }
  };

  /**
   * 导出订单
   * @param params
   * @returns {Promise<void>}
   */
  @action
  getExportOrder = async (params) => {
    const data = await httpClient.get(finance.GET_EXPORT_ORDER, {
      params,
      responseType: 'blob',
    });
    return data;
    // if(data.success){
    //   return data.data
    // }
  };

  /**
   * 结算发起报表透视（详情）
   * @param params
   * @returns {Promise<void>}
   */
  @action
  getDetailRecordDetailList = async (params) => {
    const data = await httpClient.post(finance.GET_DETAIL_RECORD_DETAIL_LIST, {
      ...params,
    });
    if (data.success) {
      let res = addKey(data.data);
      this.recordDetailList = res;
    }
  };

  /**
   * 结算发起订单明细（详情）
   * @param params
   * @returns {Promise<void>}
   */
  @action
  getDetailOrderDetailList = async (params) => {
    const data = await httpClient.post(finance.GET_DETAIL_ORDER_DETAIL_LIST, {
      ...params,
    });
    if (data.success) {
      let res = addKey(data.data);
      this.orderDetailList = res;
    }
  };

  /**
   * 导出明细（详情）
   * @param params
   * @returns {Promise<void>}
   */
  @action
  getDetailExportDetail = async (params) => {
    const data = await httpClient.get(finance.GET_DETAIL_EXPORT_DETAIL, {
      params,
      responseType: 'blob',
    });
    return data;
  };

  /**
   * 导出订单（详情）
   * @param params
   * @returns {Promise<void>}
   */
  getDetailExportOrder = async (params) => {
    const data = await httpClient.get(finance.GET_DETAIL_EXPORT_ORDER, {
      params,
      responseType: 'blob',
    });
    return data;
  };

  /**
   * 申请结算
   * @param params
   * @returns {Promise<void>}
   */
  @action
  postApplySettlement = async (params) => {
    const data = await httpClient.post(finance.POST_APPLY_SETTLEMENT);
    console.log(data);
    if (data.success) {
      this.settlementId = data.data;
    }

    return data;
  };

  /**
   * 查询飞象开票信息和收件人信息Invoice
   * @param params
   * @returns {Promise<void>}
   */
  @action
  getInvoiceInfo = async (params) => {
    const data = await httpClient.get(finance.GET_INVOICE_INFO, { params });
    if (data.success) {
      this.invoiceInfo = data.data;
    }
  };

  /**
   * 取消结算
   * @param params
   * @returns {Promise<void>}
   */
  @action
  postCancelSettlement = async (params) => {
    const data = await httpClient.post(finance.POST_CANCEL_SETTLEMENT, {
      ...params,
    });
    return data;
  };

  /**
   * 提交结算
   * @param params
   * @returns {Promise<any|AxiosResponse<T>>}
   */
  @action
  postSubmitSettlement = async (params) => {
    const data = await httpClient.post(finance.POST_SUBMIT_SETTLEMENT, {
      ...params,
    });
    return data;
  };

  /**
   * 获取单个结算单信息
   * @param params
   * @returns {Promise<void>}
   */
  @action
  getOneSettlementInfo = async (params) => {
    const data = await httpClient.get(finance.GET_ONE_SETTLEMENT_INFO, {
      params,
    });
    if (data.success) {
      this.oneSettlementInfo = data.data;
    }
  };
  //获取父订单号
  @action getParentOrderNo = async (params) => {
    const data = await httpClient.get(finance.GET_PARENT_ORDER_NO, { params });
    return data;
  };
}

export default new FinanceStore();
