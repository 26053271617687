import React, { useState } from 'react'
import styles from './index.module.scss'
import { Form, Input, Button, Space, Modal, Radio,Table,Tabs } from 'antd'
import { showMoney } from 'utils/tools'
import ActNumComp from '../actNumComp/index'
const { TextArea } = Input
const { TabPane } = Tabs

function RebateApplyDetail() {
  const [form] = Form.useForm()
  const [form2] = Form.useForm()
  const [form2Data, setForm2Data] = useState([])
  const [selVal, setSelVal] = useState(1)
  const [actNumVis,setActNumVis] = useState(false)
  const handleSubmit = async () => {
    try {
      const data = await form2.validateFields()
      console.log(data)
    } catch (e) {
      console.log(e)
    }
    // const data = await form2.getFieldValue()
    // console.log(data, '===')
  }
  
  
  
  const inputBlur = (e, record, type) => {
    // const val = e.target.value
    // const _data = [...data]
    // const index = _data.findIndex((item) => item.id === record.id)
    // const target = _data[index]
    // //是否数字 大于0
    // const isLimit = isNaN(val) || val < 0
    // if (type == 1) {
    //   _data.splice(index, 1, { ...target, num: isLimit ? '' : val })
    // } else {
    //   _data.splice(index, 1, { ...target, price: isLimit ? '' : val })
    // }
    // setData(_data)
  }

  const handleOk = () => {

  }
  const handleCancel = () => {

  }

  const inputClick = () => {
    return false
  }

  const columns = [
    {
      title: '商品名称',
      dataIndex: 'name',
      render: () => (
        <div>1</div>
        // <div className = 'fx-flexbox'>
        //   <img src = {record.mainImage} style = {{width:'100px',height:'73px'}} />
        //   <div style={{marginLeft:'15px'}}>
        //     <p className='fx-ellipsis-row' style={{width:'230px',color:'#1890ff'}}>{record.name}</p>
        //     <p>{record.categoryTreeName}</p>
        //   </div>
        // </div>
      ),
    },
    {
      title: '市场价',
      dataIndex: 'market',
      render: (info, record) => {
        return (
        <div>{ showMoney(info) }</div>
        )
      },
    },
    {
      title: '销售价',
      dataIndex: 'sales',
      render: (info, record) => {
        return (
        <div>{ showMoney(info) }</div>
        )
      },
    },
    {
      title: '享金',
      dataIndex: 'enjoy',
      render: (info, record) => {
        return (
        <div>{ showMoney(info) }</div>
        )
      },
    },
    {
      title: '活动数量',
      dataIndex: 'price',
      // key: 'price',
      render: (info, record) => {
        return (
          <Input
            key={info}
            style={{ width: '120px' }}
            onClick={ inputClick }
            // onBlur={(e) => {
            //   inputBlur(e, record, 2)
            // }}
            defaultValue={info}
          />
        )
      },
    },
    {
      title: '操作',
      key: 'action',
      render: () => <div style={{ color: 'blue' }}>移除</div>,
    },
  ]

  const testData = [
    {
      name:'11',
      market:'22',
      sales:'33',
      enjoy:'44',
      price:'55'
    }
  ]

  const modalData = [
    {type:'移动4g',color:'红色',momory:'64g',nowMomory:2,num:19},
    {type:'移动4g',color:'黄色',momory:'64g',nowMomory:80,num:39}
  ]


  return (
    <div className={styles.rebateApplyContent}>
      <div className={styles.desc}>
        <div className={styles.title}>活动介绍</div>
        <div className={styles.info}>
          <div>活动名称： 返点会场-高佣金</div>
          <div>预热时间： 2020-04-15 19:00:00～2020-04-18 18:59:59</div>
          <div>活动时间： 2020-04-15 19:00:00～2020-04-18 18:59:59</div>
          <div
            style={{ display: 'flex', alignItems: 'flex-start', color: 'red' }}
          >
            <span>报名说明： </span>
            <span
              style={{
                display: 'inline-block',
                width: '200px',
                wordWrap: 'break-word',
              }}
            >
              xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
            </span>
          </div>
        </div>
      </div>

      <div className={styles.actInfo}>
        <div className={styles.title} style={{ marginBottom: '30px' }}>
          活动资料
        </div>
        <Form style={{ marginLeft: '50px' }} form={form}>
          <Form.Item label="会场名称" name="name">
            <Input style={{ width: '300px' }} />
          </Form.Item>
          <Form.Item label="会场卖点">
            <div style={{ display: 'flex' }}>
              <Form.Item name="test1">
                <Input
                  style={{ width: '300px' }}
                  maxLength={6}
                  placeholder="最多6个字符"
                />
              </Form.Item>
              <Form.Item name="test2" style={{ marginLeft: '10px' }}>
                <Input
                  style={{ width: '300px' }}
                  maxLength={6}
                  placeholder="最多6个字符"
                />
              </Form.Item>
            </div>
          </Form.Item>
          <Form.Item label="会场说明">
            <TextArea
              rows={4}
              style={{ width: '700px' }}
              maxLength={200}
              placeholder="最多可输入200个字"
            />
          </Form.Item>

          <Form.Item label="发货说明">
            <TextArea
              rows={3}
              style={{ width: '700px' }}
              maxLength={100}
              placeholder="最多可输入100个字"
            />
          </Form.Item>

          <Form.Item label="服务说明">
            <TextArea
              rows={3}
              style={{ width: '700px' }}
              maxLength={100}
              placeholder="最多可输入100个字"
            />
          </Form.Item>

          <Form.Item label="会场背景图"></Form.Item>
        </Form>
        <div className={styles.actDiscounts}>
          <div className={styles.title} style={{ marginBottom: '20px' }}>
            活动力度
          </div>
          <div style={{ marginLeft: '50px' }}>
            <div>满10000元，返现2%</div>
            <div>满10000元，返现2%</div>
            <div>满10000元，返现2%</div>
          </div>
        </div>
        
      </div>
    
      <div className={ styles.goodsBox } style={{ marginBottom:'200px' }}>
        <div className={styles.title}>提报商品</div>
        <div style={{ marginLeft:'50px',marginTop:'20px' }}>
          <div style={{ marginBottom:'20px' }}><span>已报名50
        已通过 30已驳回 10待审核 10</span></div>
        <Tabs defaultActiveKey="1" >
          <TabPane tab="已报名" key="1">
            <div style={{ marginTop:'20px',marginBottom:'20px' }}><Button type='primary'>选择商品</Button></div>
          <Table dataSource={testData} columns={columns} rowKey={'id'} />
          
          </TabPane>
          <TabPane tab="新增加" key="2">
            <Table dataSource={testData} columns={columns} rowKey={'id'} />
            
          </TabPane>
        </Tabs>
          
        </div>
      </div>
      <Modal visible={ actNumVis } title="活动数量" width={800} onOk={ handleOk } onCancel={ handleCancel }>
          <ActNumComp  modalData = { modalData }/>
      </Modal>
    </div>
  )
}
export default RebateApplyDetail
