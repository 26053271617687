import React, { useState, useEffect } from 'react'
import { Form, Input, message, Modal, Select, Space, Table } from 'antd'
import PropTypes from 'prop-types'
import lodash, { divide } from 'lodash'
import stores from 'stores'
import styles from './index.module.scss'

const { Option } = Select
const { commonStore, orderInfoStore } = stores
const statusMap = [
  {
    text: "已发货",
    color: "#52c41a"
  }, {
    text: "已发货",
    color: "#52c41a"
  }, {
    text: "待发货",
    color: "#fa541c"
  }, {
    text: "已发货",
    color: "#52c41a"
  },]
const goodsSourceMap = new Map([[1, "供应商商品"], [2, "店铺商品"]])

// 订单状态：1-新单，2-提交审批，3-待审批，4-待付款，5-待发货，6-已发货，7-部分发货，8-已关闭，9-待接口确认，10-已完成，11-已取消
/**
 * 商品发货弹窗
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function GoodsDeliver(props) {
  const { info, visible, onOk, onCancel, type } = props;
  const [form] = Form.useForm()
  const [selectedItems, setSelectedItems] = useState([])
  const [selectedKeys, setSelectedKeys] = useState([])
  const [expressList, setExpressList] = useState([])
  const [goodsExpressInfo, setGoodsExpressInfo] = useState({ deliverGoodsList: [] })
  const [refresh, setRefresh] = useState(false);
  useEffect(() => {
    refresh && setTimeout(() => setRefresh(false));
  }, [refresh]);
  useEffect(() => {
    if (type === "once") {
      _getOnceGoodsExpressInfo()
    } else {
      _getGoodsExpressInfo()
    }
    _getExpressList()
  }, [])


  const _getGoodsExpressInfo = async () => {
    let params = {
      shopOrderNo: info.shopOrderNo
    }
    let res = await orderInfoStore.getDeliverInfo(params)
    setGoodsExpressInfo(res)

  }
  const _getOnceGoodsExpressInfo = async () => {
    let params = {
      supplierOrderNo: info.supplierOrderNo
    }
    let res = await orderInfoStore.getOnceDeliverInfo(params)

    setGoodsExpressInfo(res)

  }
  const _getExpressList = async () => {
    const list = await commonStore.getExpressList({})
    setExpressList(list)
  }
  const columns = [
    { title: "商品", dataIndex: "goodsName", align: "center", render: text => <p className={styles.goods_name}>{text}</p> },
    // {
    //   title: "商品渠道", align: "center", dataIndex: "goodsSource",
    //   render: text => goodsSourceMap.get(text)
    // },
    { title: "数量", align: "center", dataIndex: "num" },
    {
      title: "物流 | 单号", align: "center",
      render: (text, record) => record.expressName && record.expressNo ? `${record.expressName} | ${record.expressNo}` : `${record.expressName || ""}${record.expressNo || ""}`
    },
    {
      title: "状态", align: "center",
      dataIndex: "deliveryStatus",
      render: text => <span style={{ color: statusMap[text].color }}>{statusMap[text].text}</span>
    },

  ]

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedItems(selectedRows)
      setSelectedKeys(selectedRowKeys)
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    },
    getCheckboxProps: record => ({
      disabled: !(+record.deliveryStatus === 2 && (+record.afterSaleStatus === 0 || +record.afterSaleStatus === 3)), // Column configuration not to be checked
      name: record.orderGoodsId,
    }),
  };

  const confirm = async () => {
    if (!selectedItems.length) {
      message.error("请选择要发货的商品")
      return;
    }
    const values = await form.validateFields();
    let params = {
      shopOrderNo: type === "once" ? undefined : info.shopOrderNo,
      supplierOrderNo: type === "once" ? info.supplierOrderNo : undefined,
      expressCode: values.expressCompany,
      expressNo: values.expressNum,
      orderGoodsIds: selectedKeys
    }
    let res;
    if (type === "once") {
      res = await orderInfoStore.deliverOnceGoods(params)
    } else {
      res = await orderInfoStore.deliverGoods(params)
    }

    if (res.success) {
      message.success("发货成功")
      setTimeout(() => {
        onCancel(true)
      }, 2000)
    }


  }

  const changeExpressNum = e => {
    let val = e.target.value.replace(/\s/, "");
    form.setFieldsValue({ expressNum: val })
  }

  return <Modal visible={visible} title="商品发货" onCancel={onCancel} onOk={confirm} width={900} centered>
    <p>待发货{goodsExpressInfo.deliverNumber},已选{selectedItems.length}</p>
    <Table columns={columns}
      pagination={false}
      dataSource={goodsExpressInfo.deliverGoodsList}
      rowSelection={rowSelection}
      rowKey={record => record.orderGoodsId} />

    <Form form={form} layout="inline">
      <Space size={32}
        align="start"
        style={{ padding: "16px" }}>
        <Form.Item name="expressCompany"
          label="物流公司"
          rules={[{
            required: true,
            message: "请选择物流公司"
          }]}>
          <Select placeholder="请选择物流公司"
            dropdownStyle={{ maxHeight: 220 }}
            style={{ width: 150 }}
          >
            {expressList.map((item, index) =>
                <Option value={item.expressCode}
                  //  key={item.expressCode}
                  key={index}>
                  {item.expressName}
                </Option>)
            }
          </Select>
        </Form.Item>
        <Form.Item name="expressNum"
          label="物流单号"
          rules={[{
            required: true,
            message: "请输入物流单号"
          }]}>
          <Input placeholder="请填写物流单号"
            style={{ width: 150 }} maxLength={20} onChange={changeExpressNum} />
        </Form.Item>

      </Space>
    </Form>

    <p>用户收货信息：{`${goodsExpressInfo.address},${goodsExpressInfo.consignee}${goodsExpressInfo.mobileNumber ? "," + goodsExpressInfo.mobileNumber : ""}`}</p>
  </Modal>
}

GoodsDeliver.propTypes = {
  type: PropTypes.oneOf(["once", undefined, false]),
  info: PropTypes.object,
  visible: PropTypes.bool.isRequired,
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
}

export default GoodsDeliver;
