import React, { Component } from 'react';
import {
  Button,
  Steps,
  Table,
  Icon,
  Divider,
  Descriptions,
  Select,
  Input,
  Result,
  Modal,
  message,
} from 'antd';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';
import qs from 'qs';

import styles from './index.module.scss';
import { showMoney, formatTime, downloadFile } from 'utils/tools';
import { judgeNum } from 'utils/validate';

const { Step } = Steps,
  { confirm } = Modal;

@inject(...['financeStore', 'commonStore'])
@observer
class SettlementProgress extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageType: '',
      balanceId: '',
      stepCurrent: 1,
      tableLoading: false,
      tableType: 'record', // "order" | "record"
      pageNo: 1,
      pageSize: 20,

      selectedRowKeys: [],
      selectedRows: [],
      express: undefined,
      expressNum: '',
    };
  }

  async componentDidMount() {
    const { financeStore, history } = this.props;
    let query = qs.parse(history.location.search.slice(1));
    if (query.id) {
      this.setState(
        {
          pageType: 'detail',
          balanceId: query.id,
        },
        async () => {
          this.getData();
          await financeStore.getOneSettlementInfo({
            balanceId: query.id,
          });
          const { oneSettlementInfo } = this.props.financeStore;
          switch (+oneSettlementInfo.balanceStatus) {
            case 1: //刚创建
              financeStore.getInvoiceInfo({ app: 'merchant' });
              financeStore.getSettlementAccountList({});
              this.setState({
                stepCurrent: 1,
              });
              break;
            case 2: //审核中
              this.setState({
                stepCurrent: 3,
              });
              break;
            case 3: //已驳回
              this.setState({
                stepCurrent: 4,
              });
              break;
            case 4: //已通过
              this.setState({
                stepCurrent: 4,
              });
              break;
            case 5: //已取消
              break;
          }
        }
      );
    }
  }

  /**
   * 渲染step组件
   * @returns {*}
   */
  renderStep = () => {
    const { pageType, stepCurrent } = this.state;
    const { settlementBasicInfo, oneSettlementInfo } = this.props.financeStore;

    const stepData = [
      {
        title: '确定结算金额',
        desc: stepCurrent ? (
          <p style={{ color: 'red' }}>
            ¥
            {showMoney(
              pageType === 'new'
                ? settlementBasicInfo.totalAmount
                : oneSettlementInfo.balanceAmount
            )}
          </p>
        ) : (
          '选择结算日期，申请结算'
        ),
      },
      {
        title: '开具发票',
        desc: '',
      },
      {
        title: '录入发票邮寄单号',
        desc: oneSettlementInfo.applyTime
          ? formatTime(oneSettlementInfo.applyTime)
          : '',
      },
      {
        title: '等待平台审核',
        desc: '',
      },
      {
        title: '审核完成',
        desc: oneSettlementInfo.auditTime
          ? formatTime(oneSettlementInfo.auditTime)
          : '',
      },
    ];
    return (
      <Steps current={stepCurrent} labelPlacement="vertical">
        {stepData.map((item, index) => (
          <Step key={index} title={item.title} description={item.desc} />
        ))}
      </Steps>
    );
  };

  /**
   * 获取金额明细表格数据
   */
  getData = async () => {
    const { balanceId, tableType, pageNo, pageSize } = this.state;
    const {
      getDetailRecordDetailList,
      getDetailOrderDetailList,
    } = this.props.financeStore;
    this.setState({
      tableLoading: true,
    });
    let params = {
      balanceId,
      pageIndex: pageNo,
      pageSize,
    };
    switch (tableType) {
      case 'record':
        await getDetailRecordDetailList(params);
        break;
      case 'order':
        await getDetailOrderDetailList(params);
        break;
    }
    this.setState({
      tableLoading: false,
    });
  };

  /**
   * 切换表格
   * @param type
   */
  changeTableType = (type) => {
    this.setState(
      {
        tableType: type, // "order" | "record"
        pageNo: 1,
        pageSize: 20,
      },
      () => {
        this.getData();
      }
    );
  };

  /**
   *
   * 渲染金额明细表格
   * @returns {*}
   */
  renderTable = () => {
    const { tableType, tableLoading, pageNo, pageSize } = this.state;
    const { recordDetailList, orderDetailList } = this.props.financeStore;
    // 报表透视column
    const recordColumn = [
      {
        title: '商品名称',
        align: 'center',
        dataIndex: 'goodsName',
        width: 240,
        render: (text, record, index) => (
          <p className={styles.table_goods_name}>{text}</p>
        ),
      },
      {
        title: '商品ID',
        align: 'center',
        dataIndex: 'skuId',
      },
      {
        title: '规格型号',
        align: 'center',
        dataIndex: 'goodsSkuAttribute',
        render: (text, record, index) => text,
      },
      {
        title: '单位',
        align: 'center',
        dataIndex: 'goodsUnit',
      },
      {
        title: '数量',
        align: 'center',
        dataIndex: 'quantity',
        // render: (text, record, index) => formatTime(text)
      },
      {
        title: '单价',
        align: 'center',
        dataIndex: 'unitSettlePrice',
        render: (text, record, index) => `¥${showMoney(text)}`,
      },
      {
        title: '总金额',
        align: 'center',
        dataIndex: 'totalSettleAmount',
        render: (text, record, index) => `¥${showMoney(text)}`,
      },
      {
        title: '税率',
        align: 'center',
        dataIndex: 'taxRate',
        render: (text, record, index) => `${text * 100}%`,
      },
      {
        title: '税额',
        align: 'center',
        dataIndex: 'taxSettleAmount',
        render: (text, record, index) => `¥${showMoney(text)}`,
      },
    ];
    // 订单详情column
    const orderColumn = [
      {
        title: '订单下单时间',
        align: 'center',
        dataIndex: 'orderCreateTime',
        render: (text, record, index) => formatTime(text),
      },
      {
        title: '订单完成时间',
        align: 'center',
        dataIndex: 'orderFinishTime',
        render: (text, record, index) => formatTime(text),
      },
      {
        title: '订单号',
        align: 'center',
        dataIndex: 'orderNo',
      },
      {
        title: '商品名称',
        align: 'center',
        dataIndex: 'goodsName',
        width: 240,
        render: (text, record, index) => (
          <p className={styles.table_goods_name}>{text}</p>
        ),
      },
      {
        title: '商品ID',
        align: 'center',
        dataIndex: 'skuId',
      },
      {
        title: '规格型号',
        align: 'center',
        dataIndex: 'goodsSkuAttribute',
        render: (text, record, index) => text,
      },
      {
        title: '单位',
        align: 'center',
        dataIndex: 'goodsUnit',
      },
      {
        title: '数量',
        align: 'center',
        dataIndex: 'quantity',
      },
      {
        title: '单价',
        align: 'center',
        dataIndex: 'unitSettlePrice',
        render: (text, record, index) => `¥${showMoney(text)}`,
      },
      {
        title: '总金额',
        align: 'center',
        dataIndex: 'totalSettleAmount',
        render: (text, record, index) => `¥${showMoney(text)}`,
      },
      {
        title: '税率',
        align: 'center',
        dataIndex: 'taxRate',
        render: (text, record, index) => `${text * 100}%`,
      },
      {
        title: '税额',
        align: 'center',
        dataIndex: 'taxSettleAmount',
        render: (text, record, index) => `¥${showMoney(text)}`,
      },
    ];
    // 分页配置
    const pagination = {
      current: pageNo,
      pageSize,
      total:
        tableType === 'record' ? recordDetailList.total : orderDetailList.total,
      showTotal: (total) => `共有${total}条纪录  `,
      showSizeChanger: true,
      showQuickJumper: true,
      defaultPageSize: 20,
      pageSizeOptions: ['20', '50', '100'],
      onChange: this.paginationChange,
      onShowSizeChange: this.paginationPageSizeChange,
    };

    return (
      <Table
        pagination={pagination}
        columns={tableType === 'record' ? recordColumn : orderColumn}
        dataSource={
          tableLoading
            ? []
            : tableType === 'record'
            ? toJS(recordDetailList.list)
            : toJS(orderDetailList.list)
        }
        rowKey={(record) => record.key}
        scroll={{ x: '1800px' }}
        loading={tableLoading}
      />
    );
  };

  /**
   * 分页组件页码变动回调
   * @param page
   * @param pageSize
   */
  paginationChange = (page, pageSize) => {
    this.setState(
      {
        pageNo: page,
      },
      () => {
        this.getData();
      }
    );
  };

  /**
   * 分页组件pageSize变化回调
   * @param current
   * @param size
   */
  paginationPageSizeChange = (current, size) => {
    this.setState(
      {
        pageNo: 1,
        pageSize: size,
      },
      () => {
        this.getData();
      }
    );
  };

  renderStepContent = () => {
    const {
      pageType,
      stepCurrent,
      selectedRows,
      express,
      expressNum,
    } = this.state;
    const {
      settlementBasicInfo,
      invoiceInfo,
      settlementAccountList,
      oneSettlementInfo,
    } = this.props.financeStore;
    const { expressList } = this.props.commonStore;
    switch (stepCurrent) {
      case 0 /* 第1步 */:
        return (
          <div className={styles.step_content_container}>
            <p className={styles.info_line_p}>
              结算日期：{formatTime(settlementBasicInfo.startTime)} 至{' '}
              {formatTime(settlementBasicInfo.endTime)}
              <span>（截止前一天）</span>
            </p>
            <div className={styles.info_line_div}>
              <div className={styles.info_line_div_left}>
                <div className={styles.info_line_div_left_item}>
                  <p className={styles.item_top}>
                    可结算金额：
                    <span>¥{showMoney(settlementBasicInfo.totalAmount)}</span>
                  </p>
                  <p className={styles.item_bottom}>
                    （根据上次结算截止时间至当前时间前一天自动计算本次结算金额）
                  </p>
                </div>
                <div className={styles.info_line_div_left_divide}>
                  <Divider />
                </div>
                <div className={styles.info_line_div_left_item}>
                  <p className={styles.item_top}>
                    扣除手续费：
                    <span>¥{showMoney(settlementBasicInfo.fee)}</span>
                  </p>
                  <p className={styles.item_bottom}>
                    （平台收取千分之六手续费）
                  </p>
                </div>
                <div className={styles.info_line_div_left_divide}>
                  <Divider />
                </div>
                <div className={styles.info_line_div_left_item}>
                  <p className={styles.item_top}>
                    到账金额：
                    <span>¥{showMoney(settlementBasicInfo.realAmount)}</span>
                  </p>
                  <p className={styles.item_bottom}>（最终到账金额）</p>
                </div>
              </div>
              <div className={styles.info_line_div_right}>
                <div className={styles.btn_container}>
                  <Button
                    type="primary"
                    disabled={!+settlementBasicInfo.realAmount}
                    style={{ width: 100 }}
                    onClick={this.applySettlement}
                  >
                    申请结算
                  </Button>
                </div>
              </div>
            </div>
          </div>
        );
      case 1 /* 第2步 */:
        const column = [
          {
            title: '开户名',
            dataIndex: 'balanceAccountName',
            render: (text, record, index) => `开户名：${text}`,
          },
          {
            title: '开户行',
            dataIndex: 'balanceBankName',
            render: (text, record, index) => `开户行：${text}`,
          },
          {
            title: '银行账号',
            dataIndex: 'balanceAccountId',
            render: (text, record, index) => `银行账号：${text}`,
          },
        ];
        const rowSelection = {
          type: 'radio',
          onChange: this.changeBankTableSelect,
        };
        return (
          <div className={styles.step_content_container}>
            <div className={styles.info_line_div}>
              <div className={styles.info_line_div_left}>
                <p className={styles.info_line_div_left_p_text}>
                  请按导出明细后，按开票资料开具<span>增值税专用发票 </span>
                  ，并邮寄至以下地址
                </p>
              </div>
              <div className={styles.info_line_div_right}>
                <Button
                  type="default"
                  style={{ width: 100, marginRight: '20px' }}
                  onClick={this.cancelSettlement}
                >
                  取消结算
                </Button>
                <Button
                  type="primary"
                  style={{ width: 100 }}
                  onClick={() => {
                    if (selectedRows.length) {
                      this.showConfirm();
                    } else {
                      message.error('请选择结算账户！');
                    }
                  }}
                >
                  下一步
                </Button>
              </div>
            </div>
            <div className={styles.info_container}>
              <div className={styles.info_title_container}>
                <div className={styles.info_title_left}>
                  <p className={styles.info_title}>开票资料</p>
                </div>
              </div>
              <div
                className={`${styles.info_content_container} ${styles.not_table}`}
              >
                <Descriptions>
                  <Descriptions.Item label="发票抬头">
                    {invoiceInfo.invoiceTitle}
                  </Descriptions.Item>
                  <Descriptions.Item label="税号">
                    {invoiceInfo.invoiceTaxNo}
                  </Descriptions.Item>
                  <Descriptions.Item label="地址及电话">
                    {invoiceInfo.invoiceAddressPhone}
                  </Descriptions.Item>
                  <Descriptions.Item label="开户行及账号">
                    {invoiceInfo.invoiceBankAccount}
                  </Descriptions.Item>
                </Descriptions>
              </div>
            </div>
            <div className={styles.info_container}>
              <div className={styles.info_title_container}>
                <div className={styles.info_title_left}>
                  <p className={styles.info_title}>邮寄地址</p>
                </div>
              </div>
              <div
                className={`${styles.info_content_container} ${styles.not_table}`}
              >
                <Descriptions>
                  <Descriptions.Item label="收货人">
                    {invoiceInfo.receiverName}
                  </Descriptions.Item>
                  <Descriptions.Item label="联系电话">
                    {invoiceInfo.receiverPhone}
                  </Descriptions.Item>
                  <Descriptions.Item label="邮寄地址">
                    {invoiceInfo.receiverAddress}
                  </Descriptions.Item>
                </Descriptions>
              </div>
            </div>
            <div className={styles.info_container}>
              <div className={styles.info_title_container}>
                <div className={styles.info_title_left}>
                  <p className={styles.info_title}>结算账户</p>
                </div>
              </div>
              <div className={styles.info_content_container}>
                <Table
                  columns={column}
                  pagination={false}
                  showHeader={false}
                  rowSelection={rowSelection}
                  rowKey="id"
                  dataSource={settlementAccountList}
                />
              </div>
            </div>
          </div>
        );
      case 2 /* 第3步 */:
        return (
          <div className={styles.step_content_container}>
            <div className={styles.info_line_div}>
              <div className={styles.info_line_div_left}></div>
              <div className={styles.info_line_div_right}>
                <Button
                  type="default"
                  style={{ width: 100, marginRight: '20px' }}
                  onClick={this.cancelSettlement}
                >
                  取消结算
                </Button>
                <Button
                  type="primary"
                  style={{ width: 100 }}
                  onClick={this.submitSettlement}
                >
                  提交申请
                </Button>
              </div>
            </div>
            <div className={styles.info_container}>
              <div className={styles.info_title_container}>
                <div className={styles.info_title_left}>
                  <p className={styles.info_title}>请填写发票邮寄的快递单号</p>
                </div>
              </div>
              <div
                className={`${styles.info_content_container} ${styles.not_table}`}
              >
                <Descriptions>
                  <Descriptions.Item label="选择快递公司">
                    <Select
                      style={{ width: 150 }}
                      placeholder="请选择快递公司"
                      value={express}
                      onChange={this.changeExpress}
                    >
                      {expressList.map((item, index) => (
                        <Select.Option
                          value={item.expressCode}
                          key={item.expressCode}
                        >
                          {item.expressName}
                        </Select.Option>
                      ))}
                    </Select>
                  </Descriptions.Item>
                  <Descriptions.Item label="填写快递单号">
                    <Input
                      style={{ width: 150 }}
                      allowClear={true}
                      placeholder="请填写物流单号"
                      value={expressNum}
                      maxLength={20}
                      onChange={this.changeExpressNum}
                    />
                  </Descriptions.Item>
                </Descriptions>
              </div>
            </div>
            <div className={styles.info_container}>
              <div className={styles.info_title_container}>
                <div className={styles.info_title_left}>
                  <p className={styles.info_title}>结算明细</p>
                </div>
              </div>
              <div
                className={`${styles.info_content_container} ${styles.not_table}`}
              >
                <Descriptions>
                  <Descriptions.Item label="结算金额">
                    ¥{showMoney(oneSettlementInfo.balanceAmount)}
                  </Descriptions.Item>
                  <Descriptions.Item label="结算手续费">
                    ¥{showMoney(oneSettlementInfo.balanceFee)}
                  </Descriptions.Item>
                  <Descriptions.Item label="开户名">
                    {selectedRows[0].balanceAccountName}
                  </Descriptions.Item>
                  <Descriptions.Item label="开户行">
                    {selectedRows[0].balanceBankName}
                  </Descriptions.Item>
                  <Descriptions.Item label="银行账号">
                    {selectedRows[0].balanceAccountId}
                  </Descriptions.Item>
                </Descriptions>
              </div>
            </div>
          </div>
        );
      case 3 /* 第4步 */:
        return (
          <div className={styles.step_content_container}>
            <div>
              <Result
                status="success"
                title="结算申请已提交"
                subTitle="请等待平台审核，审核通过后，金额即会到账"
              />
            </div>
            <div className={styles.info_container}>
              <div className={styles.info_title_container}>
                <div className={styles.info_title_left}>
                  <p className={styles.info_title}>发票快递单号</p>
                </div>
              </div>
              <div
                className={`${styles.info_content_container} ${styles.not_table}`}
              >
                <Descriptions>
                  <Descriptions.Item label="快递公司">
                    {express
                      ? expressList.find((item) => item.expressCode === express)
                          .expressName
                      : oneSettlementInfo.deliveryCorp}
                  </Descriptions.Item>
                  <Descriptions.Item label="快递单号">
                    {expressNum || oneSettlementInfo.deliveryId}
                  </Descriptions.Item>
                </Descriptions>
              </div>
            </div>
            <div className={styles.info_container}>
              <div className={styles.info_title_container}>
                <div className={styles.info_title_left}>
                  <p className={styles.info_title}>结算明细</p>
                </div>
              </div>
              <div
                className={`${styles.info_content_container} ${styles.not_table}`}
              >
                <Descriptions>
                  <Descriptions.Item label="结算金额">
                    ¥{showMoney(oneSettlementInfo.balanceAmount)}
                  </Descriptions.Item>
                  <Descriptions.Item label="结算手续费">
                    ¥{showMoney(oneSettlementInfo.balanceFee)}
                  </Descriptions.Item>
                  <Descriptions.Item label="到账金额">
                    ¥{showMoney(oneSettlementInfo.balanceRealAmount)}
                  </Descriptions.Item>
                  <Descriptions.Item label="开户名">
                    {selectedRows[0]
                      ? selectedRows[0].balanceAccountName
                      : oneSettlementInfo.balanceAccountName}
                  </Descriptions.Item>
                  <Descriptions.Item label="开户行">
                    {selectedRows[0]
                      ? selectedRows[0].balanceBankName
                      : oneSettlementInfo.balanceBankName}
                  </Descriptions.Item>
                  <Descriptions.Item label="银行账号">
                    {selectedRows[0]
                      ? selectedRows[0].balanceAccountId
                      : oneSettlementInfo.balanceAccountId}
                  </Descriptions.Item>
                </Descriptions>
              </div>
            </div>
          </div>
        );
      case 4 /* 第5步 */:
        return (
          <div className={styles.step_content_container}>
            <div>
              {+oneSettlementInfo.balanceStatus === 4 ? (
                <Result
                  status="success"
                  title="结算申请已通过"
                  subTitle="已汇款至您的结算账户，具体到账时间以银行为准"
                />
              ) : (
                <Result
                  status="error"
                  title="结算申请已驳回，您可重新发起结算申请"
                  subTitle={
                    <div>
                      <p>驳回原因：{oneSettlementInfo.auditDetail}</p>
                      <p>如有疑问，请致电：0571-81387158 飞象财务部</p>
                    </div>
                  }
                />
              )}
            </div>
            <div className={styles.info_container}>
              <div className={styles.info_title_container}>
                <div className={styles.info_title_left}>
                  <p className={styles.info_title}>发票快递单号</p>
                </div>
              </div>
              <div
                className={`${styles.info_content_container} ${styles.not_table}`}
              >
                <Descriptions>
                  <Descriptions.Item label="快递公司">
                    {oneSettlementInfo.deliveryCorp}
                  </Descriptions.Item>
                  <Descriptions.Item label="快递单号">
                    {oneSettlementInfo.deliveryId}
                  </Descriptions.Item>
                </Descriptions>
              </div>
            </div>
            <div className={styles.info_container}>
              <div className={styles.info_title_container}>
                <div className={styles.info_title_left}>
                  <p className={styles.info_title}>结算明细</p>
                </div>
              </div>
              <div
                className={`${styles.info_content_container} ${styles.not_table}`}
              >
                <Descriptions>
                  <Descriptions.Item label="结算金额">
                    ¥{showMoney(oneSettlementInfo.balanceAmount)}
                  </Descriptions.Item>
                  <Descriptions.Item label="结算手续费">
                    ¥{showMoney(oneSettlementInfo.balanceFee)}
                  </Descriptions.Item>
                  <Descriptions.Item label="到账金额">
                    ¥{showMoney(oneSettlementInfo.balanceRealAmount)}
                  </Descriptions.Item>
                  <Descriptions.Item label="开户名">
                    {oneSettlementInfo.balanceAccountName}
                  </Descriptions.Item>
                  <Descriptions.Item label="开户行">
                    {oneSettlementInfo.balanceBankName}
                  </Descriptions.Item>
                  <Descriptions.Item label="银行账号">
                    {oneSettlementInfo.balanceAccountId}
                  </Descriptions.Item>
                </Descriptions>
              </div>
            </div>
          </div>
        );
    }
  };

  /**
   * 申请结算
   */
  applySettlement = () => {
    const {
      postApplySettlement,
      getInvoiceInfo,
      getSettlementAccountList,
    } = this.props.financeStore;
    postApplySettlement({});
    this.setState(
      {
        stepCurrent: 1,
      },
      () => {
        getInvoiceInfo({});
        getSettlementAccountList({});
      }
    );
  };

  /**
   * 取消结算
   * @returns {Promise<void>}
   */
  cancelSettlement = async () => {
    const {
      postCancelSettlement,
      settlementId,
      oneSettlementInfo,
    } = this.props.financeStore;
    let res = await postCancelSettlement({
      balanceId: settlementId || oneSettlementInfo.balanceId,
    });
    if (res.success) {
      this.props.history.push({
        pathname: '/finance/center/settlement-manage',
      });
    }
  };

  /**
   * 下一步弹窗
   */
  showConfirm = () => {
    const _this = this;
    const { getExpressList } = this.props.commonStore;
    confirm({
      title: '温馨提示',
      content: (
        <div className={styles.confirm_content}>
          <p>
            1、确定已根据明细，开具发票。（若开票内容太多，可在发票后附A4纸，将明细打印在A4纸上，并加盖财务章）；
          </p>
          <p>2、确定发票上的开票资料无误；</p>
          <p>3、确定已按照正确的地址，邮寄发票。</p>
        </div>
      ),
      okText: '去填写快递单号',
      width: 500,
      onOk() {
        getExpressList({});
        _this.setState({
          stepCurrent: 2,
        });
        console.log('OK');
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  /**
   * 选择开户行等信息
   */
  changeBankTableSelect = (selectedRowKeys, selectedRows) => {
    console.log(selectedRowKeys, selectedRows);
    this.setState({
      selectedRowKeys,
      selectedRows,
    });
  };

  /**
   * 选择快递公司
   */
  changeExpress = (value) => {
    this.setState({
      express: value,
    });
  };

  /**
   * 填写快递单号
   */
  changeExpressNum = (e) => {
    this.setState({
      expressNum: judgeNum(e.target.value.replace(/\s/, ''), 'express'),
    });
  };

  /**
   * 提交结算
   */
  submitSettlement = async () => {
    const { balanceId, express, expressNum, selectedRows } = this.state;
    const { invoiceInfo, postSubmitSettlement } = this.props.financeStore;
    const { expressList } = this.props.commonStore;
    if (!express) {
      message.error('请选择物流公司！');
      return;
    }
    if (!expressNum) {
      message.error('请填写物流单号！');
      return;
    }
    let params = {
      balanceAccountId: selectedRows[0].balanceAccountId, //银行账户
      balanceAccountName: selectedRows[0].balanceAccountName, //开户名
      balanceBankName: selectedRows[0].balanceBankName, //开户行
      balanceId, //结算单id
      deliveryAddress: invoiceInfo.receiverAddress, //收件地址
      deliveryCorp: expressList.find((item) => item.expressCode === express)
        .expressName, //快递公司
      deliveryId: expressNum, //快递单号
      deliveryPhone: invoiceInfo.receiverPhone, //收件人电话
      deliveryReceiver: invoiceInfo.receiverName, //发票收件人
      invoiceAddressPhone: invoiceInfo.invoiceAddressPhone, //（开票）地址和电话
      invoiceBankAccount: invoiceInfo.invoiceBankAccount, //（开票）开户行及账号
      invoiceTaxNo: invoiceInfo.invoiceTaxNo, //（开票）税号
      invoiceTitle: invoiceInfo.invoiceTitle, //（开票）抬头
    };
    let res = await postSubmitSettlement(params);
    if (res.success) {
      this.setState({
        stepCurrent: 3,
      });
    }
  };

  /**
   * 导出
   */
  exportOrder = async () => {
    const { balanceId, tableType } = this.state; // "order" | "record"
    const {
      getDetailExportDetail,
      getDetailExportOrder,
    } = this.props.financeStore;
    const { data, headers } =
      tableType == 'order'
        ? await getDetailExportOrder({ balanceId })
        : await getDetailExportDetail({ balanceId });
    let fileName = '';
    try {
      fileName = window.decodeURI(headers['content-disposition'].split('=')[1]);
      if (fileName.startsWith('utf-8')) {
        fileName = fileName.replace("utf-8''", '');
      }
    } catch (error) {}
    downloadFile(data, 'vnd.ms-excel', fileName);
  };

  render() {
    const { tableType } = this.state;
    const { recordDetailList, orderDetailList } = this.props.financeStore;

    return (
      <div className={styles.page_container}>
        <div className={styles.step_container}>{this.renderStep()}</div>
        {this.renderStepContent()}
        <div className={styles.info_container}>
          <div className={styles.info_title_container}>
            <div className={styles.info_title_left}>
              <p className={styles.info_title}>
                {tableType === 'order' ? '订单明细' : '开票明细'}（共
                {tableType === 'record'
                  ? recordDetailList.total
                  : orderDetailList.total}
                条）
              </p>
              {tableType === 'record' ? (
                <p className={styles.info_title_desc}>
                  （已将同类商品合并，您可点击 “
                  <span
                    className={styles.table_action_btn}
                    onClick={() => this.changeTableType('order')}
                  >
                    订单明细
                  </span>
                  ” ，查看报表明细数据）
                </p>
              ) : (
                <p className={styles.info_title_desc}>
                  （根据订单展示开票明细，您可点击 “
                  <span
                    className={styles.table_action_btn}
                    onClick={() => this.changeTableType('record')}
                  >
                    开票明细
                  </span>
                  ” ，将报表中的同类商品进行合并开票）
                </p>
              )}
            </div>
            <div className={styles.info_title_right}>
              <Button type="default" onClick={this.exportOrder}>
                <Icon type="download" style={{ fontSize: 18 }} /> 导出
              </Button>
            </div>
          </div>
          <div className={styles.info_content_container}>
            {this.renderTable()}
          </div>
        </div>
        {/*<Modal visible={true}></Modal>*/}
      </div>
    );
  }
}

export default SettlementProgress;
