import {observable, action} from "mobx";
import httpClient from "utils/httpClient";
import api from "config/api"

const {store} = api;

class StoreLocationStore {
  @observable locationList = []
  @observable defaultLocation = {}

  /**
   * 获取地址列表
   * @param params
   * @returns {Promise<*[]|T>}
   */
  @action
  getLocationList = async params => {
    const res = await httpClient.get(store.GET_lOCATION_LIST, {params})
    if (res.success) {
      this.locationList = res.data;
      return res.data.map((item, index) => Object.assign(item, {key: index}))
    }
    return [];
  }

  /**
   * 获取地址列表
   * @param params
   * @returns {Promise<*[]|T>}
   */
  @action
  getDefaultLocation = async params => {
    const res = await httpClient.post(store.POST_GET_DEFAULT_LOCATION, params)
    if (res.success) {
      this.defaultLocation = res.data;
      return res.data
    }
  }

  /**
   * 新增地址
   * @param params
   * @returns {Promise<AxiosResponse<any>>}
   */
  @action
  addLocation = async params => {
    return await httpClient.post(store.POST_ADD_lOCATION, params)
  }

  /**
   * 修改地址
   * @param params
   * @returns {Promise<AxiosResponse<any>>}
   */
  @action
  updateLocation = async params => {
    return await httpClient.post(store.POST_UPDATE_lOCATION, params)
  }

  /**
   * 删除地址
   * @param params
   * @returns {Promise<AxiosResponse<any>>}
   */
  @action
  deleteLocation = async params => {
    return await httpClient.post(store.POST_DELETE_lOCATION, params)
  }

  /**
   * 设置默认地址
   * @param params
   * @returns {Promise<AxiosResponse<any>>}
   */
  @action
  setDefaultLocation = async params => {
    return await httpClient.post(store.POST_SET_DEFAULT_LOCATION, params)
  }
}

export default new StoreLocationStore();
