import React, { Component } from 'react';
import {
  Button,
  Steps,
  Table,
  Icon,
  Divider,
  Descriptions,
  Select,
  Input,
  Result,
  Modal,
  message,
} from 'antd';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';
import qs from 'qs';

import styles from './index.module.scss';
import { showMoney, formatTime, downloadFile } from 'utils/tools';

const { Step } = Steps,
  { confirm } = Modal;

@inject(...['financeStore', 'commonStore'])
@observer
class SettlementProgress extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageType: '',
      stepCurrent: 0,
      tableLoading: false,
      tableType: 'record', // "order" | "record"
      pageNo: 1,
      pageSize: 20,
    };
  }

  async componentDidMount() {
    const { financeStore, history } = this.props;
    let query = qs.parse(history.location.search.slice(1));
    if (query.id) {
      this.setState(
        {
          pageType: 'detail',
        },
        () => {
          financeStore.getOneSettlementInfo({
            balanceId: query.id,
          });
        }
      );
    } else {
      await this.setState(
        {
          pageType: 'new',
        },
        async () => {
          await financeStore.getSettlementBasicInfo({});
          this.getData();
        }
      );
    }
  }

  /**
   * 渲染step组件
   * @returns {*}
   */
  renderStep = () => {
    const { pageType, stepCurrent } = this.state;
    const { settlementBasicInfo, oneSettlementInfo } = this.props.financeStore;
    const stepData = [
      {
        title: '确定结算金额',
        desc: stepCurrent ? (
          <p style={{ color: 'red' }}>
            ¥
            {showMoney(
              pageType === 'new'
                ? settlementBasicInfo.totalAmount
                : oneSettlementInfo.balanceAmount
            )}
          </p>
        ) : (
          '确定结算日期，申请结算'
        ),
      },
      {
        title: '开具发票',
        desc: '',
      },
      {
        title: '录入发票邮寄单号',
        desc: '',
      },
      {
        title: '等待平台审核',
        desc: '',
      },
      {
        title: '审核完成',
        desc: '',
      },
    ];
    return (
      <Steps current={stepCurrent} labelPlacement="vertical">
        {stepData.map((item, index) => (
          <Step key={index} title={item.title} description={item.desc} />
        ))}
      </Steps>
    );
  };

  /**
   * 获取金额明细表格数据
   */
  getData = async () => {
    const { pageType, tableType, pageNo, pageSize } = this.state;
    const {
      settlementBasicInfo,
      getRecordDetailList,
      getOrderDetailList,
      getDetailRecordDetailList,
      getDetailOrderDetailList,
      oneSettlementInfo,
    } = this.props.financeStore;
    // if (settlementBasicInfo.startTime && settlementBasicInfo.endTime) {
    this.setState({
      tableLoading: true,
    });
    let params =
      pageType === 'new'
        ? {
            // startTime: formatTime(settlementBasicInfo.startTime),
            // endTime: formatTime(settlementBasicInfo.endTime),
            pageNo: pageNo,
            pageSize,
          }
        : {
            balanceId: oneSettlementInfo.balanceId,
            pageNo: pageNo,
            pageSize,
          };
    console.log(tableType, '===', pageType);
    switch (tableType) {
      case 'record':
        pageType === 'new'
          ? await getRecordDetailList(params)
          : await getDetailRecordDetailList(params);
        break;
      case 'order':
        pageType === 'new'
          ? await getOrderDetailList(params)
          : await getDetailOrderDetailList(params);
        break;
    }
    this.setState({
      tableLoading: false,
    });
    // }
  };

  /**
   * 切换表格
   * @param type
   */
  changeTableType = (type) => {
    this.setState(
      {
        tableType: type, // "order" | "record"
        pageNo: 1,
        pageSize: 20,
      },
      () => {
        this.getData();
      }
    );
  };

  /**
   *
   * 渲染金额明细表格
   * @returns {*}
   */
  renderTable = () => {
    const { tableType, tableLoading, pageNo, pageSize } = this.state;
    const { recordDetailList, orderDetailList } = this.props.financeStore;
    // 报表透视column
    const recordColumn = [
      {
        title: '商品名称',
        align: 'center',
        dataIndex: 'goodsName',
        render: (text, record, index) => (
          <p className={styles.table_goods_name}>{text}</p>
        ),
      },
      {
        title: '商品ID',
        align: 'center',
        dataIndex: 'skuId',
      },
      {
        title: '规格型号',
        align: 'center',
        dataIndex: 'goodsSkuAttribute',
        render: (text, record, index) => text,
        // {
        //   text = JSON.parse(text)
        //   return text && Object.keys(text).map(item => text[item]).join(' ')
        // }
      },
      {
        title: '单位',
        align: 'center',
        dataIndex: 'goodsUnit',
      },
      {
        title: '数量',
        align: 'center',
        dataIndex: 'quantity',
        // render: (text, record, index) => formatTime(text)
      },
      {
        title: '单价',
        align: 'center',
        dataIndex: 'unitSettlePrice',
        render: (text, record, index) => `¥${showMoney(text)}`,
      },
      {
        title: '总金额',
        align: 'center',
        dataIndex: 'totalSettleAmount',
        render: (text, record, index) => `¥${showMoney(text)}`,
      },
      {
        title: '税率',
        align: 'center',
        dataIndex: 'taxRate',
        render: (text, record, index) => `${text * 100}%`,
      },
      {
        title: '税额',
        align: 'center',
        dataIndex: 'taxSettleAmount',
        render: (text, record, index) => `¥${showMoney(text)}`,
      },
    ];
    // 订单详情column
    const orderColumn = [
      {
        title: '订单下单时间',
        align: 'center',
        dataIndex: 'orderCreateTime',
        render: (text, record, index) => formatTime(text),
      },
      {
        title: '订单完成时间',
        align: 'center',
        dataIndex: 'orderFinishTime',
        render: (text, record, index) => formatTime(text),
      },
      {
        title: '订单号',
        align: 'center',
        dataIndex: 'orderNo',
      },
      {
        title: '商品名称',
        align: 'center',
        dataIndex: 'goodsName',
        render: (text, record, index) => (
          <p className={styles.table_goods_name}>{text}</p>
        ),
      },
      {
        title: '商品ID',
        align: 'center',
        dataIndex: 'skuId',
      },
      {
        title: '规格型号',
        align: 'center',
        dataIndex: 'goodsSkuAttribute',
        render: (text, record, index) => text,
      },
      {
        title: '单位',
        align: 'center',
        dataIndex: 'goodsUnit',
      },
      {
        title: '数量',
        align: 'center',
        dataIndex: 'quantity',
      },
      {
        title: '单价',
        align: 'center',
        dataIndex: 'unitSettlePrice',
        render: (text, record, index) => `¥${showMoney(text)}`,
      },
      {
        title: '总金额',
        align: 'center',
        dataIndex: 'totalSettleAmount',
        render: (text, record, index) => `¥${showMoney(text)}`,
      },
      {
        title: '税率',
        align: 'center',
        dataIndex: 'taxRate',
        render: (text, record, index) => `${text * 100}%`,
      },
      {
        title: '税额',
        align: 'center',
        dataIndex: 'taxSettleAmount',
        render: (text, record, index) => `¥${showMoney(text)}`,
      },
    ];
    // 分页配置
    const pagination = {
      current: pageNo,
      pageSize,
      total:
        tableType === 'record' ? recordDetailList.total : orderDetailList.total,
      showTotal: (total) => `共有${total}条纪录  `,
      showSizeChanger: true,
      showQuickJumper: true,
      defaultPageSize: 20,
      pageSizeOptions: ['20', '50', '100'],
      onChange: this.paginationChange,
      onShowSizeChange: this.paginationPageSizeChange,
    };

    return (
      <Table
        pagination={pagination}
        columns={tableType === 'record' ? recordColumn : orderColumn}
        dataSource={
          tableLoading
            ? []
            : tableType === 'record'
            ? toJS(recordDetailList.list)
            : toJS(orderDetailList.list)
        }
        rowKey={(record) => record.key}
        scroll={{ x: '1800px' }}
        loading={tableLoading}
      />
    );
  };

  /**
   * 分页组件页码变动回调
   * @param page
   * @param pageSize
   */
  paginationChange = (page, pageSize) => {
    this.setState(
      {
        pageNo: page,
      },
      () => {
        this.getData();
      }
    );
  };

  /**
   * 分页组件pageSize变化回调
   * @param current
   * @param size
   */
  paginationPageSizeChange = (current, size) => {
    this.setState(
      {
        pageNo: 1,
        pageSize: size,
      },
      () => {
        this.getData();
      }
    );
  };

  renderStepContent = () => {
    const { pageType, stepCurrent } = this.state;
    const {
      settlementBasicInfo,
      invoiceInfo,
      settlementAccountList,
    } = this.props.financeStore;
    switch (stepCurrent) {
      case 0 /* 第1步 */:
        return (
          <div className={styles.step_content_container}>
            <p className={styles.info_line_p}>
              结算日期：{formatTime(settlementBasicInfo.startTime)} 至{' '}
              {formatTime(settlementBasicInfo.endTime)}
              <span>（截止前一天）</span>
            </p>
            <div className={styles.info_line_div}>
              <div className={styles.info_line_div_left}>
                <div className={styles.info_line_div_left_item}>
                  <p className={styles.item_top}>
                    可结算金额：
                    <span>¥{showMoney(settlementBasicInfo.totalAmount)}</span>
                  </p>
                  <p className={styles.item_bottom}>
                    （根据上次结算截止时间至当前时间前一天自动计算本次结算金额）
                  </p>
                </div>
                <div className={styles.info_line_div_left_divide}>
                  <Divider />
                </div>
                <div className={styles.info_line_div_left_item}>
                  <p className={styles.item_top}>
                    扣除手续费：
                    <span>¥{showMoney(settlementBasicInfo.fee)}</span>
                  </p>
                  <p className={styles.item_bottom}>
                    （平台收取{settlementBasicInfo.feeRate * 100}%手续费）
                  </p>
                </div>
                <div className={styles.info_line_div_left_divide}>
                  <Divider />
                </div>
                <div className={styles.info_line_div_left_item}>
                  <p className={styles.item_top}>
                    到账金额：
                    <span>¥{showMoney(settlementBasicInfo.realAmount)}</span>
                  </p>
                  <p className={styles.item_bottom}>（最终到账金额）</p>
                </div>
              </div>
              <div className={styles.info_line_div_right}>
                <div className={styles.btn_container}>
                  <Button
                    type="primary"
                    disabled={!+settlementBasicInfo.realAmount}
                    style={{ width: 100 }}
                    onClick={this.applySettlement}
                  >
                    申请结算
                  </Button>
                </div>
              </div>
            </div>
          </div>
        );
      case 1 /* 第2步 */:
        const column = [
          {
            title: '开户名',
            dataIndex: '',
            render: (text, record, index) => `开户名：${text}`,
          },
          {
            title: '开户行',
            dataIndex: '',
            render: (text, record, index) => `开户行：${text}`,
          },
          {
            title: '银行账号',
            dataIndex: '',
            render: (text, record, index) => `银行账号：${text}`,
          },
        ];
        const rowSelection = {
          type: 'radio',
          onChange: this.changeBankTableSelect,
        };
        return (
          <div className={styles.step_content_container}>
            <div className={styles.info_line_div}>
              <div className={styles.info_line_div_left}>
                <p className={styles.info_line_div_left_p_text}>
                  请按导出明细后，按开票资料开具<span>增值税专用发票 </span>
                  ，并邮寄至以下地址
                </p>
              </div>
              <div className={styles.info_line_div_right}>
                <Button
                  type="default"
                  style={{ width: 100, marginRight: '20px' }}
                  onClick={this.cancelSettlement}
                >
                  取消结算
                </Button>
                <Button
                  type="primary"
                  style={{ width: 100 }}
                  onClick={this.showConfirm}
                >
                  下一步
                </Button>
              </div>
            </div>
            <div className={styles.info_container}>
              <div className={styles.info_title_container}>
                <div className={styles.info_title_left}>
                  <p className={styles.info_title}>开票资料</p>
                </div>
              </div>
              <div
                className={`${styles.info_content_container} ${styles.not_table}`}
              >
                <Descriptions>
                  <Descriptions.Item label="发票抬头">
                    {invoiceInfo.invoiceTitle}
                  </Descriptions.Item>
                  <Descriptions.Item label="税号">
                    {invoiceInfo.invoiceTaxNo}
                  </Descriptions.Item>
                  <Descriptions.Item label="地址及电话">
                    {invoiceInfo.invoiceAddressPhone}
                  </Descriptions.Item>
                  <Descriptions.Item label="开户行及账号">
                    {invoiceInfo.invoiceBankAccount}
                  </Descriptions.Item>
                </Descriptions>
              </div>
            </div>
            <div className={styles.info_container}>
              <div className={styles.info_title_container}>
                <div className={styles.info_title_left}>
                  <p className={styles.info_title}>邮寄地址</p>
                </div>
              </div>
              <div
                className={`${styles.info_content_container} ${styles.not_table}`}
              >
                <Descriptions>
                  <Descriptions.Item label="收货人">
                    {invoiceInfo.name}
                  </Descriptions.Item>
                  <Descriptions.Item label="联系电话">
                    {invoiceInfo.phone}
                  </Descriptions.Item>
                  <Descriptions.Item label="邮寄地址">
                    {invoiceInfo.address}
                  </Descriptions.Item>
                </Descriptions>
              </div>
            </div>
            <div className={styles.info_container}>
              <div className={styles.info_title_container}>
                <div className={styles.info_title_left}>
                  <p className={styles.info_title}>结算账户</p>
                </div>
              </div>
              <div className={styles.info_content_container}>
                <Table
                  columns={column}
                  pagination={false}
                  showHeader={false}
                  rowSelection={rowSelection}
                  dataSource={settlementAccountList}
                />
              </div>
            </div>
          </div>
        );
      case 2 /* 第3步 */:
        return (
          <div className={styles.step_content_container}>
            <div className={styles.info_line_div}>
              <div className={styles.info_line_div_left}></div>
              <div className={styles.info_line_div_right}>
                <Button
                  type="default"
                  style={{ width: 100, marginRight: '20px' }}
                >
                  取消结算
                </Button>
                <Button type="primary" style={{ width: 100 }}>
                  提交申请
                </Button>
              </div>
            </div>
            <div className={styles.info_container}>
              <div className={styles.info_title_container}>
                <div className={styles.info_title_left}>
                  <p className={styles.info_title}>请填写发票邮寄的快递单号</p>
                </div>
              </div>
              <div
                className={`${styles.info_content_container} ${styles.not_table}`}
              >
                <Descriptions>
                  <Descriptions.Item label="选择快递公司">
                    <Select style={{ width: 150 }} placeholder="请选择快递公司">
                      {}
                    </Select>
                  </Descriptions.Item>
                  <Descriptions.Item label="填写快递单号">
                    <Input style={{ width: 150 }} allowClear={true} />
                  </Descriptions.Item>
                </Descriptions>
              </div>
            </div>
            <div className={styles.info_container}>
              <div className={styles.info_title_container}>
                <div className={styles.info_title_left}>
                  <p className={styles.info_title}>结算明细</p>
                </div>
              </div>
              <div
                className={`${styles.info_content_container} ${styles.not_table}`}
              >
                <Descriptions>
                  <Descriptions.Item label="结算金额"></Descriptions.Item>
                  <Descriptions.Item label="结算手续费"></Descriptions.Item>
                  <Descriptions.Item label="开户名"></Descriptions.Item>
                  <Descriptions.Item label="开户行"></Descriptions.Item>
                  <Descriptions.Item label="银行账号"></Descriptions.Item>
                </Descriptions>
              </div>
            </div>
          </div>
        );
      case 3 /* 第4步 */:
        return (
          <div className={styles.step_content_container}>
            <div>
              <Result
                status="success"
                title="结算申请已提交"
                subTitle="请等待平台审核，审核通过后，金额即会到账"
              />
            </div>
            <div className={styles.info_container}>
              <div className={styles.info_title_container}>
                <div className={styles.info_title_left}>
                  <p className={styles.info_title}>发票快递单号</p>
                </div>
              </div>
              <div
                className={`${styles.info_content_container} ${styles.not_table}`}
              >
                <Descriptions>
                  <Descriptions.Item label="快递公司"></Descriptions.Item>
                  <Descriptions.Item label="快递单号"></Descriptions.Item>
                </Descriptions>
              </div>
            </div>
            <div className={styles.info_container}>
              <div className={styles.info_title_container}>
                <div className={styles.info_title_left}>
                  <p className={styles.info_title}>结算明细</p>
                </div>
              </div>
              <div
                className={`${styles.info_content_container} ${styles.not_table}`}
              >
                <Descriptions>
                  <Descriptions.Item label="结算金额"></Descriptions.Item>
                  <Descriptions.Item label="结算手续费"></Descriptions.Item>
                  <Descriptions.Item label="到账金额"></Descriptions.Item>
                  <Descriptions.Item label="开户名"></Descriptions.Item>
                  <Descriptions.Item label="开户行"></Descriptions.Item>
                  <Descriptions.Item label="银行账号"></Descriptions.Item>
                </Descriptions>
              </div>
            </div>
          </div>
        );
      case 4 /* 第5步 */:
        return (
          <div className={styles.step_content_container}>
            <div>
              <Result
                status="success"
                title="结算申请已通过"
                subTitle="已汇款至您的结算账户，具体到账时间以银行为准"
              />
            </div>
            <div className={styles.info_container}>
              <div className={styles.info_title_container}>
                <div className={styles.info_title_left}>
                  <p className={styles.info_title}>发票快递单号</p>
                </div>
              </div>
              <div
                className={`${styles.info_content_container} ${styles.not_table}`}
              >
                <Descriptions>
                  <Descriptions.Item label="快递公司"></Descriptions.Item>
                  <Descriptions.Item label="快递单号"></Descriptions.Item>
                </Descriptions>
              </div>
            </div>
            <div className={styles.info_container}>
              <div className={styles.info_title_container}>
                <div className={styles.info_title_left}>
                  <p className={styles.info_title}>结算明细</p>
                </div>
              </div>
              <div
                className={`${styles.info_content_container} ${styles.not_table}`}
              >
                <Descriptions>
                  <Descriptions.Item label="结算金额"></Descriptions.Item>
                  <Descriptions.Item label="结算手续费"></Descriptions.Item>
                  <Descriptions.Item label="到账金额"></Descriptions.Item>
                  <Descriptions.Item label="开户名"></Descriptions.Item>
                  <Descriptions.Item label="开户行"></Descriptions.Item>
                  <Descriptions.Item label="银行账号"></Descriptions.Item>
                </Descriptions>
              </div>
            </div>
          </div>
        );
    }
  };

  /**
   * 申请结算
   */
  applySettlement = async () => {
    const { postApplySettlement } = this.props.financeStore;
    let data = await postApplySettlement({});
    if (data.success) {
      window.open(
        `/finance/center/settlement-progress-detail?id=${data.data}`,
        '_self'
      );
    }
    console.log(data);
  };

  /**
   * 取消结算
   * @returns {Promise<void>}
   */
  cancelSettlement = async () => {
    const {
      postCancelSettlement,
      settlementId,
      oneSettlementInfo,
    } = this.props.financeStore;
    let res = await postCancelSettlement({
      balanceId: settlementId || oneSettlementInfo.balanceId,
    });
    if (res.success) {
      this.props.history.push({
        pathname: '/finance/settlement-manage',
      });
    }
  };

  /**
   * 下一步弹窗
   */
  showConfirm = () => {
    const _this = this;
    confirm({
      title: '温馨提示',
      content: (
        <div className={styles.confirm_content}>
          <p>
            1、确定已根据明细，开具发票。（若开票内容太多，可在发票后附A4纸，将明细打印在A4纸上，并加盖财务章）；
          </p>
          <p>2、确定发票上的开票资料无误；</p>
          <p>3、确定已按照正确的地址，邮寄发票。</p>
        </div>
      ),
      okText: '去填写快递单号',
      width: 500,
      onOk() {
        console.log('OK');
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  /**
   * 选择开户行等信息
   */
  changeBankTableSelect = (selectedRowKeys, selectedRows) => {
    console.log(selectedRowKeys, selectedRows);
  };

  /**
   * 导出
   */
  exportOrder = async (tableType) => {
    // 根据表格不同，导出的内容也不同

    const { getExportDetail, getExportOrder } = this.props.financeStore;
    const { data, headers } =
      tableType == 'order'
        ? await getExportOrder({})
        : await getExportDetail({});
    let fileName = '';
    try {
      fileName = window.decodeURI(headers['content-disposition'].split('=')[1]);
      if (fileName.startsWith('utf-8')) {
        fileName = fileName.replace("utf-8''", '');
      }
    } catch (error) {}
    downloadFile(data, 'vnd.ms-excel', fileName);
  };

  render() {
    const { tableType } = this.state;
    const { recordDetailList, orderDetailList } = this.props.financeStore;

    return (
      <div className={styles.page_container}>
        <div className={styles.step_container}>{this.renderStep()}</div>
        {this.renderStepContent()}
        <div className={styles.info_container}>
          <div className={styles.info_title_container}>
            <div className={styles.info_title_left}>
              <p className={styles.info_title}>
                {tableType === 'order' ? '订单明细' : '开票明细'}（共
                {tableType === 'record'
                  ? recordDetailList.total
                  : orderDetailList.total}
                条）
              </p>
              {tableType === 'record' ? (
                <p className={styles.info_title_desc}>
                  （已将同类商品合并，您可点击 “
                  <span
                    className={styles.table_action_btn}
                    onClick={() => this.changeTableType('order')}
                  >
                    订单明细
                  </span>
                  ” ，查看报表明细数据）
                </p>
              ) : (
                <p className={styles.info_title_desc}>
                  （根据订单展示开票明细，您可点击 “
                  <span
                    className={styles.table_action_btn}
                    onClick={() => this.changeTableType('record')}
                  >
                    开票明细
                  </span>
                  ” ，将报表中的同类商品进行合并开票）
                </p>
              )}
            </div>
            <div className={styles.info_title_right}>
              <Button
                type="default"
                onClick={() => this.exportOrder(tableType)}
              >
                <Icon type="download" style={{ fontSize: 18 }} /> 导出
              </Button>
            </div>
          </div>
          <div className={styles.info_content_container}>
            {this.renderTable()}
          </div>
        </div>
      </div>
    );
  }
}

export default SettlementProgress;
