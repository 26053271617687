import { observable, action } from 'mobx';
import httpClient from 'utils/httpClient';
import React, { createContext } from 'react';
import api from 'config/api';
import { addKey } from 'utils/tools';
const { activity } = api;
const { common } = api;

class ActManagementStore {
  //活动管理tab key  活动广场 我的报名记录
  @observable ActManagementKey = '1';
  @action setActManagementKey = (val) => {
    this.ActManagementKey = val;
  };
  //活动广场列表
  @observable ActSquareList = [];
  @action getActSquareList = async (params) => {
    const { data } = await httpClient.post(activity.ACT_SQUARE_LIST, {
      ...params,
    });
    this.ActSquareList = data.list;
    return data;
  };
  //活动广场tab key 活动类型
  @observable tabKey = '';
  @action setActTabKey = (index) => {
    this.tabKey = index;
  };

  /**
   * 获取报名中列表
   * @param params
   * @returns {Promise<void>}
   */
  @action
  getSignUpList = async (params) => {
    const res = await httpClient.post(activity.POST_GET_SIGN_UP_LIST, params);
    if (res.success) {
      return addKey(res.data);
    }
  };

  /**
   * 获取报名记录列表
   * @param params
   * @returns {Promise<void>}
   */
  @action
  getSignUpRecordList = async (params) => {
    const res = await httpClient.get(activity.GET_SIGN_UP_RECORD_LIST, {
      params,
    });
    if (res.success) {
      return addKey(res.data);
    }
  };

  /**
   * 用户资料列表
   * @param params
   * @returns {Promise<void>}
   */
  @action
  getUserInfoList = async (params) => {
    const res = await httpClient.post(activity.POST_GET_USER_INFO_LIST, params);
    if (res.success) {
      return addKey(res.data);
    }
  };

  /**
   * 获取派样活动详情
   * @param params
   * @returns {Promise<T>}
   */
  @action
  getActivityInfo = async (params) => {
    const res = await httpClient.get(activity.GET_ACTIVITY_INFO, { params });
    if (res.success) {
      return res.data;
    }
  };

  /**
   * 提报活动
   * @param params
   * @returns {Promise<void>}
   */
  @action
  postPresentActivity = async (params) => {
    const res = await httpClient.post(activity.POST_PRESENT_ACTIVITY, params);
    return res;
  };

  /**
   * 获取派样活动详情
   * @param params
   * @returns {Promise<T>}
   */
  @action
  downloadUserInfo = async (params) => {
    return await httpClient.get(activity.GET_DOWNLOAD_USER_INFO, {
      params,
      responseType: 'blob',
    });
  };

  @action
  exitActivity = async (params) => {
    const res = await httpClient.post(activity.POST_EXIT_ACTIVITY, params);
    return res;
  };
}

export default new ActManagementStore();
