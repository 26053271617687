import React, {useState, useEffect} from 'react';
import styles from './index.module.scss'

function Setting(props) {
  return (
    <div>
      Setting
    </div>
  );
}
export default Setting;
