import React, { useState, useEffect } from 'react';
import { Steps, Table, Descriptions, Divider, Button, Space } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Modals } from 'components';
import styles from './index.module.scss';
import { formatTime, getQuery, showMoney } from 'utils/tools';
import stores from 'stores';

// 订单状态：1-新单，2-提交审批，3-待审批，4-待付款，5-待发货，6-已发货，7-部分发货，8-已关闭，9-待接口确认，10-已完成，11-已取消
const {
  OrderChangeMoneyModal,
  OrderGoodsDeliverModal,
  OrderExpressInfoModal,
} = Modals;
const { Step } = Steps;
const { Item } = Descriptions;

const { orderInfoStore } = stores;

const getDesLabel = (str) => <div className={styles.des_label}>{str}</div>;
const getDesContent = (str) => <p className={styles.des_content}>{str}</p>;

const stepCurrentMap = {
  1: 0,
  2: 0,
  3: 0,
  4: 1,
  5: 2,
  6: 3,
  7: 2,
  8: 5,
  10: 4,
  11: 5,
};

function Detail(props) {
  const [orderInfo, setOrderInfo] = useState({
    orderGoodsList: [],
    orderStatusCycleList: [],
  });

  const [showChangeMoney, setShowChangeMoney] = useState(false);
  const [showGoodsDeliver, setShowGoodsDeliver] = useState(false);
  const [showExpressInfo, setShowExpressInfo] = useState(false);
  const [expressNum, setExpressNum] = useState(undefined);
  useEffect(() => {
    _getData();
  }, []);

  const _getData = async () => {
    let query = getQuery();
    if (query.id) {
      // let params = { shopOrderNo: query.id };
      let params = { parentOrderNo: query.id, source: query.source };
      let res = await orderInfoStore.getOrderDetail(params);
      setOrderInfo(res);
    }
  };
  return (
    <>
      {![8, 11].includes(+orderInfo['status']) && (
        <div className={styles.order_step_container}>
          <StepModule
            current={stepCurrentMap[orderInfo['status']]}
            content={orderInfo['orderStatusCycleList']}
          />
        </div>
      )}

      <div className={styles.order_detail_container}>
        <div className={styles.order_info_container}>
          <OrderInfoModule data={orderInfo} />
        </div>
        <div className={styles.order_status_container}>
          <OrderStatusDescModule
            info={orderInfo}
            current={stepCurrentMap[orderInfo['status']]}
            time={orderInfo.createTime}
            closeType={orderInfo.closeType}
            onAction={(type) => {
              switch (type) {
                case 'modify': // 改价
                  setShowChangeMoney(true);
                  break;
                case 'deliver': // 发货
                  setShowGoodsDeliver(true);
                  break;
                default:
                  break;
              }
            }}
          />
        </div>
      </div>
      <div className={styles.goods_table_container}>
        <GoodsTable
          list={orderInfo.orderGoodsList}
          onAction={(expressNum) => {
            setExpressNum(expressNum);
            setShowExpressInfo(true);
          }}
        />
      </div>

      {showChangeMoney && (
        <OrderChangeMoneyModal
          visible={showChangeMoney}
          list={orderInfo.orderGoodsList || []}
          express={orderInfo.expressCost || 0}
          orderId={orderInfo.shopOrderNo}
          onCancel={(flag) => {
            setShowChangeMoney(false);
            if (flag) {
              _getData();
            }
          }}
        />
      )}

      {showGoodsDeliver && (
        <OrderGoodsDeliverModal
          visible={showGoodsDeliver}
          info={orderInfo}
          onCancel={(flag) => {
            setShowGoodsDeliver(false);
            if (flag) {
              _getData();
            }
          }}
        />
      )}
      {showExpressInfo && (
        <OrderExpressInfoModal
          visible={showExpressInfo}
          expressNum={expressNum}
          onCancel={() => {
            setShowExpressInfo(false);
          }}
        />
      )}
    </>
  );
}

function StepModule(props) {
  const { current, content } = props;
  return (
    <Steps labelPlacement="vertical" current={current || 0}>
      {content.map((item, index) => (
        <Step
          key={index}
          title={item.statusString}
          description={formatTime(item.time)}
        />
      ))}

      {/*<Step title="买家付款"/>*/}
      {/*<Step title="商家发货"/>*/}
      {/*<Step title="交易完成"/>*/}
    </Steps>
  );
}

function OrderInfoModule(props) {
  const { data } = props;
  return (
    <>
      <Descriptions title="订单详情" column={1}>
        <Item label={getDesLabel('订单编号')} span={1}>
          {getDesContent(`${data.parentOrderNo}`)}
        </Item>
        <Item label={getDesLabel('订单金额')} span={1}>
          {getDesContent(
            `¥${showMoney(data.orderTotalPrice)}（含运费：¥${showMoney(
              data.expressCost
            )}）`
          )}
        </Item>
      </Descriptions>
      <Divider dashed />
      <Descriptions column={1}>
        <Item label={getDesLabel('收货人姓名')} span={1}>
          {getDesContent(`${data.consignee}`)}
        </Item>
        <Item label={getDesLabel('收货人手机号')} span={1}>
          {getDesContent(`${data.mobileNumber}`)}
        </Item>
        <Item label={getDesLabel('收货人地址')} span={1}>
          {getDesContent(`${data.completeAddress}`)}
        </Item>
        <Item label={getDesLabel('买家留言')} span={1}>
          {getDesContent(`${data.remark}`)}
        </Item>
      </Descriptions>
    </>
  );
}

function OrderStatusDescModule(props) {
  const { current, time, onAction, closeType, info } = props;
  useEffect(() => {
    descMap[1] = getPayDesc();
  }, []);
  const titleMap = [
    '',
    '订单状态：客户已下单，等待客户付款',
    '订单状态：买家已付款，等待商家发货',
    '订单状态：供应商已发货，等待交易完成',
    '交易完成',
    '订单状态：交易关闭',
  ];

  // 订单关闭类型,1-审批拒绝，2-超时未支付，3-审批撤回，4-退款
  const closeDesc = [
    '',
    '',
    '客户超时未支付，订单自动关闭',
    '',
    '订单中所有商品退款完成，订单自动关闭',
  ];
  const getPayDesc = () => {
    let now = new Date().getTime();
    let delta = time + 24 * 60 * 60 * 1000 - now;
    let hours = parseInt(delta / (60 * 60 * 1000));
    let minutes = parseInt((delta - hours * (60 * 60 * 1000)) / (60 * 1000));
    return (
      <p>
        如该订单未在24小时内付款，订单将自动关闭
        <br />
        支付截止剩余时间：
        {hours > 0 ? hours + '小时' + minutes + '分' : minutes + '分钟'}
      </p>
    );
  };

  const descMap = [
    '',
    getPayDesc(),
    '',
    '',
    '订单已完成，该订单已结算给供应商',
    closeDesc[closeType],
  ];
  return (
    <div className={styles.status_desc_container}>
      <Steps>
        <Step
          icon={<InfoCircleOutlined />}
          title={titleMap[current]}
          description={descMap[current]}
        />
      </Steps>
      {/*{current === 1 && (*/}
      {((+info.status === 2 && +info.approvalStatus === 1) ||
        (+info.status === 4 && +info.approvalStatus === 0)) && (
        <Button type="primary" onClick={() => onAction('modify')}>
          修改订单金额
        </Button>
      )}
      {current === 2 && (
        <Button type="primary" onClick={() => onAction('deliver')}>
          发货
        </Button>
      )}
    </div>
  );
}

function GoodsTable(props) {
  const { list, onAction } = props;
  const columns = [
    {
      title: '商品',
      align: 'center',
      render: (text, record, index) => {
        let { goodsAttribute } = record;
        let str = '';
        if (goodsAttribute) {
          goodsAttribute = JSON.parse(goodsAttribute);
          str = `规格：${Object.values(goodsAttribute).join(' ')}`;
        }
        return (
          <div className={styles.item_container}>
            <div className={styles.item_img_container}>
              <img
                src={record.goodsImageUrl}
                className={styles.item_img}
                alt=""
              />
            </div>
            <div className={styles.item_info_container}>
              <p className={styles.item_name}>{record.goodsName}</p>
              <p className={styles.item_attr}>{str}</p>
            </div>
          </div>
        );
      },
    },
    {
      title: '数量',
      align: 'center',
      dataIndex: 'quantity',
    },
    {
      title: '销售价',
      align: 'center',
      dataIndex: 'orderPrice',
      render: (text, record) => `¥${showMoney(text)}`,
    },
    {
      title: '小计',
      align: 'center',
      render: (text, record) =>
        `¥${showMoney(record.orderPrice * record.quantity)}`,
    },
    {
      title: '状态',
      align: 'center',
      dataIndex: '',
      render: (text, record) => {
        switch (+record.deliveryStatus) {
          case 1:
            return '无需发货';
          case 2:
            return '待发货';
          case 3:
            return (
              <>
                已发货
                <Button type="link" onClick={() => onAction(record.expressNo)}>
                  查看物流
                </Button>
              </>
            );
          default:
        }
        return '';
      },
    },
  ];

  return (
    <Table dataSource={list} columns={columns} pagination={false} bordered />
  );
}

export default Detail;
