import React, {useState, useEffect} from 'react'
import {Modal, Space, Table, Form, Select, Input, Radio, message, InputNumber} from 'antd'
import PropTypes from 'prop-types'
import {toJS} from 'mobx'
import styles from './index.module.scss'
import {showMoney} from "../../../../utils/tools";
import {judgeNum} from "../../../../utils/validate";
import stores from 'stores'


const {Option} = Select
const {orderInfoStore} = stores

const goodsSourceMap = new Map([[1, "供应商商品"], [2, "店铺商品"]])

/**
 * 订单改价弹窗
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function ChangeMoney(props) {
  const {visible, list, express, orderId, onOk, onCancel} = props;
  const [goodsList, setGoodsList] = useState([])
  const [goodsModifyInfo, setGoodsModifyInfo] = useState({shopOrderNo: []})
  const [refresh, setRefresh] = useState(true)
  useEffect(() => {
    refresh && setTimeout(() => setRefresh(false));
  }, [refresh])
  useEffect(() => {
    _getGoodsModifyInfo()
  }, [])
  const _getGoodsModifyInfo = async () => {
    let params = {
      shopOrderNo: orderId
    }
    let res = await orderInfoStore.getModifyPriceInfo(params)
    setGoodsModifyInfo(res)
    setGoodsList(JSON.parse(JSON.stringify(res.goodsList)).map(item => ({
      ...item,
      orderPrice: showMoney(item.orderPrice)
    })))
    if (res.expressCost !== res.originalExpressCost) {
      form.setFieldsValue({
        newExpress: res.expressCost === 0 ? 0 : res.expressCost ? showMoney(res.expressCost) : undefined,
        express: res.expressCost === 0 ? 1 : +!!res.expressCost
      })
    } else {
      form.setFieldsValue({
        newExpress: undefined,
        express: 0
      })
    }

  }
  const columns = [
    {
      title: "商品",
      dataIndex: "goodsName",
      align: "center",
      width: 220,
      render: (text) =>
        <p className={styles.goods_name}>{text}</p>
    },
    {
      title: "商品渠道",
      align: "center",
      dataIndex: "goodsSource",
      render: text => goodsSourceMap.get(text)
    },
    {title: "数量", dataIndex: "quantity",},
    {
      title: "销售价", dataIndex: "salePrice",
      render: (text) => `¥${showMoney(text)}`
    },
    {
      title: "是否改价", dataIndex: "",
      render: (text, record, index) =>
        <Select style={{minWidth: 80}}
                value={record.isChangePrice}
                onChange={val => {
                  goodsList[index].isChangePrice = val;
                  setGoodsList(goodsList)
                  setRefresh(true)
                }}>
          <Option value={0}>否</Option>
          <Option value={1}>是</Option>
        </Select>
    },
    {
      title: "修改价格",
      render: (text, record, index) =>
        <Input value={record.orderPrice}
               disabled={!record.isChangePrice}
               placeholder="大于0且小于销售价"
               style={{width: 150}}
               onChange={e => {
                 goodsList[index].orderPrice = judgeNum(e.target.value, "money")
                 setGoodsList(goodsList)
                 setRefresh(true)
                 console.log(index, e.target.value)
               }}/>
    },
  ]
  const [form] = Form.useForm()
  const confirm = async () => {
    const values = await form.validateFields()
    let params = {shopOrderNo: orderId}
    if (goodsModifyInfo.originalExpressCost) {
      if (!values.express) {
        params.expressCost =goodsModifyInfo.originalExpressCost
      } else {
        params.expressCost = values.newExpress * 1e2
      }
    } else {
      params.expressCost = undefined
    }

    params.goodsParams = goodsList.map(item => {
      let obj = {
        orderGoodsId: item.orderGoodsId
      }
      if (!item.isChangePrice) {
        obj.orderPrice = item.salePrice
      } else {
        obj.orderPrice = item.orderPrice * 1e2
      }
      return obj
    })

    const res = await orderInfoStore.modifyPrice(params)
    if (res.success) {
      message.success("改价成功")
      setTimeout(() => {
        onCancel(true)
      }, 1000)

    }
  }
  return <Modal title="修改订单金额" visible={visible} onCancel={onCancel} onOk={confirm} centered width={900}>
    <Table columns={columns}
           dataSource={goodsList}
           pagination={false}
           rowKey={record=>record.orderGoodsId}/>
    {!!goodsModifyInfo.originalExpressCost &&
    <Space align="start" size={32} className={styles.express_money_container}>
      <span>运费金额：¥{showMoney(goodsModifyInfo.originalExpressCost)}</span>
      <Form form={form} layout="inline">
        <Form.Item name="express" label="是否修改运费金额">
          <Radio.Group defaultValue={0}
                       onChange={e => {
                         let val = e.target.value
                         if (!val) {
                           form.setFieldsValue({newExpress: undefined})
                         }
                       }}>
            <Radio value={0}>否</Radio>
            <Radio value={1}>是</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item shouldUpdate>
          {() =>
            <Form.Item name="newExpress"
                       dependencies={["express"]}
                       rules={[({getFieldValue}) => ({
                         validator(rule, value) {
                           if (getFieldValue("express") === 1 && (!value && value !== 0)) {
                             return Promise.reject("请输入修改运费金额")
                           }
                           return Promise.resolve();
                         }
                       })]}>
              <InputNumber placeholder="大于等于0且小于当前运费金额"
                           min={0}
                           max={showMoney(goodsModifyInfo.originalExpressCost) - 0.01}
                           style={{width: 200}}
                           disabled={!form.getFieldValue("express")}/>
            </Form.Item>}
        </Form.Item>

      </Form>
    </Space>}

  </Modal>
}

ChangeMoney.propTypes = {
  list: PropTypes.array,
  express: PropTypes.number,
  orderId: PropTypes.number || PropTypes.string,
  visible: PropTypes.bool.isRequired,
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
}

export default ChangeMoney;
