import React, { useEffect, useState } from 'react'
import { Modal, Descriptions, Input, Button, Row, Col, Checkbox } from "antd";
import PropTypes from 'prop-types'
import stores from 'stores'
import styles from "./index.module.scss"

const { storeFreightStore } = stores

function ChooseArea(props) {
  const { visible, onOk, onCancel, freightIndex, type,modalType } = props;

  const [area, setArea] = useState([]);
  const [refresh, setRefresh] = useState(false)
  useEffect(() => {
    refresh && setTimeout(() => setRefresh(false));
  }, [refresh])
  useEffect(() => {
    _init();
  }, [])

  const _init = async () => {
    let areaStr = localStorage.getItem("freightArea");
    if (!areaStr) {
      await storeFreightStore.getFreightArea({ dictType: "freightArea" })
      areaStr = localStorage.getItem("freightArea")
    }
    let area = JSON.parse(areaStr);
    if (type === 'edit') {
      area = area.map(item => {
        if (item.freightIndex === freightIndex) {
          item.check = true
        }
        item.children = item.children.map(itemIn => {
          if (itemIn.freightIndex === freightIndex) {
            itemIn.check = true
          }
          return itemIn
        })
        return item
      })
    }

    setArea(area)
  }

  const modalOk = () => {
    area.map(item => {
      // 被勾选的替换成传入的index，去掉勾的把index置为0，原本非传入的index的项不变
      item.freightIndex = item.check ? freightIndex : item.freightIndex === freightIndex ? 0 : item.freightIndex;
      //所有的勾去掉
      item.check = false;
      item.children = item.children.map(itemIn => {
        itemIn.freightIndex = itemIn.check ? freightIndex : itemIn.freightIndex === freightIndex ? 0 : itemIn.freightIndex;
        itemIn.check = false;
        return itemIn
      });
      return item
    })
    setArea(area);
    localStorage.setItem("freightArea", JSON.stringify(area));
    onCancel()
  }

  const renderArea = () => {
    return area.map((item, index) =>
      <Row key={index}>
        <Col key={item.id}
          span={4}
          className={styles.area_cell}>
          <Checkbox checked={item.check}
            onChange={e => checkChange(e, item, index, -1)}>
            <span style={{ color: item.freightIndex > 0 ? 
              "#DB211D" :
               item.freightIndex < 0 ? 
               "#1890FF" : 
               "#3c3c3c" }}>{item.dictValue}</span></Checkbox>
        </Col>
        {item.children.map((itemIn, indexIn) =>
          <Col key={itemIn.id}
            span={4}
            className={styles.area_cell}>
            <Checkbox checked={itemIn.check}
              onChange={e => checkChange(e, itemIn, index, indexIn)}>
              <span
                style={{ color: itemIn.freightIndex > 0 ? 
                "#DB211D" :
                 itemIn.freightIndex < 0 ? 
                 "#1890FF" : 
                 "#3c3c3c" }}>{itemIn.dictValue}{item.freightIndex > 0}</span></Checkbox>
          </Col>
        )}
      </Row>
    )
  }

  const checkChange = (e, item, index, indexIn) => {
    let value = e.target.checked
    console.log(value, item, index, indexIn)
    if (indexIn === -1) {
      area[index].check = value;
      area[index].children = area[index].children.map(obj => {
        obj.check = value
        return obj
      })
    } else {
      area[index].children[indexIn].check = value
      area[index].check = area[index].children.every(obj => obj.check)
    }
    console.log(area)
    setArea(area);
    setRefresh(true)
  }

  return <Modal visible={visible}
    title={modalType==="disabled"?"设置不可配送区域":"为指定地区设置运费"}
    centered
    onOk={modalOk}
    onCancel={onCancel}
    width={660}>
    <p className={styles.modal_body_tip}>红色文字表示在其他指定地区中有设置运费，再次勾选将会移入不可配送区域;绿色文字表示在不可配送区域中。</p>
    <div className={styles.area_container}>
      {renderArea()}
    </div>
  </Modal>
}

ChooseArea.propTypes = {
  visible: PropTypes.bool.isRequired,
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
}

export default ChooseArea;
