import React, {useState, useEffect} from 'react';
import {Button, Descriptions, message, Modal, Space} from "antd";
import {ExclamationCircleOutlined} from '@ant-design/icons';
import stores from 'stores'
import styles from './index.module.scss'
import {formatTime, getQuery, showMoney} from "utils/tools";
import {Modals, PreviewPic} from 'components'

const {Item} = Descriptions
const {orderServiceStore} = stores

const {OrderServiceDealModal, OrderServiceRefundModal, OrderServiceDealDeliverModal} = Modals

const getItemLabel = str => <p className={styles.item_label}>{str}</p>

const auditStatusMap = new Map([
  [1, "待审核"],
  [2, "已通过"],
  [3, "已驳回"],
])

const statusMap = new Map([
  [2, '待退款'],
  [3, '退款中'],
  [4, '退款失败'],
  [5, '待买家退货'],
  [6, '待换货'],
  [7, '已完成'],
  [9, '已撤回'],
])

const typeMap = new Map([
  [0, "我要退款"],
  [1, "我要退货退款"],
  [2, "仅退款（不退货）"],
  [3, "我要换货"],
])


function Detail(props) {
  const [pageType, setPageType] = useState("")
  const [info, setInfo] = useState({})
  const [orderInfo, setOrderInfo] = useState({})
  const [showDeal, setShowDeal] = useState(false)
  const [showDeliver, setShowDeliver] = useState(false)
  const [showRefund, setShowRefund] = useState(false)
  const [showPreview, setShowPreview] = useState(false)
  const [previewPic, setPreviewPic] = useState("")

  useEffect(() => {
    _getData()
  }, [])

  const _getData = async () => {
    let query = getQuery();
    if (query.id) {
      let res = await orderServiceStore.getServiceInfo({id: query.id})
      setInfo(res)
      setOrderInfo(res.refundOrderGoodsBo ? res.refundOrderGoodsBo[0] : {})
      setPageType(res.auditStatus)
    }
  }

  const refund = async (reason) => {
    if (reason) {//驳回
      console.log(reason)
      let params = {
        id: info.id,
        auditStatus: 3,
        auditReason: reason
      }
      let res = await orderServiceStore.refundVerify(params)
      if (res.success) {
        message.success("已拒绝")
        setTimeout(() => {
          setShowRefund(false)
          _getData()
        }, 1000)
      }
    } else {
      setShowRefund(false)
    }
  }

  const getGoods = () => {
    let {goodsAttribute} = orderInfo;
    let str = '';
    if (goodsAttribute) {
      goodsAttribute = JSON.parse(goodsAttribute);
      str = `规格：${Object.entries(goodsAttribute).map(item => `${item[0]}:${item[1]}`).join(' ')}`;
    }
    return (
      <div className={styles.item_container}>
        <div className={styles.item_img_container}>
          <img
            src={orderInfo.goodsImageUrl}
            className={styles.item_img}
            alt=""
          />
        </div>
        <div className={styles.item_info_container}>
          <p className={styles.item_name}>{orderInfo.goodsName}</p>
          <p className={styles.item_attr}>{str}</p>
        </div>
      </div>
    );
  }
  const refundMoney = async () => {
    Modal.confirm({
      title: '确认退款',
      icon: <ExclamationCircleOutlined/>,
      content: '是否已确认收到退货商品，进行退款？',
      async onOk() {
        let params = {
          refundOrderId: info.id
        }
        let res = await orderServiceStore.refundApply(params)
        if (res.success) {
          message.success("退款已发起！")
          _getData();
        }
      },
    })
  }
  return (
    <>
      <div className={styles.title_container}>
        <Descriptions column={1}>
          <Item label="售后工单">{info.id}</Item>
          <Item label="申请时间">{formatTime(info.createTime)}</Item>
        </Descriptions>
      </div>
      <div className={styles.content_container}>
        <Descriptions title="订单详情" column={1} style={{marginBottom: 30}}>
          <Item label={getItemLabel("订单编号")}>{info.orderId}</Item>
          <Item
            label={getItemLabel("订单金额")}>{`¥${showMoney(info.orderAmount + info.orderExpressCost)} （含运费：¥${showMoney(info.orderExpressCost)}）`}</Item>
          <Item label={getItemLabel("收货人姓名")}>{info.orderConsignee}</Item>
          <Item label={getItemLabel("收货人手机号")}>{info.orderMobileNumber}</Item>
          <Item label={getItemLabel("收货人地址")}>{info.orderAddress}</Item>
          {/*<Item label={getItemLabel("买家留言")}></Item>*/}
          <Item label={getItemLabel("售后商品")}>{getGoods()}</Item>
          <Item label={getItemLabel("售后类型")}>{typeMap.get(info.type)}</Item>
          <Item label={getItemLabel("售后原因")}>{info.reason}</Item>
          <Item label={getItemLabel("售后商品件数")}>{orderInfo.applyReturnQuantity}</Item>
          <Item label={getItemLabel("问题描述")}>{info.refundDesc}</Item>
          {[1, 3].includes(info.type) &&
          <>
            <Item
              label={getItemLabel("退货地址信息")}>{`${info.refundConsignee} ${info.refundLandlineNumber || ''}${info.refundMobileNumber || ""} ${info.province || " "}${info.city || " "}${info.district || " "}${info.town || " "}${info.address || " "}`}</Item>

            <Item label={getItemLabel("凭证")}>{
              info.refundVoucherBos &&
              info.refundVoucherBos.map((item, index) =>
                <img src={item.voucherUrl}
                     key={index}
                     onClick={() => {
                       setPreviewPic(item.voucherUrl)
                       setShowPreview(true)
                     }}
                     className={styles.card_img}/>)
            }</Item>
          </>}

          <Item label={getItemLabel("联系方式")}>
            {info.contactName}
            <br/>
            {info.contactTel}</Item>
          <Item label={getItemLabel("审批状态")}>{auditStatusMap.get(info.auditStatus)}</Item>
          {+info.type !== 1 &&
          <>
            <Item label={getItemLabel("审批时间")}>{formatTime(info.auditTime)}</Item>
            <Item label={getItemLabel("处理批注(必填)")}>{info.auditReason}</Item>
          </>}

        </Descriptions>

        {+info.auditStatus === 2 &&
        <Descriptions title="订单售后信息" column={1} style={{marginBottom: 30}}>
          {
            <Item label={getItemLabel(+info.type === 3 ? "换货数量" : "退货数量")}>{orderInfo.realReturnQuantity}</Item>}
          {[0, 1, 2].includes(+info.type) &&
          <>
            <Item
              label={getItemLabel("退款商品合计")}>{showMoney(orderInfo.refundPrice * orderInfo.realReturnQuantity)}</Item>
            <Item label={getItemLabel("运费退款合计")}>{showMoney(orderInfo.expressCost)}</Item>
            <Item
              label={getItemLabel("退款总计")}>{showMoney(orderInfo.refundPrice * orderInfo.realReturnQuantity + orderInfo.expressCost)}</Item>
          </>}
          <Item label={getItemLabel("售后状态")}>{statusMap.get(info.status)}</Item>
          {[1, 3].includes(+info.type) && ![9].includes(+info.status) &&
          <>
            <Item
              label={getItemLabel("退货物流信息")}>{`${info.returnExpressName || ""}  ${info.returnExpressSerial || ""}`}</Item>
            <Item label={getItemLabel("退货时间")}>{formatTime(info.returnTime)}</Item>
          </>}
          {+info.type === 3 && ![9].includes(+info.status) && <>
            <Item
              label={getItemLabel("换货物流信息")}>{`${info.exchangeExpressName || ""}  ${info.exchangeExpressSerial || ""}`}</Item>
            <Item label={getItemLabel("换货时间")}>{formatTime(info.exchangeTime)}</Item>
          </>}

          {[0, 1, 2].includes(+info.type) && ![9].includes(+info.status) &&
          <Item label={getItemLabel("退款时间")}>{formatTime(info.refundTime)}</Item>}

          {[9].includes(+info.status) &&
          <>
            <Item label={getItemLabel("撤回时间")}>{formatTime(info.withdrawalTime)}</Item>
            <Item label={getItemLabel("撤回原因")}>{info.withdrawalReason}</Item>
          </>}

          <Item>
            {[0, 1, 2].includes(+info.type) && +info.status === 2 &&
            <Button type="primary" onClick={refundMoney}>确认退款</Button>}
            {+info.type === 3 && +info.status === 6 &&
            <Button type="primary" onClick={() => setShowDeliver(true)}>确认换货</Button>}
          </Item>
        </Descriptions>}
        {+info.auditStatus === 1 &&
        <Space>
          <Button type="primary" onClick={() => {
            setShowDeal(true)
          }}>处理</Button>
          <Button onClick={() => {
            setShowRefund(true)
          }}>驳回</Button>
        </Space>}
      </div>

      {/*处理弹窗*/}
      {showDeal &&
      <OrderServiceDealModal visible={showDeal} onCancel={(flag) => {
        if (flag) {
          _getData()
        }
        setShowDeal(false)
      }} info={info}/>}

      {/*物流弹窗*/}
      {showDeliver &&
      <OrderServiceDealDeliverModal visible={showDeliver}
                                    onCancel={(flag) => {
                                      if (flag) {
                                        _getData()
                                      }
                                      setShowDeliver(false)
                                    }}
                                    info={info}/>}

      {/*驳回弹窗*/}
      {showRefund &&
      <OrderServiceRefundModal visible={showRefund}
                               onCancel={refund}/>}

      {/*图片预览*/}
      {showPreview &&
      <PreviewPic visible={showPreview}
                  pic={previewPic}
                  onClose={() => setShowPreview(false)}/>}
    </>
  );
}

export default Detail;
