import axios from 'axios';
import { notification } from 'antd';
import { mainHost, DATA_CODE } from 'config';

const service = axios.create({
  baseURL: mainHost,
  timeout: 60000,
});
const downLoadMap = [
  '/balance/supply/exportInvoiceGoodsBeforeApply',
  '/balance/supply/exportOrderGoodsBeforeApply',
  '/balance/supply/exportInvoiceGoodsAfterApply',
  '/balance/supply/exportOrderGoodsAfterApply',
  '/merchant/marketing/activity/sample/export',
];

const loginMap = [
  '/login/telephoneAuth/step1',
  '/login/telephoneAuth/step2',
  '/login/telephone/step1',
  '/login/telephone/step2',
];

service.interceptors.request.use(
  (config) => {
    if (config.url === '/gift/wechat/miniapp/releaseTemplate') {
      config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
    } else {
      config.headers['Content-Type'] = 'application/json;charset=UTF-8';
    }
    if (loginMap.find((item) => config.url.endsWith(item))) {
      delete config.headers['authority_token'];
    } else {
      config.headers['authority_token'] = localStorage.getItem('token');
    }

    return config;
  },
  (err) => {
    notification.error({
      title: '发送请求失败',
      message: err,
    });
    return false;
  }
);
service.interceptors.response.use(
  (res) => {
    const { status, data, statusText, config, headers } = res;
    return new Promise((resolve, reject) => {
      if (status < 200 || status > 300) {
        notification.error({
          message: `请求错误: ${status}`,
          description: statusText,
        });
      } else if (downLoadMap.includes(config.url)) {
        return resolve({ data, headers });
      } else if (!data.success) {
        if (data.code === DATA_CODE.GOODS_CHANGE_PERMIT_BY_ACTIVITY) {
          // 变更商品在活动中
        } else {
          notification.error({
            message: '提示',
            description: data.message,
          });
          if (data.code === DATA_CODE.LOGIN_ERROR)
            window.location.href = '/login';
        }
        return resolve(data);
      } else {
        return resolve(data || true);
      }
      return reject();
    });
  },
  (err) => {
    notification.error({
      title: '提示',
      message: '系统错误',
    });
    return false;
  },
  (err) => {
    // const { status, statusText } = err.response;

    notification.error({
      title: '提示',
      message: '系统错误',
    });
    return false;
  }
);

export default service;
