import React, {useState, useEffect} from 'react';
import {Form, Input, Button, Space, Row, Col} from 'antd'
import {CheckOutlined} from '@ant-design/icons'
import styles from './index.module.scss'
import {GoodsAddCard} from 'components'

const {Item} = Form
const Open = props => {
  const [form] = Form.useForm();
  const [canUseName, setCanUseName] = useState(false)
  const [smsText, setSmsText] = useState("获取验证码")
  const [canSendSms, setCanSendSms] = useState(true)

  const checkName = () => {
    setCanUseName(!canUseName)
    // TODO:校验名字可用性
  }

  let smsCodeInterval = null;

  const validatePhone = () => {
    // TODO:校验是否填写手机号
    return true
  }
  const sendSms = () => {
    if (validatePhone()) {
      if (canSendSms) {
        setCanSendSms(false);
        let count = 10;
        setSmsText(`${count}s后重新获取`);
        smsCodeInterval = setInterval(() => {
          if (count > 0) {
            setSmsText(`${--count}s后重新获取`);
          } else {
            setSmsText(`获取验证码`);
            setCanSendSms(true);
            clearInterval(smsCodeInterval)
          }
        }, 1000)

        // TODO:请求接口获取短信
      }
    }

  }

  const save = () => {
    form.validateFields().then(values => {
      let params = {}
      // TODO:保存
    })
  }

  return (
    <GoodsAddCard title="账号信息">
      <Form form={form} labelCol={{span: 4}} wrapperCol={{span: 18}}>
        <Item label="账号名称" name="username"
              rules={[{required: true, message: "请输入账号名称"}]}>
          <Space size={20}>
            <Input allowClear style={{width: 330}}/>
            {!canUseName ?
              <Button style={{width: 150}} onClick={checkName}>可用校验</Button> :
              <div className={styles.check_success}><CheckOutlined/>账号可用</div>}

          </Space>

        </Item>
        <Item label="登录密码" name="password"
              rules={[{required: true, message: "请输入8-20位字母、数字、字符其中至少两种"},
                // {min: 8, message: "长度只能在8-20个字符之间"},
                {
                  pattern: /^((?=.*[a-zA-Z])(?=.*\d))|((?=.*[a-zA-Z])(?=.*[$@$!%*?&.]))|((?=.*\d)(?=.*[$@$!%*?&.]))[A-Za-z\d$@$!%*?&.]{8,16}$/,
                  message: "请输入8-20位字母、数字、字符其中至少两种"
                }]}>
          <Input allowClear style={{width: 500}} placeholder="8-20位字符" maxLength={20}/>
        </Item>
        <Item label="安全手机号" name="phone"
              rules={[{required: true, message: "请输入安全手机号"},
                {pattern: /^1\d{10}$/, message: "请输入正确的手机号"}]}>
          <Input allowClear style={{width: 500}} placeholder="11位手机号" maxLength={11}/>
        </Item>
        <Item label="短信验证码" name="smscode"
              rules={[{required: true, message: "请输入短信验证码"}]}>
          <Space size={20}>
            <Input allowClear style={{width: 330}} placeholder="请输入验证码"/>
            <Button style={{width: 150}}
                    type="primary"
                    disabled={!canSendSms}
                    onClick={sendSms}>{smsText}</Button>
          </Space>
        </Item>
        <Item label="供应商简称" name="abbreviation"
              rules={[{required: true, message: "请输入供应商简称"},
                {min: 3, message: "简称至少3个字符"}]}>
          <Input allowClear style={{width: 500}} placeholder="3-10个字符" maxLength={10}/>
        </Item>

        <Item>
          <Row>
            <Col offset={6} span={6}>
              <Button style={{width: 150}} type="primary" onClick={save}>保存</Button>
            </Col>
          </Row>
        </Item>
      </Form>
    </GoodsAddCard>
  );
};

export default Open;
