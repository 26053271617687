import React, {Component} from 'react'
import {Button, Row, Col, Card, Statistic, Badge, Popover} from "antd";
import {inject,observer} from 'mobx-react'

import styles from './index.module.scss'

@inject(...["financeStore"])
@observer
class FinanceOverview extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    this.props.financeStore.getOverviewData()
  }

  goToManage=()=>{
    window.location.href='/finance/center/settlement-manage'
  }


  render() {
    const cardStyle = {
      height: 150,
      display: "flex",
      alignItems: "center"
    };
    const badgeStyle={
      backgroundColor: '#faad14',
      color: '#fff',
    }
    const {overviewData}= this.props.financeStore
    return (
      <div className={styles.overview_container}>
        <Row>
          <Col span={8}>
            <Card bodyStyle={cardStyle}>
              <p>账户余额：</p>
              <Statistic value={overviewData.restAmount/100} precision={2} style={{marginRight:10}}/>
              <Popover content={
                <p>账户余额=可结算总额+即将到账总额</p>
              }>
                <Badge
                  count={"?"}
                  style={badgeStyle}

                />
              </Popover>
            </Card>
          </Col>
          <Col span={8}>
            <Card bodyStyle={cardStyle}>
              <p>可结算总额： </p>
              <Statistic value={overviewData.availableAmount/100} precision={2} style={{marginRight:10}}/>
              <Popover content={
                <p>可提现至结算账户的金额</p>
              }>
                <Badge
                  count={"?"}
                  style={badgeStyle}
                />
              </Popover>
              <Button style={{width:100,marginLeft:20}} onClick={this.goToManage}>结算管理</Button>
            </Card>
          </Col>
          <Col span={8}>
            <Card bodyStyle={cardStyle}>
              <p>即将到账总额：</p>
              <Statistic value={overviewData.amount/100} precision={2} style={{marginRight:10}}/>
              <Popover content={
                <p>订单未完成金额或账期中的订单金额</p>
              }>
                <Badge
                  count={"?"}
                  style={badgeStyle}
                />
              </Popover>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}

export default FinanceOverview;
