import { observable, action, runInAction, toJS } from "mobx";
import httpClient from "utils/httpClient";
import api from "config/api"

const { goods } = api;

class DetailStore {




  /**
   * 获取商品详情
   * @param {*} params 
   */
  @action
  getGoodsDetail = async params => {
    const res = await httpClient.post(goods.POST_GET_GOODS_DETAIL, params);
    if (res.success) {
      return res.data
    }
  }

  /**
   * 获取运费模板详情
   * @param {*} params 
   */
  @action
  getFreightDetail = async params => {
    const res = await httpClient.post(goods.POST_GET_FREIGHT_DETAIL, params);
    if (res.success) {
      return res.data
    }
  }


  // 获取区域运费
  @action
  getExpressCost = async params => {
    const res = await httpClient.post(goods.POST_GET_FREIGHT, params);
    if (res.success) {
      return res.data
    }
  }
}

export default new DetailStore();