import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Tab from './tab';
import SendSampleApply from './sendSampleApply';
import SendSampleDetail from './sendSampleApply/sendSampleDetail';
import SendSampleUserInfo from './sendSampleApply/sendSampleUserInfo';
import RebateApply from './rebateAct/rebateApply';
import RebateApplyDetail from './rebateAct/rebateApplyDetail';
import FactionList from './sendSampleApply/faction';
import FactionPresent from './sendSampleApply/faction/present';

function ActManagement(props) {
  return (
    <Switch>
      {/* <Route path="/activity/management" exact component={Tab}/> */}
      {/* 派样 */}
      {/* <Route path="/activity/sendSampleApply" exact component={SendSampleApply}/> */}
      {/* <Route path="/act/sendSampleDetail" exact component={SendSampleDetail}/>
      <Route path="/act/sendSampleUser" exact component={SendSampleUserInfo}/> */}
      <Route path="/activity/faction" exact component={FactionList} />
      <Route
        path="/activity/faction/present"
        exact
        component={FactionPresent}
      />
      {/* 返点 */}
      {/* <Route path="/activity/rebateApply" exact component={RebateApply}/>
      <Route path="/activity/rebateApplyDetail" exact component={RebateApplyDetail}/> */}
    </Switch>
  );
}

export default ActManagement;
