import React, {useState, useEffect} from 'react';
import {Table, Button, Modal, message} from 'antd'
import {ExclamationCircleOutlined} from "@ant-design/icons"
import {Modals} from 'components'
import styles from './index.module.scss'

import stores from 'stores'

const {confirm} = Modal
const {storeLocationStore} = stores
const {StoreAddAddressModal} = Modals

function RetLocation(props) {
  const [list, setList] = useState([])

  const [actionItem, setActionItem] = useState({})
  const [actionName, setActionName] = useState("add");//add | edit
  const [showAdd, setShowAdd] = useState(false)
  useEffect(() => {
    _getData();
  }, [])

  const _getData = async () => {
    const locationList = await storeLocationStore.getLocationList({type: 2})
    setList(locationList)
  }


  const setDefault = async record => {
    const res = await storeLocationStore.setDefaultLocation({id: record.id});
    if (res.success) {
      message.success("设置成功！")
      _getData();
    }
  }

  const editLocation = record => {
    setActionName("edit");
    setActionItem(record)
    setShowAdd(true)
  }

  const deleteLocation = record => {
    confirm({
      title: '删除',
      icon: <ExclamationCircleOutlined/>,
      content: '是否删除该收货地址？',
      centered: true,
      async onOk() {
        const res = await storeLocationStore.deleteLocation({id: record.id})
        if (res.success) {
          message.success("删除成功！")
          _getData();
        }
      }
    });
  }

  const columns = [
    {
      title: "设置默认",
      align: "center",
      width: "100px",
      dataIndex: 'tag',
      render: (text, record) => +text === 1 ?
        <span style={{color: "#E9967A"}}>默认地址</span> :
        <Button type="link" onClick={() => setDefault(record)}>设为默认</Button>
    }, {
      title: "地址信息",
      align: "center",
      render: (text, record) => <p style={{textAlign: "left", paddingLeft: 200, paddingRight: 200}}>
        {`联系人：${record.consignee}${record.mobileNumber ? ('，' + record.mobileNumber) : ""}${record.landlineNumber ? ('，' + record.landlineNumber) : ""}`}
        <br/>
        {`地址：${record.fullAddress}`}
      </p>
    }, {
      title: "操作",
      align: "center",
      width: "200px",
      render: (text, record) => <>
        <Button type="link" onClick={() => editLocation(record)}>编辑</Button>
        {/*<Button type="link" onClick={() => deleteLocation(record)}>删除</Button>*/}
      </>
    },]
  const renderTable = () => {
    return <Table columns={columns} dataSource={list} pagination={false} rowkey={record => record.key}/>
  }
  return (
    <>
      <div style={{lineHeight: "40px", padding: "24px"}}>
        <Button type="primary" onClick={() => {
          setShowAdd(true)
        }}>新增地址</Button>
      </div>
      {renderTable()}
      {showAdd &&
      <StoreAddAddressModal visible={showAdd}
                            onOk={() => {
                            }}
                            onCancel={type => {
                              setShowAdd(false)
                              if (type) {
                                _getData()
                              }
                            }}
                            type={actionName}
                            item={actionItem}/>}

    </>
  );
}

export default RetLocation;
