import React, {Component} from "react";
import {Icon, Button, Divider, Menu, message} from "antd";
import {inject, observer} from "mobx-react";
import qs from 'qs'
import styles from "./index.module.scss";
import {routers} from "config/route";

const {Item} = Menu

@inject("commonStore")
@observer
class TopBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showFeeModal: false,
      barType: "c",
      menuCurrent: "home"
    }
  }

  componentDidMount() {
    // const {postUserInfo} = this.props.commonStore;
    // const pathname=window.location.pathname,params=qs.parse(window.location.search.slice(1))
    // if(pathname==="/home"){
    //   if(params.token){
    //     window.localStorage.setItem("token",params.token);
    //     postUserInfo({})
    //   }else{
    //     message.error("请确认页面来自何方~")
    //   }
    // }
  }

  render() {
    const {showFeeModal, barType, menuCurrent} = this.state;
    const showBarTypeMap = {a: 1, b: 2, c: 3};

    let userInfo = localStorage.getItem("userInfo")
    let nickname = userInfo ? JSON.parse(userInfo).nickName : ""

    return (
      <div className={styles.topbar}>
        <div className={styles.topbar_container}>
          <div className={styles.topbar_left}>
            <img src="/imgs/logo_all.png" className={styles.top_logo} alt=""/>
            <span className={styles.topbar_title}>飞象供应商管理平台</span>
          </div>
          <div className={styles.topbar_right}>
            <span style={{margin: 0}}>{nickname}</span>
            <Divider type="vertical"
                     style={{
                       backgroundColor: showBarTypeMap[barType] === 3 ? "#fff" : "#ccc",
                       height: "20px"
                     }}/>
            <Button
              type="link"
              style={{padding: 0}}
              onClick={() => {
                this.props.commonStore.logout()
              }}
            >
              退出
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default TopBar;
