import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {Layout} from "antd";

import styles from './index.module.scss'

const {Sider, Content} = Layout

function GoodsAddCard(props) {
  const {title, children} = props
  return <div className={styles.goods_card_container}>
    <Layout className={styles.layout}>
      <Sider className={styles.sider} width={150}>{title}</Sider>
      <Content className={styles.content}>
        <div style={{width: 1100}}>
          {children}
        </div>
      </Content>
    </Layout>
  </div>


}

GoodsAddCard.propTypes = {
  title: PropTypes.string
}


export default GoodsAddCard
