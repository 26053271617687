import React, {useState, useEffect} from 'react';
import {Button, Table} from "antd";
import stores from 'stores'
import styles from './index.module.scss'

const {goodsResourceStore} = stores

function Resource(props) {

  const [pageNo, setPageNo] = useState(1)
  const [pageSize, setPageSize] = useState(20)
  const [dataList, setDataList] = useState({list: [], total: 0});
  const [refreshData, setRefreshData] = useState(true)
  useEffect(() => {
    _getData()
  }, [])

  useEffect(() => {
    refreshData && _getData()
    refreshData && setTimeout(() => setRefreshData(false));
  }, [refreshData])
  /**
   * 页码改变
   * @param page
   * @param pageSize
   * @returns {Promise<void>}
   */
  const changePage = (page, pageSize) => {
    setPageNo(page);
    setRefreshData(true)
  }

  /**
   * pageSize改变
   * @param current
   * @param size
   * @returns {Promise<void>}
   */
  const changePageSize = (current, size) => {
    setPageNo(1);
    setPageSize(size);
    setRefreshData(true)
  }
  const pagination = {
    total: dataList.total,
    pageSize,
    current: pageNo,
    showSizeChanger: true,
    showQuickJumper: true,
    pageSizeOptions: ["20", "50", "100"],
    defaultPageSize: 20,
    onChange: changePage,
    onShowSizeChange: changePageSize,
    showTotal: total => `共 ${total} 条数据`,
  }
  const _getData = async () => {
    let params = {
      pageNo, pageSize
    }
    let data = await goodsResourceStore.getResourceList(params)
    console.log(data)
    setDataList(data)
  }

  const columns = [
    {
      title: "商品名称", align: "center", width: 300, render: (text, record) =>
        <div className={styles.item_container}>
          <div className={styles.item_img_container}>
            <img src={record.mainImage} className={styles.item_img} alt=""/>
          </div>
          <div className={styles.item_info_container}>
            <p className={styles.item_name}>{record.name}</p>
          </div>
        </div>
    },
    {title: "品牌", align: "center", dataIndex: "brandName"},
    {title: "状态", align: "center", dataIndex: "syncStatus", render: text => !!+text ? "已同步" : "未同步"},

    {
      title: "操作", align: "center", render: (text, record) =>
        <Button type="link"
                disabled={+record.syncStatus === 1}
                onClick={() => window.open(`/goods/edit?id=${record.spuId}&type=resource`, "_self")}>上架</Button>
    }

  ]

  return (
    <div>
      <Table columns={columns} dataSource={dataList.list} pagination={pagination}/>
    </div>
  );

}

export default Resource;
