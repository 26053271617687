import moment from 'moment'
import route from "config/route"
import qs from 'qs'
import { message } from "antd";
import { REG_MAP } from './enum'


/**
 * 每个页面页头标题获取
 * @param path
 * @returns {*|string}
 */
export function getTitle(path) {
  let title;
  for (let i = 0; i < route.length; i++) {
    let router = route[i];
    if (path === router.route) {
      title = router.notShowTitle ? "" : router.title || router.name;
      break;
    } else if (router.children && router.children.length) {
      for (let j = 0; j < router.children.length; j++) {
        let child = router.children[j];
        if (path === child.route) {
          title = child.notShowTitle ? "" : child.title || child.name;
          break;
        }
      }
    }
  }
  return title;
}

/**
 * 图片上传处理图片转base64方法
 * @param file
 * @returns {Promise<any>}
 */
export function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error)
  })
}

/**
 * 笛卡尔积
 * @param arr
 * @returns {*|Array|*|(function(*, *): Array)}
 */
export function cartesianProduct(array) {
  if (array.length < 2) return array[0] || [];
  return [].reduce.call(array, function (col, set) {
    let res = [];
    col.forEach(function (c) {
      set.forEach(function (s) {
        let t = [].concat(Array.isArray(c) ? c : [c]);
        t.push(s);
        res.push(t);
      })
    });
    return res;
  });
}

/**
 * 比较对象内容是否相等
 * @param a
 * @param b
 * @returns {boolean}
 * @constructor
 */
export function objectEquals(a, b) {
  var aProps = Object.getOwnPropertyNames(a);
  var bProps = Object.getOwnPropertyNames(b);
  if (aProps.length != bProps.length) {
    return false;
  }
  for (var i = 0; i < aProps.length; i++) {
    var propName = aProps[i]
    if (propName === "key") continue;
    var propA = a[propName]
    var propB = b[propName]
    if ((typeof (propA) === 'object')) {
      if (this.isObjectValueEqual(propA, propB)) {
        // return true     这里不能return ,后面的对象还没判断
      } else {
        return false
      }
    } else if (propA !== propB) {
      return false
    }
  }
  return true
}

/**
 * 金额显示格式化
 * @param data
 * @returns {string}
 */
export function showMoney(data) {
  return (+data / 100).toFixed(2)
}

/**
 * 格式化时间
 * @param data
 * @returns {string}
 */
export function formatTime(data, fmtType = 'YYYY-MM-DD HH:mm:ss') {
  return data ? moment(data).format(fmtType) : ''
}

/**
 * 给数组列表添加key
 * @param data
 * @returns {*}
 */
export function addKey(data) {
  data.list = data.list.map((item, index) => Object.assign(item, { key: index }))
  return data
}

/**
 * 获取链接参数
 * @returns {*}
 */
export function getQuery() {
  return qs.parse(window.location.search.substring(1));
}

/**
 * 运费模板区域列表格式化
 * @param data
 * @returns {*}
 */
export function formatArea(data) {
  return data.map(item => {
    let arr = null;
    if (item.children) {
      arr = formatArea(item.children)
    }
    return {
      id: item.id,
      parentId: item.parentId,
      dictKey: item.dictKey,
      dictValue: item.dictValue,
      children: arr,
      check: false,
      freightIndex: 0
    }
  })
}


/**
 * 下载接口返回的文件流
 * @param {*} data 文件流
 * @param {*} type 文件类型
 * @param {*} fileName 下载文件名
 */
export function downloadFile(data, type = 'vnd.ms-excel', fileName = '') {
  const blob = new Blob([data], { type: `application/${type};charset=utf-8` });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = fileName || formatTime(new Date(), 'YYYYMMDDHHmmss') + '下载文件';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
}

/**
 * 校验上传文件
 * @param data
 */
export function checkFile(file, config = "default") {
  //type:img ppt pdf file
  config = config === "default" ?
    {
      type: ["img"],
      size: 2
    } :
    config

  if (!config.type.every(item => REG_MAP[item].test(file.name.toLowerCase()))) {
    message.error("请确保文件格式正确")
    return false
  }

  if (file.size > config.size * (2 ** 20)) {
    message.error("请确保文件大小！")
    return false
  }
  return true
}

/**
 * 手机号中间四位 **** 显示
 * @param num
 * @returns {string|*}
 */
export const secretPhoneNum = (num) => {
  const str = num + "";
  return /^1\d{10}$/.test(str) ?
    str.replace(/^(\d{3})\d{4}(\d{4})$/, "$1****$2") :
    str;
}

