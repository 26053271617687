import React from 'react';
import {Route, Switch} from "react-router-dom";

import Dashboard from "./Dashboard";

function DashboardModule(props) {

  return (
      <Switch>
        <Route path="/dashboard" exact component={Dashboard}/>
      </Switch>
  )
}

export default DashboardModule;
