export default [
  {
    id: 1,
    key: 'dashboard',
    name: '数据概览',
    route: '/dashboard',
    disabled: true
  },
  {
    id: 2,
    key: 'order',
    name: '订单管理',
    route: '/order',
    children: [
      // {
      //   id: 21,
      //   name: '订单列表',
      //   key: 'order_list',
      //   route: '/order/list',
      // },
       {
        id: 22,
        name: '一件代发订单列表',
        key: 'order_list_once',
        route: '/order/list/once',
      },
      {
        id: 23,
        name: '售后管理',
        key: 'order_service',
        route: '/order/service',
        // disabled:true
      },
    ],
  }, {
    id: 3,
    key: 'activity',
    name: '营销推广',
    route: '/activity',
    children: [
      // {
      //   id:31,
      //   name:'活动广场',
      //   key:'activity_management',
      //   route:'/activity/management'
      // },
      {
        id: 32,
        name: '派样商品列表',
        key: 'activity_faction',
        route: '/activity/faction',
      },
      // {
      //   id:33,
      //   name:'派样商品提报',
      //   key:'sendSampleApply',
      //   route:'/activity/faction/present'
      // },

    ],
  }, 
  {
    id: 4,
    key: 'goods',
    name: '商品管理',
    route: '/goods',
    children: [
      {
        id: 41,
        name: '发布商品',
        key: 'goods_add',
        route: '/goods/add',
      }, {
        id: 42,
        name: '集采商品',
        key: 'goods_purchase',
        route: '/goods/purchase',
        children: [
          {
            id: 421,
            name: "我的集采商品",
            key: "goods_purchase_list",
            route: '/goods/purchase/list',
          }, {
            id: 422,
            name: "变更审核记录",
            key: "goods_purchase_modify",
            route: '/goods/purchase/modify',
          },
        ]
      }, {
        id: 43,
        name: '代发商品',
        key: 'goods_once',
        route: '/goods/once',
        children: [
          {
            id: 431,
            name: "我的代发商品",
            key: "goods_once_list",
            route: '/goods/once/list',
          }, {
            id: 432,
            name: "变更审核记录",
            key: "goods_once_modify",
            route: '/goods/once/modify',
          },
        ]
      }, {
        id: 44,
        name: '渠道商品',
        key: 'goods_self',
        route: '/goods/self',
      },
      // {
      //   id: 45,
      //   name: '商品资源',
      //   key: 'goods_resource',
      //   route: '/goods/resource',
      // },
    ],
  }, {
    id: 5,
    key: 'finance',
    name: '财务管理',
    route: '/finance',
    children: [
      {
        id: 51,
        name: '结算中心',
        key: 'finance_center',
        route: '/finance/center',
      },
      {
        id: 52,
        name: '交易明细',
        key: 'finance_detail',
        route: '/finance/detail',
      },
      {
        id: 53,
        name: '结算账户管理',
        key: 'finance_account',
        route: '/finance/account',
      },
    ],
  }, {
    id: 6,
    key: 'store',
    name: '商铺管理',
    route: '/store',
    children: [
      {
        id: 61,
        name: '商铺信息',
        key: 'store_info',
        route: '/store/info',
      }, {
        id: 62,
        name: '商铺设置',
        key: 'store_setting',
        route: '/store/setting',
        disabled: true
      },
    ],
  },
  {
    id: 7,
    key: "supply",
    name: "供应商管理",
    route: "/supply",
    disabled: true,
    children: [
      {
        id: 71,
        name: '开通供应商',
        key: 'supply_open',
        route: '/supply/open',
      }, {
        id: 72,
        name: '我的供应商',
        key: 'supply_manage',
        route: '/supply/manage',
      },
    ]
  }, {
    id: 8,
    name: "设置",
    key: "setting",
    route: "/setting",
    children: [
      {
        id: 81,
        name: "运费模板设置",
        key: "setting_freight",
        route: "/setting/freight",
      }, {
        id: 82,
        name: "地址设置",
        key: "setting_location",
        route: "/setting/location",
      },

    ]
  }
];
