import React, { Component } from 'react';
import {
  Modal,
  Cascader,
  Select,
  Input,
  Button,
  Table,
  Divider,
  message,
} from 'antd';
import { inject, observer } from 'mobx-react';

import RangeInput from '../../RangeInput';
import { showMoney } from 'utils/tools';
import styles from './index.module.scss';

/**
 * @props visible:Boolean
 *
 * @props value:String
 * @props onChange:Function
 */
@inject(...['supplierStore'])
@observer
class PickGoods extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categoryData: [],
      categoryVal: [],
      rangePrice: [],
      rangePriceBool: true,
      rangeProfit: [],
      rangeProfitBool: true,
      keyword: '',
      selectedRowKeys: [],
      selectedRows: [],

      sortInfo: {},
      orderName: '',
      orderWay: '',
      pageNo: 1,
      pageSize: 20,
    };
  }

  async componentDidMount() {
    const { postGoodsCategoryList } = this.props.supplierStore;

    //请求一级分类数据
    let categoryList = await postGoodsCategoryList({ parentId: 0 });
    this.setState({
      categoryData: categoryList.map((item) => ({
        value: item.id,
        label: item.name,
        isLeaf: false,
      })),
    });
    this.getData();
  }

  getCateData = async (selectedOptions) => {
    const { postGoodsCategoryList } = this.props.supplierStore;
    const targetOption = selectedOptions[selectedOptions.length - 1];
    targetOption.loading = true;
    console.log('targetOption====', targetOption);
    let categoryList = await postGoodsCategoryList({
      parentId: targetOption.value,
    });
    targetOption.children = categoryList.map((item) => ({
      value: item.id,
      label: item.name,
      isLeaf: +item.level === 1 ? false : true,
    }));
    targetOption.loading = false;
    this.setState({
      categoryData: [...this.state.categoryData],
    });
  };

  changeCategory = (value, selectedOptions) => {
    this.setState({
      categoryVal: value,
    });
    // console.log(value, '===');
    // if (value.length === 2) {
    //   this.setState({
    //     categoryVal: value,
    //   });
    // }
  };

  /**
   * 获取列表数据
   */
  getData = () => {
    const {
      categoryVal,
      rangePrice,
      rangePriceBool,
      rangeProfit,
      rangeProfitBool,
      pageNo,
      pageSize,
      keyword,
      orderName,
      orderWay,
    } = this.state;
    const { activityId } = this.props;
    const { getPickGoodsList } = this.props.supplierStore;
    let params = {
      activityId,
      platformStatus: 10,
      status: 10,
      upPlatform: 10,
      categoryId: categoryVal.length
        ? categoryVal[categoryVal.length - 1]
        : undefined,
      supplyPriceMin: rangePrice[0] ? rangePrice[0] * 1e2 : undefined,
      supplyPriceMax: rangePrice[1] ? rangePrice[1] * 1e2 : undefined,
      profitMin: rangeProfit[0] || undefined,
      profitMax: rangeProfit[1] || undefined,
      keyword,
      orderName,
      orderWay,
      pageNo,
      pageSize,
    };
    getPickGoodsList(params);
    this.setState({
      selectedRows: [],
    });
  };

  /**
   * 渲染表格
   * @returns {*}
   */
  renderTable = () => {
    const { pageNo, pageSize, sortInfo } = this.state;
    const { pickGoodsList } = this.props.supplierStore;
    const columns = [
        {
          title: '商品',
          align: 'center',
          dataIndex: 'name',
          render: (text, record, index) => (
            <div className={styles.name_container}>
              <div className={styles.img_container}>
                <img src={record.mainImage} alt="" />
              </div>
              <p className={styles.name}>{text}</p>
            </div>
          ),
        },
        {
          title: '市场价',
          align: 'center',
          dataIndex: 'marketPriceMin',
          sorter: true,
          sortOrder:
            (sortInfo.field === 'marketPriceMin' && sortInfo.order) || false,
          render: (text, record, index) => `¥${showMoney(text)}`,
        },
        {
          title: '供应价',
          align: 'center',
          dataIndex: 'sellStairList',
          sorter: true,
          sortOrder:
            (sortInfo.field === 'sellStairList' && sortInfo.order) || false,
          render: (text, record, index) => {
            if (record.salesType === 2) {
              return showMoney(record.supplyPriceMin);
            } else {
              let arr = record.sellStairList.map((item, index, arr) => {
                if (!item.price || !item.minNum) {
                  return '';
                }
                if (arr.length - 1 === index) {
                  return `¥${showMoney(item.price)}(≥${item.minNum}${
                    record.unit || '个'
                  })`;
                } else if (item.minNum === item.maxNum) {
                  return `¥${showMoney(item.price)}(${item.minNum}${
                    record.unit || '个'
                  })`;
                } else {
                  return `¥${showMoney(item.price)}(${item.minNum}-${
                    item.maxNum
                  }${record.unit || '个'})`;
                }
              });
              return (
                <p dangerouslySetInnerHTML={{ __html: arr.join('<br/>') }} />
              );
            }
          },
        },
        {
          title: '利润率',
          align: 'center',
          dataIndex: 'profitMin',
          sorter: true,
          sortOrder:
            (sortInfo.field === 'profitMin' && sortInfo.order) || false,
          render: (text, record, index) => {
            if (record.salesType === 2) {
              return `${record.profitMin.toFixed(2)}%`;
            } else {
              let arr = record.sellStairList.map((item, index, arr) => {
                return `${item.profit.toFixed(2)}%`;
              });
              return (
                <p dangerouslySetInnerHTML={{ __html: arr.join('<br/>') }} />
              );
            }
          },
        },
        {
          title: '库存',
          align: 'center',
          dataIndex: 'stock',
          render: (text, record, index) => text,
        },
        {
          title: '可报名状态',
          align: 'center',
          dataIndex: 'registrationStatus',
          render: (text, record) => (+text === 0 ? '可报名' : '不可报名'),
        },
      ],
      pagination = {
        size: 'small',
        total: pickGoodsList.total,
        current: pageNo,
        pageSize,
        onChange: this.changePagination,
        showTotal: (total) => `共${total}条数据`,
      },
      rowSelection = {
        onChange: this.selectRows,
        columnWidth: 40,
        selectedRowKeys: this.state.selectedRowKeys,
        getCheckboxProps: (record) => ({
          disabled:
            this.props.value.includes(record.goodsId) ||
            +record.registrationStatus !== 0, // Column configuration not to be checked
          name: record.name,
        }),
      };
    console.log(this.props.value, '===');

    return (
      <Table
        size="small"
        columns={columns}
        pagination={pagination}
        rowSelection={rowSelection}
        dataSource={pickGoodsList.list}
        rowKey={'goodsId'}
        onChange={this.tableChange}
      />
    );
  };
  /**
   * 表格选择项
   * @param selectedRowKeys
   * @param selectedRows
   */
  selectRows = (selectedRowKeys, selectedRows) => {
    this.setState({
      selectedRowKeys,
      selectedRows,
    });
  };

  /**
   * 页码跳转
   * @param page
   */
  changePagination = (page) => {
    this.setState(
      {
        pageNo: page,
      },
      this.getData
    );
  };

  tableChange = (pagination, filters, sorter) => {
    console.log(sorter);
    if (sorter.field) {
      if (sorter.column) {
        const orderNameMap = {
          marketPriceMin: 'search_market_price_min',
          sellStairList: 'search_supply_price_min',
          profitMin: 'search_profit_min',
        };
        const orderMap = {
          ascend: 'asc',
          descend: 'desc',
        };
        this.setState(
          {
            sortInfo: sorter,
            orderName: orderNameMap[sorter.field],
            orderWay: orderMap[sorter.order] || '',
            pageNo: 1,
          },
          () => {
            this.getData();
          }
        );
      } else {
        this.setState(
          {
            sortInfo: {},
            orderName: '',
            orderWay: '',
          },
          () => {
            this.getData();
          }
        );
      }
    }
  };

  /**
   * 关闭弹窗
   * @param type
   */
  cancel = (type) => {
    this.props.onCancel(type);
  };

  /**
   * 确定选择
   */
  ok = () => {
    const { selectedRows } = this.state;
    this.props.onChange(selectedRows);
    this.cancel('ok');
  };

  search = () => {
    const { rangePriceBool, rangeProfitBool } = this.state;
    if (!rangePriceBool || !rangeProfitBool) {
      message.error('请输入正确的筛选信息！');
      return;
    }
    this.setState(
      {
        pageNo: 1,
      },
      this.getData
    );
  };

  reset = () => {
    this.setState(
      {
        categoryVal: [],
        rangePrice: [],
        rangePriceBool: true,
        rangeProfit: [],
        rangeProfitBool: true,
        keyword: '',
        selectedRows: [],
        sortInfo: {},
        orderName: '',
        orderWay: '',
        pageNo: 1,
      },
      this.getData
    );
  };

  render() {
    const {
      categoryData,
      categoryVal,
      rangePrice,
      rangePriceBool,
      rangeProfit,
      rangeProfitBool,
      keyword,
      selectedRows,
    } = this.state;
    const { visible } = this.props;
    return (
      <Modal
        title="选择商品"
        visible={visible}
        footer={false}
        width={1012}
        centered={true}
        bodyStyle={{ padding: 0 }}
        onCancel={this.cancel}
      >
        <div className={styles.modal_content}>
          <div className={styles.screen_container}>
            <div className={styles.screen_line}>
              <Cascader
                options={categoryData}
                loadData={this.getCateData}
                onChange={this.changeCategory}
                placeholder="分类"
                value={categoryVal}
                changeOnSelect
                size="small"
                style={{
                  marginRight: 16,
                  minWidth: '100px',
                }}
              />
              <RangeInput
                name="供应价"
                addonAfter="¥"
                divider="至"
                value={rangePrice}
                onChange={(vals, isOk) =>
                  this.setState({
                    rangePrice: vals,
                    rangePriceBool: isOk,
                  })
                }
                size="small"
                style={{ width: 130 }}
              />
              <div style={{ width: 16, height: 32 }} />
              <RangeInput
                name="利润率"
                addonAfter="%"
                divider="至"
                value={rangeProfit}
                onChange={(vals, isOk) =>
                  this.setState({
                    rangeProfit: vals,
                    rangeProfitBool: isOk,
                  })
                }
                size="small"
                style={{ width: 130 }}
              />
              <Input
                style={{ width: 150, marginLeft: 16 }}
                placeholder="请输入商品名称"
                value={keyword}
                size="small"
                onChange={(e) =>
                  this.setState({ keyword: e.target.value.trim() })
                }
              />
              <Button
                type="primary"
                size="small"
                style={{ width: 100, marginRight: 16 }}
                onClick={this.search}
              >
                搜索
              </Button>
              <Button style={{ width: 100 }} size="small" onClick={this.reset}>
                重置搜索
              </Button>
            </div>
          </div>

          <div className={styles.table_container}>{this.renderTable()}</div>
        </div>
        <Divider />
        <div className={styles.modal_footer}>
          <div className={styles.left}>已选择：{selectedRows.length}件</div>
          <div className={styles.right}>
            <Button onClick={this.cancel} style={{ marginRight: 16 }}>
              取消
            </Button>
            <Button type="primary" onClick={this.ok}>
              确定
            </Button>
          </div>
        </div>
      </Modal>
    );
  }
}

/**
 * @props visible:Boolean
 *
 * @props value:String
 * @props onChange:Function
 */
// PickGoods.propTypes = {
//   visible: Modal.propTypes.visible,
//   onCancel: Modal.propTypes.onCancel,
//   // activityId: PropTypes.string || PropTypes.number
// }

export default PickGoods;
