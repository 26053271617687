import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom'
import { Button, Modal, Card, Row, Col, Space, message } from 'antd'

import { formatTime, showMoney } from "utils/tools";
import stores from 'stores'
import styles from './index.module.scss'

const { confirm, warning, info } = Modal;
const { storeFreightStore, storeLocationStore } = stores

const deliveryTypeMap = new Map([
  [1, "快递"],
  [2, "物流"],
  [3, "运费可议"],
])

function Freight(props) {
  const [dataList, setDataList] = useState([])

  useEffect(() => {
    _getData();
    localStorage.removeItem("freightArea");
  }, [])

  const _getData = async () => {
    const res = await storeFreightStore.getFreightList({})
    setDataList(res)
  }

  const goToAdd = async () => {
    const res = await storeLocationStore.getDefaultLocation({ type: 2 })
    if (res) {
      props.history.push({
        pathname: "/setting/freight/add"
      })
    } else {
      confirm({
        title: '您还没有完善店铺地址，请先添加',
        okText: "去添加",
        centered: true,
        onOk() {
          props.history.push({
            pathname: "/setting/location"
          })
        }
      })
    }
  }

  return (
    <div className={styles.freight_temp_list_container}>
      <div className={styles.add_line_container}>
        <Button type="primary" disabled={dataList.length >= 10} onClick={goToAdd}>新建运费模板</Button>
        {dataList.length >= 10 && <span className={styles.add_line_tip}>最多可添加10个运费模板</span>}

      </div>
      <div className={styles.temp_list_container}>
        {dataList.map(item =>
          <FreightCard key={item.key}
            item={item}
            onChange={() => _getData()} />)}
      </div>
    </div>
  );
}


const FreightCard = withRouter(function (props) {
  const { item, onChange } = props;

  const editFreightTemp = () => {
    window.open(`/setting/freight/add?id=${item.freightTemplateId}`, "_self")
    // props.history.push({
    //   pathname: `/setting/freight/add?id=${item.freightTemplateId}`,
    // })
  }

  const goToGoodsList = () => {
    // TODO: 跳转到运费模板对应的商品列表
  }

  const showDelConfirm = () => {
    if (!!item.goodsNumber) {
      warning({
        title: '删除失败',
        content: '运费模版正在被使用，无法删除！',
      });
    } else {
      confirm({
        title: '确认删除运费模板？',
        // content: 'Some descriptions',
        async onOk() {
          const res = await storeFreightStore.deleteFreight({ templateId: item.freightTemplateId })
          if (res.success) {
            message.success("删除成功！")
            onChange()
          }
        },
      });
    }

  }

  return <Card bodyStyle={{ paddingTop: 0, paddingBottom: 10 }} style={{ marginBottom: 20 }}>
    <div className={styles.temp_container}>
      <div className={styles.temp_head_container}>
        <div className={styles.head_info}>
          <p className={styles.temp_name}>{item.templateName}</p>
          <p className={styles.temp_from}>(发货地：{`${item.provinceName} ${item.cityName} ${item.districtName}`})</p>
        </div>
        <Space>
          {!!item.goodsNumber && <p className={styles.temp_used}>(已被{item.goodsNumber}个商品使用)</p>}
          <p className={styles.temp_time}>最后编辑时间：{formatTime(item.modifyTime)}</p>
          <Button type="link" disabled={true} onClick={goToGoodsList}>查看商品</Button>
          <Button type="link" onClick={editFreightTemp}>编辑</Button>
          <Button type="link" onClick={showDelConfirm}>删除</Button>
        </Space>
      </div>
      <div className={styles.temp_content_container}>
        <Row className={styles.temp_content_title}>
          <Col span={5}>运送方式</Col>
          <Col span={5}>运送至</Col>
          <Col span={9}>费率({item.isFreeShipping ? "卖家承担运费" : `按${getUnit(item.tradeType, true)}计费`})</Col>
          <Col span={5}>不可配送区域</Col>
        </Row>
        {item.rules.map((itemIn, indexIn) =>
          <Row className={styles.temp_content_info} key={indexIn}>
            <Col span={5}>{indexIn === 0 && item.deliveryType && item.deliveryType.split(",").map(i => deliveryTypeMap.get(+i)).join(",")}</Col>
            <Col span={5}>{itemIn.isDefault === 1 || itemIn.isDefault === null ?
              "全国" :
              itemIn.provinceNames}</Col>

            <Col span={9}>{item.isFreeShipping ?
              "包邮" :
              `${item.tradeType === 1 ?
                itemIn.firstNumber :
                itemIn.firstNumber / 1e6}${getUnit(item.tradeType)}以内${showMoney(itemIn.firstPrice)}元；每增加${item.tradeType === 1 ?
                  itemIn.nextNumber :
                  itemIn.nextNumber / 1e6}${getUnit(item.tradeType)}，增加运费${showMoney(itemIn.nextPrice)}元`}</Col>
            <Col span={5}>{indexIn === 0 && (item.noDeliveryRegionNames||"无")}</Col>
          </Row>
        )}
      </div>
    </div>
  </Card>
})

const getUnit = (type, unitType) => type === 1 ? '件' : unitType ? '重' : '公斤'

export default withRouter(Freight);
