import React, {useState, useEffect} from 'react'
import {Modal, Steps} from 'antd'
import PropTypes from 'prop-types'
import styles from './index.module.scss'
import stores from "stores"

const {Step} = Steps
const {commonStore} = stores

/**
 * 物流信息弹窗
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function ExpressInfo(props) {
  const {expressNum, visible, onOk, onCancel} = props;

  const [expressDetail, setExpressDetail] = useState({expressInfo: [], expressNo: "", expressName: ""})
  useEffect(() => {
    _getExpressDetail();
  }, [expressNum])

  const _getExpressDetail = async () => {
    let params = {
      expressNo: expressNum
    }
    const detail = await commonStore.getExpressInfo(params)
    setExpressDetail(detail)
  }
  return <Modal title="物流信息"
                visible={visible}
                width={500}
                onCancel={onCancel}
                centered
                footer={false}>
    <div className={styles.express_info_container}>
      <div className={styles.express_basic_info_container}>
        <span>{`快递公司： ${expressDetail.expressName || ""}`}</span>
        <span>{`快递单号： ${expressDetail.expressNo || ""}`}</span>
      </div>

      <div className={styles.express_steps_container}>
        <Steps progressDot={true} current={0} direction="vertical">
          {expressDetail.expressInfo ? (expressDetail.expressInfo || []).map((item, index) =>
            <Step key={index} description={`${item.time} ${item.context}`}/>
          ) : <p>暂无物流信息</p>}
        </Steps>
      </div>
    </div>
  </Modal>
}

ExpressInfo.propTypes = {
  expressNum: PropTypes.number || PropTypes.string,
  visible: PropTypes.bool.isRequired,
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
}

export default ExpressInfo;
