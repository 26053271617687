import commonStore from './common'
import localStore from './local'
import activityStore from './actManagement'
import supplierStore from './supplier'
import financeStore from './finance'
import goodsStore from './goods'
import storeStore from './store'
import orderStore from './order'


// const {storeLocationStore} = storeStore
export default {
  localStore,
  commonStore,
  activityStore,
  supplierStore,
  financeStore,
  // goods
  ...goodsStore,
  // store
  ...storeStore,
  //order
  ...orderStore

}
