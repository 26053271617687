import { observable, action } from 'mobx';
import httpClient from 'utils/httpClient';
import api from 'config/api';
import { addKey } from 'utils/tools';

const { order } = api;

class OrderInfoStore {
  @observable orderList = { list: [], total: 0 };
  @observable orderDetail = {
    orderGoodsList: [],
    orderStatusCycleList: [],
  };
  @observable modifyPriceInfo = {};
  @observable deliverInfo = {};

  /**
   * 获取订单列表
   * @param params
   * @returns {Promise<{total: number, list: []}>}
   */
  @action
  getOrderList = async (params) => {
    const res = await httpClient.post(order.POST_GET_ORDER_LIST, params);
    if (res.success) {
      this.orderList = addKey(res.data);
    }
    return this.orderList;
  };

  /**
   * 获取订单详情
   * @param params
   * @returns {Promise<{}>}
   */
  @action
  getOrderDetail = async (params) => {
    const res = await httpClient.get(order.GET_ORDER_DETAIL, { params });
    if (res.success) {
      this.orderDetail = res.data;
    }
    return this.orderDetail;
  };

  /**
   * 获取改价商品列表
   * @param params
   * @returns {Promise<{}>}
   */
  @action
  getModifyPriceInfo = async (params) => {
    const res = await httpClient.get(order.GET_MODIFY_PRICE_INFO, { params });
    if (res.success) {
      this.modifyPriceInfo = res.data;
    }
    return this.modifyPriceInfo;
  };

  /**
   * 改价
   * @param params
   * @returns {Promise<AxiosResponse<any>>}
   */
  @action
  modifyPrice = async (params) => {
    const res = await httpClient.post(order.POST_MODIFY_PRICE, params);
    return res;
  };

  /**
   * 发货
   * @param params
   * @returns {Promise<AxiosResponse<any>>}
   */
  @action
  deliverGoods = async (params) => {
    const res = await httpClient.post(order.POST_DELIVER_GOODS, params);
    return res;
  };
  /**
   * 发货
   * @param params
   * @returns {Promise<AxiosResponse<any>>}
   */
  @action
  deliverOnceGoods = async (params) => {
    const res = await httpClient.post(order.POST_DELIVER_ONCE_GOODS, params);
    return res;
  };

  /**
   * 查询发货信息
   * @param params
   * @returns {Promise<{}>}
   */
  @action
  getDeliverInfo = async (params) => {
    const res = await httpClient.get(order.GET_DELIVER_INFO, { params });
    if (res.success) {
      this.deliverInfo = res.data;
    }
    return this.deliverInfo;
  };

  /**
   * 查询一件代发发货信息
   * @param params
   * @returns {Promise<{}>}
   */
  @action
  getOnceDeliverInfo = async (params) => {
    const res = await httpClient.get(order.GET_ONCE_DELIVER_INFO, { params });
    if (res.success) {
      this.deliverInfo = res.data;
    }
    return this.deliverInfo;
  };

  /**
   * 获取一件代发订单详情
   * @param params
   * @returns {Promise<{}>}
   */
  @action
  getOnceOrderDetail = async (params) => {
    const res = await httpClient.get(order.GET_ONCE_ORDER_DETAIL, { params });
    if (res.success) {
      this.orderDetail = res.data;
    }
    return this.orderDetail;
  };
}

export default new OrderInfoStore();
