import React, {useEffect, useState} from 'react';
import {Button, Table, Input, Space} from "antd";
import styles from './index.module.scss'

const List = props => {

  const [keyword, setKeyword] = useState(undefined)
  const [pageNo, setPageNo] = useState(1)
  const [pageSize, setPageSize] = useState(20)
  const [dataList, setDataList] = useState({list: [], total: 0});
  const [refreshData, setRefreshData] = useState(true)
  const [refresh, setRefresh] = useState(true)
  useEffect(() => {
    refreshData && _getData()
    refreshData && setTimeout(() => setRefreshData(false));
  }, [refreshData])
  useEffect(() => {
    refresh && setTimeout(() => setRefresh(false));
  }, [refresh])

  const columns = [
    {title: '供应商账号/ID'},
    {title: '供应商简称'},
    {title: '已发布商品数'},
    {title: '已上架商品数'},
    {title: '开通时间'},
    {title: '最后登录时间'},
    {title: '状态'},
    {title: '操作'},
  ]

  /**
   * 页码改变
   * @param page
   * @param pageSize
   * @returns {Promise<void>}
   */
  const changePage = (page, pageSize) => {
    setPageNo(page);
    setRefreshData(true)
  }

  /**
   * pageSize改变
   * @param current
   * @param size
   * @returns {Promise<void>}
   */
  const changePageSize = (current, size) => {
    setPageNo(1);
    setPageSize(size);
    setRefreshData(true)
  }
  const pagination = {
    total: dataList.total,
    pageSize,
    current: pageNo,
    showSizeChanger: true,
    showQuickJumper: true,
    pageSizeOptions: ["20", "50", "100"],
    defaultPageSize: 20,
    onChange: changePage,
    onShowSizeChange: changePageSize,
    showTotal: total => `共 ${total} 条数据`,
  }

  // 获取子供应商列表
  const _getData = async () => {
    let params = {
      pageNo,
      pageSize,
      keyword
    }
    console.log(params)
    // TODO:请求子供应商列表
    setDataList({list: [], total: 0})
  }

  const search = () => {
    setPageNo(1)
    setRefreshData(true)
  }
  return (
    <div>
      <div className={styles.open_line}>
        <Button type="primary" size="large" style={{width: 150}}
                onClick={() => window.open(`/supply/open`, "_self")}>开通供应商</Button>
        {/*TODO:复制地址*/}
      </div>
      <div className={styles.search_line}>
        <Space>
          <Input allowClear
                 style={{width: 200}}
                 placeholder="输入关键字搜索"
                 value={keyword}
                 onChange={e => setKeyword(e.target.value.trim())}/>
          <Button onClick={search}>查询</Button>
        </Space>
      </div>
      <div className={styles.table_container}>
        <Table columns={columns}
               dataSource={dataList.list}
               pagination={pagination}
               rowKey={record => record.key}/>
      </div>

    </div>
  );
};


export default List;
