import React, { useState, useEffect, useRef } from 'react';
import { Button, Upload } from 'antd';
import BraftEditor from 'braft-editor';
import { ContentUtils } from 'braft-utils';
import 'braft-editor/dist/index.css';
import commonStore from 'stores/common';

function Editor({ onChange, value }) {
  const [editorVal, setEditorVal] = useState(
    BraftEditor.createEditorState(null)
  );

  const [uploadPicList, setUploadPicList] = useState([]);

  const editorRef = useRef();
  const isFirstRef = useRef(true);
  useEffect(() => {
    if (isFirstRef.current) {
      if (value != '<p></p>' && value) {
        setEditorVal(BraftEditor.createEditorState(value));
        isFirstRef.current = false;
      }
    }
    // console.log('gggg', value);
    // ContentUtils.clear(editorVal);

    // ContentUtils.insertHTML(editorVal, value);
    // setEditorVal(editorVal);
  }, [value]);

  useEffect(() => {
    const html = editorVal.toHTML();
    onChange(html);
  }, [editorVal]);

  useEffect(() => {
    console.log(uploadPicList, '=====list');
    uploadPicList.length &&
      setEditorVal(ContentUtils.insertMedias(editorVal, uploadPicList));
  }, [uploadPicList]);

  const handleChange = (editorVal) => {
    setEditorVal(editorVal);
  };

  const uploadHandler = async (param) => {
    console.log(param);
    if (!param.file) {
      return false;
    }
    const fileForm = new FormData();
    fileForm.append('files', param.file);
    const res = await commonStore.postUploadFiles(fileForm);
    setEditorVal(
      ContentUtils.insertMedias(editorVal, [
        {
          type: 'IMAGE',
          url: res[0],
        },
      ])
    );
    // editorState: ContentUtils.insertMedias(editorVal, [{
    //   type: 'IMAGE',
    //   url: URL.createObjectURL
    // }])
    //
  };
  const isClick = useRef(false);
  const changeUpload = async (file, fileList) => {
    if (!isClick.current) {
      isClick.current = true;
      const fileForm = new FormData();
      fileList.forEach((item) => {
        fileForm.append('files', item);
      });
      // fileForm.append('files', file);
      let res = await commonStore.postUploadFiles(fileForm);
      isClick.current = false;
      res = res.reverse();
      const result = [];
      res.forEach((item) => {
        result.unshift({ type: 'IMAGE', url: item });
      });
      setUploadPicList(result);
      return false;
    } else {
      return false;
    }
  };
  const extendControls = [
    {
      key: 'antd-uploader',
      type: 'component',
      component: (
        <Upload
          accept="image/*"
          showUploadList={false}
          multiple={true}
          beforeUpload={changeUpload}
          // onChange={changeUpload}
          // customRequest={changeUpload}
        >
          <Button type="link" style={{ padding: '3px 8px', height: '42px' }}>
            插入图片
          </Button>
          {/*</button>*/}
        </Upload>
      ),
    },
  ];

  const media = {
    accepts: {
      image: 'image/png,image/jpeg,image/gif,image/webp,image/apng,image/svg',
      video: 'video/mp4',
      audio: false,
    },
    uploadFn: async ({ file, success, error, progress }) => {
      const fileForm = new FormData();
      fileForm.append('files', file);

      const res = await commonStore.postUploadFiles(fileForm);
      success({
        url: res[0],
      });
    },
    // validateFn: (file)={}
  };
  const imageControls = [
    'float-left', // 设置图片左浮动
    'float-right', // 设置图片右浮动
    'align-left', // 设置图片居左
    'align-center', // 设置图片居中
    'align-right', // 设置图片居右
    'link', // 设置图片超链接
    'size', // 设置图片尺寸
    'remove', // 删除图片
  ];

  return (
    <div style={{ width: 780, border: '1px solid #eee' }}>
      <BraftEditor
        value={editorVal}
        onChange={handleChange}
        ref={editorRef}
        media={media}
        imageControls={imageControls}
        extendControls={extendControls}
      />
    </div>
  );
}

export default Editor;
