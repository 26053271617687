import {action, observable} from 'mobx'

class LocalStore {
  //变更商品原因
  @observable goodsModifyReason = undefined
  @action
  setGoodsModifyReason = (val) => {
    this.goodsModifyReason = val
    localStorage.setItem("modifyReason", val)
  }
  @action
  getGoodsModifyReason = () => {
    return this.goodsModifyReason || localStorage.getItem("modifyReason")
  }
  @action
  deleteGoodsModifyReason = () => {
    this.goodsModifyReason = undefined;
    localStorage.removeItem("modifyReason")
  }


}

export default new LocalStore();
