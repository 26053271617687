import React, { useEffect, useState } from 'react';
import {
  Card,
  Space,
  Button,
  Row,
  Col,
  DatePicker,
  Select,
  Input,
  Pagination,
  Table,
} from 'antd';
import styles from './index.module.scss';
import { ButtonGroupRadio, Modals } from 'components';
import { formatTime, showMoney } from 'utils/tools';
import lodash from 'lodash';
import stores from 'stores';

const { RangePicker } = DatePicker;
const { Option } = Select;
const { OrderChangeMoneyModal, OrderGoodsDeliverModal } = Modals;
const { orderInfoStore } = stores;
const searchTypeMap = new Map([
  [1, '订单编号'],
  [2, '收货人姓名'],
  [3, '收货人手机号'],
  // [4, '物流单号'],
  [5, '商品名称(模糊)'],
]);

// 1-新单，
// 2-提交审批，
// 3-待审批，
// 4-待付款，
//
// 5-待发货，
// 6-已发货，
// 7-部分发货，
// 8-已关闭，
// 9-待接口确认，
// 10-已完成，
//
// 11-已取消，
// 12-待会员支付  待发货

const orderStatusSearchMap = new Map([
  [5, '待发货'],
  [7, '部分发货'],
  [6, '已发货'],
  [10, '已完成'],
  [8, '已关闭'],
]);

const orderStatusMap = new Map([
  [5, '待发货'],
  [6, '已发货'],
  [7, '部分发货'],
  [8, '已关闭'],
  [9, '待接口确认'],
  [10, '已完成'],
]);
const expressStatusMap = new Map([
  [1, '待发货'],
  [2, '已发货'],
]);
const getSearchItemLabel = (str) => (
  <p className={styles.search_label}>{str}</p>
);

const ListSelf = () => {
  const stateList = [
    { label: '全部订单', value: 0 },
    { label: '待发货', value: 1 },
    { label: '已发货', value: 2 },
    { label: '已完成', value: 3 },
    { label: '已关闭', value: 4 },
  ];

  const stateTabMap = new Map([
    [0, [5, 6, 7, 8, 9, 10]],
    [1, [5, 7]],
    [2, [6]],
    [3, [10]],
    [4, [8]],
  ]);

  const widthArr = [300, 200, 200, 200, 200, 200, 200];
  const columns = [
    { title: '商品名称', align: 'center', width: widthArr[0] },
    { title: '供应价', align: 'center', width: widthArr[1] },
    { title: '数量', align: 'center', width: widthArr[2] },
    { title: '售后', align: 'center', width: widthArr[3] },
    // {title: "收货人", align: "center", width: widthArr[4]},
    { title: '订单总价', align: 'center', width: widthArr[5] },
    { title: '状态', align: 'center', width: widthArr[6] },
    { title: '操作', align: 'center', width: widthArr[7] },
  ];

  const [tab, setTab] = useState(0);
  const [searchSpread, setSearchSpread] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(20);

  const [createTime, setCreateTime] = useState([]);
  const [searchType, setSearchType] = useState(undefined);
  const [keyword, setKeyword] = useState(undefined);
  const [orderStatus, setOrderStatus] = useState(undefined);
  const [completeTime, setCompleteTime] = useState([]);

  const [dataList, setDataList] = useState({ list: [], total: 0 });
  const [refreshData, setRefreshData] = useState(true);

  const [showChangeMoney, setShowChangeMoney] = useState(false);
  const [showGoodsDeliver, setShowGoodsDeliver] = useState(false);
  const [actionItem, setActionItem] = useState({});
  useEffect(() => {
    refreshData && _getData();
    refreshData && setTimeout(() => setRefreshData(false));
  }, [refreshData]);

  /**
   * 页码改变
   * @param page
   * @param pageSize
   * @returns {Promise<void>}
   */
  const changePage = (page, pageSize) => {
    setPageNo(page);
    setRefreshData(true);
  };

  /**
   * pageSize改变
   * @param current
   * @param size
   * @returns {Promise<void>}
   */
  const changePageSize = (current, size) => {
    setPageNo(1);
    setPageSize(size);
    setRefreshData(true);
  };
  const pagination = {
    total: dataList.total,
    pageSize,
    current: pageNo,
    showSizeChanger: true,
    showQuickJumper: true,
    pageSizeOptions: ['20', '50', '100'],
    defaultPageSize: 20,
    onChange: changePage,
    onShowSizeChange: changePageSize,
    showTotal: (total) => `共 ${total} 条数据`,
  };

  const _getData = async () => {
    let params = {
      orderStatusList:
        +tab || (!+tab && !orderStatus) ? stateTabMap.get(tab) : [orderStatus],
      pageNo,
      pageSize,
      startTime: createTime[0]
        ? createTime[0].format('YYYY-MM-DD') + ' 00:00:00'
        : undefined,
      endTime: createTime[1]
        ? createTime[1].format('YYYY-MM-DD') + ' 23:59:59'
        : undefined,
      parentOrderNo: +searchType === 1 ? keyword : undefined,
      consignee: +searchType === 2 ? keyword : undefined,
      mobileNumber: +searchType === 3 ? keyword : undefined,
      //TODO： 物流单号
      goodsName: +searchType === 5 ? keyword : undefined,
      completeStartTime: completeTime[0]
        ? completeTime[0].format('YYYY-MM-DD') + ' 00:00:00'
        : undefined,
      completeEndTime: completeTime[1]
        ? completeTime[1].format('YYYY-MM-DD') + ' 23:59:59'
        : undefined,
    };
    let data = await orderInfoStore.getOrderList(params);
    setDataList(data);
  };
  const tableAction = (data, type) => {
    setActionItem(data);
    switch (type) {
      case 'modify':
        setShowChangeMoney(true);
        break;
      case 'deliver':
        setShowGoodsDeliver(true);
        break;
      case 'continue':
        alert(123);
    }
  };
  const search = () => {
    setRefreshData(true);
  };

  const reset = () => {
    setCreateTime([]);
    setSearchType(undefined);
    setKeyword(undefined);
    setOrderStatus(undefined);
    setCompleteTime([]);
    setRefreshData(true);
  };
  return (
    <>
      <div className={styles.btn_tab_container}>
        <ButtonGroupRadio
          list={stateList}
          value={tab}
          onChange={(val) => {
            setTab(val);
            setRefreshData(true);
          }}
        />
      </div>
      <div className={styles.screen_container}>
        <Card
          type="inner"
          size="small"
          title={<span>筛选查询</span>}
          extra={
            <Space>
              {searchSpread ? (
                <Button type="link" onClick={() => setSearchSpread(false)}>
                  收起高级搜索
                </Button>
              ) : (
                <Button type="link" onClick={() => setSearchSpread(true)}>
                  展开高级搜索
                </Button>
              )}
              <span>
                <Button type="primary" onClick={search} style={{ width: 100 }}>
                  查询
                </Button>
                <Button onClick={reset} style={{ width: 100 }}>
                  重置
                </Button>
              </span>
            </Space>
          }
        >
          <Row>
            <Col span={8} className={styles.search_Item}>
              {getSearchItemLabel('下单时间：')}
              <RangePicker
                allowClear
                value={createTime}
                onChange={(val) => setCreateTime(val)}
              />
            </Col>
            <Col span={16} className={styles.search_Item}>
              {getSearchItemLabel('订单搜索：')}
              <Select
                style={{ width: 200 }}
                allowClear
                placeholder="搜索类型"
                value={searchType}
                onChange={(val) => setSearchType(val)}
              >
                {[...searchTypeMap].map((item) => (
                  <Option value={item[0]} key={item[0]}>
                    {item[1]}
                  </Option>
                ))}
              </Select>
              <Input
                allowClear
                placeholder="输入关键字"
                style={{ width: 250 }}
                value={keyword}
                onChange={(e) => setKeyword(e.target.value.trim())}
              />
            </Col>
            {searchSpread && (
              <>
                {+tab === 0 && (
                  <Col span={8} className={styles.search_Item}>
                    {getSearchItemLabel('订单状态：')}
                    <Select
                      style={{ width: 278 }}
                      allowClear
                      placeholder="订单状态"
                      value={orderStatus}
                      onChange={(val) => setOrderStatus(val)}
                    >
                      {[...orderStatusSearchMap].map((item) => (
                        <Option value={item[0]} key={item[0]}>
                          {item[1]}
                        </Option>
                      ))}
                    </Select>
                  </Col>
                )}

                {/*<Col span={16} className={styles.search_Item}>*/}
                {/*  {getSearchItemLabel("快递状态：")}*/}
                {/*  <Select style={{width: 200}} allowClear placeholder="快递状态">*/}
                {/*    {[...expressStatusMap].map(item =>*/}
                {/*      <Option value={item[0]} key={item[0]}>{item[1]}</Option>)}*/}
                {/*  </Select>*/}
                {/*</Col>*/}
                <Col span={8} className={styles.search_Item}>
                  {getSearchItemLabel('订单完成时间：')}
                  <RangePicker
                    allowClear
                    value={completeTime}
                    onChange={(val) => setCompleteTime(val)}
                  />
                </Col>
              </>
            )}
          </Row>
        </Card>
      </div>
      <div className={styles.table_container}>
        {dataList.list.length ? (
          <div className={styles.head_container}>
            <TableHead columns={columns} />
          </div>
        ) : (
          <TableHead columns={columns} />
        )}
        <div className={styles.tbody_container}>
          {dataList.list.map((item, index) => (
            <TableItem
              columns={columns}
              data={item}
              type={item.status}
              key={item.key}
              onAction={tableAction}
            />
          ))}
        </div>
        <div className={styles.pagination_container}>
          {!!dataList.list.length && <Pagination {...pagination} />}
        </div>
      </div>
    </>
  );
};

const TableItem = (props) => {
  const { data, columns, onAction } = props;
  let newColumns = lodash.cloneDeep(columns);
  const getColSpan = (text, record, index, value) => {
    const obj = {
      children: value,
      props: {},
    };
    if (index > 0) {
      obj.props.rowSpan = 0;
    } else {
      obj.props.rowSpan = data.goodsList.length;
    }
    return obj;
  };
  // 商品名称
  newColumns[0] = Object.assign(newColumns[0], {
    render: (text, record, index) => {
      let { goodsAttribute } = record;
      let str = '';
      if (goodsAttribute) {
        goodsAttribute = JSON.parse(goodsAttribute);
        str = `规格：${Object.values(goodsAttribute).join(' ')}`;
      }
      return (
        <div className={styles.item_container}>
          <div className={styles.item_img_container}>
            <img
              src={record.goodsImageUrl}
              className={styles.item_img}
              alt=""
            />
          </div>
          <div className={styles.item_info_container}>
            <p className={styles.item_name}>{record.goodsName}</p>
            <p className={styles.item_attr}>{str}</p>
            {!!record.goodsNo && (
              <p className={styles.item_attr}>货号：{record.goodsNo}</p>
            )}
          </div>
        </div>
      );
    },
  });
  // 供应价
  newColumns[1] = Object.assign(newColumns[1], {
    dataIndex: 'supplyPrice',
    render: (text) => `¥${showMoney(text)}`,
  });
  // 数量
  newColumns[2] = Object.assign(newColumns[2], {
    dataIndex: 'quantity',
  });
  // 订单总价
  newColumns[4] = Object.assign(newColumns[4], {
    render: (text, record, index) =>
      getColSpan(
        text,
        record,
        index,
        <>
          <span>商品：¥{showMoney(data.orderAmount - data.expressCost)}</span>
          <br />
          <span>运费：¥{showMoney(data.expressCost)}</span>
        </>
      ),
  });
  // 状态
  newColumns[5] = Object.assign(newColumns[5], {
    render: (text, record, index) =>
      getColSpan(text, record, index, orderStatusMap.get(data.orderStatus)),
  });
  // 操作
  newColumns[6] = Object.assign(newColumns[6], {
    render: (text, record, index) =>
      getColSpan(
        text,
        record,
        index,
        +data.orderStatus === 9 ? (
          <Button type="primary" onClick={() => onAction(data, 'continue')}>
            继续下单
          </Button>
        ) : (
          ''
        )
      ),
  });

  return (
    <>
      <div className={styles.table_item_head}>
        <Space>
          <span>订单编号：{data.parentOrderNo}</span>
          <span>下单时间：{formatTime(data.createTime)}</span>
          <span>共{data.goodsNumber}件商品</span>
        </Space>
        <Space>
          {/*<span>*/}
          {/*  订单总价：¥{showMoney(data.orderTotalPrice)}（含运费：¥*/}
          {/*  {showMoney(data.expressCost)}）*/}
          {/*</span>*/}
          <Button
            type="link"
            onClick={() => {
              window.open(
                `/order/list/detail/once?id=${data.parentOrderNo}`,
                '_self'
              );
            }}
          >
            查看详情
          </Button>
        </Space>
      </div>
      <Table
        bordered
        columns={newColumns}
        dataSource={data.goodsList}
        showHeader={false}
        pagination={false}
        rowKey={(record) => record.orderGoodsId}
      />
    </>
  );
};

function TableHead(props) {
  const { columns } = props;
  return <Table columns={columns} size="middle" pagination={false} bordered />;
}

export default ListSelf;
