import React, { useState, useEffect } from 'react';
import { Button, Card, Descriptions, Empty, Space, Table } from 'antd';
import styles from './index.module.scss';
import stores from 'stores';
import { Modals } from 'components';

import { formatTime, getQuery, showMoney } from 'utils/tools';

const { Item } = Descriptions;

const { orderInfoStore } = stores;
const {
  OrderChangeMoneyModal,
  OrderGoodsDeliverModal,
  OrderExpressInfoModal,
} = Modals;
const orderStatusMap = new Map([
  [5, '待发货'],
  [6, '已发货'],
  [7, '部分发货'],
  [8, '已关闭'],
  [9, '待接口确认'],
  [10, '已完成'],
]);
const getDescItemLabel = (str) => <p className={styles.item_label}>{str}</p>;
const getDeadline = (time) => {
  let now = new Date().getTime();
  let delta = time + 24 * 60 * 60 * 1000 - now;
  let days = parseInt(delta / (24 * 60 * 60 * 1000));
  let hours = parseInt((delta - days * 24 * 60 * 60 * 1000) / (60 * 60 * 1000));
  let minutes = parseInt(
    (delta - days * 24 * 60 * 60 * 1000 - hours * (60 * 60 * 1000)) /
      (60 * 1000)
  );
  let seconds = parseInt(
    delta -
      days * 24 * 60 * 60 * 1000 -
      hours * (60 * 60 * 1000) -
      (minutes * (60 * 1000)) / 1000
  );
  return `${days}天${hours}:${minutes}:${seconds}后自动取消`;
};
const DetailSelf = () => {
  const [orderInfo, setOrderInfo] = useState({
    goodsList: [],
  });

  const [timeStr, setTimeStr] = useState('');
  const [showGoodsDeliver, setShowGoodsDeliver] = useState(false);
  const [showExpressInfo, setShowExpressInfo] = useState(false);
  const [expressNum, setExpressNum] = useState(undefined);
  const [goodsList, setGoodsList] = useState([]);

  useEffect(() => {
    _getData();
  }, []);

  useEffect(() => {
    if (+orderInfo.orderStatus === 9) {
      interval = setInterval(
        () => setTimeStr(getDeadline(orderInfo.createTime)),
        500
      );
    }
  }, [orderInfo]);
  const _getData = async () => {
    let query = getQuery();
    if (query.id) {
      let params = { parentOrderNo: query.id };
      let res = await orderInfoStore.getOnceOrderDetail(params);
      setGoodsList(res.goodsList);
      setOrderInfo(res);
    }
  };

  const columns = [
    {
      title: '商品图片',
      align: 'center',
      dataIndex: 'goodsImageUrl',
      render: (text) => <img src={text} style={{ width: 60, height: 60 }} />,
    },
    {
      title: '商品名称',
      align: 'center',
      dataIndex: 'goodsName',
      render: (text) => <p className="ellipsis-row-2">{text}</p>,
    },
    // { title: '子供应商', align: 'center' },
    {
      title: '货号',
      align: 'center',
      dataIndex: 'goodsNo',
    },
    { title: '规格', align: 'center', dataIndex: 'goodsAttribute' },
    { title: '数量', align: 'center', dataIndex: 'quantity' },
    {
      title: '供应价',
      align: 'center',
      dataIndex: 'supplyPrice',
      render: (text) => showMoney(text),
    },
    {
      title: '供应价小计',
      align: 'center',
      dataIndex: 'supplyTotalPrice',
      render: (text) => showMoney(text),
    },
    // { title: '售后信息', align: 'center',dataIndex: 'quantity', },
    {
      title: '物流信息',
      align: 'center',
      dataIndex: 'expressName',
      render: (text, record) => {
        return record.deliveryStatus === 3 ? (
          <div>
            <Button type="link">{record.expressName}</Button>
            <br />
            <Button
              type="link"
              onClick={() => {
                setShowExpressInfo(true);
                setExpressNum(record.expressNo);
              }}
            >
              单号:{record.expressNo}
            </Button>
          </div>
        ) : (
          '-'
        );
      },
    },
  ];

  let interval = null;
  return (
    <Card
      type="inner"
      size="large"
      title={
        <Space size={24} style={{ height: 60 }}>
          <span className={styles.order_status}>
            当前订单状态：{orderStatusMap.get(orderInfo.orderStatus)}
          </span>
          {[5, 7].includes(+orderInfo.orderStatus) && (
            <Button
              type="primary"
              style={{ width: 100 }}
              onClick={() => setShowGoodsDeliver(true)}
            >
              发货
            </Button>
          )}
          {[9].includes(+orderInfo.orderStatus) && (
            <>
              <Button type="primary">继续下单</Button>
              <span>{timeStr}</span>
            </>
          )}
        </Space>
      }
      extra={''}
    >
      <Descriptions title="基本信息" layout="vertical" bordered column={6}>
        <Item label={getDescItemLabel('订单编号')} span={1}>
          {/* {orderInfo.supplierOrderNo} */}
          {orderInfo.parentOrderNo}
        </Item>
        <Item label={getDescItemLabel('买家下单时间')} span={1}>
          {formatTime(orderInfo.createTime)}
        </Item>
        <Item label={getDescItemLabel('订单备注')} span={1}>
          {orderInfo.remark}
        </Item>
        <Item label={getDescItemLabel('')} span={1}></Item>
        <Item label={getDescItemLabel('')} span={1}></Item>
        <Item label={getDescItemLabel('')} span={1}></Item>
      </Descriptions>
      <Descriptions
        title="收货人信息"
        layout="vertical"
        bordered
        column={4}
        style={{ marginTop: 32 }}
      >
        <Item label={getDescItemLabel('收货人')} span={1}>
          {orderInfo.consignee}
        </Item>
        <Item label={getDescItemLabel('手机号码')} span={1}>
          {orderInfo.mobileNumber}
        </Item>
        <Item label={getDescItemLabel('收货地址')} span={1}>
          {orderInfo.address}
        </Item>
        <Item label={getDescItemLabel('')} span={1}></Item>
      </Descriptions>
      <Descriptions title="商品信息" bordered style={{ marginTop: 32 }}>
        <Item style={{ padding: 0 }}>
          <Table
            dataSource={goodsList}
            columns={columns}
            pagination={false}
            rowKey={(record, index) => index}
            bordered
            footer={(data) => (
              <div className={styles.table_footer}>
                <Space>
                  <span>供应价合计：</span>
                  <span className={styles.table_money}>
                    ¥{showMoney(orderInfo.supplyTotalPrice)}
                  </span>
                </Space>
              </div>
            )}
          />
        </Item>
      </Descriptions>

      <Descriptions title="操作信息" bordered style={{ marginTop: 32 }}>
        <Item style={{ padding: 0 }}>
          <Empty
            image={false}
            description={
              <p
                style={{ color: '#ccc', fontSize: '12px', lineHeight: '60px' }}
              >
                当前无操作信息
              </p>
            }
            imageStyle={{ height: 0 }}
          />
        </Item>
      </Descriptions>
      {/* 发货弹窗 */}
      {showGoodsDeliver && (
        <OrderGoodsDeliverModal
          visible={showGoodsDeliver}
          type="once"
          info={orderInfo}
          onCancel={(flag) => {
            setShowGoodsDeliver(false);
            if (flag) {
              _getData();
            }
          }}
        />
      )}
      {/* 物流信息弹窗 */}
      {showExpressInfo && (
        <OrderExpressInfoModal
          visible={showExpressInfo}
          expressNum={expressNum}
          onCancel={() => {
            setShowExpressInfo(false);
          }}
        />
      )}
    </Card>
  );
};

export default DetailSelf;
