import React, {Component} from "react";
import {Route, Switch, Redirect, withRouter} from "react-router-dom";
import {BackTop} from 'antd'
import {inject, observer} from "mobx-react";
import styles from "./index.module.scss";
import {routers} from "config/route";
import {getTitle} from "utils/tools"

// component
import {TopBar, Bread,} from "components";
import LeftNav from 'components/LeftNav';
import FixMenu from "components/FixTopMenu";

import DashboardModule from 'views/Dashboard';
import OrderModule from 'views/Order';
import ActManagement from 'views/actManagement';
import ActivityModule from 'views/Activity';
import GoodsModule from 'views/Goods';
import StoreModule from 'views/Store';
import FinanceModule from 'views/Finance';
import SupplyModule from 'views/Supply';
import SettingModule from 'views/Setting';

// router


@withRouter
@inject("commonStore")
@observer
class Container extends Component {
  componentDidMount() {
    this.props.commonStore.getAccountRights();
  }


  render() {
    const pathname = this.props.location.pathname;
    return (
      <div className={styles.container} id="main">
        <TopBar title={getTitle(pathname)}/>
        <FixMenu/>
        <div className={styles.content}>
          <Switch>
            <Route path="/dashboard" component={DashboardModule}/>
            <Route path="/order" component={OrderModule}/>
            <Route path="/goods" component={GoodsModule}/>
            <Route path="/store" component={StoreModule}/>
            <Route path="/finance" component={FinanceModule}/>
            <Route path="/activity" component={ActManagement}/>
            <Route path="/supply" component={SupplyModule}/>
            <Route path="/setting" component={SettingModule}/>
            <Redirect from="/" to="/goods/add"/>
          </Switch>
        </div>
        <BackTop/>
      </div>
    );
  }
}

export default Container;
