import React, {Component} from 'react'
import {Button, Alert, Table, Divider, Modal, message} from "antd";
import {inject, observer} from 'mobx-react'


import styles from './index.module.scss'
import SettlementAccountActionModal from 'components/Modals/SettlementAccountAction'

const {confirm} = Modal;

@inject(...['financeStore'])
@observer
class FinanceSettlementAccount extends Component {
  constructor(props) {
    super(props)
    this.state = {
      actionModal: false,
      modalType: "",
      actionAccount: {}
    }
  }

  componentDidMount() {
    this.getData()
  }

  getData = () => {
    this.props.financeStore.getSettlementAccountList({})
  }


  showConfirmDel = (item) => {
    let _this = this
    confirm({
      title: '确定删除？',
      async onOk() {
        let result = await _this.props.financeStore.postDelSettlementAccount({
          id: item.id
        })
        if (result.success) {
          message.success("删除成功！")
          _this.getData()
        }
        console.log('OK');
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }


  accountAction = (type, item) => {
    if (type === 'del') {
      this.showConfirmDel(item)
    } else {
      this.setState({
        actionModal: true,
        modalType: type,
        actionAccount: item
      })
    }
  }

  renderTable = () => {
    const {financeStore} = this.props;
    const columns = [
      {
        title: '开户名',
        align: "center",
        dataIndex: "balanceAccountName"
      }, {
        title: '账号',
        align: "center",
        dataIndex: "balanceAccountId"
      }, {
        title: '开户行',
        align: "center",
        dataIndex: "balanceBankName"
      }, {
        title: '操作',
        align: "center",
        render: (text, record, index) =>
          <div>
            <span className={styles.table_action_btn} onClick={() => this.accountAction("edit", record)}>编辑</span>
            <Divider type="vertical"/>
            <span className={styles.table_action_btn} onClick={() => this.accountAction("del", record)}>删除</span>
          </div>
      },
    ]

    return <Table columns={columns}
                  pagination={false}
                  dataSource={financeStore.settlementAccountList}
                  rowKey={record => record.id}/>
  }

  render() {
    const {actionModal, modalType, actionAccount} = this.state
    const {financeStore} = this.props;
    return (
      <div className={styles.page_container}>
        <Alert
          message="温馨提示："
          description={<div>
            <p>1、结算账户是平台方用来与供应商进行资金结算时使用，提供打款账号，方便平台方为供应商结算打款；</p>
            <p>2、当前仅提供对公银行转账一种结算方式，不支持支付宝或对私转账；</p>
            <p>3、只能添加与已认证主体名称一致的结算账户，请准确填写，以免造成资金损失。</p>
          </div>}
          type="warning"
        />

        <div className={styles.table_container}>
          <div className={styles.add_container}>
            <Button type="primary" style={{
              width: 120,
              marginRight: 20
            }} onClick={() => this.accountAction("add", {})}
                    disabled={financeStore.settlementAccountList.length > 9}>新增结算账户</Button>
            <span>最多新增10个结算账户</span>
          </div>
          {this.renderTable()}
        </div>
        {actionModal ?
          <SettlementAccountActionModal visible={actionModal}
                                        type={modalType}
                                        account={actionAccount}
                                        close={(e) => {
                                          if (e === 'ok') {
                                            this.getData()
                                          }
                                          this.setState({actionModal: false})
                                        }}
          /> : ''}
      </div>
    )
  }
}

export default FinanceSettlementAccount;
