import React, { useState, useEffect } from 'react';

import { Radio, Tooltip } from 'antd';

import ImgScroll from 'components/ImgScroll';

import stores from 'stores'
import { parseQueryString, regFenToYuan, objClone } from 'utils/common';
import './index.scss';
import { getQuery } from 'utils/tools';


const { goodsDetailStore } = stores

const detailTabs = [
  { title: '图文详情', key: 'introduce' },
  { title: '商品参数', key: 'goods-params' },
];

function GoodsDetail(props) {
  const { id: goodsId = '' } = getQuery();

  const [detailData, setDetailData] = useState({});
  const [sendAddr, setSendAddr] = useState('');
  const [expressCost, setExpressCost] = useState(0);
  const [attributeList, setAttributeList] = useState([]);
  const [firstAttrId, setFirstAttrId] = useState('');
  const [infoTabKey, setInfoTabKey] = useState('introduce');

  useEffect(() => {
    let query = getQuery();
    getGoodsDetail(query.id);
  }, [])

  useEffect(() => {
    if (detailData.freightTemplateId) {
      getSendAddr();
    }
  }, [detailData.freightTemplateId])

  const getGoodsDetail = async (goodsId) => {
    const data = await goodsDetailStore.getGoodsDetail({ goodsId });

    if (data) {
      setDetailData(data);
      getExpressCost(data);

      const list = objClone(data.attributeList) || []
      list.length === 1 && list.unshift('');
      list[0] && setFirstAttrId(list[0].valueList[0].attributeValueId);
      setAttributeList(list);
    }
  }

  const getSendAddr = async () => {
    const data = await goodsDetailStore.getFreightDetail({ templateId: detailData.freightTemplateId });

    if (data) {
      const addr = data.provinceName + data.cityName + data.districtName;
      setSendAddr(addr);
    }
  }

  const getExpressCost = async (params = {}) => {
    const goodsList = [{
      goodsId,
      goodsNumber: 1,
      skuId: params.skuList[0].skuId,
    }]

    const data = await goodsDetailStore.getExpressCost({ provinceId: 15, goodsList, poolId: params.poolId, companyId: params.companyId });

    data && setExpressCost(data.reduce((pre, next) => pre + next.memberFreight, 0));
  }

  return (
    <div className="goods-detail-page">
      <div className="goods-detail-title">商品详情</div>
      <div className="line" />
      <div className="goods-main-info">
        <div>商品ID：{detailData.goodsId}</div>
        <div>商品品牌：{detailData.brandName}</div>
        <div>商品类目：{detailData.categoryNameList?.join('>')}</div>
      </div>

      <div style={{display:"flex"}}>
        <ImgScroll items={detailData.imageList} />

        <div className="info-box">
          <div className="goods-name">{detailData.name}</div>
          {detailData.description && <div className="goods-desc">{detailData.description}</div>}

          <div className="goods-price-box">
            {/* 阶梯价 */}
            {detailData.salesType === 1 && (
              <>
                <div className="price-item">
                  <div className="price-label sale">价&nbsp;格</div>
                  {detailData.stairList.slice().sort((a, b) => a.minNum - b.minNum).map((item, ind) => (
                    <div key={`item_${ind}`} className="price-value sale">¥{regFenToYuan(item.salePrice)}</div>
                  ))}
                </div>
                {!detailData.isSupportOnce && (
                  <div className="price-item">
                    <div className="price-label" style={{ marginTop: 12, marginBottom: 12 }}>起批量</div>
                    {detailData.stairList.slice().sort((a, b) => a.minNum - b.minNum).map((item, ind) => (
                      <div key={`item_count_${ind}`} style={{ marginTop: 12, marginBottom: 12 }} className="price-value">
                        {ind === detailData.stairList.length - 1
                          ? `≥${item.minNum}${detailData.unit}`
                          : `${item.minNum}-${item.maxNum}${detailData.unit}`
                        }
                      </div>
                    ))}
                  </div>
                )}
                <div className="price-item" style={{ marginTop: !!detailData.isSupportOnce ? 26 : 0 }}>
                  <div className="price-label">市场价</div>
                  <div className="price-value">
                    {detailData.marketPriceMin === detailData.marketPriceMax
                      ? `¥${regFenToYuan(detailData.marketPriceMin)}`
                      : `¥${regFenToYuan(detailData.marketPriceMin)} - ¥${regFenToYuan(detailData.marketPriceMax)}`
                    }
                  </div>
                </div>
              </>
            )}

            {/* 规格价 */}
            {detailData.salesType === 2 && (
              <>
                <div className="price-item">
                  <div className="price-label sale">价&nbsp;格</div>
                  <div className="price-value sale spec">
                    {detailData.salePriceMin === detailData.salePriceMax
                      ? `¥${regFenToYuan(detailData.salePriceMin)}`
                      : `¥${regFenToYuan(detailData.salePriceMin)} - ¥${regFenToYuan(detailData.salePriceMax)}`
                    }
                  </div>
                </div>
                {!!detailData.isSupportPurchase && (
                  <div className="price-item">
                    <div className="price-label" style={{ marginTop: 12, marginBottom: 12 }}>起批量</div>
                    <div style={{ marginTop: 12, marginBottom: 12 }} className="price-value">
                      ≥{detailData.minBuyNum}{detailData.unit}
                    </div>
                  </div>
                )}
                <div className="price-item" style={{ marginTop: !!detailData.isSupportPurchase ? 0 : 26 }}>
                  <div className="price-label">市场价</div>
                  <div className="price-value spec">
                    {detailData.marketPriceMin === detailData.marketPriceMax
                      ? `¥${regFenToYuan(detailData.marketPriceMin)}`
                      : `¥${regFenToYuan(detailData.marketPriceMin)} - ¥${regFenToYuan(detailData.marketPriceMax)}`
                    }
                  </div>
                </div>
              </>
            )}
          </div>

          <div className="goods-spec-box">
            <div className="goods-spec-label">配送至</div>
            <div className="goods-spec-value">
              <div style={{ color: '#444', marginBottom: 10 }}>
                浙江省 杭州市 西湖区
                <span className="express-cost">快递 &nbsp;{expressCost ? `¥${regFenToYuan(expressCost)}` : '包邮'}</span>
              </div>
              <div>
                {detailData.thirdSku ? (
                  <span>由 <span style={{ color: '#D1302C' }}>京东</span> 发货，并提供售后服务</span>
                ) : (
                    <span>从{sendAddr}发货</span>
                  )}
              </div>
            </div>
          </div>

          {!detailData.isExistSpec && (
            <div className="goods-spec-box">
              <div className="goods-spec-label">库存</div>
              <div className="goods-spec-value">
                {detailData.stock} 可售
              </div>
            </div>
          )}

          {attributeList[0] && (
            <div className="goods-spec-box">
              <div className="goods-spec-label">{attributeList[0].name}</div>
              <div className="goods-spec-value">
                <Radio.Group value={firstAttrId} onChange={e => setFirstAttrId(e.target.value)}>
                  {attributeList[0].valueList.map((item, index) => (
                    <Radio.Button
                      value={item.attributeValueId}
                      key={index}
                    >
                      {item.image ? (
                        <Tooltip
                          placement="bottomLeft"
                          title={item.value}
                        >
                          <img
                            src={item.image}
                            style={{ width: 50, height: 50 }}
                            alt={item.value}
                          />
                        </Tooltip>
                      ) : (
                          <span className="spec-text">
                            {item.value}
                          </span>
                        )}
                    </Radio.Button>
                  ))}
                </Radio.Group>
              </div>
            </div>
          )}

          {attributeList[1] && (
            <div className="goods-spec-box">
              <div className="goods-spec-label">{attributeList[1].name}</div>
              <div className="goods-spec-value">
                {attributeList[1].valueList.map(item => (
                  <div className="spec-item flex-box" key={item.attributeValueId}>
                    {attributeList[0] === '' && item.image ? (
                      <div>
                        <Tooltip
                          placement="bottomLeft"
                          title={item.value}
                        >
                          <img
                            src={item.image}
                            style={{ width: 50, height: 50 }}
                            alt={item.value}
                          />
                        </Tooltip>
                      </div>
                    ) : (
                        <div>{item.value}</div>
                      )}

                    <div>¥
                      {detailData.salesType === 1 ? (
                        regFenToYuan(detailData.stairList[0]?.salePrice)
                      ) : (
                          regFenToYuan(detailData.skuList.find(sku => sku.attributeValueIndex === [firstAttrId, item.attributeValueId].filter(item => !!item).sort((a,b)=>a-b).join('_'))?.salePrice)
                        )}
                    </div>

                    <div>
                      {detailData.skuList.find(sku => sku.attributeValueIndex === [firstAttrId, item.attributeValueId].filter(item => !!item).sort().join('_'))?.stock} 可售
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="goods-info">
        <div className="goods-info-title">
          {detailTabs.map(item => (
            <div
              className={`goods-info-title-item ${infoTabKey === item.key
                ? 'goods-info-title-item-actived'
                : ''
                }`}
              key={item.key}
              onClick={() => setInfoTabKey(item.key)}
            >
              {item.title}
            </div>
          ))}
        </div>

        <div
          className="goods-detail"
          style={{
            display: infoTabKey === 'introduce' ? 'flex' : 'none',
          }}
        >
          <div dangerouslySetInnerHTML={{ __html: detailData.detail }} />
        </div>

        <div
          className="goods-info-box"
          style={{
            display:
              infoTabKey === 'goods-params' ? 'block' : 'none',
          }}
        >
          {detailData.propertyList?.map(item => (
            <div className="goods-params-wrap" key={item.propertyBlockId}>
              <div className="goods-params-title">{item.propertyBlockName}</div>
              <div className="goods-params-info">
                {item.propertyList?.map((params, paramsInd) => (
                  <div
                    className="goods-params-item"
                    key={`goods_params_item_${paramsInd}`}
                  >
                    <div className="goods-params-label">
                      {params.propertyName}
                    </div>
                    <div className="goods-params-value">
                      {params.propertyValue}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>

    </div>
  )
}

export default GoodsDetail;
