import React, { useState, useEffect } from 'react';
import { Affix, Menu, Divider, Button } from 'antd';
import { HomeTwoTone, CloseCircleOutlined } from '@ant-design/icons';
import route from 'config/route';
import { withRouter } from 'react-router-dom';
import { routeSubObj } from 'config/routeSub';
import stores from 'stores'

import styles from './index.module.scss';

const { SubMenu, Item } = Menu;
const needCachePathHead = route
  .filter((item, index) => index > 0)
  .map((item) => item.route);

const { commonStore } = stores

function FixTopMenu(props) {
  const [menuVal, setMenuVal] = useState('dashboard');
  const [linkList, setLinkList] = useState([]);
  const [refresh, setRefresh] = useState(false);


  /**
   * 监听改变menu的key
   */
  useEffect(() => {
    console.log(props.location.pathname);
    if (
      props.location.pathname &&
      props.location.pathname !== '/' &&
      needCachePathHead.some((item) => props.location.pathname.startsWith(item))
    ) {
      const pathnameArr = props.location.pathname.split('/');
      let key = '';
      if (pathnameArr[2]) {
        key = [pathnameArr[1], pathnameArr[2]].join('_');
      } else {
        key = pathnameArr[1];
      }
      setMenuVal(key);
    }
  }, [props.location.pathname]);

  /**
   * 监听设置historyTabbar
   */
  useEffect(() => {
    if (
      props.location.pathname &&
      props.location.pathname !== '/' &&
      props.location.pathname !== '/goods/add' &&
      needCachePathHead.some((item) => props.location.pathname.startsWith(item))
    ) {
      const pathname = props.location.pathname;
      const path = props.location.pathname + props.location.search;
      const pathListStr = sessionStorage.getItem('pathList');
      let pathList = [];
      if (pathListStr) {
        pathList = [...new Set(JSON.parse(pathListStr))];
      }
      if(pathname === '/goods/modify/detail' ){
        let index = pathList.findIndex((item) =>
        item.path.startsWith(pathname));
        if (index === -1) {
          let name ="变更详情";
          pathList.push({ path, name });
          sessionStorage.setItem('pathList', JSON.stringify(pathList));
        } else {
          pathList[index].path = path;
        }
      }else if (pathname === '/goods/purchase/modify' || pathname === '/goods/once/modify') {
        let index = pathList.findIndex((item) =>
        item.path.startsWith(pathname));
        if (index === -1) {
          let name =pathname.indexOf("once")>-1?"代发变更列表":"集采变更列表";
          pathList.push({ path, name });
          sessionStorage.setItem('pathList', JSON.stringify(pathList));
        } else {
          pathList[index].path = path;
        }
      } else if (pathname === '/goods/purchase/list' || pathname === '/goods/once/list' || pathname === '/goods/self') {
        let index = pathList.findIndex((item) =>
          item.path.startsWith(pathname)
        );
        if (index === -1) {
          let name = '';
          let routeObj = route.find((item) => pathname.startsWith(item.route));
          console.log(pathname, routeObj);
          name = routeObj.name;
          if (routeObj.children && routeObj.children.length) {
            name = routeObj.children.find((itemIn) =>
              pathname.startsWith(itemIn.route)
            ).name;
          }
          pathList.push({ path, name });
          sessionStorage.setItem('pathList', JSON.stringify(pathList));
        } else {
          pathList[index].path = path;
        }
      } else if (pathname === '/goods/edit') {
        let indexA = pathList.findIndex((item) =>
          item.path.startsWith(pathname) && item.path.indexOf('add') > -1);
        let indexE = pathList.findIndex((item) =>
          item.path.startsWith(pathname) && item.path.indexOf('edit') > -1);
        let indexM = pathList.findIndex((item) =>
          item.path.startsWith(pathname) && item.path.indexOf('modify') > -1);
        if (indexA === -1 && indexE === -1 && indexM === -1) {
          let name = '';
          let routeObj = route.find((item) => pathname.startsWith(item.route));
          console.log(1111, pathname, path, routeObj);
          let search = path.split("?");
          console.log("search ====", search)
          if (search[1].indexOf("type=add") > -1) {
            name = "发布商品"
          } else if (search[1].indexOf("type=edit") > -1) {
            name = "编辑商品"
          } else if (search[1].indexOf("type=modify") > -1) {
            name = "变更商品"
          }
          // name = routeObj.name;
          // if (routeObj.children && routeObj.children.length) {
          //   name = routeObj.children.find((itemIn) =>
          //     pathname.startsWith(itemIn.route)
          //   ).name;
          // }
          // name =
          //   path.indexOf('edit') > -1
          //     ? '编辑商品'
          //     : path.indexOf('edit') > -1
          //     ? '变更商品'
          //     : name;
          pathList.push({ path, name });
          sessionStorage.setItem('pathList', JSON.stringify(pathList));
        } else {
          if (indexA > -1) {
            pathList[indexA].path = path;
          }
          if (indexE > -1) {
            pathList[indexE].path = path;
          }
          if (indexM > -1) {
            pathList[indexM].path = path;
          }
        }
      } else if (pathname === '/goods/detail') {
        let index = pathList.findIndex((item) => item.path === pathname);
        if (index === -1) {
          let name = '商品详情';
          pathList.push({ path, name });
        } else {
          pathList[index].path = path;
        }
      } else if (pathname === '/order/list/detail'||pathname === '/order/list/detail/once') {
        let index = pathList.findIndex((item) => item.path === pathname);
        if (index === -1) {
          let name = '订单详情';
          pathList.push({ path, name });
        } else {
          pathList[index].path = path;
        }
      } else if (pathname.startsWith('/finance/center/')) {
        let index = pathList.findIndex((item) => item.path === pathname);
        const name = routeSubObj[pathname];
        if (index === -1) {
          pathList.push({ path, name });
        } else {
          pathList[index].path = path;
        }
      } else {
        let index = pathList.findIndex((item) => item.path === path);
        if (index === -1) {
          let name = '';
          let routeObj = route.find((item) => pathname.startsWith(item.route));
          console.log(pathname, routeObj);
          name = routeObj.name;
          if (routeObj.children && routeObj.children.length) {
            name = routeObj.children.find((itemIn) =>
              pathname.startsWith(itemIn.route)
            ).name;
          }
          pathList.push({ path, name });
          sessionStorage.setItem('pathList', JSON.stringify(pathList));
        }
      }

      setLinkList(pathList);
    }
  }, [props.location.pathname, props.location.search]);

  useEffect(() => {
    setRights()
  }, [])

  useEffect(() => {
    refresh && setTimeout(() => setRefresh(false));
  }, [refresh]);


  const setRights = async () => {
    await commonStore.getAccountRights();
    // 初始化设置商铺，子供应商权限
    let accountRightsStr = localStorage.getItem("accountRights");
    let accountRights;
    if (accountRightsStr) {
      accountRights = JSON.parse(accountRightsStr)
      if (!accountRights.shopOpend) {
        route[5].disabled = true;
        route[3].children[1].disabled = true;
        route[2].disabled = true;
      }
      if (!accountRights.haveManagePower) {
        route[6].disabled = true
      }

      if (+accountRights.supplyMode === 1) {//一件代发
        route[3].children[3].disabled = true;
      }
      if (+accountRights.supplyMode === 2) {//自有
        route[3].children[2].disabled = true;
      }
    }
    [route[1], route[2], route[3]] = [route[3], route[1], route[2]]
    setRefresh(true)
  }


  /**
   * menu改变跳转
   * @param e
   */
  const clickMenu = (e) => {
    // console.log(e.key)
    setMenuVal(e.key);
    props.history.push('/' + e.key.split('_').join('/'));
  };

  /**
   * historyTabbar点击跳转
   * @param item
   * @param index
   */
  const selectHistoryTab = (item, index) => {
    window.open(item.path, '_self');
    // props.history.push({
    //   pathname: item.path
    // })
  };

  /**
   * 删除tabbar项
   * @param e
   * @param item
   * @param index
   */
  const delHistoryTab = (e, item, index) => {
    e.stopPropagation();
    // 关闭的是当前页
    if (props.location.pathname === item.path) {
      // index>0
      if (index > 0) {
        props.history.push({
          pathname: linkList[index - 1].path,
        });
      } else {
        if (linkList[1]) {
          props.history.push({
            pathname: linkList[1].path,
          });
        } else {
          props.history.push({
            pathname: route[0].route,
          });
        }
      }
    }
    linkList.splice(index, 1);
    setLinkList(linkList);
    sessionStorage.setItem('pathList', JSON.stringify(linkList));
    // TODO:关闭不是当前页不生效
    setRefresh(true);
  };
  return (
    <>
      <Affix offsetTop={0} onChange={() => console.log('fix')}>
        <div className={styles.content_container}>
          <div className={styles.menu_content}>
            <Menu
              triggerSubMenuAction="hover"
              mode="horizontal"
              onClick={clickMenu}
              selectedKeys={[menuVal]}
              style={{ marginLeft: '-20px', border: 'none' }}
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
            >
              {route.filter(item => !item.disabled).map((item, index) =>
                item.children && item.children.length ? (
                  <SubMenu title={item.name} key={item.key}>
                    {item.children.filter(itemIn => !itemIn.disabled).map(
                      (itemIn) => itemIn.children && itemIn.children.length ?
                        <SubMenu title={itemIn.name} key={itemIn.key}>
                          {itemIn.children.filter(itemIn2 => !itemIn2.disabled).map(itemIn2 =>
                            <Item key={itemIn2.key} title={itemIn2.name}>
                              {itemIn2.name}
                            </Item>)}
                        </SubMenu> :
                        <Item key={itemIn.key} title={itemIn.name}>
                          {itemIn.name}
                        </Item>
                    )}
                  </SubMenu>
                ) : (
                    <Item key={item.key} title={item.name}>
                      {item.name}
                    </Item>
                  )
              )}
            </Menu>
          </div>
          <div className={styles.history_container}>
            <Button
              type="link"
              size="large"
              style={{
                height: 50,
                fontSize: '24px',
                marginTop: '-4px',
                paddingTop: 0,
                paddingBottom: 0,
              }}
              onClick={() =>
                props.history.push({
                  // pathname: route[0].route,
                  pathname: "/goods/add"
                })
              }
            >
              <HomeTwoTone />
            </Button>
            <Divider
              type="vertical"
              style={{ backgroundColor: '#999', height: 20 }}
            />
            <div className={styles.tab_container}>
              <div
                className={styles.tab_wrapper}
                style={{
                  width:
                    linkList.reduce(
                      (sum, item) => (sum += item.name.length * 16 + 68),
                      0
                    ) + 16,
                }}
              >
                {linkList.map((item, index) => (
                  <div
                    className={`${styles.tab_item} ${item.path ===
                      props.location.pathname + props.location.search
                      ? styles.tab_item_active
                      : ''
                      }`}
                    key={index}
                    onClick={() => selectHistoryTab(item, index)}
                  >
                    <span className={styles.item_name}>{item.name}</span>
                    <span
                      className={styles.item_close_wrapper}
                      onClick={(e) => delHistoryTab(e, item, index)}
                    >
                      {' '}
                      <CloseCircleOutlined className={styles.item_close} />
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </Affix>
    </>
  );
}

export default withRouter(FixTopMenu);
