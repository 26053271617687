import React, {Component} from 'react'
import PropTypes from 'prop-types'
import styles from "./index.module.scss"

/**
 * 推荐分类标签组
 *
 * @props data:Array{
 *   label,key,isChecked
 * }
 *
 * @props onChange:Function(keys,items)
 */
class CheckTags extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: []
    }
  }

  componentDidMount() {
    this.setState({
      data: this.props.data
    })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.data !== this.props.data) {
      this.setState({
        data: this.props.data
      })
    }
  }

  change = (item, index) => {
    const {type} = this.props;
    let arr = this.state.data;
    switch (type) {
      case "multi":
        item.isChecked = !item.isChecked;
        arr[index] = item;
        break;
      case "single":
        arr = arr.map((tag, i) => {
          tag.isChecked = i === index ? true : false;
          return tag;
        })
        break;
    }
    this.setState({
      data: arr
    }, () => {
      let items = this.state.data.filter(item => item.isChecked)
      let keys = items.map(item => item.key)
      this.props.onChange(keys, items)
    })
  }

  render() {
    const {data} = this.state;
    return (
      <div className={styles.tags_container}>
        {data.map((item, index) =>
          <span key={item.key}
                className={`${styles.tag} ${item.isChecked && styles.tag_active}`}
                onClick={() => this.change(item, index)}>{item.label}</span>
        )}
      </div>
    );
  }
}

CheckTags.propTypes = {
  data: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  type: PropTypes.bool || PropTypes.oneOf(['multi', 'single'])
}

export default CheckTags;
