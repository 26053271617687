import React from 'react';
import {Route, Switch} from "react-router-dom";

import Center from "./Center";
import Detail from "./Detail";
import Account from "./Account";
import SettlementManage from './Center/settlement_manage'
import SettlementProgress from './Center/settlement_progress'
import SettlementProgressDetail from './Center/settlement_progress/detail'
import DealDetail from './Detail'
import SettlementAccount from './Account'

function ActivityModule(props) {

  return (
    <Switch>
      {/* 结算管理 */}
      <Route path="/finance/center/settlement-manage" exact component={SettlementManage}/>
      <Route path="/finance/center" exact component={Center}/>
      <Route path="/finance/center/settlement-progress" exact component={SettlementProgress}/>
      <Route path="/finance/center/settlement-progress-detail" exact component={SettlementProgressDetail}/>
      <Route path="/finance/detail" exact component={DealDetail}/>
      <Route path="/finance/account" exact component={SettlementAccount}/>
      {/* <Route path="/finance/detail" exact component={Detail}/>
      <Route path="/finance/account" exact component={Account}/> */}
    </Switch>
  )
}

export default ActivityModule;
