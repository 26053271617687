
import moment from 'moment'
import  httpClient  from "utils/httpClient"
import api from "config/api"
const { common }=api
// 补0操作
const binary = (num, Bits) => {
  let resArry = [];
  let xresArry = [];
  let i = 0;
  //除2取余
  for (; num > 0;) {
    resArry.push(num % 2);
    num = parseInt(num / 2);
    i++;
  }
  //倒序排列
  for (let j = i - 1; j >= 0; j--) {
    xresArry.push(resArry[j]);
  }
  //报错
  if (Bits < xresArry.length) {
    console.log("控制位小于转换位数");
  }
  //补0操作
  if (Bits) {
    for (let r = xresArry.length; r < Bits; r++) {
      xresArry.unshift("0");
    }
  }
  return xresArry.join().replace(/,/g, "");
}

/**
 * 格式化url参数
 */
const parseQueryString = () => {
  const url = window.location.href
  const regUrl = /^[^\?]+\?([\w\W]+)$/ //eslint-disable-line
  const regPara = /([^&=]+)=([\w\W]*?)(&|$|#)/g
  const arrUrl = regUrl.exec(url)
  let ret = {}
  if (arrUrl && arrUrl[1]) {
    const strPara = arrUrl[1]
    let result
    while ((result = regPara.exec(strPara)) != null) {
      ret[result[1]] = result[2]
    }
  }
  return ret
}

/**
 * 时间戳转换
 */
const repeat = (str = '0', times) => (new Array(times + 1)).join(str)
// 时间前面 + 0
const pad = (num, maxLength = 2) => repeat('0', maxLength - num.toString().length) + num
// 时间格式的转换  12:00:00
const formatTime = time => `${pad(time.getHours())}:${pad(time.getMinutes())}:${pad(time.getSeconds())}.${pad(time.getMilliseconds(), 3)}`
//  2018-12-12 12:00:00
const formatAllTime = time => `${time.getFullYear()}-${pad(time.getMonth() + 1)}-${pad(time.getDate())} ${pad(time.getHours())}:${pad(time.getMinutes())}:${pad(time.getSeconds())}`
// 自定义转换
export const newFormatTime = (time, fmt = 'YYYY-MM-DD HH:mm:ss') => {
  return time ? moment(time).format(fmt) : '';
};

// antd需要的table data-key
const formatTableKey = list => {
  return list.map((val, key) => {
    val.key = key
    return val
  })
}


// 商品管理-分类
// 格式化分类table，添加key值
const formatCateTable = (list, parent) => {
  const keyArr = list.map((val, key) => {
    val.categoryId = val.categoryId || val.id
    val.key = `${Date.now()}${val.categoryId}`
    val.index = parent ? parent.index.concat([key]) : [key];
    if (Reflect.has(val, 'children')){
      val.children && val.children.map((value, index) => {
        return value.key=`${value.categoryId}-${index}`
      })
    }
    return val
  })
  return keyArr
}

// 格式化新增分类-分类treeselect，添加key值，替换属性名; 只显示到1级分类。
const formatTopCateTree = (list) => {
  const keyArr = list.map((val1, key) => {
    val1.children = [] // 只显示到二级分类
    formatCateTreeItem(val1)
    return val1
  })
  return keyArr
}


// 格式化商品库-分类treeselect-无限级，添加key值，替换属性名;
const formatStoreCateTree = (list) => {
  const keyArr = list.map((val1) => {
    checkCateTreeChild(val1)
    formatCateTreeItem(val1)
    return val1
  })
  return keyArr
}
const checkCateTreeChild = (val) => {
  if (Reflect.has(val, 'children')){
    val.children && val.children.map((item) => {
      checkCateTreeChild(item)
      formatCateTreeItem(item)
      return item
    })
  }
}

// 分类管理-treeselect，根据后端接口修改字段名
const formatCateTreeItem = (item) => {
  item.key = item.categoryTree
  item.title = item.categoryName
  item.id = item.categoryId
  item.value = item.categoryTree
  item.pId = item.parentId
  item.selectable = item.categoryLevel !== 1
  return item
}



// 格式化新增标签-标签treeselect，添加key值，替换属性名; 只显示到二级分类。
const formatLabelTree = (list) => {
  const keyArr = list.map((val1, key) => {
    formatLabelTreeItem(val1)
    return val1
  })
  return keyArr
}
const formatLabelTreeItem = (item) => {
  item.key = item.id
  item.title = item.name
  item.id = item.id
  item.value = item.id
  item.pId = item.parent
  return item
}

// 格式化 选择区域select
const formatArea = (list, pid) => {
  return list && list.map((val) => {
    val.isLeaf = false;
    // val.key = val.code
    // val.pid = pid
    return val;
  })
}

// 匹配地址list对应的id, 返回对应的索引
const matchAreaId = (list, id) => {
  let index = 0
  list && list.forEach((item, i) => {
    if (item.code === id) {
      index = i
    }
  })
  return index
}


// 商品管理=商品库
// 格式化表单的address入参
const formatAddress = (val, regions = []) => {
  regions.provinceId = val[0]
  regions.cityId = val[1]
  regions.districtId = val[2]
  return regions
}


// 去除数组中的empty类型 （建议typeof(key) !== undefined）?'empty'
const formatEmpty = (arr) => {
  return arr.filter(key => typeof(key) !== 'empty' )
  // return arr.filter(key => typeof(key) !== 'undefined' )
}

// 格式化商城-京东-调价阶梯-入参
const formatStairPriceParams = (_maxNum, _minNum, _price, _rate, newArr = []) => {
  formatEmpty(_maxNum).forEach((item, index) => {
    newArr.push({})
    newArr[index]['maxNum'] = item
  })
  formatEmpty(_minNum).forEach((item, index) => { newArr[index]['minNum'] = item })
  _price && formatEmpty(_price).forEach((item, index) => { newArr[index]['price'] = item*100 })
  _rate && formatEmpty(_rate).forEach((item, index) => { newArr[index]['priceRate'] = item })
  return newArr
}


// 格式化商城-自营-调价挑选弹窗-入参
const formatSelfPriceParams = (_appType, _isAutoPutaway, _reviseType, _rate, newArr = []) => {
  _appType.forEach((item, index) => {
    newArr.push({})
    newArr[index]['poolId'] = item
    newArr[index]['changeType'] = 2 // 后端需要，写死
  })
  _isAutoPutaway.forEach((item, index) => {
    newArr[index]['isAutoPutaway'] = item ? 1 : 0
  })
  _reviseType.forEach((item, index) => { newArr[index]['reviseType'] = item })
  _rate.forEach((item, index) => { newArr[index]['rate'] = item })
  return newArr
}

// 格式化商城-自营-键值对数组化
const formatKeysArray = (obj, fields, appTypeArr) => {
  fields.forEach(function (value, index, object) {
    obj[value] = [];

    appTypeArr.map((item, i) => {
      obj[value].push(obj[value + item]);
      delete obj[value + item];
    })

    // for (let i = 0; obj[value + i] !== undefined; i++) {
    //   console.log(value + i)
    //   obj[value].push(obj[value + i]);
    //   delete obj[value + i];
    // }
  })
  return obj
}

// 搜索时-价格单位-元转化成分
const formatPriceUnitEnlarge = (values) => {
  if(values.supplyPriceMin) values.supplyPriceMin = values.supplyPriceMin * 100
  if(values.supplyPriceMax) values.supplyPriceMax = values.supplyPriceMax * 100
  if(values.priceMax) values.priceMax = values.priceMax * 100
  if(values.priceMin) values.priceMin = values.priceMin * 100
  return values
}
// 分转元
const regFenToYuan = (fen) => {
  let num = fen;
  num = fen * 0.01;
  num += '';
  let reg = num.indexOf('.') >-1 ? /(\d{1,3})(?=(?:\d{3})+\.)/g : /(\d{1,3})(?=(?:\d{3})+$)/g;
  num = num.replace(reg,'$1');
  num = toDecimal2(num)
  return num;
}
const formatAmount = (amount, decimal) => {
  amount = amount.toString(10).split('.');
  let _int = amount[0].split(''), _decimals = amount[1], _num = '';
  for (let i = _int.length - 1, j = 1; i >= 0; i--, j++) {
    _num += _int[i];
    if (j % 3 === 0 && j < _int.length) {
      _num += ',';
    }
  }
  _num = _num.split('').reverse().join('');
  return _num + '.' + (_decimals ? _decimals : '00');
}
const toDecimal2 = (x) => {
  let f = parseFloat(x);
  if (isNaN(f)) {
    return false;
  }
  f = Math.round(x * 100) / 100;
  let s = f.toString();
  let rs = s.indexOf('.');
  if (rs < 0) {
    rs = s.length;
    s += '.';
  }
  while (s.length <= rs + 2) {
    s += '0';
  }
  return s;
}





// 格式化表单的空值,null
const formatNull = obj => {
  for (const [key, val] of Object.entries(obj)) {
    if (val === '' || val === null) {
      obj[key] = undefined
    }
  }
}



const formatHomeOperateCateTree = (list) => {
  const keyArr = list.map((val1, key) => {
    if (Reflect.has(val1, 'children')){
      val1.children && val1.children.map((val2, index) => {
        val2.children = [] // 只显示到二级分类
        formatHomeOperateCateTreeItem(val2)
        return val2
      })
    }
    formatHomeOperateCateTreeItem(val1)
    return val1
  })
  return keyArr
}

const formatHomeOperateCateTreeItem = (item) => {
  item.key = item.categoryTree
  item.title = item.categoryName
  item.id = item.categoryId
  item.value = item.categoryTree
  item.pId = item.parentId
  return item
}

//批量上传文件

const uploadMoreFiles = async(fileList) => {
  if(fileList.length){
    let newArr = []
    await Promise.all(
      fileList.map(async (item)=>{
        let formdata = new FormData() // FormData 对象
        formdata.append("file",item)
        formdata.append('directory',null)
        let { data } = await httpClient.post(common.UPLOAD_SINGLE_FILE_NEW, formdata , {
          withCredentials:false,
          headers: {
            'Content-Type': 'multipart/form-data',
            'Accept': '*/*'
          },
        })
        newArr.push(data)
      })
    )
    return newArr
  }
}

//路由参数
const getQueryVariableToObj = () => {
  var query = window.location.search.substring(1);
  var vars = query.split("&");
  const _obj = {}
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split("=");
    _obj[pair[0]] = pair[1]
    // if(pair[0] == variable){
    //   return pair[1];
    // }
  }
  return _obj;
}


/**
 * JSON 克隆
 * @param {Object | Json} jsonObj json对象
 * @return {Object | Json} 新的json对象
 */
export function objClone(jsonObj) {
  var buf;
  if (jsonObj instanceof Array) {
    buf = [];
    var i = jsonObj.length;
    while (i--) {
      buf[i] = objClone(jsonObj[i]);
    }
    return buf;
  } else if (jsonObj instanceof Object) {
    buf = {};
    for (var k in jsonObj) {
      buf[k] = objClone(jsonObj[k]);
    }
    return buf;
  } else {
    return jsonObj;
  }
}

/**
 * 将[d, h, m] 转换成分钟数
 * @param {Array}
 * @return {Number} 分钟数
 */
export function parseTimeToMinutes(arr = []) {
  const [d = 0, h = 0, m = 0] = arr;
  return (d * 24 * 60) + (h * 60) + m;
}

/**
 * 将分钟数转换成 [d, h, m]
 * @param {Number}
 * @return {Array}
 */
export function parseMinutesToArr(mins = 0) {
  const d = parseInt(mins / (24 * 60));
  const h = parseInt(mins % (24 * 60) / 60);
  const m = mins % (24 *60) % 60;

  return [d, h, m];
}

/**
 * 下载接口返回的文件流
 * @param {*} data 文件流
 * @param {*} type 文件类型
 * @param {*} fileName 下载文件名
 */
export function downloadFile(data, type = 'vnd.ms-excel', fileName = '') {
  const blob = new Blob([data], { type: `application/${type};charset=utf-8`} );
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = fileName || newFormatTime(new Date(), 'YYYYMMDDHHmmss') + '下载文件';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
}

export {
  parseQueryString,
  formatTime,
  formatAllTime,
  formatTableKey,
  formatTopCateTree,
  formatHomeOperateCateTree,
  formatCateTable,
  formatStoreCateTree,
  formatLabelTree,
  formatNull,

  formatArea,
  matchAreaId,
  formatAddress,
  formatEmpty,
  formatStairPriceParams,
  formatSelfPriceParams,
  formatKeysArray,
  formatPriceUnitEnlarge,
  regFenToYuan,
  formatAmount,
  binary,
  uploadMoreFiles,
  getQueryVariableToObj

}
