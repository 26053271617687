import {observable, action} from "mobx";
import httpClient from "utils/httpClient";
import api from "config/api"

const {common} = api

class CommonStore {
  /* 用户信息 */
  @observable userInfo = {};
  @observable expressList = [];
  @observable expressInfo = {expressInfo: []};
  @observable commonLocationList = [];


  /**
   * 获取短信验证码
   * @param params
   * @returns {Promise<AxiosResponse<any>>}
   */
  @action
  getSmsCode = async params => {
    const res = await httpClient.post(common.POST_GET_SMS_CODE, params)
    return res
  }

  /**
   * 密码登录
   * @param params
   * @returns {Promise<AxiosResponse<any>>}
   */
  @action
  loginByPwd = async params => {
    const res = await httpClient.post(common.POST_LOGIN_BY_PWD, params);
    return res;
  }

  @action
  loginByPwd2 = async params => {
    const res = await httpClient.post(common.POST_LOGIN_BY_PWD_2, params);
    if (res.success) {
      localStorage.setItem("token", res.data.authorityToken);
      localStorage.setItem("userInfo", JSON.stringify(res.data))
    }
    return res;
  }
  /**
   * 短信验证码
   * @param params
   * @returns {Promise<AxiosResponse<any>>}
   */
  @action
  loginBySms = async params => {
    const res = await httpClient.post(common.POST_LOGIN_BY_SMS, params);
    if (res.success) {
      localStorage.setItem("token", res.data.authorityToken);
      localStorage.setItem("userInfo", JSON.stringify(res.data))
    }
    return res;
  }
  @action
  loginBySms2 = async params => {
    const res = await httpClient.post(common.POST_LOGIN_BY_SMS_2, params);
    if (res.success) {
      localStorage.setItem("token", res.data.authorityToken);
      localStorage.setItem("userInfo", JSON.stringify(res.data))
    }
    return res;
  }

  /**
   * 获取用户的状态信息
   */
  @action
  postUserInfo = async (params) => {
    const data = await httpClient.post(common.POST_USER_INFO, {...params});
    if (data.success) {
      this.userInfo = data.data;
    }
  }

  /**
   * 退出登录
   * @returns {Promise<void>}
   */
  @action
  logout = async () => {
    const res = await httpClient.post(common.POST_LOGOUT)
    if (res.success) {
      window.open('/login', "_self")
      localStorage.removeItem('userInfo')
      localStorage.removeItem('token')
      localStorage.removeItem('modifyReason')
      sessionStorage.removeItem('pathList')
    }
  }

  /**
   * 查询账户是否开通商铺，是否有供应商管理权限
   * @returns {Promise<T>}
   */
  @action
  getAccountRights = async () => {
    const res = await httpClient.get(common.GET_ACCOUNT_RIGHTS)
    if (res.success) {
      localStorage.setItem("accountRights", JSON.stringify(res.data))
      return res.data
    }
  }

  /**
   * 获取供应商简称列表（下拉）
   * @returns {Promise<T>}
   */
  getSupplierSimpleList=async ()=>{
    const res=await httpClient.get(common.GET_SUPPLIER_SIMPLE_LIST);
    if(res.success){
      return res.data
    }
  }

  /**
   * 获取三级地址
   * @param params
   * @returns {Promise<*[]|*>}
   */
  @action
  getLocationLevel = async params => {
    const res = await httpClient.post(common.GET_LEVEL_PROVINCE_CITY_DISTRICT, params)
    if (res.success) {
      return res.data.map(item => ({
        name: item.name,
        id: item.code,
        level: item.level
      }))
    }
    return []
  }
  /**
   * 获取二级分类
   * @param params
   * @returns {Promise<*[]|*>}
   */
  @action
  getCategoryLevel = async params => {
    const res = await httpClient.post(common.POST_GET_LEVEL_CATEGORY, params)
    if (res.success) {
      return res.data.map(item => ({
        name: item.categoryName,
        id: item.categoryId,
        level: item.categoryLevel
      }))
    }
    return []
  }

   /**
   * 获取关键字搜索分类
   * @param params
   * @returns {Promise<*[]|*>}
   */
  @action
  getCategorySearch = async params => {
    const res = await httpClient.post(common.POST_GET_SEARCH_CATEGORY, params)
    if (res.success) {
      return res.data
    }
    return []
  }


  /**
   * 上传文件
   */
  @action
  postBatchUploadFile = async (params) => {
    const data = await httpClient.post(common.POST_BATCH_UPLOAD_FILE_RETURN_IDS, params);
    return data;
  }

  /**
   * 上传单个文件
   * @param params
   * @returns {Promise<{success}|any|AxiosResponse<T>>}
   */
  @action
  postUploadFile = async (params) => {
    const data = await httpClient.post(common.POST_UPLOAD_FILE, params, {withCredentials: true})
    if (data.success) {
      return data
    }
  }

  /**
   * 上传多个文件
   * @param params
   * @returns {Promise<{success}|any|AxiosResponse<T>>}
   */
  @action
  postUploadFiles = async (params) => {
    const res = await httpClient.post(common.POST_UPLOAD_FILES, params, {withCredentials: true})
    if (res.success) {
      return res.data
    }
  }

  /**
   * 获取快递公司列表
   * @param params
   * @returns {Promise<void>}
   */
  @action
  getExpressList = async (params) => {
    const res = await httpClient.get(common.GET_EXPRESS_LIST, {params})
    if (res.success) {
      this.expressList = res.data
    }
    return this.expressList
  }

  @action
  getExpressInfo = async params => {
    const res = await httpClient.get(common.GET_EXPRESS_INFO, {params})
    if (res.success) {
      this.expressInfo = res.data
    }
    return this.expressInfo
  }

  /**
   * 获取通用的三级地址
   * @param params
   * @returns {Promise<void>}
   */
  @action
  async postLocationByParent(params) {
    const data = await httpClient.post(common.GET_LEVEL_PROVINCE_CITY_DISTRICT, {...params})
    if (data.success) {
      let arr = data.data.map(item => ({
        parentId: item.parentCode,
        id: item.code,
        name: item.name,
        level: item.level
      }));
      this.commonLocationList = arr;
      return arr
    }
  }
}

export default new CommonStore();
