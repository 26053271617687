import React, { Component } from 'react';
import {
  Button,
  Select,
  Input,
  DatePicker,
  Radio,
  Table,
  Icon,
  Alert,
  Statistic,
} from 'antd';
import { inject, observer } from 'mobx-react';
import { formatTime, showMoney } from 'utils/tools';

import styles from './index.module.scss';

const { Option } = Select;
const { RangePicker } = DatePicker;

@inject(...['financeStore'])
@observer
class FinanceDealDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timeType: 1,
      date: [],
      dateStr: [],
      btnTime: null,
      dealType: undefined,
      feeType: undefined,
      keywordType: 1,
      keyword: '',

      pageNo: 1,
      pageSize: 20,
    };
  }

  componentDidMount() {
    this.getData();
  }

  /**
   * 改变时间range
   * @param date
   * @param dateStr
   */
  changeTime = (date, dateStr) => {
    this.setState({
      date,
      dateStr,
      btnTime: null,
    });
  };

  /**
   * 改变时间radio
   * @param e
   */
  changeBtnTime = (e) => {
    let val = e.target.value;
    this.setState({
      btnTime: val,
    });
  };

  /**
   * 获取数据
   *
   */
  getData = () => {
    const {
      dateStr,
      btnTime,
      dealType,
      feeType,
      keywordType,
      keyword,
      pageNo,
      pageSize,
    } = this.state;
    const { financeStore } = this.props;
    const bizTypeArr = ['', '101001', '104001', '103001'],
      dataRangeObj = {
        1: 'today',
        7: 'latest7',
        15: 'latest15',
        30: 'latest30',
      };
    let params = {
      payTimeEnd: dateStr[0],
      payTimeStart: dateStr[1],
      dateRange: dataRangeObj[btnTime],
      bizType: bizTypeArr[dealType],
      feeType,
      keyType: keywordType,
      keyNo: keyword || null,
    };
    financeStore.postDealDetailData(params);
    Object.assign(params, {
      pageNo: pageNo,
      pageSize,
    });
    financeStore.postDealDetailsList(params);
  };

  /**
   * 查找
   */
  screen = () => {
    this.setState(
      {
        pageNo: 1,
      },
      () => {
        this.getData();
      }
    );
  };

  /**
   * 渲染表格
   * @returns {*}
   */
  renderTable = () => {
    const { dealDetailList } = this.props.financeStore;
    const typeMap = {
        101001: '销售',
        102001: '充值',
        104001: '退款',
        103001: '结算',
      },
      feeTypeMap = {
        10100101: '商品', //（销售子类型）
        10100102: '运费', //（销售子类型）
        10100103: '采购', //（销售子类型）
        10200101: '充值', //（充值子类型）
        10300101: '结算手续费', //（结算子类型）
        10300102: '结算款', //（结算子类型）
        10400101: '运费退款', //（退款子类型）
        10400102: '商品退款', //（退款子类型
        10500101: '结算手续费',
      },
      flagArr = ['+', '-'];
    const columns = [
      {
        title: '入账时间',
        align: 'center',
        dataIndex: 'payTime',
        render: (text, record, index) => formatTime(text),
      },
      {
        title: '财务流水号丨交易单号',
        align: 'center',
        render: (text, record, index) => (
          <div>
            {record.serialNo}
            {record.orderNo ? ' | ' : ''}
            <span
              className={styles.table_action_btn}
              style={{ cursor: 'default' }}
            >
              {record.orderNo}
            </span>
          </div>
        ),
      },
      {
        title: '交易类型',
        align: 'center',
        dataIndex: 'type',
        render: (text, record, index) => typeMap[text],
      },
      {
        title: '费用科目',
        align: 'center',
        dataIndex: 'feeType',
        render: (text, record, index) => feeTypeMap[text],
      },
      {
        title: '收支金额（元）',
        align: 'center',
        dataIndex: 'amount',
        render: (text, record, index) => (
          <span
            style={{
              color: +record.flag ? '#EC2200' : '#1C9F5D',
            }}
          >{`${flagArr[record.flag]}${showMoney(text)}`}</span>
        ),
      },
      {
        title: '实时余额（元）',
        align: 'center',
        dataIndex: 'restAmount',
        render: (text, record, index) => showMoney(text),
      },
      {
        title: '操作',
        align: 'center',
        render: (text, record, index) => (
          <span
            className={styles.table_action_btn}
            onClick={() => this.goToDetail(record)}
          >
            详情
          </span>
        ),
      },
    ];

    /**
     * 分页组件
     * @type {{current: *, total: number, defaultPageSize: number, pageSizeOptions: string[], onChange: FinanceDealDetail.paginationChange, showTotal: (function(*): string), pageSize: *, onShowSizeChange: FinanceDealDetail.paginationPageSizeChange, showQuickJumper: boolean, showSizeChanger: boolean}}
     */
    const pagination = {
      current: this.state.pageNo,
      pageSize: this.state.pageSize,
      total: dealDetailList.total,
      showTotal: (total) => `共有${total}条纪录  `,
      showSizeChanger: true,
      showQuickJumper: true,
      defaultPageSize: 20,
      pageSizeOptions: ['20', '50', '100'],
      onChange: this.paginationChange,
      onShowSizeChange: this.paginationPageSizeChange,
    };
    return (
      <Table
        pagination={pagination}
        columns={columns}
        scroll={{ x: '1200px' }}
        dataSource={dealDetailList.list}
        rowKey={(record) => record.serialNo}
      />
    );
  };

  /**
   * 分页组件页码变动回调
   * @param page
   * @param pageSize
   */
  paginationChange = (page, pageSize) => {
    this.setState(
      {
        pageNo: page,
      },
      () => {
        this.getData();
      }
    );
  };

  /**
   * 分页组件pageSize变化回调
   * @param current
   * @param size
   */
  paginationPageSizeChange = (current, size) => {
    this.setState(
      {
        pageNo: 1,
        pageSize: size,
      },
      () => {
        this.getData();
      }
    );
  };

  /**
   * 跳转详情
   */
  goToDetail = async (record) => {
    const { getParentOrderNo } = this.props.financeStore;
    //flag:0收入，订单，1支出，结算
    if (+record.flag) {
      if (record.type === '104001') {
        // const { data } = await getParentOrderNo({
        //   parentOrderNo: record.orderNo,
        // });
        window.open(`/order/service/detail?id=${record.orderNo}`, '_self'); //退款--售后页面 :
      } else {
        window.open(
          `/finance/center/settlement-progress-detail?id=${record.orderNo}`,
          '_self'
        );
      }
    } else {
      window.open(`/order/list/detail/once?id=${record.orderNo}`, '_self');
    }
  };

  render() {
    const { dealDetailData } = this.props.financeStore;
    // const containerHeight=window.innerHeight-80,containerWidth=window.innerWidth-200;
    return (
      <div className={styles.scroll_page}>
        <div className={styles.page_container}>
          <div className={styles.page_top}>
            <div className={styles.screen_container}>
              {/*<Select style={{width: 120, marginRight: 20}}*/}
              {/*        value={this.state.timeType}*/}
              {/*        onChange={val => this.setState({*/}
              {/*          timeType: val*/}
              {/*        })}>*/}
              {/*  <Option value={1} key={1}>入账时间</Option>*/}
              {/*  /!*<Option value={2} key={2}>下单时间</Option>*!/*/}
              {/*</Select>*/}
              {/*<span>入账时间：</span>*/}
              <RangePicker
                onChange={this.changeTime}
                style={{ marginRight: 20 }}
              />
              <Radio.Group
                value={this.state.btnTime}
                onChange={this.changeBtnTime}
                buttonStyle="solid"
                style={{ marginRight: '20px' }}
              >
                <Radio.Button value="1">今日</Radio.Button>
                <Radio.Button value="7">近7日</Radio.Button>
                <Radio.Button value="15">近15日</Radio.Button>
                <Radio.Button value="30">近30日</Radio.Button>
              </Radio.Group>
              <Select
                style={{ width: 120, marginRight: 20 }}
                placeholder="交易类型"
                value={this.state.dealType}
                allowClear
                onChange={(val) =>
                  this.setState({
                    dealType: val,
                  })
                }
              >
                <Option value={1} key={1}>
                  销售
                </Option>
                <Option value={2} key={2}>
                  退款
                </Option>
                <Option value={3} key={3}>
                  结算
                </Option>
              </Select>
              <Select
                style={{ width: 120, marginRight: 20 }}
                placeholder="费用科目"
                value={this.state.feeType}
                allowClear
                onChange={(val) =>
                  this.setState({
                    feeType: val,
                  })
                }
              >
                <Option value={'10100101'} key={'10100101'}>
                  商品
                </Option>
                <Option value={'10100102'} key={'10100102'}>
                  运费
                </Option>
                <Option value={'10300102'} key={'10300102'}>
                  结算款
                </Option>
                <Option value={'10500101'} key={'10500101'}>
                  结算手续费
                </Option>
              </Select>
              <Select
                style={{ width: 150 }}
                value={this.state.keywordType}
                onChange={(val) =>
                  this.setState({
                    keywordType: val,
                  })
                }
              >
                <Option value={1} key={1}>
                  根据财务流水号
                </Option>
                <Option value={2} key={2}>
                  根据订单号
                </Option>
              </Select>
              <Input
                allowClear={true}
                placeholder="输入关键字查询"
                value={this.state.keyword}
                onChange={(e) =>
                  this.setState({ keyword: e.target.value.trim() })
                }
                style={{
                  width: 150,
                  marginRight: 20,
                }}
              />
              <Button
                type="primary"
                style={{ width: 100, marginRight: 20 }}
                onClick={this.screen}
              >
                筛选
              </Button>
              <Button type="default" disabled={true}>
                <Icon type="download" style={{ fontSize: 18 }} /> 下载
              </Button>
            </div>
            <div className={styles.info_container}>
              <Alert
                message={false}
                description={
                  <div className={styles.alert_container}>
                    <div className={styles.alert_title}>
                      <p>金额统计</p>
                    </div>
                    <div className={styles.alert_content}>
                      <p className={styles.alert_content_title}>收入</p>
                      <div className={styles.alert_content_money}>
                        <Statistic
                          value={dealDetailData.income / 100}
                          precision={2}
                          valueStyle={{ color: '#3f8600' }}
                          prefix="+"
                          suffix="元"
                        />
                      </div>
                      <p className={styles.alert_content_num}>
                        {dealDetailData.incomeCount} 笔
                      </p>
                    </div>
                    <div className={styles.alert_content}>
                      <p className={styles.alert_content_title}>支出</p>
                      <div className={styles.alert_content_money}>
                        <Statistic
                          value={dealDetailData.expense / 100}
                          precision={2}
                          valueStyle={{ color: '#cf1322' }}
                          prefix="-"
                          suffix="元"
                        />
                      </div>
                      <p className={styles.alert_content_num}>
                        {dealDetailData.expenseCount} 笔
                      </p>
                    </div>
                  </div>
                }
                type="info"
              />
            </div>
          </div>
          <div className={styles.table_container}>{this.renderTable()}</div>
        </div>
      </div>
    );
  }
}

export default FinanceDealDetail;
