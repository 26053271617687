import React, {useState, useEffect} from 'react';
import {
  Tabs,
  Table,
  Pagination,
  Space,
  Button,
  Form,
  Input,
  DatePicker,
} from 'antd';
import lodash from 'lodash';
import {withRouter} from 'react-router-dom';
import styles from './index.module.scss';
import {formatTime, getQuery, showMoney} from 'utils/tools';
import {Modals} from 'components';
import stores from 'stores';
import moment from 'moment';

const {TabPane} = Tabs;
const {RangePicker} = DatePicker;
const {OrderChangeMoneyModal, OrderGoodsDeliverModal} = Modals;
const {orderInfoStore} = stores;

const widthArr = [400, 200, 200, 200, false];
const plateformMap=new Map([
  [1,"集采订单"],
  [2,"一件代发"],
])
const columns = [
  {
    title: '商品',
    align: 'center',
    dataIndex: '',
    width: widthArr[0],
    render: (text, record, index) => {
      let {goodsAttribute} = record;
      let str = '';
      if (goodsAttribute) {
        goodsAttribute = JSON.parse(goodsAttribute);
        str = `规格：${Object.values(goodsAttribute).join(' ')}`;
      }
      return (
        <div className={styles.item_container}>
          <div className={styles.item_img_container}>
            <img
              src={record.goodsImageUrl}
              className={styles.item_img}
              alt=""
            />
          </div>
          <div className={styles.item_info_container}>
            <p className={styles.item_name}>{record.goodsName}</p>
            <p className={styles.item_attr}>{str}</p>
          </div>
        </div>
      );
    },
  },
  {
    title: '供应价',
    align: 'center',
    dataIndex: 'supplyPrice',
    width: widthArr[1],
    render: (text, record) => `¥${showMoney(text)}`,
  },
  {
    title: '数量',
    align: 'center',
    width: widthArr[2],
    dataIndex: 'quantity',
  },
  {
    title: '订单状态',
    align: 'center',
    width: widthArr[3],
    dataIndex: '',
    render: (text) => 123,
  },
  {
    title: '操作',
    align: 'center',
    width: widthArr[4],
    dataIndex: '',
    render: (text) =>blankButton ,
  },
];
// const blankButton=<Button style={{opacity:0}}>去发货</Button>
const blankButton=""
function OrderList(props) {
  const [form] = Form.useForm();
  const [tab, setTab] = useState('0');
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [searchParams, setSearchParams] = useState({});
  const [dataList, setDataList] = useState({list: [], total: 0});
  const [refreshData, setRefreshData] = useState(true);

  const [showChangeMoney, setShowChangeMoney] = useState(false);
  const [showGoodsDeliver, setShowGoodsDeliver] = useState(false);
  const [actionItem, setActionItem] = useState({});
  useEffect(() => {
    // 获取链接的tab值 10 -10 -20
    let query = getQuery();
    console.log(props.location.search);
    if (query.tab) {
      setTab(query.tab);
      setRefreshData(true);
    }
  }, [props.location.search]);
  useEffect(() => {
    refreshData && _getData();
    refreshData && setTimeout(() => setRefreshData(false));
  }, [refreshData]);

  const _getData = async () => {
    if (tab === 'modify') {
      // 改价记录
    } else {
      // 订单
      let params = {
        orderStatusList: +tab === 0 ? undefined : tab.split(','),
        pageNo,
        pageSize,
        ...searchParams,
      };
      let data = await orderInfoStore.getOrderList(params);
      setDataList(data);
    }
  };
  const tableAction = (data, type) => {
    setActionItem(data);
    switch (type) {
      case 'modify':
        setShowChangeMoney(true);
        break;
      case 'deliver':
        setShowGoodsDeliver(true);
        break;
    }
  };

  /**
   * 页码改变
   * @param page
   * @param pageSize
   * @returns {Promise<void>}
   */
  const changePage = (page, pageSize) => {
    setPageNo(page);
    setRefreshData(true);
  };

  /**
   * pageSize改变
   * @param current
   * @param size
   * @returns {Promise<void>}
   */
  const changePageSize = (current, size) => {
    setPageNo(1);
    setPageSize(size);
    setRefreshData(true);
  };
  const pagination = {
    total: dataList.total,
    pageSize,
    current: pageNo,
    showSizeChanger: true,
    showQuickJumper: true,
    pageSizeOptions: ['20', '50', '100'],
    defaultPageSize: 20,
    onChange: changePage,
    onShowSizeChange: changePageSize,
    showTotal: (total) => `共 ${total} 条数据`,
  };


  const search = values => {
    console.log(values)

    let params = {
      startTime: values.timeRange && values.timeRange[0]
        ? values.timeRange[0].format('YYYY-MM-DD HH:mm:ss')
        : undefined,
      endTime: values.timeRange && values.timeRange[1]
        ? values.timeRange[1].format('YYYY-MM-DD HH:mm:ss')
        : undefined,
      goodsName: values.name,
      orderNo: values.orderId,
    };
    setSearchParams(params);
    setPageNo(1)
    setRefreshData(true);
  };

  return (
    <>
      <Tabs activeKey={tab} onChange={val => {
        form.resetFields();
        setSearchParams({})
        setTab(val)
        setRefreshData(true)
      }}>
        <TabPane key="0" tab="全部订单"/>
        <TabPane key="4" tab="待付款"/>
        <TabPane key="5,7" tab="待发货"/>
        <TabPane key="6" tab="已发货"/>
        <TabPane key="10" tab="已完成"/>
        {/*<TabPane key="modify" tab="改价记录"/>*/}
      </Tabs>
      <Form
        form={form}
        layout="inline"
        onFinish={search}
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          padding: '16px 0',
        }}
      >
        <Form.Item name="timeRange" label="下单时间">
          <RangePicker
            showTime={{
              defaultValue: [
                moment('00:00:00', 'HH:mm:ss'),
                moment('23:59:59', 'HH:mm:ss'),
              ],
            }}
          />
        </Form.Item>
        <Form.Item name="name">
          <Input
            allowClear
            placeholder="请输入商品名称"
            style={{width: 200}}
          />
        </Form.Item>
        <Form.Item name="orderId">
          <Input
            allowClear
            placeholder="请输入订单编号"
            style={{width: 200}}
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            筛选
          </Button>
        </Form.Item>
      </Form>
      <div className={styles.table_container}>
        {dataList.list.length ? (
          <div className={styles.head_container}>
            <TableHead columns={columns}/>
          </div>
        ) : (
          <TableHead columns={columns}/>
        )}
        <div className={styles.tbody_container}>
          {dataList.list.map((item, index) => (
            <TableItem
              columns={columns}
              data={item}
              type={item.status}
              key={item.key}
              onAction={tableAction}
            />
          ))}
        </div>
        <div className={styles.pagination_container}>
          {!!dataList.list.length && <Pagination {...pagination} />}
        </div>
      </div>

      {showChangeMoney && (
        <OrderChangeMoneyModal
          visible={showChangeMoney}
          list={actionItem.goodsList || []}
          express={actionItem.expressCost || 0}
          orderId={actionItem.shopOrderNo}
          onCancel={(flag) => {
            setShowChangeMoney(false);
            if (flag) {
              setRefreshData(true)
            }
          }}
          onOk={() => {
          }}
        />
      )}

      {showGoodsDeliver && (
        <OrderGoodsDeliverModal
          visible={showGoodsDeliver}
          // visible={true}
          info={actionItem}
          onCancel={(flag) => {
            setShowGoodsDeliver(false);
            if (flag) {
              setRefreshData(true)
            }
          }}
          onOk={() => {
          }}
        />
      )}
    </>
  );
}

const TableItem = withRouter((props) => {
  const {data, columns, type, onAction} = props;
  let newColumns = lodash.cloneDeep(columns);
  {
    /*订单状态：1-新单，2-提交审批，3-待审批，4-待付款，5-待发货，6-已发货，7-部分发货，8-已关闭，9-待接口确认，10-已完成，11-已取消*/
  }
  switch (+type) {
    case 2:
      newColumns[3].render = (text, record, index) => getColSpan(text, record, index, "提交审批")
      newColumns[4].render = (text, record, index) => getColSpan(text, record, index, (+data.approvalStatus === 1) &&
        <Button type="primary" onClick={() => onAction(data, 'modify')}>
          修改订单
        </Button>);
      break
    case 4: //待付款
      newColumns[3].render = (text, record, index) => getColSpan(text, record, index, getPayDesc());
      newColumns[4].render = (text, record, index) => getColSpan(text, record, index, (+data.approvalStatus === 0) ?
        <Button type="primary" onClick={() => onAction(data, 'modify')}>
          修改订单
        </Button>: blankButton);
      break;
    case 5: //待发货
    case 7: //部分发货
      newColumns[3].render = (text, record, index) => getColSpan(text, record, index, "待发货");
      newColumns[4].render = (text, record, index) =>
        getColSpan(text, record, index,
          <Button type="primary"
                  onClick={() => onAction(data, 'deliver')}>
            去发货
          </Button>);
      break;
    case 6: //已发货
      newColumns[3].render = (text, record, index) => getColSpan(text, record, index, "已发货");
      newColumns[4].render = (text, record, index) => getColSpan(text, record, index,blankButton);
      break;
    case 10: //已完成
      newColumns[3].render = (text, record, index) => getColSpan(text, record, index, "已完成");
      newColumns[4].render = (text, record, index) => getColSpan(text, record, index,blankButton);
      break;
    case 8: //已关闭
    case 11: //已取消
      newColumns[3].render = (text, record, index) => getColSpan(text, record, index, "已关闭");
      newColumns[4].render = (text, record, index) => getColSpan(text, record, index, blankButton);
      break;
  }

  const getColSpan = (text, record, index, value) => {
    const obj = {
      children: value,
      props: {},
    };
    if (index > 0) {
      obj.props.rowSpan = 0;
    } else {
      obj.props.rowSpan = data.orderGoodsList.length;
    }
    return obj
  }

  const getPayDesc = () => {
    let now = new Date().getTime();
    let delta = data.createTime + 24 * 60 * 60 * 1000 - now;
    let hours = parseInt(delta / (60 * 60 * 1000));
    let minutes = parseInt(
      (delta - hours * (60 * 60 * 1000)) / (60 * 1000)
    );
    let str = `
<p>如该订单未在24小时内付款，订单将自动关闭<br/>
支付截止剩余时间：${
      hours > 0 ? hours + '小时' + minutes + '分' : minutes + '分钟'
    }</p>`;
    return (
      <>
        <span>待付款</span>
        <div
          style={{fontSize: '12px'}}
          dangerouslySetInnerHTML={{__html: str}}
        />
      </>
    );
  }
  return (
    <>
      <div className={styles.table_item_head}>
        <Space>
          <span>订单编号：{data.parentOrderNo}</span>
          <span>下单时间：{formatTime(data.createTime)}</span>
          {/*<span>共{data.goodsNumber}件商品</span>*/}
          <span>订单类型：{plateformMap.get(data.platform)/* TODO:对接口订单类型数据*/}</span>
        </Space>
        <Space>
          <span>
            订单总价：¥{showMoney(data.orderTotalPrice)}（含运费：¥
            {showMoney(data.expressCost)}）
          </span>
          <Button
            type="link"
            onClick={() => {
              window.open(`/order/list/detail?id=${data.parentOrderNo}`, '_self');
            }}
          >
            查看详情
          </Button>
        </Space>
      </div>
      <Table
        columns={newColumns}
        dataSource={data.orderGoodsList}
        showHeader={false}
        pagination={false}
        rowKey={(record) => record.orderGoodsId}
      />
    </>
  );
});

function TableHead(props) {
  const {columns} = props;
  return <Table columns={columns} size="middle" pagination={false}/>;
}

export default withRouter(OrderList);
