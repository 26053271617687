import Loading from './Loading'
import Bread from './Bread'
import Footer from "./Footer"
import TopBar from './TopBar'
import GoodsAddCard from './GoodsAddCard'

import ImgScroll from "./ImgScroll"

import CheckTags from './CheckTags';
import ScrollImgs from './ScrollImgs';
import ModuleTitle from './ModuleTitle'
import PreviewPic from "./PreviewPic";
import RangeInput from './RangeInput'
import ButtonGroupRadio from './ButtonGroupRadio'
// import PageMenu from './PageMenu'
import Order from "./Order"
//ListItem

//Modal
import Modals from './Modals'


const ListItem = {}

export {
  Loading,
  Bread, Footer, TopBar, GoodsAddCard,ImgScroll,
  CheckTags, ScrollImgs, ModuleTitle, RangeInput, Order, PreviewPic, ButtonGroupRadio,
  ListItem, Modals
}
