import React, { Component } from 'react';
import { Breadcrumb } from 'antd'
import { Link } from 'react-router-dom'

import styles from './index.module.scss';

class Bread extends Component {
	constructor(props) {
		super(props)
		this.state = {
		}
	}

	render () {
		return (
			<div className={styles.bread_container}>
				<Breadcrumb style={{ margin: 10 }}>
					{this.props.breadcrumbArr.map(item => (
						<Breadcrumb.Item key={item.id} routes={item.route}>
							<Link to={item.route ? item.route : '#'}>	{item.name}</Link>
						</Breadcrumb.Item>
					))}
				</Breadcrumb>
			</div>
		)
	}
}

export default Bread
