import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { inject, observer } from "mobx-react";
import { Menu, Icon, Button } from "antd";
import {DoubleRightOutlined} from "@ant-design/icons"

import route from "config/route";

import styles from "./index.module.scss";

const { SubMenu } = Menu;
@inject("commonStore")
@withRouter
@observer
class LeftNav extends Component {
  state = {
    collapsed: false
  };

  // 菜单收起
  toggleCollapsed = () => {
    this.setState({
      collapsed: !this.state.collapsed
    });
  };

  // 菜单选中
  handleSelect = ({ key }) => {
    this.props.history.push(key);
  };

  render() {
    const { collapsed } = this.state;
    // 选中的顶部menu
    const { topMenu } = this.props.commonStore;
    // const routeList = route.filter(item => item.key === topMenu);
    let arr = this.props.location.pathname.split("/");
    let key = "";
    if (arr.length > 3) {
      key = arr.splice(0, 3).join("/");
    } else {
      key = arr.join("/");
    }
    return (
      <div className={styles.left_nav}>
        <div className={styles.logo_box}>
          <div className={styles.logo}></div>
        </div>
        <Menu
          defaultSelectedKeys={[key]}
          defaultOpenKeys={[this.props.location.pathname.split("/")[1]]}
          mode="inline"
          inlineCollapsed={this.state.collapsed}
          onSelect={this.handleSelect}
        >
          {route.map((item, index) =>
            item.children ? (
              <SubMenu
                key={item.key}
                title={
                  <span>
                    <Icon type={item.icon} />
                    <span>{item.name}</span>
                  </span>
                }
              >
                {item.children.map((ele, i) => (
                  <Menu.Item
                    onClick={() => {
                      this.handleSelect({ key: ele.route });
                    }}
                    key={ele.route}
                  >
                    {ele.name}
                  </Menu.Item>
                ))}
              </SubMenu>
            ) : (
              <Menu.Item key={item.route}>
                <Icon type={item.icon} />
                <span>{item.name}</span>
              </Menu.Item>
            )
          )}
       </Menu>
        {/* <Button type="link" className={styles.collapse_btn} onClick={this.toggleCollapsed}>
          {collapsed ? <DoubleRightOutlined/> : "收起"}
        </Button> */}
      </div>
    );
  }
}

export default LeftNav;
