import React, {useState, useEffect} from 'react';
import {Button, Descriptions, Row, Col, Modal, Input, Form} from "antd";
import styles from './index.module.scss'
import {GoodsAddCard, Modals} from 'components'

const {Item} = Descriptions
const {SupplyChangePhoneModal, SupplyChangePwdModal} = Modals

const getLabel = (str) => <p className={styles.item_label}>
  <span className={styles.label_txt}>{str}</span>
</p>
const Edit = () => {
  const [showChangePwd, setShowChangePwd] = useState(false)
  const [showChangePhone, setShowChangePhone] = useState(false)
  const changePwd = () => {
    setShowChangePwd(true)
  }

  const changePhone = () => {
    setShowChangePhone(true)
  }


  return (
    <>
      <GoodsAddCard title="账号信息">
        <Descriptions column={1}>
          <Item label={getLabel("账号名称")}>{}</Item>
          <Item label={getLabel("供应商简称")}></Item>
          <Item label={getLabel("登录密码")}><Button type="link" onClick={changePwd}>修改密码</Button></Item>
          <Item label={getLabel("安全手机号")}><Button type="link" onClick={changePhone}>修改手机号</Button></Item>
        </Descriptions>

        <Row style={{paddingTop: 32}}>
          <Col span={6} offset={6}>
            <Button type="primary" style={{width: 100}}>保存</Button>
          </Col>
        </Row>
      </GoodsAddCard>
      {showChangePwd &&
      <SupplyChangePwdModal visible={showChangePwd} onCancel={(flag => {
        if (flag) {

        }
        setShowChangePwd(false)
      })}/>}
      {showChangePhone &&
      <SupplyChangePhoneModal visible={showChangePhone} onCancel={flag => {
        if (flag) {
        }
        setShowChangePhone(false)
      }}/>}
    </>
  );
};

export default Edit;
