import React from 'react';
import {Route, Switch} from "react-router-dom";

import List from "./List";
import OrderDetail from './List/Detail'
import ListOnce from './List/Once/List'
import DetailOnce from "./List/Once/Detail";
import OrderChangePriceDetail from './List/ChangePriceDetail'
import Service from "./Service";
import ServiceDetail from './Service/Detail'

function OrderModule(props) {
  return (
    <Switch>
      <Route path="/order/list" exact component={List}/>
      <Route path="/order/list/detail" exact component={OrderDetail}/>
      <Route path="/order/list/once" exact component={ListOnce}/>
      <Route path="/order/list/detail/once" exact component={DetailOnce}/>
      <Route path="/order/list/change-price-detail" exact component={OrderChangePriceDetail}/>
      <Route path="/order/service" exact component={Service}/>
      <Route path="/order/service/detail" exact component={ServiceDetail}/>
    </Switch>
  )
}

export default OrderModule;
