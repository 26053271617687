import React, {Component} from 'react'

import styles from "./index.module.scss"


/**
 * @props style:Object
 * @props title:String
 * @props color:String
 * @value value:String
 *
 * @props onChange:Function(value) ""|"desc"|"asc"
 */
class Order extends Component {
  constructor(props) {
    super(props);
    this.state = {
      index:0,
      value:""
    }
  }
  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.value !== this.props.value) {
      this.setState({
        value: nextProps.value
      })
    }
  }

  click=()=>{
    const {order}=this.props;
    const orderMap=[""].concat(order||["asc","desc"]);
    const {value}=this.state;
    const index=orderMap.indexOf(value)

    let i=index+1>=orderMap.length?0:index+1;
    this.setState({
      index:i,
      value:orderMap[i]
    },()=>{
      this.props.onChange(this.state.value)
    })


  }
  render() {
    const {style, title,color} = this.props;
    const {value}=this.state;
    const colorMap = {theme: color||"#e3481f", disable: "#a6a6a6"};
    return (
      <div style={style} className={styles.order_container} onClick={this.click}>
        <span className={styles.title}>{title}</span>
        <svg width="8px" height="13px" viewBox="0 0 8 13">
          <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="平台商品" transform="translate(-194.000000, -194.000000)" fill="#A6A6A6">
              <g id="Group-11-Copy" transform="translate(147.000000, 190.000000)">
                <g id="Group-2-Copy" transform="translate(47.000000, 4.000000)">
                  <polygon id="Triangle" fill={value==="desc"?colorMap.theme:colorMap.disable}
                           transform="translate(4.000000, 10.300000) rotate(-270.000000) translate(-4.000000, -10.300000) "
                           points="6.66666667 10.3 1.33333333 14.3 1.33333333 6.3"></polygon>
                  <polygon id="Triangle-Copy" fill={value==="asc"?colorMap.theme:colorMap.disable}
                           transform="translate(4.000000, 3.300000) scale(1, -1) rotate(-270.000000) translate(-4.000000, -3.300000) "
                           points="6.66666667 3.3 1.33333333 7.3 1.33333333 -0.7"></polygon>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    );
  }
}

export default Order;

