import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Form, Modal, Select, Input, message} from "antd";

import stores from 'stores'

const {Item, useForm} = Form
const {Option} = Select
const {commonStore, orderServiceStore} = stores

function DealDeliver(props) {
  const {visible, onCancel, info} = props
  const [form] = useForm()

  const [expressList, setExpressList] = useState([])

  useEffect(() => {
    getExpressList()
  }, [])

  const getExpressList = async () => {
    const list = await commonStore.getExpressList({})
    setExpressList(list)
  }


  const confirm = () => {
    form.validateFields().then(async values => {
      let params = {
        refundOrderId: info.id,
        exchangeExpressCode: values.expressCompany,
        exchangeExpressName: expressList.find(item => item.expressCode === values.expressCompany).expressName,
        exchangeExpressSerial: values.expressNum,
      }
      let res = await orderServiceStore.changeGoods(params)

      if (res.success) {
        message.success("发货成功！")
        setTimeout(() => {
          onCancel(true)
        }, 1000)
      }
    })
  }

  const changeExpressNum = e => {
    let val = e.target.value.replace(/\s/, "")
    form.setFieldsValue({expressNum: val})
  }

  return (
    <Modal title="换货物流信息" visible={visible} onOk={confirm} onCancel={() => onCancel()} centered>
      <Form form={form}>
        <Item name="expressCompany"
              label={"物流公司"}
              layout={"inline"}
              rules={[{
                required: true,
                message: "请选择物流公司"
              }]}>
          <Select placeholder="请选择物流公司"
                  style={{width: 150}}>{
            expressList.map((item, index) => <Option value={item.expressCode} key={item.expressCode}>
              {item.expressName}
            </Option>)
          }
          </Select>
        </Item>
        <Item name="expressNum"
              label="物流单号"
              rules={[{
                required: true,
                message: "请输入物流单号"
              }]}>
          <Input placeholder="请填写物流单号"
                 style={{width: 150}} maxLength={20} onChange={changeExpressNum}/>
        </Item>
      </Form>
    </Modal>
  );
}

DealDeliver.propTypes = {
  visible: PropTypes.bool.isRequired,
  onOk: PropTypes.func,
  onCancel: PropTypes.func,
};

export default DealDeliver;
