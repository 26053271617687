import React from 'react';
import {Route, Switch} from "react-router-dom";


import Info from "./Info";
import Setting from "./Setting";

function StoreModule(props) {

  return (
    <Switch>
      <Route path="/store/info" exact component={Info}/>
      <Route path="/store/setting" exact component={Setting}/>
    </Switch>
  )
}

export default StoreModule;
