import React, { Component } from 'react'
import { Card, Descriptions, Row, Col } from "antd";
import { inject, observer } from "mobx-react";
import qs from 'qs'

import styles from './index.module.scss';
import { formatTime, getQuery, showMoney } from "utils/tools";
import stores from 'stores'

const { Item } = Descriptions;

const { goodsModifyStore } = stores

// const colSpan = [4, 20]

@inject(...["goodsModifyStore"])
@observer
class GoodsUpdateApplyDetail extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modifyDetail: {
        afterContent: {},
        beforeContent: {}
      }
    }
  }

  async componentDidMount() {
    const { getModifyDetail } = this.props.goodsModifyStore;
    let query = getQuery();
    let params = {
      id: query.id
    };
    const res = await getModifyDetail(params);
    if (res) {
      this.setState({
        modifyDetail: res
      })
    }

  }

  formatModifyInfo = () => {
    const { modifyDetail } = this.state;
    if (modifyDetail.beforeContent && modifyDetail.afterContent) {
      let before = [], after = [];

      function addModify(name, label, isArray) {
        let flag = isArray ?
          modifyDetail.beforeContent[name] ?
            modifyDetail.beforeContent[name].length
            : false
          : modifyDetail.beforeContent[name];
        if (flag) {
          let beforeObj = {
            label,
            value: modifyDetail.beforeContent[name]
          }, afterObj = {
            label,
            value: modifyDetail.afterContent[name]
          };
          before.push(beforeObj);
          after.push(afterObj);
        }
      }

      // 商品名称 name
      addModify("name", "商品名称");
      //商品类目 categoryName
      addModify("categoryName", "商品类目");
      // 品牌 brandName
      addModify("brandName", "品牌");
      // 产地 goodsArea
      addModify("goodsArea", "产地");
      // 型号 goodsNo
      addModify("goodsNo", "型号");
      // 条码 upc
      addModify("upc", "条码");
      // 商品图片
      addModify("imageList", "商品图片");

      return { before, after };
    }
    return {}
  }


  render() {
    const { modifyDetail } = this.state;
    const statusMap = {
      1: "已通过", 2: "待审核", 3: "已驳回"
    };
    const { beforeContent, afterContent } = modifyDetail
    // let {before, after} = this.formatModifyInfo();
    const freightTypeMap = {
      1: "包邮", 2: "运费模板"
    }, infoSaleTypeMap = {
      1: "按商品数量报价", 2: "按商品规格"
    };

    return (
      <div className={styles.page_container}>
        <Card style={{ marginBottom: "30px" }}>
          <Descriptions title="商品信息变更审核状态">
            <Item label="">{statusMap[modifyDetail.status]}</Item>
          </Descriptions>
          <Descriptions title="申请单信息" column={2}>
            <Item label="商品名称" span={2}>{modifyDetail.goodsName}</Item>
            <Item label="申请时间">{formatTime(modifyDetail.createTime)}</Item>
            <Item label="处理时间">{formatTime(modifyDetail.approveTime)}</Item>
            <Item label="变更类型">信息变更</Item>
            <Item label="申请原因">{modifyDetail.applyReason}</Item>
            <Item label="审核意见" span={2}>{modifyDetail.rejectReason}</Item>

          </Descriptions>
        </Card>

        <Card>
          <Descriptions title="以下为修改内容" />
          <Row gutter={24}>
            {beforeContent && <Col span={12}>
              <p className={styles.edit_info_title} style={{ marginBottom: "10px" }}>编辑前信息</p>
              {/*{beforeContent.cargoType &&<Row gutter={16} style={{paddingTop: 16}}>*/}
              {/*  <Col style={{textAlign: "right", lineHeight: "24px"}} span={4}>货品类型：</Col>*/}
              {/*  <Col style={{lineHeight: "24px"}} span={20}>*/}
              {/*    <div>jofrldfmkksld;kf解放的看法of基础课佛为了疯狂从了我我破发次哦恶婆婆分呕吐覅欧派会发生纠纷环境啥的纪委书记都爱囧闻</div>*/}
              {/*  </Col>*/}
              {/*</Row>}*/}
              {beforeContent.cargoType && <p>
                <span>货品类型：</span>
                <span>{beforeContent.cargoType.map((item, i) => `${item}、`)}</span>
              </p>}
              {beforeContent.name && <p><span>商品名称：</span><span>{beforeContent.name}</span></p>}
              {beforeContent.categoryName && <p><span>商品类目：</span><span>{beforeContent.categoryName}</span></p>}
              {beforeContent.desc && <p><span>商品描述：</span><span>{beforeContent.desc}</span></p>}

              {(beforeContent.brandName ||
                beforeContent.taxRate ||
                beforeContent.unit ||
                beforeContent.originPlace ||
                beforeContent.packUnit ||
                beforeContent.goodsNo ||
                beforeContent.upc ||
                (!!beforeContent.propertyList && !!beforeContent.propertyList.length)) && <p>
                  <span>商品属性：</span>
                  <span>
                    <table border='1' className={styles.content_table}>
                      {!!beforeContent.brandName && <tr><td className={styles.property_name}>商品品牌</td><td>{`${beforeContent.brandName} `}</td> </tr>}
                      {!!beforeContent.taxRate && <tr><td className={styles.property_name}>发票类型</td><td>{`${beforeContent.taxRate} `}</td> </tr>}
                      {!!beforeContent.unit && <tr><td className={styles.property_name}>商品单位</td><td>{`${beforeContent.unit} `}</td> </tr>}
                      {!!beforeContent.originPlace && <tr><td className={styles.property_name}> 原产地</td><td>{`${beforeContent.originPlace} `}</td> </tr>}
                      {!!beforeContent.packUnit && <tr><td className={styles.property_name}>箱规</td><td>{`${beforeContent.packUnit} `}</td> </tr>}
                      {!!beforeContent.goodsNo && <tr><td className={styles.property_name}>商品货号</td><td>{`${beforeContent.goodsNo} `}</td> </tr>}
                      {!!beforeContent.upc && <tr> <td className={styles.property_name}>商品条码</td><td>{`${beforeContent.upc} `}</td> </tr>}
                      {!!beforeContent.propertyList && !!beforeContent.propertyList.length &&
                        beforeContent.propertyList.map(item =>
                          <tr><td className={styles.property_name}>{`${item.propertyName}`}</td><td>{`${item.propertyValue}`}</td> </tr>)}
                    </table>
                  </span>
                </p>}


              {beforeContent.salesType &&
                <p><span>报价方式：</span><span>{infoSaleTypeMap[beforeContent.salesType]}</span></p>}

              {/* 按商品数量报价时，显示阶梯价格table 和 现货库存table */}
              {beforeContent.stairList && !!beforeContent.stairList.length && <p>
                <span>阶梯价：</span>
                <table border='1' className={styles.content_table}>
                  <thead>
                    <tr>
                      <th style={{ width: 100 }}>购买数量</th>
                      <th style={{ width: 120 }}>供应价（元/个）</th>
                      <th style={{ width: 120 }}>销售价（元/个）</th>
                    </tr>
                  </thead>
                  <tbody>
                    {beforeContent.stairList.map((item, i, arr) =>
                      <tr key={i}>
                        <td>{i === arr.length - 1 ? `≥ ${item.minNum}` : `${item.minNum} - ${item.maxNum}`}</td>
                        <td>{showMoney(item.supplyPrice)}</td>
                        <td>{showMoney(item.salePrice)}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </p>}
              {beforeContent.salesType === 1 && beforeContent.skuList && !!beforeContent.skuList.length && <p>
                <span>现货库存：</span>
                <table border='1' className={styles.content_table}>
                  <thead>
                    <tr>
                      {/* 属性规格-列 */}
                      {[...Object.entries(beforeContent.skuList[0].attribute)].map(([key, val]) => <th
                        key={key}>{key}</th>)}
                      <th>型号</th>
                      <th>库存</th>
                    </tr>
                  </thead>
                  <tbody>
                    {beforeContent.skuList.map((item, index) =>
                      <tr key={index}>
                        {/* 属性规格-列 */}
                        {[...Object.entries(item.attribute)].map(([key, val]) => <th key={key}>{val}</th>)}
                        <td>{item.goodsNo}</td>
                        <td>{item.stock}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </p>}

              {/* 按商品规格报价时，显示不同规格报价 和 现货库存总数 */}
              {beforeContent.salesType === 2 && beforeContent.skuList && !!beforeContent.skuList.length && <p>
                <span>报价：</span>
                <table border='1' className={styles.content_table}>
                  <thead>
                    <tr>
                      {/* 属性规格-列 */}
                      {[...Object.entries(beforeContent.skuList[0].attribute)].map(([key, val]) => <th
                        key={key}>{key}</th>)}
                      <th>型号</th>
                      <th>供应价</th>
                      <th>成本价</th>
                      <th>市场价</th>
                      <th>库存</th>
                    </tr>
                  </thead>
                  <tbody>
                    {beforeContent.skuList.map((item, index) =>
                      <tr key={index}>
                        {/* 属性规格-列 */}
                        {[...Object.entries(item.attribute)].map(([key, val]) => <th key={key}>{val}</th>)}
                        <td>{item.goodsNo}</td>
                        <td>{showMoney(item.supplyPrice)}</td>
                        <td>{showMoney(item.costPrice)}</td>
                        <td>{showMoney(item.marketPrice)}</td>
                        <td>{item.stock}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </p>}
              {beforeContent.stock && <p><span>现货库存总数：</span><span>{beforeContent.stock}</span></p>}

              {beforeContent.marketPrice &&
                <p><span>市场价：</span><span>{showMoney(beforeContent.marketPrice)}元</span></p>}
              {beforeContent.costPrice &&
                <p><span>成本价：</span><span>{showMoney(beforeContent.costPrice)}元</span></p>}
              {beforeContent.supplyPrice &&
                <p><span>供应价：</span><span>{showMoney(beforeContent.supplyPrice)}元</span></p>}
              {beforeContent.salePrice &&
                <p><span>销售价：</span><span>{showMoney(beforeContent.salePrice)}元</span></p>}
              {beforeContent.minBuyNum &&
                <p><span>起批量：</span><span>{beforeContent.minBuyNum}</span></p>}
              {beforeContent.freightTemplateName &&
                <p><span>运费模板：</span><span>{beforeContent.freightTemplateName}</span></p>}
              {(beforeContent.weight === 0 || beforeContent.weight) &&
                <p><span>单位重量：</span><span>{beforeContent.weight / 1e6}kg</span></p>}
              {beforeContent.imageList && !!beforeContent.imageList.length && <p>
                <span>商品图：</span>
                <span>
                  {beforeContent.imageList.map((item, i) =>
                    <img src={item} style={{
                      width: 80,
                      height: 80,
                      background: 'rgba(215, 215, 215, 1)',
                      marginRight: 4
                    }} alt="" />
                  )}
                </span>
              </p>}
              {beforeContent.detail && <p>
                <span>商品详情：</span>
                <span dangerouslySetInnerHTML={{ __html: beforeContent.detail }}></span>
              </p>}
            </Col>}
            {afterContent && <Col span={12}>
              <p className={styles.edit_info_title} style={{ marginBottom: "10px" }}>编辑后信息</p>
              {/*<Row gutter={16} style={{paddingTop: 16}}>*/}
              {/*  <Col style={{textAlign: "right", lineHeight: "24px"}} span={4}>商品名称：</Col>*/}
              {/*  <Col style={{lineHeight: "24px"}} span={20}>*/}
              {/*    <div>jofrldfmkksld;kf解放的看法of基础课佛为了疯狂从了我我破发次哦恶婆婆分呕吐覅欧派会发生纠纷环境啥的纪委书记都爱囧闻</div>*/}
              {/*  </Col>*/}
              {/*</Row>*/}
              {afterContent.cargoType && <p>
                <span>货品类型：</span>
                <span>{afterContent.cargoType.map((item, i) => `${item}、`)}</span>
              </p>}
              {afterContent.name && <p><span>商品名称：</span><span>{afterContent.name}</span></p>}
              {afterContent.categoryName && <p><span>商品类目：</span><span>{afterContent.categoryName}</span></p>}
              {afterContent.desc && <p><span>商品描述：</span><span>{afterContent.desc}</span></p>}

              {(afterContent.brandName ||
                afterContent.taxRate ||
                afterContent.unit ||
                afterContent.originPlace ||
                afterContent.packUnit ||
                afterContent.goodsNo ||
                afterContent.upc ||
                (!!afterContent.propertyList && !!afterContent.propertyList.length)) && <p>
                  <span>商品属性：</span>
                  <span>
                    <table border='1' className={styles.content_table}>
                      {!!afterContent.brandName && <tr> <td className={styles.property_name}>商品品牌</td><td>{`${afterContent.brandName} `}</td></tr>}
                      {!!afterContent.taxRate && <tr><td className={styles.property_name}>发票类型</td><td>{`${afterContent.taxRate} `}</td></tr>}
                      {!!afterContent.unit && <tr> <td className={styles.property_name}>商品单位</td><td>{`${afterContent.unit} `}</td></tr>}
                      {!!afterContent.originPlace && <tr><td className={styles.property_name}> 原产地</td><td>{`${afterContent.originPlace} `}</td></tr>}
                      {!!afterContent.packUnit && <tr><td className={styles.property_name}>箱规</td><td>{`${afterContent.packUnit} `}</td></tr>}
                      {!!afterContent.goodsNo && <tr><td className={styles.property_name}>商品货号</td><td>{`${afterContent.goodsNo} `}</td></tr>}
                      {!!afterContent.upc && <tr><td className={styles.property_name}>商品条码</td><td>{`${afterContent.upc} `}</td></tr>}
                      {!!afterContent.propertyList && !!afterContent.propertyList.length &&
                        afterContent.propertyList.map(item =>
                          <tr><td className={styles.property_name}>{`${item.propertyName}`}</td><td>{`${item.propertyValue}`}</td></tr>)}
                    </table>
                  </span>
                </p>}

              {afterContent.salesType &&
                <p><span>报价方式：</span><span>{infoSaleTypeMap[afterContent.salesType]}</span></p>}

              {/* 按商品数量报价时，显示阶梯价格table 和 现货库存table */}
              {afterContent.stairList && !!afterContent.stairList.length && <p>
                <span>阶梯价：</span>
                <table border='1' className={styles.content_table}>
                  <thead>
                    <tr>
                      <th style={{ width: 100 }}>购买数量</th>
                      <th style={{ width: 120 }}>供应价（元/个）</th>
                      <th style={{ width: 120 }}>销售价（元/个）</th>
                    </tr>
                  </thead>
                  <tbody>
                    {afterContent.stairList.map((item, i, arr) =>
                      <tr key={i}>
                        <td>{i === arr.length - 1 ? `≥ ${item.minNum}` : `${item.minNum} - ${item.maxNum}`}</td>
                        <td>{showMoney(item.supplyPrice)}</td>
                        <td>{showMoney(item.salePrice)}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </p>}
              {afterContent.salesType === 1 && afterContent.skuList && !!afterContent.skuList.length && <p>
                <span>现货库存：</span>
                <table border='1' className={styles.content_table}>
                  <thead>
                    <tr>
                      {/* 属性规格-列 */}
                      {[...Object.entries(afterContent.skuList[0].attribute)].map(([key, val]) => <th
                        key={key}>{key}</th>)}
                      <th>型号</th>
                      <th>库存</th>

                    </tr>
                  </thead>
                  <tbody>
                    {afterContent.skuList.map((item, index) =>
                      <tr key={index}>
                        {/* 属性规格-列 */}
                        {[...Object.entries(item.attribute)].map(([key, val]) => <th key={key}>{val}</th>)}
                        <td>{item.goodsNo}</td>
                        <td>{item.stock}</td>

                      </tr>
                    )}
                  </tbody>
                </table>
              </p>}

              {/* 按商品规格报价时，显示不同规格报价 和 现货库存总数 */}
              {afterContent.salesType === 2 && afterContent.skuList && !!afterContent.skuList.length && <p>
                <span>报价：</span>
                <table border='1' className={styles.content_table}>
                  <thead>
                    <tr>
                      {/* 属性规格-列 */}
                      {[...Object.entries(afterContent.skuList[0].attribute)].map(([key, val]) => <th
                        key={key}>{key}</th>)}
                      <th>型号</th>
                      <th>供应价</th>
                      <th>成本价</th>
                      <th>市场价</th>
                      <th>库存</th>

                    </tr>
                  </thead>
                  <tbody>
                    {afterContent.skuList.map((item, index) =>
                      <tr key={index}>
                        {/* 属性规格-列 */}
                        {[...Object.entries(item.attribute)].map(([key, val]) => <th key={key}>{val}</th>)}
                        <td>{item.goodsNo}</td>
                        <td>{showMoney(item.supplyPrice)}</td>
                        <td>{showMoney(item.costPrice)}</td>
                        <td>{showMoney(item.marketPrice)}</td>
                        <td>{item.stock}</td>

                      </tr>
                    )}
                  </tbody>
                </table>
              </p>}
              {afterContent.stock && <p><span>现货库存总数：</span><span>{afterContent.stock}</span></p>}

              {afterContent.marketPrice &&
                <p><span>市场价：</span><span>{showMoney(afterContent.marketPrice)}元</span></p>}
              {afterContent.costPrice &&
                <p><span>成本价：</span><span>{showMoney(afterContent.costPrice)}元</span></p>}
              {afterContent.supplyPrice &&
                <p><span>供应价：</span><span>{showMoney(afterContent.supplyPrice)}元</span></p>}
              {afterContent.salePrice &&
                <p><span>销售价：</span><span>{showMoney(afterContent.salePrice)}元</span></p>}
              {afterContent.minBuyNum &&
                <p><span>起批量：</span><span>{afterContent.minBuyNum}</span></p>}
              {afterContent.freightType &&
                <p><span>运费设置：</span><span>{freightTypeMap[afterContent.freightType]}</span></p>}
              {!!afterContent.freightTemplateName &&
                <p><span>运费模板：</span><span>{afterContent.freightTemplateName}</span></p>}
              {(afterContent.weight === 0 || afterContent.weight) &&
                <p><span>单位重量：</span><span>{afterContent.weight / 1e6}kg</span></p>}
              {afterContent.imageList && !!afterContent.imageList.length && <p>
                <span>商品图：</span>
                <span>
                  {afterContent.imageList.map((item, i) =>
                    <img src={item} style={{
                      width: 80,
                      height: 80,
                      background: 'rgba(215, 215, 215, 1)',
                      marginRight: 4
                    }} alt="" />
                  )}
                </span>
              </p>}
              {afterContent.detail && <p>
                <span>商品详情：</span>
                <span dangerouslySetInnerHTML={{ __html: afterContent.detail }}></span>
                {/* <span>{afterContent.detail}</span> */}
              </p>}
            </Col>}
          </Row>
        </Card>
      </div>);
  }

}

export default GoodsUpdateApplyDetail;
