import React,{Component} from 'react'
import styles from './index.module.scss'

class Footer extends Component{
  constructor(props) {
    super(props);

  }

  render() {
    return(
      <div className={styles.footer_container}>
        <span className={styles.footer_text}>©2019 fxqifu.com. All rights reserved 杭州飞象企服网络技术有限公司</span>
        <span className={styles.footer_text}>电信与信息服务业务经营许可证：浙ICP证 B2-20170751 | 浙ICP备17028214号-2 | 浙公网安备33010602008661</span>
      </div>
    )
  }

}

export default Footer;
