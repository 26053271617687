import React, {useState, useEffect} from 'react'
import {Modal, Input, message} from "antd";
import PropTypes from 'prop-types'
import {withRouter} from 'react-router-dom'
import {DATA_CODE} from "config";

import stores from 'stores'

import styles from './index.module.scss'

const {goodsOwnStore,goodsModifyStore, localStore} = stores

function Modify(props) {
  const {item, visible, onOk, onCancel} = props
  const [modifyType, setModifyType] = useState(1)
  const [modifyReason, setModifyReason] = useState("")


  const confirm = async () => {
    if ((modifyType === 1 || modifyType === 3) && !modifyReason) {
      message.error("请输入变更原因！")
      return
    }
    localStore.setGoodsModifyReason(modifyReason)
    let res = null
    switch (modifyType) {
      case 1://变更交易信息
        // TODO: 校验是否参加活动
        res = await goodsModifyStore.checkIsInActivity({
          goodsId: item.goodsId
        })
        if (res.success) {
          window.open(`/goods/edit?type=modify&modifyType=deal&id=${item.goodsId}`, "_self")
        } else if (res.code === DATA_CODE.GOODS_CHANGE_PERMIT_BY_ACTIVITY) {
          const textStyle = {
            textAlign: "center",
            fontSize: "16px",
            lineHeight: "32px"
          }
          Modal.info({
            icon: false,
            centered: true,
            zIndex: 10001,
            content: (<>
              <div style={textStyle}>该商品正在参加活动：</div>
              <div style={{textAlign: "center"}}>
                {res.message}
              </div>
              <div style={textStyle}>请先退出活动 再修改！</div>
            </>),
            okText: "确定",
            onOk() {
            },
          });
        }
        // props.history.push({pathname: `/goods/add?type=modify&id=${item.goodsId}`})
        break;
      case 2://变更基础信息
        window.open( `/goods/edit?type=modify&modifyType=basic&id=${item.goodsId}`,"_self")
        break;
      case 3://商品下架
        let params = {
          applyReason: modifyReason,
          changeType: 3,
          goodsId: item.goodsId,
          goodsParams: {}
        }
        res = await goodsModifyStore.requestModify(params)
        if (res.success) {
          message.success("提交成功！")
          onCancel(true)
        } else if (res.code === DATA_CODE.GOODS_CHANGE_PERMIT_BY_ACTIVITY) {
          const textStyle = {
            textAlign: "center",
            fontSize: "16px",
            lineHeight: "32px"
          }
          Modal.info({
            icon: false,
            centered: true,
            zIndex: 10001,
            content: (<>
              <div style={textStyle}>该商品正在参加活动：</div>
              <div style={{textAlign: "center"}}>
                {res.message}
              </div>
              <div style={textStyle}>请先退出活动 再修改！</div>
            </>),
            okText: "确定",
            onOk() {
            },
          });
        }
        break;
    }
  }

  return <Modal visible={visible}
                title="选择变更类型"
                onOk={confirm}
                onCancel={onCancel} width={700}>
    <div className={styles.modal_item}>
      <p className={styles.item_title}>请选择变更类型，部分类型需提交变更申请，平台审核通过后将生效。</p>
      <div className={styles.item_content}>
        <div className={`${styles.modify_type} ${modifyType === 1 ? styles.modify_type_active : ''}`}
             onClick={() => setModifyType(1)}>
          <p className={styles.modify_type_title}>变更交易信息</p>
          <p className={styles.modify_type_content}>如修改商品库存、价格、运费等信息。</p>
        </div>
        <div className={`${styles.modify_type} ${modifyType === 2 ? styles.modify_type_active : ''}`}
             onClick={() => setModifyType(2)}>
          <p className={styles.modify_type_title}>变更基础信息</p>
          <p className={styles.modify_type_content}>如修改商品名称、商品主图、商品详情图文介绍。</p>
        </div>
        <div className={`${styles.modify_type} ${modifyType === 3 ? styles.modify_type_active : ''}`}
             onClick={() => setModifyType(3)}>
          <p className={styles.modify_type_title}>商品下架</p>
          <p className={styles.modify_type_content}>选择将商品下架，将不再展示在平台销售。</p>
        </div>

      </div>
    </div>
    <div className={styles.modal_item}>
      {modifyType === 2 ?
        <p className={styles.item_title}>变更商品基础信息，无需平台审核，点击确定后进入商品编辑页，保存即变更</p> :
        <>
          <p className={styles.item_title}>填写变更原因</p>
          <div className={styles.item_content}>
            <Input.TextArea placeholder="请描述变更原因，120个字符以内。"
                            maxLength={120}
                            style={{height: 100}}
                            value={modifyReason}
                            onChange={e => setModifyReason(e.target.value.trim())}/>
          </div>
        </>}
    </div>
  </Modal>
}

Modify.propTypes = {
  visible: PropTypes.bool.isRequired,
  onOk: PropTypes.func,
  onCancel: PropTypes.func,
}

export default withRouter(Modify)












