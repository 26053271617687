import { observable, action } from "mobx";
import httpClient from "utils/httpClient";
import api from "config/api"
import { addKey } from "utils/tools";

const { goods } = api;

class GoodsOwnStore {
  @observable brandList = []
  @observable goodsEditInfo = {}
  @observable modifyList = { list: [], total: 0 }
  @observable modifyDetail = {}
  @observable goodsListTimeout = null
  @observable goodsList = { total: 0, list: [] }


  /**
   * 获取品牌列表
   * @param params
   * @returns {Promise<void>}
   */
  @action
  getBrandList = async params => {
    const res = await httpClient.post(goods.POST_GET_BRAND_LIST, params)
    if (res.success) {
      this.brandList = res.data.list
    }
  }

  /**
   * 通过id获取品牌信息
   * @param params
   * @returns {Promise<void>}
   */
  @action
  getBrandInfoById = async params => {
    const res = await httpClient.post(goods.POST_GET_BRAND_INFO_BY_ID, params)
    if (res.success) {
      this.brandList = [res.data]
    }
  }

  /**
   * 商品录入
   * @param params
   * @returns {Promise<AxiosResponse<any>>}
   */
  @action
  postAddGoods = async params => {
    const res = await httpClient.post(goods.POST_ADD_GOODS, params)
    return res
  }

  /**
   * 商品编辑
   * @param params
   * @returns {Promise<AxiosResponse<any>>}
   */
  @action
  postUpdateGoods = async params => {
    const res = await httpClient.post(goods.POST_UPDATE_GOODS, params)
    return res
  }

  /**
   * 获取商品编辑信息
   * @param params
   * @returns {Promise<{}>}
   */
  @action
  getEditInfo = async params => {
    const res = await httpClient.post(goods.POST_GET_EDIT_INFO, params);
    if (res.success) {
      this.goodsEditInfo = res.data
    }
    return this.goodsEditInfo
  }

  /**
   * 获取商品列表
   * @param params
   * @returns {Promise<T>}
   */
  @action
  getGoodsList = async params => {
    /**
     * 2020-12-29
     * 此处可能因为产生连续请求接口，
     * 数据量差异导致先请求的后返回，
     * 最后页面拿到的展示数据错误，
     * 使用节流方案改造原本的请求方式，
     * 但是页面上会产生500ms的数据延迟，暂时权宜之计
     * 
     *  */  
    if (this.goodsListTimeout) {
      clearTimeout(this.goodsListTimeout)
    }
    return new Promise((resolve) => {
      this.goodsListTimeout = setTimeout(async () => {
        const res = await httpClient.post(goods.GET_GOODS_LIST, params)
        // this.loadingGoodsList = false;
        if (res.success) {
          // return addKey(res.data)
          resolve(addKey(res.data))
        }
      }, 500)
    })

    // if (!this.loadingGoodsList) {
    //   this.loadingGoodsList = true;

    // }

  }

  /**
   * 下架商品
   * @param params
   * @returns {Promise<AxiosResponse<any>>}
   */
  @action
  downGoods = async params => {
    const res = await httpClient.post(goods.POST_DOWN_GOODS, params)
    return res
  }

  /**
   * 上架商品
   * @param params
   * @returns {Promise<AxiosResponse<any>>}
   */
  @action
  upGoods = async params => {
    const res = await httpClient.post(goods.POST_UP_GOODS, params)
    return res
  }

  /**
   * 删除商品
   * @param params
   * @returns {Promise<AxiosResponse<any>>}
   */
  @action
  deleteGoods = async params => {
    const res = await httpClient.post(goods.POST_DEL_GOODS, params)
    return res
  }

  /**
   * 商品变更列表
   * @param params
   * @returns {Promise<{total: number, list: []}>}
   */
  @action
  postModifyApplyList = async (params) => {
    const res = await httpClient.post(goods.POST_MODIFY_APPLY_LIST, params)
    if (res.success) {
      this.modifyList = addKey(res.data);
    }
    return this.modifyList
  }

  /**
   * 商品变更详情
   * @param params
   * @returns {Promise<void>}
   */
  @action
  postModifyApplyDetail = async (params) => {
    const res = await httpClient.post(goods.POST_MODIFY_APPLY_DETAIL, params)
    if (res.success) {
      this.modifyDetail = res.data;
    }
    return this.modifyDetail
  }

  /**
   * 商品变更申请
   * @param params
   * @returns {Promise<void>}
   */
  @action
  postModifyApply = async (params) => {
    const res = await httpClient.post(goods.POST_MODIFY_APPLY, params)
    return res;
  }

  /**
   * 判断商品是否参加活动
   * @param params
   * @returns {Promise<AxiosResponse<any>>}
   */
  @action
  checkGoodsInActivity = async params => {
    const res = await httpClient.post(goods.POST_CHECK_GOODS_IN_ACTIVITY, params)
    return res
  }


}

export default new GoodsOwnStore();
