import React, {useEffect, useState} from 'react';
import {Modal, Form, Input, InputNumber, Radio, Space, Select, message} from "antd";
import PropTypes from 'prop-types'
import styles from "./index.module.scss"
import stores from 'stores'

const {orderServiceStore, commonStore} = stores
const {Item} = Form;
const {Option} = Select
const typeMap = new Map([
  [0, "发货前退款"],
  [1, "我要退货退款"],
  [2, "仅退款（不退货）"],
  [3, "我要换货"],
])

const labelCol = {span: 6}
const wrapperCol = {span: 14}


const getFormLabel = str => <span style={{display: "inline-block", width: 100, textAlign: "right"}}>{str}</span>

// 0-发货前退款 1-我要退货退款 2-仅退款（不退货） 3-我要换货
function DealService(props) {
  const {visible, onOk, onCancel, info} = props

  const [form] = Form.useForm()


  const [isExpress, setIsExpress] = useState(0)
  useEffect(() => {
    if (+info.type === 0) {
      form.setFieldsValue({
        num: info.refundOrderGoodsBo && info.refundOrderGoodsBo[0].applyReturnQuantity
      })
    }
  }, [info])

  const confirm = () => {
    form.validateFields().then(async values => {
      let params = {
        id: info.id,
        auditStatus: 2,
        realReturnQuantity: values.num,
        isRefundExpressCost: !!+values.isExpress,
        refundExpressCost: values.expressMoney ? values.expressMoney * 1e2 : undefined,
        auditReason: values.reason
      }
      let res = await orderServiceStore.refundVerify(params)
      if (res.success) {
        message.success("已同意")
        setTimeout(() => {
          onCancel(true)
        }, 1000)
      }
    })
    // onCancel();
  }
  const changeExpress = (e) => {
    console.log(e.target.value)
    setIsExpress(e.target.value)
  }
  const getForm = () => {
    return +info.type === 3 ?
      <>
        <Item label={"换货商品件数"} name="num" required rules={[{
          required: true, message: "请输入换货商品数"
        }]} labelCol={labelCol} wrapperCol={wrapperCol}>
          <InputNumber style={{width: 200}}
                       placeholder="不大于售后数量"
                       min={1}
                       max={info.refundOrderGoodsBo && info.refundOrderGoodsBo[0].applyReturnQuantity}
                       precision={0} step={1}/>
        </Item>
      </> :
      <>
        <Item label="退货数量" name="num" labelAlign="right" labelCol={labelCol} rules={[{
          required: true, message: "请输入退货数量"
        }]}>
          <InputNumber min={1}
                       disabled={+info.type === 0}
                       style={{width: 200}}
                       placeholder="不大于售后数量"
                       step={1}
                       max={info.refundOrderGoodsBo && info.refundOrderGoodsBo[0].applyReturnQuantity}
                       precision={0}/>
        </Item>

        <Item label="是否退运费" name="isExpress" required initialValue="0" labelAlign="right" labelCol={labelCol}>
          <Radio.Group onChange={changeExpress}>
            <Radio value="1">是</Radio>
            <Radio value="0">否</Radio>
          </Radio.Group>
        </Item>
        {+isExpress === 1 &&
        <Item label="退还运费金额" name="expressMoney"
              labelAlign="right" labelCol={labelCol} rules={[{required: +isExpress === 1, message: "请输入退还运费金额"}]}>
          <InputNumber min={0}
                       max={info.orderExpressCost / 100}
                       precision={2}
                       step={0.1}
                       style={{width: 200}}/>
        </Item>}

      </>
  }

  return (
    <Modal visible={visible} okText="确认处理" centered onCancel={onCancel} onOk={confirm}>
      <Form form={form} labelAlign="right" labelCol={labelCol} wrapperCol={wrapperCol}>
        {getForm()}
        <Item label={"处理备注"} name="reason" required labelAlign="right" labelCol={labelCol}
              wrapperCol={wrapperCol} rules={[{
          required: true, message: "请输入备注信息"
        }]}>
          <Input.TextArea maxLength={50} showCount style={{height: 100}}/>
        </Item>
      </Form>
    </Modal>
  );
}

DealService.propTypes = {
  visible: PropTypes.bool.isRequired,
  onOk: PropTypes.func,
  onCancel: PropTypes.func,
  info: PropTypes.object
}


export default DealService;
