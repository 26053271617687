import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Modal, Form, Input, Space, Button} from "antd";

const {Item} = Form

const ChangePhone = props => {
  const {visible, onCancel} = props
  const [formPhone] = Form.useForm()
  const [smsText, setSmsText] = useState("获取验证码")
  const [canSendSms, setCanSendSms] = useState(true)
  let smsCodeInterval = null;

  const validatePhone = () => {
    // TODO:校验是否填写手机号
    return true
  }
  const sendSms = () => {
    if (validatePhone()) {
      if (canSendSms) {
        setCanSendSms(false);
        let count = 10;
        setSmsText(`${count}s后重新获取`);
        smsCodeInterval = setInterval(() => {
          if (count > 0) {
            setSmsText(`${--count}s后重新获取`);
          } else {
            setSmsText(`获取验证码`);
            setCanSendSms(true);
            clearInterval(smsCodeInterval)
          }
        }, 1000)

        // TODO:请求接口获取短信
      }
    }

  }
  const confirm = () => {
    formPhone.validateFields().then(values => {
      // TODO:请求修改密码接口
    })
  }
  return (
    <Modal title="修改密码" centered width={400} visible={visible} onCancel={onCancel} onOk={confirm}>
      <Form form={formPhone} style={{paddingTop: 32}}>
        <Item name="phone"
              rules={[{required: true, message: "请输入安全手机号"},
                {pattern: /^1\d{10}$/, message: "请输入正确的手机号"}]}>
          <Input allowClear style={{width: 350}} placeholder="11位手机号" maxLength={11}/>
        </Item>
        <Item name="smscode"
              rules={[{required: true, message: "请输入短信验证码"}]}>
          <Space size={20}>
            <Input allowClear style={{width: 180}} placeholder="请输入验证码"/>
            <Button style={{width: 150}}
                    type="primary"
                    disabled={!canSendSms}
                    onClick={sendSms}>{smsText}</Button>
          </Space>
        </Item>
      </Form>
    </Modal>
  );
};

ChangePhone.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func
};

export default ChangePhone;
