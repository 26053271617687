import React, {useState, useEffect} from 'react';
import {
  Tabs,
  Descriptions,
  Form,
  Button,
  Input,
  Row,
  Col,
  Upload,
  Space,
  message,
} from 'antd';
import {LoadingOutlined, PlusOutlined} from '@ant-design/icons';
import stores from 'stores';
import {PreviewPic} from 'components';
import styles from './index.module.scss';
import {checkFile, getBase64, getQuery, showMoney} from 'utils/tools';

const {storeInfoStore, commonStore} = stores;

const {TabPane} = Tabs;
const {Item} = Descriptions;

function Info(props) {
  const [tab, setTab] = useState('info'); //info | set

  useEffect(() => {
    let query = getQuery();
    if (query.tab) {
      setTab(query.tab);
    }
  }, []);
  return (
    <div style={{paddingLeft: 24}}>
      <Tabs activeKey={tab} onChange={(key) => setTab(key)}>
        <TabPane tab="商铺信息" key="info">
          {tab === 'info' && <StoreInfo/>}
        </TabPane>
        <TabPane tab="商铺设置" key="set">
          {tab === 'set' && <StoreSet/>}
        </TabPane>
      </Tabs>
    </div>
  );
}

function StoreInfo(props) {
  const getTitle = (str) => <div className={styles.des_item_label}>{str}</div>;

  const [info, setInfo] = useState({});
  const [prevPic, setPrevPic] = useState({show: false, pic: ''});

  useEffect(() => {
    _getData();
  }, []);

  const _getData = async () => {
    const data = await storeInfoStore.getStoreInfo({});
    setInfo(data);
  };

  return (
    <>
      <Descriptions>
        <Item span={3} label={getTitle('商铺名称')}>
          {info.name}
        </Item>
        <Item span={3} label={getTitle('商铺类型')}>
          {info.shopTypeName}
        </Item>
        <Item span={3} label={getTitle('主营类目')}>
          {info.categoryName}
        </Item>
        <Item span={3} label={getTitle('主营品牌')}>
          {info.mainBrandName}
        </Item>
        <Item span={3} label={getTitle('店铺保证金(元)')}>
          ¥{showMoney(info.deposit)}
        </Item>
        <Item span={3} label={getTitle('技术服务费')}>
          {info.technicalServiceFee}
        </Item>
        <Item span={3} label={getTitle('订单佣金费')}>
          {info.orderCommission / 100}%
        </Item>
        {info.qualificationProof && (
          <Item span={3} label={getTitle('特殊经营类目资质证明')}>
            {info.qualificationProof.split(',').map((item, index) => (
              <img
                src={item}
                className={styles.info_pic}
                onClick={() => setPrevPic({show: true, pic: item})}
              />
            ))}
          </Item>
        )}
      </Descriptions>
      <PreviewPic
        visible={prevPic.show}
        pic={prevPic.pic}
        onClose={() => setPrevPic({show: false, pic: ''})}
      />
    </>
  );
}

function StoreSet(props) {
  const [form] = Form.useForm();
  const [storeId, setStoreId] = useState();
  const [logoImg, setLogoImg] = useState([]);
  const [bgImg, setBgImg] = useState([]);
  const [prevPic, setPrevPic] = useState({show: false, pic: ''});
  const [refresh, setRefresh] = useState(true);
  useEffect(() => {
    refresh && setTimeout(() => setRefresh(false));
  }, [refresh]);

  useEffect(() => {
    _initInfo();
  }, []);

  const _initInfo = async () => {
    let data = await storeInfoStore.getStoreSettingInfo({});
    setStoreId(data.id);
    if (data.logo) {
      setLogoImg([
        {
          uid: 1,
          name: 'logo.png',
          response: {data: data.logo},
          url: data.logo,
        },
      ]);
    } else {
      setLogoImg([]);
    }
    if (data.backgroundImage) {
      setBgImg([
        {
          uid: 1,
          name: 'bg.png',
          response: {data: data.backgroundImage},
          url: data.backgroundImage,
        },
      ]);
    } else {
      setBgImg([]);
    }
    form.setFieldsValue({
      managerName: data.brandManagerName || undefined,
      managerTel: data.brandManagerPhone || undefined,
      storeExplain: data.shopDescription || undefined,
      sellingPoint: [
        {
          first: data.sellingPoint ?
            data.sellingPoint.split(',')[0] : undefined,
          last: data.sellingPoint ?
            data.sellingPoint.split(',')[1] : undefined,
        },
      ],
      deliverExplain: data.deliveryDescription || undefined,
      serviceExplain: data.serviceDescription || undefined,
    });
  };
  const formItemLayout = {
    labelCol: {span: 5},
    wrapperCol: {span: 14},
  };
  const textAreaStyle = {width: 500, height: 150};
  const normFile = (e) => {
    console.log('Upload event:', e);
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };
  const uploadButton = (
    <div>
      <PlusOutlined/>
      <div style={{marginTop: 8}}>Upload</div>
    </div>
  );

  /**
   * 上传图片
   * @param file
   * @param fileList
   * @param type
   * @returns {Promise<boolean|void>}
   */
  const uploadImg = async ({file, fileList}, type) => {
    if (type === 'logo') {
      if (checkFile(file)) {
        setLogoImg([file]);
        let params = new FormData();
        params.append('file', file);
        return new Promise((res, rej) => {
          res(commonStore.postUploadFile(params));
        }).then((res) => {
          if (res.success) {
            let obj = {
              lastModified: file.lastModified,
              originFileObj: file,
              uid: file.uid,
              name: file.name,
              type: file.type,
              size: file.size,
              response: res,
              url: res.data,
            };
            setLogoImg([obj]);
          }
        });
      } else {
        return false;
      }
    }
    if (type === 'bg') {
      if (checkFile(file)) {
        setBgImg([file]);
        let params = new FormData();
        params.append('file', file);
        return new Promise((res, rej) => {
          res(commonStore.postUploadFile(params));
        }).then((res) => {
          if (res.success) {
            let obj = {
              lastModified: file.lastModified,
              originFileObj: file,
              uid: file.uid,
              name: file.name,
              type: file.type,
              size: file.size,
              response: res,
              url: res.data,
            };
            setBgImg([obj]);
          }
        });
      } else {
        return false;
      }
    }
  };
  /**
   * 预览图片
   * @param file
   * @returns {Promise<void>}
   */
  const previewImg = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPrevPic({show: true, pic: file.url || file.preview});
  };

  const saveSetting = async (values) => {
    // console.log(values);
    let params = {
      id: storeId,
      logo: logoImg.length ? logoImg[0].response.data : undefined,
      backgroundImage: bgImg.length ? bgImg[0].response.data : undefined,
      brandManagerName: values.managerName.trim(),
      brandManagerPhone: values.managerTel.trim(),
      shopDescription: values.storeExplain,
      sellingPoint: [
        values.sellingPoint[0].first,
        values.sellingPoint[0].last,
      ].join(','),
      deliveryDescription: values.deliverExplain,
      serviceDescription: values.serviceExplain,
    };
    let res = await storeInfoStore.updateStoreInfo(params);
    if (res.success) {
      message.success('修改成功！');
    }
  };
  return (
    <>
      <Form
        form={form}
        {...formItemLayout}
        labelAlign="right"
        onFinish={saveSetting}
      >
        <Form.Item
          label="商铺logo"
          name="logo"
          // valuePropName="fileList"
          // getValueFromEvent={normFile}
        >
          <Upload
            listType="picture-card"
            fileList={logoImg}
            onRemove={() => setLogoImg([])}
            onPreview={previewImg}
            beforeUpload={(file) => false}
            onChange={({file, fileList, event}) => {
              if (fileList.length) {
                uploadImg({file, fileList}, 'logo');
              } else {
                setLogoImg([]);
              }
            }}
          >
            {!logoImg.length && uploadButton}
          </Upload>
        </Form.Item>
        <Form.Item label="商铺背景图" name="bg">
          <Upload
            listType="picture-card"
            fileList={bgImg}
            onRemove={() => setBgImg([])}
            onPreview={previewImg}
            beforeUpload={(file) => false}
            onChange={({file, fileList, event}) => {
              if (fileList.length) {
                uploadImg({file, fileList}, 'bg');
              } else {
                setBgImg([]);
              }
            }}>
            {!bgImg.length && uploadButton}
          </Upload>
        </Form.Item>
        <Form.Item label="品牌经理姓名" name="managerName" rules={[{required: true, message: '请输入品牌经理姓名!'}]}>
          <Input style={{width: 200}} allowClear/>
        </Form.Item>
        <Form.Item label="品牌经理电话" name="managerTel" rules={[{required: true, message: '请输入品牌经理电话!'}]}>
          <Input style={{width: 200}} allowClear/>
        </Form.Item>
        <Form.Item
          label="商铺说明"
          name="storeExplain"
          // rules={[{required: true, message: '请输入商铺说明!'}]}
        >
          <Input.TextArea
            maxLength={200}
            placeholder="限200个字"
            style={textAreaStyle}
          />
        </Form.Item>
        <Form.List name="sellingPoint">
          {(fields) => {
            fields[0] = {name: 0, key: 0, isListField: true, fieldKey: 0};
            // console.log("fields=====", fields)
            return (
              <Form.Item label="商铺卖点" required={false}>
                <Space>
                  <Form.Item
                    {...fields[0]}
                    name={[fields[0].name, 'first']}
                    fieldKey={[fields[0].fieldKey, 'first']}
                    validateTrigger={['onChange', 'onBlur']}
                    // rules={[{required: true, message: '请输入商铺卖点!'}]}
                  >
                    <Input
                      placeholder="限制6个字，如：全场包邮"
                      maxLength={6}
                      style={{width: 200}}
                    />
                  </Form.Item>
                  <Form.Item
                    {...fields[0]}
                    name={[fields[0].name, 'last']}
                    fieldKey={[fields[0].fieldKey, 'last']}
                    validateTrigger={['onChange', 'onBlur']}
                    // rules={[{required: true, message: '请输入商铺卖点!'}]}
                  >
                    <Input
                      placeholder="限制6个字，如：正品保证"
                      maxLength={6}
                      style={{width: 200}}/>
                  </Form.Item>
                </Space>
              </Form.Item>);
          }}
        </Form.List>
        <Form.Item label="发货说明" name="deliverExplain">
          <Input.TextArea
            maxLength={100}
            placeholder="限100个字"
            style={textAreaStyle}
          />
        </Form.Item>
        <Form.Item label="服务说明" name="serviceExplain">
          <Input.TextArea
            maxLength={100}
            placeholder="限100个字"
            style={textAreaStyle}/>
        </Form.Item>
        <Row>
          <Col offset={7}>
            <Button type="primary" htmlType="submit" style={{width: 150}}>
              保存
            </Button>
          </Col>
        </Row>
      </Form>
      <PreviewPic
        visible={prevPic.show}
        pic={prevPic.pic}
        onClose={() => setPrevPic({show: false, pic: ''})}/>
    </>
  );
}

export default Info;
