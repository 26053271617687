import React, {useEffect, useState} from 'react';
import {Modal, Form, Input} from "antd";
import PropTypes from 'prop-types'
import styles from "./index.module.scss"

// 0-发货前退款 1-我要退货退款 2-仅退款（不退货） 3-我要换货
function RefundService(props) {
  const {visible, onOk, onCancel} = props
  const [form] = Form.useForm();

  const confirm = () => {
    form.validateFields().then(value => {
      onCancel(value.reason)
    })
  }
  return (
    <Modal visible={visible}
           okText="确认驳回"
           title="填写驳回原因"
           centered
           onOk={confirm}
           onCancel={() => onCancel()}>
      <Form form={form}>
        <Form.Item name="reason" required rules={[{required: true, message: "请输入驳回原因"}]}>
          <Input.TextArea placeholder="120字以内" maxLength={120} style={{width: "100%", height: 150}}/>
        </Form.Item>
      </Form>
    </Modal>
  );
}

RefundService.propTypes = {
  visible: PropTypes.bool.isRequired,
  onOk: PropTypes.func,
  onCancel: PropTypes.func,
}


export default RefundService;
